import AuthService from "../AuthService";

const truckSupply = {
  getData() {
    return AuthService.get(`/api/truck-supply`).then((res) => res);
  },
  getDataById(id) {
    return AuthService.get(`/api/truck-supply/${id}`).then((res) => res);
  },
  save(data) {
    return AuthService.post("/api/truck-supply/save", data).then((res) => res);
  },
  update(data) {
    return AuthService.post(`/api/truck-supply/update/${data.id}`, data).then(
      (res) => res
    );
  },
  changeStatus(data) {
    return AuthService.post(`/api/truck-supply/change-status`, data).then(
      (res) => res
    );
  },
  delete(id) {
    return AuthService.post(`/api/truck-supply/delete/${id}`).then(
      (res) => res
    );
  },
  getMasterDataForSearch() {
    return AuthService.get(`/api/get-masterdata-trucksupply`).then(
      (res) => res
    );
  },
  exportExcel(item) {
    return AuthService.get(
      `/api/export-excel-accept-tender?item=${JSON.stringify(item)}`
    ).then((res) => res);
  },
  search(data) {
    return AuthService.get(
      `/api/truck-supply-search?page=${data.page}&driver_name=${data.driver_name}&truck_license_id=${data.truck_license_id}&trailer_license_id=${data.trailer_license_id}&carrier_id=${data.carrier_id}&expiration_date_driver=${data.expiration_date_driver}&expiration_date_truck=${data.expiration_date_truck}&expiration_date_trailer=${data.expiration_date_trailer}&province1=${data.province1}&province2=${data.province2}`
    ).then((res) => res);
  },
  getTruckSupplyActive() {
    return AuthService.get(`/api/truck-supply-active`).then((res) => res);
  },
};

export default truckSupply;
