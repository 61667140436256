import Service from "../../services";

const state = {};

const getters = {};

const actions = {
  save({ commit }, data) {
    return Service.cost_per_truck.save(data).then((res) => {
      return res;
    });
  },
  payroll({ commit }, data) {
    return Service.cost_per_truck.payroll(data).then((res) => {
      return res;
    });
  },
  getData({ commit }, mode) {
    return Service.cost_per_truck.getData(mode).then((res) => {
      return res;
    });
  },
  removeTrip({ commit }, data) {
    return Service.cost_per_truck.removeTrip(data).then((res) => {
      return res;
    });
  },
  setTrip({ commit }, data) {
    return Service.cost_per_truck.setTrip(data).then((res) => {
      return res;
    });
  },
  changeStatus({ commit }, data) {
    return Service.cost_per_truck.changeStatus(data).then((res) => {
      return res;
    });
  },
  getMasterData({ commit }) {
    return Service.cost_per_truck.getMasterData().then((res) => {
      return res;
    });
  },
  search({ commit }, data) {
    return Service.cost_per_truck.search(data).then((res) => {
      return res;
    });
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
