// import Service from "../../services";

const state = {
  activeMenu: true,
};

const getters = {};

const actions = {};

const mutations = {
  SET_ACTIVE_MENU(state, data) {
    state.activeMenu = data;
  },
  SET_MINI(state, val) {
    state.activeMenu = val;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
