import AuthService from "../AuthService";
import Service from "../Service";

const auth = {
  login(data) {
    return Service.post("/api/auth/login", data).then((res) => res);
  },
  logout() {
    return AuthService.get(`api/auth/logout`).then((res) => res);
  },
  register(data) {
    return AuthService.post(`api/auth/register`, data).then((res) => res);
  },
  changePassword(data) {
    return AuthService.post(`api/auth/change-password`, data).then(
      (res) => res
    );
  },
  forgotPassword(data) {
    return Service.post(`api/auth/forgot-password`, data).then((res) => res);
  },
  resetPassword(data) {
    return Service.post(`api/auth/reset-password`, data).then((res) => res);
  },
};

export default auth;
