<template>
  <div>
    <!-- <Sidebar />

    <v-app-bar app>
      <Navbar />
    </v-app-bar> -->

    <router-view />
  </div>
  <!-- <div>
    <Navbar />
    <v-layout style="margin-top: 1px; height: 100%;" class=" fill-height">
      <v-flex
        shrink
        style="display: block;background-color: #000; min-height: 100vh"
      >
        <Sidebar />
      </v-flex>

      <v-flex style="background-color: #fff">
        <v-main>
          {{ activeMenu }}
          <div class="content">
            <router-view />
          </div>
        </v-main>
      </v-flex>
    </v-layout>

    <div class="version-control">
      <span class=" font-weight-bold">Version:</span> 0.7
      <span class=" font-weight-bold">Release date:</span>
      30/09/2022
    </div>
  </div> -->
</template>

<script>
import Navbar from "./Navbar.vue";
import Sidebar from "./Sidebar";
export default {
  components: {
    // Sidebar,
    // Navbar,
  },
  data: () => ({
    cards: ["Today", "Yesterday"],
    drawer: null,
    links: [
      ["mdi-inbox-arrow-down", "Inbox"],
      ["mdi-send", "Send"],
      ["mdi-delete", "Trash"],
      ["mdi-alert-octagon", "Spam"],
    ],
  }),
  // get vuex menu state
  computed: {
    activeMenu() {
      return this.$store.state.menu.activeMenu;
    },
  },
};
</script>

<style lang="css">
.content {
  max-width: calc(100% - 40px);
}
</style>
