<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      :return-value.sync="time"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          @click:clear="onClearable"
          clearable
          :height="height"
          append-icon="mdi-timetable"
          readonly
          v-bind="attrs"
          v-on="on"
          :class="borderColor"
          :placeholder="placeholder"
          color="gray"
          :background-color="disabled ? '#E5E7E9' : '#fff'"
          outlined
          dense
          :value="setModel"
          :rules="required ? [(v) => !!v || 'field is required'] : []"
          :label="label"
          required
          :disabled="disabled"
          :hide-details="hideDetail"
        ></v-text-field>
      </template>
      <v-time-picker
        v-if="menu"
        v-model="time"
        full-width
        format="24hr"
        @click:minute="$refs.menu.save(time)"
        @input="selectTime"
      ></v-time-picker>
    </v-menu>
  </div>
</template>
<script>
export default {
  data() {
    return {
      time: null,
      menu: false,
    };
  },
  props: {
    model: {
      default: "",
    },
    label: {
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      default: "",
    },
    hideDetail: {
      type: Boolean,
    },
    min: {
      default: "",
    },
    placeholder: { default: "" },
    required: {
      type: Boolean,
      default: false,
    },
    height: {
      default: "37px",
    },
    borderColor: {
      default: "gray",
    },
  },
  computed: {
    setModel() {
      this.setValTime();
      return this.model;
    },
  },

  methods: {
    setValTime() {
      this.time = this.model ? this.model.substring(5, 0) : "";
    },
    selectTime(val) {
      this.menu_date = false;
      this.$emit("update-data", val);
    },
    onClearable() {
      this.time = null;
      this.selectTime("");
    },
  },
};
</script>
