import AuthService from "../AuthService";

const order = {
  getOrders(data) {
    return AuthService.get(`/api/order?page=${data.page}`).then((res) => {
      return res;
    });
  },
  saveOrder(data) {
    return AuthService.post("/api/order/save", data).then((res) => {
      return res;
    });
  },
  updateOrder(data) {
    return AuthService.post(`/api/order/update/${data.id}`, data).then(
      (res) => res
    );
  },
  deleteOrder(id) {
    return AuthService.post(`/api/order/delete/${id}`).then((res) => res);
  },
  duplicateOrder(id) {
    return AuthService.post(`/api/order/duplicate/${id}`).then((res) => res);
  },
  printOrder(data) {
    return AuthService.post(`/api/print-order`, data[0]).then((res) => res);
  },
  search(data) {
    return AuthService.get(
      `/api/order-search?page=${data.page}&order_id=${
        data.order_id
      }&importer_exporter_id=${
        data.importer_exporter_id
      }&load_and_unload_date_from=${
        data.load_and_unload_date_from
      }&load_and_unload_date_to=${data.load_and_unload_date_to}&customer_id=${
        data.customer_id
      }&booking_no=${data.booking_no}&create_update_order_from=${
        data.create_update_order_from
      }&create_update_order_to=${data.create_update_order_to}&inv_po=${
        data.inv_po
      }&wh_factory_id=${data.wh_factory_id}&status=${
        data.status
      }&cy_date_from=${data.cy_date_from}&cy_date_to=${
        data.cy_date_to
      }&cy_place_id=${data.cy_place_id}&mode=${JSON.stringify(data.mode)}`
    ).then((res) => res);
    // &is_import=${data.is_import}&is_export=${data.is_export}&is_empty=${data.is_empty}&is_laden=${data.is_laden}&is_drop_load=${data.is_drop_load}
  },
  getOrderById(id) {
    return AuthService.get(`/api/order/${id}`).then((res) => res);
  },
};

export default order;
