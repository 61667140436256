import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "@/plugins/vuetify"; //
import "./assets/css/font.css";
import "./assets/css/styles.css";
import VueSweetalert2 from "vue-sweetalert2";
import VueApexCharts from "vue-apexcharts";

// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";

Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

Vue.use(VueSweetalert2);
Vue.use(require("vue-moment"));
Vue.config.productionTip = false;

Vue.mixin({
  methods: {
    subStringTime(text) {
      if (text && text.length > 5) {
        return text.slice(0, 5);
      } else {
        return text;
      }
    },
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
