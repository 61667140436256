// export header table
export const headers_accept_tender = [
  {
    text: "No.",
    value: "no",
    align: "start",
    sortable: false,
    width: "50px",
    class: "bg-gray",
  },
  {
    text: "Order Driver",
    value: "job_no",
    align: "center",
    sortable: false,
    width: "120px",
    class: "bg-gray",
  },
  {
    text: "Driver",
    value: "driver_name",
    align: "start",
    sortable: false,
    width: "200px",
    class: "bg-gray",
  },
  {
    text: "ทะเบียนรถ",
    value: "truck_license_id",
    align: "start",
    sortable: false,
    width: "100px",
    class: "bg-gray",
  },
  {
    text: "CY Place/Release Port",
    value: "cy_place",
    align: "start",
    sortable: false,
    width: "200px",
    class: "bg-gray",
  },
  {
    text: "Qty",
    value: "qty",
    align: "center",
    sortable: false,
    width: "100px",
    class: "bg-gray",
  },
  {
    text: "Size Container",
    value: "container_size",
    align: "center",
    sortable: false,
    width: "100px",
    class: "bg-gray",
  },
  {
    text: "Iso Type",
    value: "isotype",
    align: "center",
    sortable: false,
    width: "100px",
    class: "bg-gray",
  },
  {
    text: "WH/Factory*1",
    value: "wh_factory",
    align: "start",
    sortable: false,
    width: "200px",
    class: "bg-gray",
  },
  {
    text: "Load/Unload Date*1",
    value: "load_unload_date",
    align: "center",
    sortable: false,
    width: "200px",
    class: "bg-gray",
  },
  {
    text: "Load/Unload Time*1",
    value: "load_unload_time",
    align: "center",
    sortable: false,
    width: "200px",
    class: "bg-gray",
    divider: true,
  },
  {
    text: "สถานะงาน",
    value: "status",
    align: "center",
    sortable: false,
    width: "150px",
    class: "bg-yellow",
  },
];

export const headers_receive_container = [
  {
    text: "No.",
    value: "no",
    align: "start",
    sortable: false,
    width: "50px",
    class: "bg-gray",
  },
  {
    text: "Order Driver",
    value: "job_no",
    align: "center",
    sortable: false,
    width: "120px",
    class: "bg-gray",
  },
  {
    text: "Driver",
    value: "driver_name",
    align: "start",
    sortable: false,
    width: "200px",
    class: "bg-gray",
  },
  {
    text: "ทะเบียนรถ",
    value: "truck_license_id",
    align: "start",
    sortable: false,
    width: "100px",
    class: "bg-gray",
  },
  {
    text: "CY Place/Release Port",
    value: "cy_place",
    align: "start",
    sortable: false,
    width: "200px",
    class: "bg-gray",
  },
  {
    text: "Qty",
    value: "qty",
    align: "center",
    sortable: false,
    width: "100px",
    class: "bg-gray",
  },
  {
    text: "Size Container",
    value: "container_size",
    align: "center",
    sortable: false,
    width: "100px",
    class: "bg-gray",
  },
  {
    text: "Iso Type",
    value: "isotype",
    align: "center",
    sortable: false,
    width: "100px",
    class: "bg-gray",
  },
  {
    text: "WH/Factory*1",
    value: "wh_factory",
    align: "start",
    sortable: false,
    width: "200px",
    class: "bg-gray",
  },
  {
    text: "Load/Unload Date*1",
    value: "load_unload_date",
    align: "center",
    sortable: false,
    width: "200px",
    class: "bg-gray",
  },
  {
    text: "Load/Unload Time*1",
    value: "load_unload_time",
    align: "center",
    sortable: false,
    width: "200px",
    class: "bg-gray",
    divider: true,
  },
  {
    text: "เบอร์ตู้",
    value: "pickup_container_no",
    align: "center",
    sortable: false,
    width: "150px",
    class: "bg-yellow",
    divider: true,
  },
  {
    text: "เบอร์ซีล",
    value: "seal_no",
    align: "center",
    sortable: false,
    width: "150px",
    class: "bg-yellow",
    divider: true,
  },
  {
    text: "ภาพท้ายตู้",
    value: "pickup_container_image",
    align: "center",
    sortable: false,
    width: "150px",
    class: "bg-yellow",
    divider: true,
  },
  {
    text: "น้ำหนักตู้",
    value: "cabinet_weight",
    align: "center",
    sortable: false,
    width: "150px",
    class: "bg-yellow",
    divider: true,
  },
  {
    text: "วันเวลาในการรับตู้",
    value: "is_pickup_success_datetime",
    align: "center",
    sortable: false,
    width: "150px",
    class: "bg-yellow",
    divider: true,
  },
];

export const headers_arrived = [
  {
    text: "Order Driver",
    value: "job_no",
    align: "center",
    sortable: false,
    width: "120px",
    class: "bg-gray",
  },
  {
    text: "Driver",
    value: "driver_name",
    align: "start",
    sortable: false,
    width: "200px",
    class: "bg-gray",
  },
  {
    text: "ทะเบียนรถ",
    value: "truck_license_id",
    align: "start",
    sortable: false,
    width: "100px",
    class: "bg-gray",
  },
  {
    text: "CY Place/Release Port",
    value: "cy_place",
    align: "start",
    sortable: false,
    width: "200px",
    class: "bg-gray",
  },
  {
    text: "Qty",
    value: "qty",
    align: "center",
    sortable: false,
    width: "100px",
    class: "bg-gray",
  },
  {
    text: "Size Container",
    value: "container_size",
    align: "center",
    sortable: false,
    width: "100px",
    class: "bg-gray",
  },
  {
    text: "Iso Type",
    value: "isotype",
    align: "center",
    sortable: false,
    width: "100px",
    class: "bg-gray",
  },
  {
    text: "Container No.",
    value: "pickup_container_no",
    align: "center",
    sortable: false,
    width: "100px",
    class: "bg-gray",
  },
  {
    text: "Receive Container",
    value: "is_pickup_success_datetime",
    align: "center",
    sortable: false,
    width: "200px",
    class: "bg-gray",
  },
  {
    text: "WH/Factory*1",
    value: "wh_factory",
    align: "start",
    sortable: false,
    width: "200px",
    class: "bg-gray",
  },
  {
    text: "Load/Unload Date*1",
    value: "load_unload_date",
    align: "center",
    sortable: false,
    width: "200px",
    class: "bg-gray",
  },
  {
    text: "Load/Unload Time*1",
    value: "load_unload_time",
    align: "center",
    sortable: false,
    width: "200px",
    class: "bg-gray",
    divider: true,
  },
  {
    text: "ถึงโรงงาน",
    value: "factory_success_date",
    align: "center",
    sortable: false,
    width: "150px",
    class: "bg-yellow",
    divider: true,
  },
  {
    text: "เวลาส่วนต่าง",
    value: "factory_success_date_diff",
    align: "center",
    sortable: false,
    width: "150px",
    class: "bg-yellow",
    divider: true,
  },
];

export const headers_start_load_unload = [
  {
    text: "Order Driver",
    value: "job_no",
    align: "center",
    sortable: false,
    width: "120px",
    class: "bg-gray",
  },
  {
    text: "Driver",
    value: "driver_name",
    align: "start",
    sortable: false,
    width: "200px",
    class: "bg-gray",
  },
  {
    text: "ทะเบียนรถ",
    value: "truck_license_id",
    align: "start",
    sortable: false,
    width: "100px",
    class: "bg-gray",
  },
  {
    text: "Qty",
    value: "qty",
    align: "center",
    sortable: false,
    width: "100px",
    class: "bg-gray",
  },
  {
    text: "Size Container",
    value: "container_size",
    align: "center",
    sortable: false,
    width: "100px",
    class: "bg-gray",
  },
  {
    text: "Iso Type",
    value: "isotype",
    align: "center",
    sortable: false,
    width: "100px",
    class: "bg-gray",
  },
  {
    text: "Container No.",
    value: "pickup_container_no",
    align: "center",
    sortable: false,
    width: "100px",
    class: "bg-gray",
  },
  {
    text: "Receive Container",
    value: "is_pickup_success_datetime",
    align: "center",
    sortable: false,
    width: "150px",
    class: "bg-gray",
  },
  {
    text: "WH/Factory*1",
    value: "wh_factory",
    align: "start",
    sortable: false,
    width: "200px",
    class: "bg-gray",
  },
  {
    text: "Load/Unload Date*1",
    value: "load_unload_date",
    align: "center",
    sortable: false,
    width: "200px",
    class: "bg-gray",
  },
  {
    text: "Load/Unload Time*1",
    value: "load_unload_time",
    align: "center",
    sortable: false,
    width: "200px",
    class: "bg-gray",
    divider: true,
  },
  {
    text: "ถึงโรงงาน",
    value: "factory_success_date",
    align: "center",
    sortable: false,
    width: "150px",
    divider: true,
  },
  {
    text: "เริ่มบรรจุสินค้า",
    value: "start_product_success_date",
    align: "center",
    sortable: false,
    width: "150px",
    class: "bg-yellow",
    divider: true,
  },
  {
    text: "เวลาส่วนต่าง",
    value: "start_product_success_date_diff",
    align: "center",
    sortable: false,
    class: "bg-yellow",
    width: "150px",
  },
];

export const headers_end_load_unload = [
  {
    text: "Order Driver",
    value: "job_no",
    align: "center",
    sortable: false,
    width: "120px",
    class: "bg-gray",
  },
  {
    text: "Driver",
    value: "driver_name",
    align: "start",
    sortable: false,
    width: "200px",
    class: "bg-gray",
  },
  {
    text: "ทะเบียนรถ",
    value: "truck_license_id",
    align: "start",
    sortable: false,
    width: "100px",
    class: "bg-gray",
  },
  {
    text: "CY Place/Release Port",
    value: "cy_place",
    align: "start",
    sortable: false,
    width: "200px",
    class: "bg-gray",
  },
  {
    text: "Qty",
    value: "qty",
    align: "center",
    sortable: false,
    width: "100px",
    class: "bg-gray",
  },
  {
    text: "Size Container",
    value: "container_size",
    align: "center",
    sortable: false,
    width: "100px",
    class: "bg-gray",
  },
  {
    text: "Iso Type",
    value: "isotype",
    align: "center",
    sortable: false,
    width: "100px",
    class: "bg-gray",
  },
  {
    text: "Container No.",
    value: "pickup_container_no",
    align: "center",
    sortable: false,
    width: "100px",
    class: "bg-gray",
  },
  {
    text: "Return place",
    value: "return_place",
    align: "center",
    sortable: false,
    width: "200px",
    class: "bg-gray",
  },
  {
    text: "FRD",
    value: "frd",
    align: "center",
    sortable: false,
    width: "150px",
    class: "bg-gray",
  },
  {
    text: "Closing Date/Time",
    value: "closing_date",
    align: "center",
    sortable: false,
    width: "200px",
    class: "bg-gray",
    divider: true,
  },
  {
    text: "ถึงโรงงาน",
    value: "factory_success_date",
    align: "center",
    sortable: false,
    width: "150px",
    divider: true,
  },
  {
    text: "เริ่มบรรจุสินค้า",
    value: "start_product_success_date",
    align: "center",
    sortable: false,
    width: "150px",
    divider: true,
  },
  {
    text: "บรรจุสินค้าเสร็จ",
    value: "is_finish_success_datetime",
    align: "center",
    sortable: false,
    width: "150px",
    class: "bg-yellow",
    divider: true,
  },
  {
    text: "เวลาส่วนต่าง",
    value: "is_finish_success_datetime_diff",
    align: "center",
    sortable: false,
    class: "bg-yellow",
    width: "150px",
  },
];

export const headers_return = [
  {
    text: "Order Driver",
    value: "job_no",
    align: "center",
    sortable: false,
    width: "120px",
    class: "bg-gray",
  },
  {
    text: "Driver",
    value: "driver_name",
    align: "start",
    sortable: false,
    width: "200px",
    class: "bg-gray",
  },
  {
    text: "ทะเบียนรถ",
    value: "truck_license_id",
    align: "start",
    sortable: false,
    width: "100px",
    class: "bg-gray",
  },
  {
    text: "Qty",
    value: "qty",
    align: "center",
    sortable: false,
    width: "100px",
    class: "bg-gray",
  },
  {
    text: "Size Container",
    value: "container_size",
    align: "center",
    sortable: false,
    width: "100px",
    class: "bg-gray",
  },
  {
    text: "Iso Type",
    value: "isotype",
    align: "center",
    sortable: false,
    width: "100px",
    class: "bg-gray",
  },
  {
    text: "Container No.",
    value: "pickup_container_no",
    align: "center",
    sortable: false,
    width: "100px",
    class: "bg-gray",
  },
  {
    text: "WH/Factory*1",
    value: "wh_factory",
    align: "start",
    sortable: false,
    width: "200px",
    class: "bg-gray",
  },
  {
    text: "Return place",
    value: "return_place",
    align: "center",
    sortable: false,
    width: "200px",
    class: "bg-gray",
  },
  {
    text: "วันเวลาที่ต้องถึง",
    value: "datetime_arrive",
    align: "center",
    sortable: false,
    width: "200px",
    class: "bg-green",
    divider: true,
  },
  {
    text: "วันเวลาที่คืนตู้",
    value: "is_job_success_datetime",
    align: "center",
    sortable: false,
    width: "200px",
    class: "bg-yellow",
    divider: true,
  },
  {
    text: "รูปท้ายตู้",
    value: "job_done_container_image",
    align: "center",
    sortable: false,
    width: "150px",
    class: "bg-yellow",
    divider: true,
  },
  {
    text: "ภาพใบคืนตู้",
    value: "container_return_image",
    align: "center",
    sortable: false,
    width: "150px",
    class: "bg-yellow",
    divider: true,
  },
];

export const headers_shipping = [
  {
    text: "Order Driver",
    value: "job_no",
    align: "center",
    sortable: false,
    width: "120px",
    class: "bg-gray",
  },
  {
    text: "Driver",
    value: "driver_name",
    align: "start",
    sortable: false,
    width: "200px",
    class: "bg-gray",
  },
  {
    text: "ทะเบียนรถ",
    value: "truck_license_id",
    align: "start",
    sortable: false,
    width: "100px",
    class: "bg-gray",
  },
  {
    text: "Qty",
    value: "qty",
    align: "center",
    sortable: false,
    width: "100px",
    class: "bg-gray",
  },
  {
    text: "Size Container",
    value: "container_size",
    align: "center",
    sortable: false,
    width: "100px",
    class: "bg-gray",
  },
  {
    text: "Iso Type",
    value: "isotype",
    align: "center",
    sortable: false,
    width: "100px",
    class: "bg-gray",
  },
  {
    text: "Container No.",
    value: "pickup_container_no",
    align: "center",
    sortable: false,
    width: "100px",
    class: "bg-gray",
  },
  {
    text: "เบอร์ซีล",
    value: "seal_no",
    align: "center",
    sortable: false,
    width: "200px",
    class: "bg-gray",
  },
  {
    text: "Return Place",
    value: "return_place",
    align: "center",
    sortable: false,
    width: "150px",
    class: "bg-gray",
    divider: true,
  },
  {
    text: "วันเวลาที่ได้ใบกำกับตู้",
    value: "ship_date",
    align: "center",
    sortable: false,
    width: "150px",
    class: "bg-yellow",
    divider: true,
  },
  {
    text: "ไฟล์ใบกำกับตู้",
    value: "container_invoice",
    align: "center",
    sortable: false,
    width: "150px",
    class: "bg-yellow",
    divider: true,
  },
];

export const headers_shore = [
  {
    text: "Order Driver",
    value: "job_no",
    align: "center",
    sortable: false,
    width: "120px",
    class: "bg-gray",
  },
  {
    text: "Driver",
    value: "driver_name",
    align: "start",
    sortable: false,
    width: "200px",
    class: "bg-gray",
  },
  {
    text: "ทะเบียนรถ",
    value: "truck_license_id",
    align: "start",
    sortable: false,
    width: "100px",
    class: "bg-gray",
  },
  {
    text: "Qty",
    value: "qty",
    align: "center",
    sortable: false,
    width: "100px",
    class: "bg-gray",
  },
  {
    text: "Size Container",
    value: "container_size",
    align: "center",
    sortable: false,
    width: "100px",
    class: "bg-gray",
  },
  {
    text: "Iso Type",
    value: "isotype",
    align: "center",
    sortable: false,
    width: "100px",
    class: "bg-gray",
  },
  {
    text: "Container No.",
    value: "pickup_container_no",
    align: "center",
    sortable: false,
    width: "100px",
    class: "bg-gray",
  },
  {
    text: "เบอร์ซีล",
    value: "seal_no",
    align: "center",
    sortable: false,
    width: "200px",
    class: "bg-gray",
  },
  {
    text: "Return Place",
    value: "return_place",
    align: "center",
    sortable: false,
    width: "150px",
    class: "bg-gray",
    divider: true,
  },
  {
    text: "วันเวลาที่ได้ใบเสร็จ",
    value: "shore_date",
    align: "center",
    sortable: false,
    width: "150px",
    class: "bg-yellow",
    divider: true,
  },
  {
    text: "ไฟล์ใบเสร็จ",
    value: "tax_invoice_file",
    align: "center",
    sortable: false,
    width: "150px",
    class: "bg-yellow",
    divider: true,
  },
];
