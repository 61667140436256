<template>
  <div>
    {{ $route.name }}
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      permission: (state) => state.auth.permission,
    }),
  },
};
</script>

<style></style>
