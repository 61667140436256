<template>
  <div class="mx-8">
    <div class="mb-4 mt-1 d-flex justify-start align-center">
      <img
        width="25"
        src="../../../assets/images/menu-icons/NewspaperColor.png"
        alt=""
      />
      <span class="ml-2 font-weight-bold">ค่าผ่านท่า และค่าชั่ง</span>
    </div>

    <div>
      <DataTableTollsScale />
    </div>

    
  </div>
</template>

<script>
import DataTableTollsScale from "../../../components/table/DataTableTollsScale.vue";
export default {
  components: { DataTableTollsScale },
};
</script>

<style></style>
