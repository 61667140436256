<template>
  <div>
    <div class="ml-8 mb-4 mt-1">
      <v-icon color="#338A68" class="mr-2">mdi-truck</v-icon>
      <span class=" font-weight-bold text-uppercase">{{ $route.name }}</span>
    </div>

    <div class=" mx-8">
      <v-layout wrap>
        <!-- Search -->
        <v-flex lg6 pr-2>
          <!-- Form -->
          <v-card outlined flat min-height="400px">
            <v-form
              class="px-6 pt-12"
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-layout>
                <v-flex xs6>
                  <v-layout class="align-center" mb-3>
                    <v-flex class="xs5 text-end  font-12 mr-3 font-weight-bold">
                      Driver Name. :
                    </v-flex>
                    <v-flex class="xs7 px-0">
                      <TextInput
                        hideDetails
                        :model="form.driver_name"
                        rulse="fieldRequire"
                        @update-data="form.driver_name = $event"
                      />
                    </v-flex>
                  </v-layout>

                  <v-layout class="align-center" mb-3>
                    <v-flex class="xs5 text-end font-12 mr-3 font-weight-bold">
                      Truck License No :
                    </v-flex>
                    <v-flex class="xs7 px-0">
                      <AutocompleteInput
                        hideDetails
                        :model="form.truck_license_id"
                        rulse="fieldRequire"
                        :items="truckLicenseList"
                        itemText="plate_no"
                        itemValue="plate_no"
                        @update-data="
                          form.truck_license_id = $event ? $event.plate_no : ''
                        "
                      />
                    </v-flex>
                  </v-layout>

                  <v-layout class="align-center" mb-3>
                    <v-flex class="xs5 text-end font-12 mr-3 font-weight-black">
                      Trailer License No. :
                    </v-flex>
                    <v-flex class="xs7 px-0">
                      <AutocompleteInput
                        hideDetails
                        :model="form.trailer_license_id"
                        rulse="fieldRequire"
                        :items="trailerLicenseList"
                        itemText="plate_no"
                        itemValue="plate_no"
                        @update-data="
                          form.trailer_license_id = $event
                            ? $event.plate_no
                            : ''
                        "
                      />
                    </v-flex>
                  </v-layout>

                  <v-layout class="align-center" mb-3>
                    <v-flex class="xs5 text-end  font-12 mr-3 font-weight-bold">
                      Carrier :
                    </v-flex>
                    <v-flex class="xs7 px-0">
                      <AutocompleteInput
                        hideDetails
                        :model="form.carrier_id"
                        rulse="fieldRequire"
                        :items="carrierList"
                        itemText="name"
                        itemValue="carrier_id"
                        @update-data="
                          form.carrier_id = $event ? $event.carrier_id : ''
                        "
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>

                <v-flex xs6>
                  <v-layout class="align-center" mb-3>
                    <v-flex class="xs4 text-end font-12  font-weight-bold mr-3">
                      Expiration Date :
                    </v-flex>
                    <v-flex class="xs8 mt-n5">
                      <v-layout wrap>
                        <v-flex
                          shrink
                          class="d-flex justify-center align-center"
                        >
                          <v-checkbox
                            v-model="form.expiration_date_driver"
                            label="Driver"
                            color="#338A68"
                            hide-details
                          >
                            <template v-slot:label>
                              <span
                                class="ml-n2 mr-3 font-weight-black font-12"
                              >
                                Driver
                              </span>
                            </template>
                          </v-checkbox>
                        </v-flex>
                        <v-flex shrink>
                          <v-checkbox
                            v-model="form.expiration_date_truck"
                            label="Truck"
                            color="#338A68"
                            hide-details
                          >
                            <template v-slot:label>
                              <span
                                class="ml-n2 mr-3 font-weight-black font-12"
                              >
                                Truck
                              </span>
                            </template>
                          </v-checkbox>
                        </v-flex>
                        <v-flex shrink>
                          <v-checkbox
                            v-model="form.expiration_date_trailer"
                            label="Trailer"
                            color="#338A68"
                            hide-details
                          >
                            <template v-slot:label>
                              <span
                                class="ml-n2 mr-3 font-weight-black font-12"
                              >
                                Trailer
                              </span>
                            </template>
                          </v-checkbox>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>

                  <v-layout class="align-center" mb-3>
                    <v-flex class="xs4 text-end  font-12 mr-3 font-weight-bold">
                      Province :
                    </v-flex>
                    <v-flex class="xs8 px-0">
                      <AutocompleteInput
                        :model="form.province1"
                        rulse="fieldRequire"
                        :items="provinceList"
                        itemText="name_th"
                        itemValue="code"
                        @update-data="
                          form.province1 = $event ? $event.code : ''
                        "
                      />
                    </v-flex>
                  </v-layout>

                  <v-layout class="align-center ml-2 mb-3">
                    <v-flex
                      class="xs4 text-end ml-n1 font-12 mr-3 font-weight-bold"
                    >
                      Province :
                    </v-flex>
                    <v-flex class="xs8">
                      <AutocompleteInput
                        :model="form.province2"
                        rulse="fieldRequire"
                        :items="provinceList"
                        itemText="name_th"
                        itemValue="code"
                        @update-data="
                          form.province2 = $event ? $event.code : ''
                        "
                      />
                    </v-flex>
                  </v-layout>

                  <v-layout mb-3>
                    <v-flex class="text-end font-12">
                      <v-btn
                        depressed
                        color="#F7D25D"
                        class="font-weight-bold"
                        @click="search(1)"
                      >
                        <v-icon class="mr-3">mdi-magnify</v-icon>
                        Search
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-form>

            <!-- Truck Summary -->
            <div class="truck-summary px-2">
              <div class="pl-2 mb-1 font-weight-bold">
                Truck Summary
              </div>
              <v-layout mb-8>
                <v-flex xs3 px-2>
                  <v-card class="text-center" style="border: 1px solid #000">
                    <div class="pa-2 font-20 font-weight-bold">
                      {{ truckSummaryDriver ? truckSummaryDriver : 0 }}
                    </div>
                    <div style="border-top: 1px solid #000"></div>
                    <div class="pa-1" style="background-color: #E8F3F6">
                      Driver
                    </div>
                  </v-card>
                </v-flex>
                <v-flex xs3 px-2>
                  <v-card class="text-center" style="border: 1px solid #000">
                    <div class="pa-2 font-20 font-weight-bold">
                      {{ truckSummaryTruck ? truckSummaryTruck : 0 }}
                    </div>
                    <div style="border-top: 1px solid #000"></div>
                    <div class="pa-1" style="background-color: #E3F8CF">
                      Truck
                    </div>
                  </v-card>
                </v-flex>
                <v-flex xs3 px-2>
                  <v-card class="text-center" style="border: 1px solid #000">
                    <div class="pa-2 font-20 font-weight-bold">
                      {{ truckSummaryTrailer ? truckSummaryTrailer : 0 }}
                    </div>
                    <div style="border-top: 1px solid #000"></div>
                    <div class="pa-1" style="background-color: #FFF0C2">
                      Trailer
                    </div>
                  </v-card>
                </v-flex>
                <v-flex xs3 px-2>
                  <v-card class="text-center" style="border: 1px solid #000">
                    <div class="pa-2 font-20 font-weight-bold">
                      {{ truckSummaryComplete ? truckSummaryComplete : 0 }}
                    </div>
                    <div style="border-top: 1px solid #000"></div>
                    <div class="pa-1" style="background-color: #ECE0FF">
                      Complete
                    </div>
                  </v-card>
                </v-flex>
              </v-layout>
            </div>
          </v-card>
        </v-flex>

        <!-- Graph -->
        <v-flex lg6 pl-2>
          <v-card
            outlined
            flat
            class="pa-3"
            min-height="400px"
            style="overflow-x: scroll"
          >
            <!-- Truck Status -->
            <div class="truck-summary px-2">
              <div class="pl-2 mb-1 font-weight-bold">
                Truck Status
              </div>
              <v-layout mb-8>
                <v-flex style="max-width: 20%" px-2>
                  <v-card class="text-center" style="border: 1px solid #000">
                    <div
                      class="pa-2 font-20 font-weight-bold"
                      style="color: #000"
                    >
                      {{ truckStatusCommit ? truckStatusCommit : 0 }}
                    </div>
                    <div style="border-top: 1px solid #000"></div>
                    <div
                      class="pa-1"
                      style="background-color: #4F4F4F;color: #fff"
                    >
                      Commit
                    </div>
                  </v-card>
                </v-flex>
                <v-flex style="max-width: 20%" px-2>
                  <v-card class="text-center" style="border: 1px solid #000">
                    <div
                      class="pa-2 font-20 font-weight-bold"
                      style="color: #EB5757"
                    >
                      {{
                        truckStatusNotAvaliable ? truckStatusNotAvaliable : 0
                      }}
                    </div>
                    <div style="border-top: 1px solid #000"></div>
                    <div
                      class="pa-1"
                      style="background-color: #EB5757;color: #fff"
                    >
                      Not Avaliable
                    </div>
                  </v-card>
                </v-flex>
                <v-flex style="max-width: 20%" px-2>
                  <v-card class="text-center" style="border: 1px solid #000">
                    <div
                      class="pa-2 font-20 font-weight-bold"
                      style="color: #F7D25D"
                    >
                      {{ truckStatusPast ? truckStatusPast : 0 }}
                    </div>
                    <div style="border-top: 1px solid #000"></div>
                    <div
                      class="pa-1"
                      style="background-color: #F7D25D;color: #000"
                    >
                      Past
                    </div>
                  </v-card>
                </v-flex>
                <v-flex style="max-width: 20%" pl-2>
                  <v-card
                    class="text-center"
                    style="border: 1px solid #000;border-top-right-radius: 0px;border-bottom-right-radius: 0px;"
                  >
                    <div
                      class="pa-2 font-20 font-weight-bold"
                      style="color: #EC8235"
                    >
                      {{ truckStatusNotFee ? truckStatusNotFee : 0 }}
                    </div>
                    <div style="border-top: 1px solid #000"></div>
                    <div
                      class="pa-1"
                      style="background-color: #EC8235;color: #fff"
                    >
                      Not Free
                    </div>
                  </v-card>
                </v-flex>
                <v-flex style="max-width: 20%" pr-2>
                  <v-card
                    class="text-center"
                    style="border: 1px solid #000;border-top-left-radius: 0px;border-bottom-left-radius: 0px"
                  >
                    <div
                      class="pa-2 font-20 font-weight-bold"
                      style="color: #338A68"
                    >
                      {{
                        truckStatusAvaliableFee ? truckStatusAvaliableFee : 0
                      }}
                    </div>
                    <div style="border-top: 1px solid #000"></div>
                    <div
                      class="pa-1"
                      style="background-color: #338A68;color: #fff"
                    >
                      Available Free
                    </div>
                  </v-card>
                </v-flex>
              </v-layout>
            </div>

            <!-- Expiration Date -->
            <div class="truck-summary px-2">
              <div class="pl-2 mb-3 font-weight-bold">
                Expiration Date
              </div>
              <v-layout mb-4>
                <v-flex px-2 style="max-with: 20%;">
                  <v-card style="border: 1px solid #000;border-radius: 5px">
                    <v-layout>
                      <v-flex
                        style="background-color: #9162DE;color: #fff; border-right: 1px solid #000"
                        class="pa-2 xs5 text-center"
                      >
                        D1
                      </v-flex>
                      <v-flex class="xs8 pa-2 text-center">
                        {{ expire_date.driver ? expire_date.driver.d1 : 0 }}
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
                <v-flex px-2 style="max-with: 20%;">
                  <v-card style="border: 1px solid #000;border-radius: 5px">
                    <v-layout>
                      <v-flex
                        style="background-color: #9162DE;color: #fff; border-right: 1px solid #000"
                        class="pa-2 xs5 text-center"
                      >
                        D2
                      </v-flex>
                      <v-flex class="xs8 pa-2 text-center">
                        {{ expire_date.driver ? expire_date.driver.d2 : 0 }}
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>

                <v-flex style="max-with: 20%;min-width: 20%"></v-flex>
                <v-flex style="max-with: 20%;min-width: 20%"></v-flex>
                <v-flex style="max-with: 20%;min-width: 20%"></v-flex>
              </v-layout>

              <v-layout mb-4>
                <v-flex px-2 style="max-with: 20%;">
                  <v-card style="border: 1px solid #000;border-radius: 5px">
                    <v-layout>
                      <v-flex
                        style="background-color: #338A68;color: #fff; border-right: 1px solid #000"
                        class="pa-2 xs5 text-center"
                      >
                        T1
                      </v-flex>
                      <v-flex class="xs8 pa-2 text-center">
                        {{ expire_date.truck ? expire_date.truck.t1 : 0 }}
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
                <v-flex px-2 style="max-with: 20%;">
                  <v-card style="border: 1px solid #000;border-radius: 5px">
                    <v-layout>
                      <v-flex
                        style="background-color: #338A68;color: #fff; border-right: 1px solid #000"
                        class="pa-2 xs5 text-center"
                      >
                        T2
                      </v-flex>
                      <v-flex class="xs8 pa-2 text-center">
                        {{ expire_date.truck ? expire_date.truck.t2 : 0 }}
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
                <v-flex px-2 style="max-with: 20%;">
                  <v-card style="border: 1px solid #000;border-radius: 5px">
                    <v-layout>
                      <v-flex
                        style="background-color: #338A68;color: #fff; border-right: 1px solid #000"
                        class="pa-2 xs5 text-center"
                      >
                        T3
                      </v-flex>
                      <v-flex class="xs8 pa-2 text-center">
                        {{ expire_date.truck ? expire_date.truck.t3 : 0 }}
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
                <v-flex px-2 style="max-with: 20%;">
                  <v-card style="border: 1px solid #000;border-radius: 5px">
                    <v-layout>
                      <v-flex
                        style="background-color: #338A68;color: #fff; border-right: 1px solid #000"
                        class="pa-2 xs5 text-center"
                      >
                        T4
                      </v-flex>
                      <v-flex class="xs8 pa-2 text-center">
                        {{ expire_date.truck ? expire_date.truck.t4 : 0 }}
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
                <v-flex px-2 style="max-with: 20%;">
                  <v-card style="border: 1px solid #000;border-radius: 5px">
                    <v-layout>
                      <v-flex
                        style="background-color: #338A68;color: #fff; border-right: 1px solid #000"
                        class="pa-2 xs5 text-center"
                      >
                        T5
                      </v-flex>
                      <v-flex class="xs8 pa-2 text-center">
                        {{ expire_date.truck ? expire_date.truck.t5 : 0 }}
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>

              <v-layout mb-4>
                <v-flex px-2 style="max-with: 20%;">
                  <v-card style="border: 1px solid #000;border-radius: 5px">
                    <v-layout>
                      <v-flex
                        style="background-color: #62C4E3;color: #fff; border-right: 1px solid #000"
                        class="pa-2 xs5 text-center"
                      >
                        TR1
                      </v-flex>
                      <v-flex class="xs8 pa-2 text-center">
                        {{ expire_date.trailer ? expire_date.trailer.tr1 : 0 }}
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
                <v-flex px-2 style="max-with: 20%;">
                  <v-card style="border: 1px solid #000;border-radius: 5px">
                    <v-layout>
                      <v-flex
                        style="background-color: #62C4E3;color: #fff; border-right: 1px solid #000"
                        class="pa-2 xs5 text-center"
                      >
                        TR2
                      </v-flex>
                      <v-flex class="xs8 pa-2 text-center">
                        {{ expire_date.trailer ? expire_date.trailer.tr2 : 0 }}
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
                <v-flex px-2 style="max-with: 20%;">
                  <v-card style="border: 1px solid #000;border-radius: 5px">
                    <v-layout>
                      <v-flex
                        style="background-color: #62C4E3;color: #fff; border-right: 1px solid #000"
                        class="pa-2 xs5 text-center"
                      >
                        TR3
                      </v-flex>
                      <v-flex class="xs8 pa-2 text-center">
                        {{ expire_date.trailer ? expire_date.trailer.tr3 : 0 }}
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
                <v-flex px-2 style="max-with: 20%;">
                  <v-card style="border: 1px solid #000;border-radius: 5px">
                    <v-layout>
                      <v-flex
                        style="background-color: #62C4E3;color: #fff; border-right: 1px solid #000"
                        class="pa-2 xs5 text-center"
                      >
                        TR4
                      </v-flex>
                      <v-flex class="xs8 pa-2 text-center">
                        {{ expire_date.trailer ? expire_date.trailer.tr4 : 0 }}
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
                <v-flex px-2 style="max-with: 20%;min-width: 20%"></v-flex>
              </v-layout>
            </div>
          </v-card>
        </v-flex>
      </v-layout>

      <!-- button -->
      <v-card outlined flat class="px-4 my-4">
        <v-layout>
          <!-- Export Excel -->
          <v-flex shrink mr-3>
            <v-btn
              outlined
              depressed
              color="#338A68"
              dark
              class="my-4 font-weight-bold"
              @click="exportExcel()"
            >
              <v-icon class="mr-3">mdi-file-excel</v-icon>
              <span class="text-capitalize">Export Excel</span>
            </v-btn>
          </v-flex>

          <!-- Active -->
          <!-- <v-flex shrink mr-3>
            <v-btn
              depressed
              color="#338A68"
              dark
              class="my-4 font-weight-bold"
              @click="changeStatus(1, 'Active')"
            >
              <span class="text-capitalize">Active</span>
            </v-btn>
          </v-flex> -->
          <!-- Past -->
          <!-- <v-flex shrink mr-3>
            <v-btn
              depressed
              color="#F7D25D"
              class="my-4 font-weight-bold"
              @click="changeStatus(2, 'Past')"
            >
              <span class="text-capitalize">Past</span>
            </v-btn>
          </v-flex> -->
          <!-- Inactive -->
          <!-- <v-flex shrink mr-3>
            <v-btn
              depressed
              color="#EB5757"
              dark
              class="my-4 font-weight-bold"
              @click="changeStatus(3, 'Inactive')"
            >
              <span class="text-capitalize">Inactive</span>
            </v-btn>
          </v-flex> -->
          <!-- Block -->
          <!-- <v-flex shrink mr-3>
            <v-btn
              depressed
              color="#EB5757"
              dark
              class="my-4 font-weight-bold"
              @click="changeStatus(4, 'Block')"
            >
              <span class="text-capitalize">Block</span>
            </v-btn>
          </v-flex> -->

          <v-spacer></v-spacer>
          <v-flex shrink>
            <v-btn
              depressed
              color="#338A68"
              dark
              class="my-4 font-weight-bold"
              @click="$router.push('/truck-supply/create')"
            >
              <v-icon class="mr-3">mdi-plus-circle-outline</v-icon>
              <span class="text-capitalize">Add</span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </div>

    <v-layout mb-6>
      <v-flex style="width: 1px">
        <DataTableTruckSupply
          :headers="headersTruckSupply"
          :items="dataTruckSupply"
          :editItem="editItem"
          :deleteItem="deleteItem"
          :selectItem="selectItem"
          :clearSelected="clearSelected"
          :isClearSelected="isClearSelected"
        />
      </v-flex>
    </v-layout>

    <v-layout mt-4>
      <v-flex>
        <Paginate
          :current_page="paginate.current_page"
          :last_page="paginate.last_page"
          :nextPage="search"
        />
      </v-flex>
    </v-layout>

    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="false"
    />
  </div>
</template>

<script>
import XLSX from "xlsx"; // import xlsx;
import moment from "moment";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import TextInput from "../../../components/input/TextInput.vue";
import AutocompleteInput from "../../../components/select/AutocompleteInput";
import DataTableTruckSupply from "../../../components/table/DataTableTruckSupply.vue";
import Paginate from "../../../components/pagination/Paginate.vue";

export default {
  components: {
    Loading,
    TextInput,
    AutocompleteInput,
    DataTableTruckSupply,
    Paginate,
  },
  data() {
    return {
      isClearSelected: false,
      paginate: {
        current_page: 1,
        from: 1,
        last_page: 1,
        per_page: 1,
        total: 1,
        to: 1,
      },
      selectedItems: [],
      dataExcel: [],
      expire_date: [],
      isLoading: false,
      fullPage: true,
      valid: true,
      form: {
        driver_name: "",
        truck_license_id: "",
        trailer_license_id: "",
        carrier_id: "",
        expiration_date_driver: "",
        expiration_date_truck: "",
        expiration_date_trailer: "",
        province1: "",
        province2: "",
      },
      truckLicenseList: [],
      trailerLicenseList: [],
      carrierList: [],
      provinceList: [],

      headersTruckSupply: [
        {
          text: "Actions",
          value: "actions",
          align: "center",
          sortable: false,
          width: "100px",
          class: "border-right border-left",
        },
        {
          text: "No.",
          value: "no",
          align: "center",
          sortable: false,
          width: "50px",
          class: "",
        },
        {
          text: "สถานะ",
          value: "status",
          align: "center",
          sortable: false,
          width: "150px",
          class: "",
        },
        {
          text: "บริษัท",
          value: "company",
          align: "center",
          sortable: false,
          width: "100px",
          class: "",
        },
        {
          text: "รหัสพนักงาน",
          value: "driver_code",
          align: "start",
          sortable: false,
          width: "100px",
          class: "",
        },
        {
          text: "Title",
          value: "title",
          align: "start",
          sortable: false,
          width: "100px",
          class: "",
        },
        {
          text: "Name",
          value: "name",
          align: "start",
          sortable: false,
          width: "150px",
          class: "",
        },
        {
          text: "Lastname",
          align: "lastname",
          sortable: false,
          width: "150px",
          class: "",
        },
        {
          text: "Tel",
          align: "left",
          sortable: false,
          width: "150px",
          class: "",
        },
        {
          text: "ID Line",
          value: "line_id",
          align: "start",
          sortable: false,
          width: "100px",
          class: "",
        },
        {
          text: "Code Truck",
          value: "code_truck",
          align: "center",
          sortable: false,
          width: "100px",
          class: "",
        },
        {
          text: "ประเภทTruck",
          value: "truck_type",
          align: "start",
          sortable: false,
          width: "100px",
          class: "",
        },
        {
          text: "ทะเบียน Truck",
          value: "tail_regis",
          align: "start",
          sortable: false,
          width: "100px",
          class: "",
        },
        {
          text: "Code Triler",
          value: "code_trailer",
          align: "start",
          sortable: false,
          width: "100px",
          class: "",
        },
        {
          text: "ประเภทtrailer",
          align: "trailer_type",
          sortable: false,
          width: "100px",
          class: "",
        },
        {
          text: "ทะเบียน Trailer",
          value: "tail_regis",
          align: "start",
          sortable: false,
          width: "100px",
          class: "",
        },
      ],
      dataTruckSupply: [],
      truckSummaryComplete: null,
      truckSummaryDriver: null,
      truckSummaryTruck: null,
      truckSummaryTrailer: null,
      truckStatusNotAvaliable: null,
      truckStatusPast: null,
      truckStatusNotFee: null,
      truckStatusAvaliableFee: null,
      truckStatusCommit: null,
    };
  },
  created() {
    this.getTruckSupply(1);
    this.getMasterCarrier();
    this.getMasterDataForSearch();
  },
  methods: {
    getTruckSupply(page) {
      this.isLoading = true;
      this.$store.dispatch("truck_supply/getData", page).then((res) => {
        this.isLoading = false;
        if (res.data) {
          //! เลข no table
          res.data.data = res.data.data.map((data, i) => ({
            ...data,
            no: (res.data.current_page - 1) * res.data.per_page + i + 1,
          }));
          this.dataTruckSupply = res.data.data;
          this.paginate.current_page = res.data.current_page;
          this.paginate.last_page = res.data.last_page;
        }
      });
    },
    clearSelected(val) {
      this.isClearSelected = val;
    },
    editItem(item) {
      this.$router.push({
        name: "Edit Truck Supply",
        params: { id: item.truck_supply_id },
      });
    },
    deleteItem(item) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            this.$store
              .dispatch("truck_supply/delete", item.truck_supply_id)
              .then((res) => {
                this.getTruckSupply(this.paginate.current_page);
                this.isLoading = false;
                this.$swal.fire({
                  icon: "success",
                  title: "Delete successfully!",
                  showConfirmButton: false,
                  timer: 1500,
                });
              })
              .catch((error) => {
                this.isLoading = false;
              });
          }
        });
    },
    changeStatus(id, status) {
      if (this.selectedItems.length === 0) {
        this.$swal.fire({
          title: "Something went wrong!",
          text: "Please, Select at least 1 item",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      } else {
        this.$swal
          .fire({
            title: "Are you sure?",
            text: "Do you want to change status?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, change it!",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.isLoading = true;
              this.$store
                .dispatch("truck_supply/changeStatus", {
                  status_id: id,
                  status_name: status,
                  item: this.selectedItems,
                })
                .then((res) => {
                  this.getTruckSupply(1);
                  this.getMasterDataForSearch();
                  this.isClearSelected = false;
                  this.isLoading = false;
                  this.$swal.fire({
                    icon: "success",
                    title: "Change Status successfully!",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                })
                .catch((error) => {
                  this.isLoading = false;
                });
            }
          });
      }
    },
    exportExcel() {
      if (this.dataExcel.length === 0) {
        this.$swal.fire({
          title: "Something went wrong!",
          text: "Please, Select at least 1 item",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      } else {
        this.$swal
          .fire({
            title: "Are you sure?",
            text: `Do you want to Export Truck Supply ${this.dataExcel.length} rows to Excel?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Export!",
          })
          .then((result) => {
            if (result.isConfirmed) {
              const dataWS = XLSX.utils.json_to_sheet(this.dataExcel);
              const wb = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(wb, dataWS);
              const fileName = `truck_supply_${moment(new Date()).format(
                "DD_MM_YYYY_h_mm_ss"
              )}.xlsx`;
              XLSX.writeFile(wb, fileName);
              this.isClearSelected = false;
            }
          });
      }
    },

    selectItem(item) {
      this.selectedItems = [];
      this.dataExcel = [];
      item.forEach((data) => {
        if (data.drivers) {
          this.selectedItems.push({
            driver_id: data.drivers?.driver_id,
          });
        }

        // map data for excel
        this.dataExcel.push({
          // driver
          "เลขบัตรประชาชน ": data.drivers?.personal_id
            ? data.drivers?.personal_id
            : "-",
          "สถานที่ออกบัตรประชาชน ": data.drivers?.issue_by_name
            ? data.drivers?.issue_by_name
            : "-",
          "วันหมดอายุบัตรประชาชน ": data.drivers?.personal_id_expri_date
            ? moment(data.drivers?.personal_id_expri_date).format("DD/MM/YYYY")
            : "-",
          "รหัสพนักงาน ": data.drivers?.driver_code
            ? data.drivers?.driver_code
            : "-",
          "คำนำหน้านาม ": data.drivers?.title_name
            ? data.drivers?.title_name
            : "-",
          "เพศ ": data.drivers?.sex_name ? data.drivers?.sex_name : "-",
          "ชื่อจริง ": data.drivers?.f_name ? data.drivers?.f_name : "-",
          "นามสกุล ": data.drivers?.l_name ? data.drivers?.l_name : "-",
          "เบอร์โทร ": data.drivers?.tel ? data.drivers?.tel : "-",
          "วัน/เดือน/ปีเกิด ": data.drivers?.birth_date
            ? moment(data.drivers?.birth_date).format("DD/MM/YYYY")
            : "-",
          "อายุ ": data.drivers?.age ? data.drivers?.age : "-",
          "ที่อยู่ 1": data.drivers?.address1 ? data.drivers?.address1 : "-",
          "ที่อยู่ 2": data.drivers?.address2 ? data.drivers?.address2 : "-",
          "การศึกษา ": data.drivers?.education_name
            ? data.drivers?.education_name
            : "-",
          "ประเภทใบอนุญาติขับขี่ ": data.drivers?.license_type_name
            ? data.drivers?.license_type_name
            : "-",
          "เลขที่ใบขับขี่ ": data.drivers?.license_no
            ? data.drivers?.license_no
            : "-",
          "วันที่ออกใบขับขี่ ": data.drivers?.lissued_date
            ? moment(data.drivers?.lissued_date).format("DD/MM/YYYY")
            : "-",
          "สถานที่ออกใบขับขี่ ": data.drivers?.lissued_by_name
            ? data.drivers?.lissued_by_name
            : "-",
          "วันหมดอายุใบขับขี่ ": data.drivers?.driver_license_expir
            ? moment(data.drivers?.driver_license_expir).format("DD/MM/YYYY")
            : "-",
          "Working Date": data.drivers?.working_date
            ? moment(data.drivers?.working_date).format("DD/MM/YYYY")
            : "-",
          "อายุงาน ": data.drivers?.driving_exp
            ? data.drivers?.driving_exp
            : "-",
          "ประวัติราชการ ": data.drivers?.government_history
            ? data.drivers?.government_history
            : "-",
          "ที่ทำงานล่าสุด ": data.drivers?.latest_work_place
            ? data.drivers?.latest_work_place
            : "-",
          "สาเหตุที่ออก ": data.drivers?.reason_resignation
            ? data.drivers?.reason_resignation
            : "-",
          "สถานะการทำงาน ": data.drivers?.status_name
            ? data.drivers?.status_name
            : "-", //ไม่พร้อมปฏิบัติงาน // block
          "ชื่อเล่น ": data.drivers?.n_name ? data.drivers?.n_name : "-",
          "ประเภทพนักงาน ": data.drivers?.driver_type_name
            ? data.drivers?.driver_type_name
            : "-",
          "ชื่อผู้ติดต่อบริษัทต้นสังกัด ": data.drivers?.contact_person
            ? data.drivers?.contact_person
            : "-",
          "ตำแหน่งผู้ติดต่อ ": data.drivers?.contact_rel
            ? data.drivers?.contact_rel
            : "-",
          "เบอร์โทรผู้ติดต่อ ": data.drivers?.contact_tel
            ? data.drivers?.contact_tel
            : "-",
          "ชื่อบริษัท ": data.drivers?.company_name
            ? data.drivers?.company_name
            : "-",
          "E-Mail": data.drivers?.email ? data.drivers?.email : "-",
          "ID Line": data.drivers?.line_id ? data.drivers?.line_id : "-",

          // Truck
          "ทะเบียนรถ ": data.truck?.plate_no ? data.truck?.plate_no : "-",
          "จังหวัด ": data.truck?.province_name
            ? data.truck?.province_name
            : "-",
          "รหัสรถ ": data.truck?.car_code ? data.truck?.car_code : "-",
          "ยี่ห้อ ": data.truck?.car_brand ? data.truck?.car_brand : "-",
          "ปีรถ ": data.truck?.car_year ? data.truck?.car_year : "-",
          "รหัสตัวถังรถ ": data.truck?.car_body_code
            ? data.truck?.car_body_code
            : "-",
          "รหัสเครื่องยนต์ ": data.truck?.car_engine_code
            ? data.truck?.car_engine_code
            : "-",
          "ประเภทรถ ": data.truck?.car_type_name
            ? data.truck?.car_type_name
            : "-",
          "ประเภทรถย่อย ": data.truck?.car_sub_type_name
            ? data.truck?.car_sub_type_name
            : "-",
          "น้ำหนักรถ ": data.truck?.car_weight ? data.truck?.car_weight : "-",
          "วันที่จดทะเบียน ": data.truck?.register_date
            ? moment(data.truck?.register_date).format("DD/MM/YYYY")
            : "-",
          "วันที่ต่อพรบ ": data.truck?.date_per_act
            ? moment(data.truck?.date_per_act).format("DD/MM/YYYY")
            : "-",
          "วันที่ต่อภาษี ": data.truck?.tax_date
            ? moment(data.truck?.date_per_act).format("DD/MM/YYYY")
            : "-",
          "บริษัทไฟแนนซ์ ": data.truck?.finance_company
            ? data.truck?.finance_company
            : "-",
          "เลขที่สัญญาเช่าซื้อ ": data.truck?.hire_purchase_no
            ? data.truck?.hire_purchase_no
            : "-",
          "Vendor GPS ": data.truck?.vender_gps ? data.truck?.vender_gps : "-",
          "GPS Box ": data.truck?.gps_box ? data.truck?.gps_box : "-",
          "Phone GPS ": data.truck?.phone_gps ? data.truck?.phone_gps : "-",
          "สัญญา GPS ": data.truck?.gps_contract
            ? data.truck?.gps_contract
            : "-",
          "วันที่ติดตั้ง GPS ": data.truck?.gps_install_date
            ? moment(data.truck?.gps_install_date).format("DD/MM/YYYY")
            : "-",
          "วันที่หมดสัญญา GPS ": data.truck?.gps_contract_expir_date
            ? moment(data.truck?.gps_contract_expir_date).format("DD/MM/YYYY")
            : "-",
          "บริษัทประกันสินค้า ": data.truck?.insurance_company
            ? data.truck?.insurance_company
            : "-",
          "วงเงินประกันภันสินค้า ": data.truck?.product_insurance_limit
            ? data.truck?.product_insurance_limit
            : "-",
          "วันเริ่มคุ้มครองประกันสินค้า ": data.truck?.protect_start_date
            ? moment(data.truck?.gps_contract_expir_date).format("DD/MM/YYYY")
            : "-",
          "บริษัทประกันรถ ": data.truck?.car_insurance_company
            ? data.truck?.car_insurance_company
            : "-",
          "ประเภทประกันรถ ": data.truck?.car_insurance_type_name
            ? data.truck?.car_insurance_type_name
            : "-",
          "วันเริ่มประกันรถ ": data.truck?.insurance_start_date
            ? moment(data.truck?.insurance_start_date).format("DD/MM/YYYY")
            : "-",
          "ทุนประกัน ": data.truck?.insurance_funds,
          // Trailer
          "ทะเบียน (หาง) ": data.trailer?.plate_no
            ? data.trailer?.plate_no
            : "-",
          "จังหวัด (หาง) ": data.trailer?.province_name
            ? data.trailer?.province_name
            : "-",
          "รหัสหาง ": data.trailer?.tail_code ? data.trailer?.tail_code : "-",
          "ยี่ห้อหาง ": data.trailer?.tail_brand
            ? data.trailer?.tail_brand
            : "-",
          "ประเภทหาง ": data.trailer?.tail_type_name
            ? data.trailer?.tail_type_name
            : "-",
          "ประเภทหางย่อย ": data.trailer?.tail_sub_type_name
            ? data.trailer?.tail_sub_type_name
            : "-",
          "วันที่จดทะเบียนหาง ": data.trailer?.tail_register_date
            ? moment(data.trailer?.tail_register_date).format("DD/MM/YYYY")
            : "-",
          "วันที่ต่อพรบ.": data.trailer?.date_per_act
            ? moment(data.trailer?.date_per_act).format("DD/MM/YYYY")
            : "-",
          "วันที่ต่อภาษี(หาง) ": data.trailer?.tax_date
            ? moment(data.trailer?.tax_date).format("DD/MM/YYYY")
            : "-",
          "บริษัทประกัน ": data.trailer?.insurance_company
            ? data.trailer?.insurance_company
            : "-",
          "ประเภทประกันรถ(หาง)  ": data.trailer?.car_insurance_type_name
            ? data.trailer?.car_insurance_type_name
            : "-",
          "ทุนประกัน(หาง) ": data.trailer?.insurance_funds
            ? data.trailer?.insurance_funds
            : "-",
          "วันเริ่มประกัน(หาง) ": data.trailer?.insurance_start_date
            ? moment(data.trailer?.tax_date).format("DD/MM/YYYY")
            : "-",
        });
      });
    },

    // Search Tinder
    search(page) {
      this.isLoading = true;
      this.form.expiration_date_driver = this.form.expiration_date_driver
        ? 1
        : 0;
      this.form.expiration_date_truck = this.form.expiration_date_truck ? 1 : 0;
      this.form.expiration_date_trailer = this.form.expiration_date_trailer
        ? 1
        : 0;
      this.form["page"] = page;
      this.$store
        .dispatch("truck_supply/search", this.form)
        .then((res) => {
          this.isLoading = false;
          //! เลข no table
          res.data.data = res.data.data.map((data, i) => ({
            ...data,
            no: (res.data.current_page - 1) * res.data.per_page + i + 1,
          }));
          this.dataTruckSupply = res.data.data;
          this.paginate.current_page = res.data.current_page;
          this.paginate.last_page = res.data.last_page;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // Get Master data
    async getMasterCarrier() {
      const fields = ["is_haulage"];
      const res = await this.$store.dispatch(
        "master_data/getMasterCarrier",
        fields
      );
      this.carrierList = res.data[0].is_haulage;
      return res.data;
    },
    async getMasterDataForSearch() {
      const res = await this.$store.dispatch(
        "truck_supply/getMasterDataForSearch"
      );
      this.provinceList = res.data.province;
      this.truckLicenseList = res.data.trucks;
      this.trailerLicenseList = res.data.trailers;

      this.truckSummaryComplete = res.data.truck_summary_complete;
      this.truckSummaryDriver = res.data.truck_summary_driver;
      this.truckSummaryTruck = res.data.truck_summary_truck;
      this.truckSummaryTrailer = res.data.truck_summary_trailer;

      this.truckStatusNotAvaliable = res.data.truck_status_not_avaliable;
      this.truckStatusPast = res.data.truck_status_past;
      this.truckStatusNotFee = res.data.truck_status_not_fee;
      this.truckStatusAvaliableFee = res.data.truck_status_avaliable_fee;
      this.truckStatusCommit = res.data.truck_status_commit;
      this.expire_date = res.data.expire_date;

      return res.data;
    },
  },
};
</script>

<style></style>
