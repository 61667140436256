import AuthService from "../AuthService";
import Service from "../Service";

const costTruck = {
  getData(page) {
    return AuthService.get(`/api/cost-per-truck?page=${page}`).then(
      (res) => res
    );
  },
  removeTrip(data) {
    return AuthService.post(`/api/cost-per-truck/remove-trip`, data).then(
      (res) => res
    );
  },
  setTrip(data) {
    return AuthService.post(`/api/cost-per-truck/set-trip`, data).then(
      (res) => res
    );
  },
  changeStatus(data) {
    return AuthService.post(`/api/cost-per-truck/change-status`, data).then(
      (res) => res
    );
  },
  getMasterData() {
    return AuthService.get(`/api/get-masterdata-cost-per-truck`).then(
      (res) => res
    );
  },
  getDataById(id) {
    return AuthService.get(`/api/cost-per-truck/${id}`).then((res) => res);
  },
  save(data) {
    return AuthService.post("/api/cost-per-truck/save", data).then(
      (res) => res
    );
  },
  payroll(data) {
    return AuthService.get(`/api/payroll?data=${JSON.stringify(data)}`).then(
      (res) => res
    );
  },
  search(data) {
    return AuthService.get(
      `/api/cost-per-truck-search?page=${data.page}&load_and_unload_date_from=${data.load_and_unload_date_from}&load_and_unload_date_to=${data.load_and_unload_date_to}&truck_license_no=${data.truck_license_no}&province_code=${data.province_code}&tender_date_from=${data.tender_date_from}&tender_date_to=${data.tender_date_to}&first_name=${data.first_name}&last_name=${data.last_name}&carrier_id=${data.carrier_id}&cy_place_id=${data.cy_place_id}&cy_date=${data.cy_date}&order_id=${data.order_id}&new_costtruck_today=${data.new_costtruck_today}`
    ).then((res) => res);
  },
};

export default costTruck;
