import Service from "../../services";

const state = {};

const getters = {};

const actions = {
  getData({ commit }, page) {
    return Service.order_detail.getData(page).then((res) => {
      return res;
    });
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
