import Service from "../../services";

const state = {
  order_data: null,
  order_id: "",
  mode: "Import",
};

const getters = {};

const actions = {
  saveOrder({ commit }, data) {
    return Service.order.saveOrder(data).then((res) => {
      return res;
    });
  },
  updateOrder({ commit }, data) {
    return Service.order.updateOrder(data).then((res) => {
      return res;
    });
  },
  deleteOrder({ commit }, id) {
    return Service.order.deleteOrder(id).then((res) => {
      return res;
    });
  },
  duplicateOrder({ commit }, id) {
    return Service.order.duplicateOrder(id).then((res) => {
      return res;
    });
  },
  printOrder({ commit }, data) {
    return Service.order.printOrder(data).then((res) => {
      return res;
    });
  },
  getOrders({ commit }, page) {
    return Service.order.getOrders(page).then((res) => {
      if (res) {
        commit("SET_ORDER", res.data);
      }
      return res;
    });
  },
  getOrderDetail({ commit }) {
    return Service.order.getOrderDetail().then((res) => {
      if (res) {
        commit("SET_ORDER", res.data);
      }
      return res;
    });
  },
  getOrderById({ commit }, id) {
    return Service.order.getOrderById(id).then((res) => {
      return res;
    });
  },
  search({ commit }, data) {
    return Service.order.search(data).then((res) => {
      return res;
    });
  },
  setMode({ commit }, data) {
    commit("SET_MODE", data);
  },
};

const mutations = {
  SET_ORDER(state, data) {
    state.order_data = data;
  },
  SET_MODE(state, data) {
    state.mode = data;
  },
  SET_ORDER_ID(state, data) {
    state.order_id = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
