<template>
  <div>
    <v-form
      style="background-color: #F5F5F5"
      ref="form"
      v-model="valid"
      lazy-validation
      class=" py-6"
    >
      <!-- Blog 1 -->
      <v-layout mb-3>
        <v-flex xs4>
          <v-layout class="align-center">
            <v-flex class="xs5 text-end font-12 mr-3 font-weight-bold">
              CS<span style="color: red">*</span> :
            </v-flex>
            <v-flex class="shrink" @click="setData">
              {{ user.firstname }}
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-layout wrap px-6 mb-3>
        <v-flex xs3>
          <v-layout class="align-center">
            <v-flex class="xs6 text-end font-12 mr-3 font-weight-bold ">
              Customer :
            </v-flex>
            <v-flex class="xs7">
              <AutocompleteInput
                clearable
                borderColor="green"
                placeholder="Customer"
                :model="form.customer_id"
                required
                :items="customerList"
                itemText="customer_code"
                itemValue="customer_id"
                @update-data="
                  form.customer_id = $event ? $event.customer_id : '';
                  form.customer_name = $event ? $event.customer_code : '';
                  form.receipt_type_id = '';
                  form.receipt_type_name = '';
                  form.address_id = '';
                  form.address_name = '';
                "
              />
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs3>
          <v-layout class="align-center">
            <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold ">
              Exporter <span style="color: red">*</span> :
            </v-flex>
            <v-flex class="xs7">
              <AutocompleteInput
                clearable
                borderColor="green"
                placeholder="Exporter"
                :model="form.exporter_id"
                required
                :items="exporterList"
                itemText="customer_code"
                itemValue="customer_id"
                @update-data="
                  form.exporter_id = $event ? $event.customer_id : '';
                  form.exporter_name = $event ? $event.customer_code : '';
                  form.receipt_type_id = '';
                  form.receipt_type_name = '';
                  form.address_id = '';
                  form.address_name = '';
                "
              />
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs3>
          <v-layout class="align-center">
            <v-flex class="xs4 text-end font-12  font-weight-bold mr-3">
              Receipt Type <span style="color: red">*</span> :
            </v-flex>
            <v-flex class="xs7">
              <AutocompleteInput
                clearable
                borderColor="green"
                placeholder="Receipt Type"
                :model="form.receipt_type_id"
                rulse="fieldRequire"
                :items="receiptTypeList"
                itemText="receipt_type_name"
                itemValue="receipt_type_id"
                @update-data="
                  form.receipt_type_id = $event ? $event.receipt_type_id : '';
                  form.receipt_type_name = $event
                    ? $event.receipt_type_name
                    : '';
                  form.address_id = '';
                  form.address_name = '';
                "
              />
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs3>
          <v-layout class="align-center">
            <v-flex class="xs6 text-end font-12  font-weight-bold mr-3">
              Address :
            </v-flex>
            <v-flex class="xs7">
              <AutocompleteInput
                clearable
                borderColor="green"
                placeholder="customer"
                :model="form.address_id"
                rulse="fieldRequire"
                :items="addressList"
                itemText="address_name"
                itemValue="address_id"
                @update-data="onHandleAddress($event)"
              />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-layout wrap px-6>
        <v-flex xs3>
          <v-layout class="align-center">
            <v-flex class="xs6 text-end font-12 mr-3 font-weight-bold ">
              INV/.PO <span style="color: red">*</span> :
            </v-flex>
            <v-flex class="xs7">
              <TextInput
                required
                placeholder="INV/.PO"
                :model="form.inv_po"
                rulse="fieldRequire"
                @update-data="updateData('inv_po', $event)"
              />
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs3>
          <v-layout class="align-center">
            <v-flex class="xs4 text-end  font-12 mr-3 font-weight-bold ">
              Booking no. <span style="color: red">*</span> :
            </v-flex>
            <v-flex class="xs7">
              <TextInput
                placeholder="Booking no."
                hideDetail
                required
                rulse="fieldRequire"
                :model="form.booking_no"
                @update-data="updateDate('booking_no', $event)"
              />
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex xs3>
          <v-layout class="">
            <v-flex class="xs4 pt-2 text-end  font-12  font-weight-bold mr-3">
              Cost For :
            </v-flex>
            <v-flex class="xs7">
              <v-layout>
                <v-flex>
                  <v-checkbox
                    class="ma-0"
                    hide-details
                    label="ค่ายกตู้"
                    color="#338A68"
                    v-model="form.is_cost_for_lifting"
                  >
                    <template v-slot:label>
                      <div class="font-12 font-weight-bold">
                        ค่ายกตู้
                      </div>
                    </template>
                  </v-checkbox>
                </v-flex>
                <v-flex>
                  <v-checkbox
                    class="ma-0"
                    hide-details
                    label="ค่าชั่ง"
                    color="#338A68"
                    v-model="form.is_cost_for_scale"
                  >
                    <template v-slot:label>
                      <div class="font-12 font-weight-bold">
                        ค่าชั่ง
                      </div>
                    </template>
                  </v-checkbox>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex>
                  <v-checkbox
                    class="ma-0"
                    hide-details
                    label="ค่าผ่านลาน"
                    color="#338A68"
                    v-model="form.is_cost_for_lan"
                  >
                    <template v-slot:label>
                      <div class="font-12 font-weight-bold">
                        ค่าผ่านลาน
                      </div>
                    </template>
                  </v-checkbox>
                </v-flex>
                <v-flex>
                  <v-checkbox
                    class="ma-0"
                    hide-details
                    label="ค่าผ่านท่า"
                    color="#338A68"
                    v-model="form.is_cost_for_port"
                  >
                    <template v-slot:label>
                      <div class="font-12 font-weight-bold">
                        ค่าผ่านท่า
                      </div>
                    </template>
                  </v-checkbox>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex class="xs3">
          <v-layout class="align-center">
            <v-flex class="xs6 text-end font-12  font-weight-bold mr-3">
            </v-flex>
            <v-flex class="xs7 font-12">
              {{ form.address_name ? form.address_name : "-" }}
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-divider class="my-6"></v-divider>

      <!-- Blog 2 -->
      <v-layout wrap px-6 mb-3>
        <v-flex xs4>
          <v-layout class="align-center">
            <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
              Goods :
            </v-flex>
            <v-flex class="xs7">
              <AutocompleteInput
                clearable
                borderColor="green"
                placeholder="Goods"
                :model="form.goods_id"
                rulse="fieldRequire"
                :items="goodsList"
                itemText="goods_name"
                itemValue="goods_id"
                @update-data="
                  updateSelect('goods', 'goods_id', 'goods_name', $event)
                "
              />
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs4>
          <v-layout class="align-center">
            <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
              Package :
            </v-flex>
            <v-flex class="xs7">
              <AutocompleteInput
                clearable
                borderColor="green"
                placeholder="Package"
                :model="form.package_id"
                rulse="fieldRequire"
                :items="packageList"
                itemText="package_name"
                itemValue="package_id"
                @update-data="
                  updateSelect('package', 'package_id', 'package_name', $event)
                "
              />
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs4>
          <v-layout class="align-center">
            <v-flex class="xs4 text-end  font-12 mr-3 font-weight-bold">
              TON :
            </v-flex>
            <v-flex class="xs4">
              <TextInput
                inputRule="number"
                placeholder="TON"
                :model="form.ton"
                rulse="fieldRequire"
                @update-data="updateData('ton', $event)"
              />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-divider class="my-6"></v-divider>

      <!-- Blog 3 -->
      <v-layout wrap px-6 mb-3>
        <v-flex xs4>
          <v-layout class="align-center mb-3">
            <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
              Forwarder :
            </v-flex>
            <v-flex class="xs7">
              <AutocompleteInput
                clearable
                borderColor="green"
                placeholder="Forwarder"
                :model="form.forwarder_id"
                rulse="fieldRequire"
                :items="forwarderList"
                itemText="shipping_agent_name"
                itemValue="shipping_agent_id"
                @update-data="
                  updateSelect(
                    'forwarder',
                    'shipping_agent_id',
                    'shipping_agent_name',
                    $event
                  )
                "
              />
            </v-flex>
          </v-layout>

          <v-layout class="align-center mb-3">
            <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
              FEEDER VESSEL <span style="color: red">*</span> :
            </v-flex>
            <v-flex class="xs7">
              <TextInput
                required
                placeholder="FEEDER VESSEL"
                :model="form.feeder_vessel"
                rulse="fieldRequire"
                @update-data="updateData('feeder_vessel', $event)"
              />
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs4>
          <v-layout class="align-center mb-3">
            <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
              Shipping Agent <span style="color: red">*</span> :
            </v-flex>
            <v-flex class="xs7">
              <AutocompleteInput
                clearable
                required
                placeholder="Shipping Agent"
                :model="form.shipping_agent_id"
                rulse="fieldRequire"
                :items="shippingAgentList"
                itemText="shipping_agent_name"
                itemValue="shipping_agent_id"
                @update-data="
                  updateSelect(
                    'shipping_agent',
                    'shipping_agent_id',
                    'shipping_agent_name',
                    $event
                  )
                "
              />
            </v-flex>
          </v-layout>

          <v-layout class="align-center">
            <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
              OCEAN VESSEL :
            </v-flex>
            <v-flex class="xs7">
              <TextInput
                placeholder="OCEAN VESSEL"
                :model="form.ocean_vessel"
                rulse="fieldRequire"
                @update-data="updateData('ocean_vessel', $event)"
              />
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex xs4>
          <v-layout class="align-center">
            <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
              Port/Country <span style="color: red">*</span> :
            </v-flex>
            <v-flex class="xs7">
              <AutocompleteInput
                clearable
                required
                placeholder="PORT/Country"
                :model="form.port_country_id"
                rulse="fieldRequire"
                :items="portCountryList"
                itemText="port_country_name"
                itemValue="port_country_id"
                @update-data="
                  form.port_country_id = $event ? $event.port_country_id : '';
                  form.port_country_name = $event
                    ? $event.port_country_name
                    : '';
                "
              />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-divider class="my-6"></v-divider>

      <!-- Blog 4 -->
      <v-layout wrap px-6 mb-3>
        <v-flex xs4>
          <v-layout class="align-center mb-3">
            <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
              Shipping :
            </v-flex>
            <v-flex class="xs7">
              <AutocompleteInput
                clearable
                placeholder="Shipping"
                :model="form.shipping_id"
                rulse="fieldRequire"
                :items="shippingList"
                itemText="name"
                itemValue="carrier_id"
                @update-data="
                  updateSelect('shipping', 'carrier_id', 'name', $event)
                "
              />
            </v-flex>
          </v-layout>

          <v-layout class="align-center mb-3">
            <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
              Haulage (est.) :
            </v-flex>
            <v-flex class="xs7">
              <AutocompleteInput
                clearable
                placeholder="Haulage"
                :model="form.haulage_id"
                rulse="fieldRequire"
                :items="haulageList"
                itemText="name"
                itemValue="carrier_id"
                @update-data="
                  updateSelect('haulage', 'carrier_id', 'name', $event)
                "
              />
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex xs4>
          <v-layout class="align-center mb-3">
            <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
              Shore :
            </v-flex>
            <v-flex class="xs7">
              <AutocompleteInput
                clearable
                placeholder="Shore"
                :model="form.shore_id"
                rulse="fieldRequire"
                :items="shoreList"
                itemText="name"
                itemValue="carrier_id"
                @update-data="
                  updateSelect('shore', 'carrier_id', 'name', $event)
                "
              />
            </v-flex>
          </v-layout>

          <v-layout class="align-center mb-3">
            <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
              Fumigate :
            </v-flex>
            <v-flex class="xs7">
              <AutocompleteInput
                clearable
                placeholder="Fumigate"
                :model="form.fumigate_id"
                rulse="fieldRequire"
                :items="fumigateList"
                itemText="name"
                itemValue="carrier_id"
                @update-data="
                  updateSelect('fumigate', 'carrier_id', 'name', $event)
                "
              />
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex xs4>
          <v-layout class="align-center mb-3">
            <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
              Survey :
            </v-flex>
            <v-flex class="xs7">
              <AutocompleteInput
                clearable
                placeholder="Survey"
                :model="form.survey_id"
                rulse="fieldRequire"
                :items="surveyList"
                itemText="name"
                itemValue="carrier_id"
                @update-data="
                  updateSelect('survey', 'carrier_id', 'name', $event)
                "
              />
            </v-flex>
          </v-layout>

          <v-layout class="align-center mb-3">
            <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
              Others :
            </v-flex>
            <v-flex class="xs7">
              <AutocompleteInput
                clearable
                placeholder="Others"
                :model="form.others_id"
                rulse="fieldRequire"
                :items="othersList"
                itemText="name"
                itemValue="carrier_id"
                @update-data="
                  updateSelect('others', 'carrier_id', 'name', $event)
                "
              />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-divider class="my-6"></v-divider>

      <!-- Blog 5 -->
      <v-layout wrap px-6 mb-3>
        <v-flex>
          <v-layout>
            <v-flex xs4>
              <v-layout class="align-center mb-3">
                <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
                  FRD <span style="color: red">*</span> :
                </v-flex>
                <v-flex class="xs4">
                  <DatePicker
                    required
                    placeholder="DD/MM/YY"
                    hideDetail
                    rulse="fieldRequire"
                    :model="date.frd"
                    @update-data="updateDate('frd', $event)"
                  ></DatePicker>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

          <v-layout>
            <v-flex xs4>
              <v-layout class="align-center">
                <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
                  Return place <span style="color: red">*</span> :
                </v-flex>
                <v-flex class="xs7">
                  <AutocompleteInput
                    clearable
                    required
                    placeholder="RETURN PLACE"
                    :model="form.return_place_id"
                    rulse="fieldRequire"
                    :items="returnPlaceList"
                    itemText="port_discharge_name"
                    itemValue="port_discharge_id"
                    @update-data="
                      updateSelect(
                        'return_place',
                        'port_discharge_id',
                        'port_discharge_name',
                        $event
                      )
                    "
                  />
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs4>
              <v-layout class="align-center mb-3">
                <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
                  Closing <br />
                  Date Time <span style="color: red">*</span> :
                </v-flex>
                <v-flex class="xs4">
                  <DatePicker
                    required
                    placeholder="DD/MM/YY"
                    hideDetail
                    rulse="fieldRequire"
                    :model="date.closing_date"
                    @update-data="updateDate('closing_date', $event)"
                  ></DatePicker>
                </v-flex>
                <v-flex class="xs4 pl-2">
                  <TimePicker
                    required
                    borderColor="green"
                    placeholder="00:00"
                    :model="form.closing_time"
                    hideDetail
                    @update-data="updateData('closing_time', $event)"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-divider class="my-6"></v-divider>

      <!-- Blog 6 -->
      <v-layout wrap px-6 mb-3>
        <v-flex>
          <v-layout mb-6>
            <v-flex xs4>
              <v-layout class="align-center">
                <v-flex
                  class="xs4 text-end font-12 mr-3 ml-n1 font-weight-bold"
                >
                  CY Date<span style="color: red">*</span> :
                </v-flex>
                <v-flex class="xs4 pr-1">
                  <DatePicker
                    required
                    placeholder="DD/MM/YY"
                    hideDetail
                    rulse="fieldRequire"
                    :model="date.cy_date"
                    @update-data="updateDate('cy_date', $event)"
                  ></DatePicker>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs4>
              <v-layout class="align-center">
                <v-flex
                  class="xs4 text-end font-12 mr-3 ml-n1 font-weight-bold"
                >
                  Container Status :
                </v-flex>
                <v-flex class="xs8 pr-1">
                  <TextInput
                    :model="form.container_status"
                    rulse="fieldRequire"
                    @update-data="form.container_status = $event"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

          <v-layout>
            <v-flex xs4>
              <v-layout class="align-center mb-3">
                <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
                  SI Cut Off <br />
                  Date Time :
                </v-flex>
                <v-flex class="xs4">
                  <DatePicker
                    placeholder="DD/MM/YY"
                    hideDetail
                    rulse="fieldRequire"
                    :model="date.si_cut_off_date"
                    @update-data="updateDate('si_cut_off_date', $event)"
                  ></DatePicker>
                </v-flex>
                <v-flex class="xs4 pl-2">
                  <TimePicker
                    borderColor="green"
                    placeholder="00:00"
                    :model="form.si_cut_off_time"
                    hideDetail
                    @update-data="updateData('si_cut_off_time', $event)"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs4>
              <v-layout class="align-center mb-3">
                <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
                  VGM Cut Off <br />
                  Date Time :
                </v-flex>
                <v-flex class="xs4">
                  <DatePicker
                    borderColor="green"
                    placeholder="DD/MM/YY"
                    hideDetail
                    :model="date.vgm_cut_off_date"
                    @update-data="updateDate('vgm_cut_off_date', $event)"
                  ></DatePicker>
                </v-flex>
                <v-flex class="xs4 pl-2">
                  <TimePicker
                    placeholder="00:00"
                    :model="form.vgm_cut_off_time"
                    hideDetail
                    @update-data="updateData('vgm_cut_off_time', $event)"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-divider class="my-6"></v-divider>

      <!-- Blog 5 -->
      <v-layout wrap px-6>
        <v-flex>
          <v-layout mb-3 v-for="(item, i) in containers_form" :key="i">
            <!-- Group 1 -->
            <v-flex shrink class="font-12 mr-4">
              <!-- CY Place -->
              <v-layout>
                <v-flex x12 class="font-12">
                  <label v-if="i == 0" class=" font-weight-bold">
                    CY Place :
                  </label>
                  <AutocompleteInput
                    :disabled="item.tender > 0"
                    clearable
                    required
                    placeholder="CY PLACE"
                    :model="item.cy_place_id"
                    rulse="fieldRequire"
                    :items="cyPlaceList"
                    itemText="cy_place_name"
                    itemValue="cy_place_id"
                    @update-data="
                      updateDataContainerSelect('cy_place', i, $event)
                    "
                  />
                </v-flex>
              </v-layout>
            </v-flex>

            <!-- Group 2 -->
            <v-flex mr-4 shrink>
              <v-layout>
                <v-flex xs4 class="font-12  mr-2">
                  <!-- QTY -->
                  <label v-if="i == 0" class=" font-weight-bold">
                    Qyt :
                  </label>
                  <TextInput
                    required
                    inputRule="number"
                    hideDetail
                    rulse="fieldRequire"
                    :model="item.qty"
                    @update-data="updateDataContainer('qty', i, $event)"
                  />
                </v-flex>
                <v-flex xs4 class="font-12  mr-2">
                  <!-- Size -->
                  <label v-if="i == 0" class=" font-weight-bold">
                    Size :
                  </label>
                  <AutocompleteInput
                    :disabled="item.tender > 0"
                    clearable
                    required
                    borderColor="green"
                    hideDetail
                    placeholder="size"
                    :model="item.container_size_id"
                    rulse="fieldRequire"
                    :items="containerSizeList"
                    itemText="size"
                    itemValue="container_size_id"
                    @update-data="
                      updateDataContainerSelect('container_size', i, $event)
                    "
                  />
                </v-flex>
                <v-flex xs4 class="font-12 ">
                  <!-- Isotype -->
                  <label v-if="i == 0" class=" font-weight-bold">
                    Isotype :
                  </label>
                  <AutocompleteInput
                    :disabled="item.tender > 0"
                    clearable
                    borderColor="green"
                    hideDetail
                    clea
                    placeholder="size"
                    :model="item.container_isotype_id"
                    :items="containerIsoTypeList"
                    itemText="isotype"
                    itemValue="container_isotype_id"
                    @update-data="
                      updateDataContainerSelect('container_isotype', i, $event)
                    "
                  />
                </v-flex>
              </v-layout>
            </v-flex>

            <!-- Group 3 -->
            <v-flex mr-4 shrink>
              <v-layout>
                <v-flex xs4>
                  <v-layout>
                    <v-flex class="font-12  pr-2">
                      <label v-if="i == 0" class=" font-weight-bold">
                        WH/Factory1<span style="color: red">*</span> :
                      </label>
                      <AutocompleteInput
                        :disabled="item.tender > 0"
                        clearable
                        hideDetail
                        required
                        placeholder="WH/Factory1"
                        :model="item.wh_factory_1_id"
                        rulse="fieldRequire"
                        :items="factoryList"
                        itemText="wh_factory_name"
                        itemValue="wh_factory_id"
                        @update-data="
                          updateDataContainerSelect('wh_factory_1', i, $event)
                        "
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs8 class="font-12">
                  <label v-if="i == 0" class=" font-weight-bold">
                    Unload / Load Date Time1<span style="color: red">* </span>
                    :
                  </label>
                  <v-layout class="align-center">
                    <v-flex class="mr-2">
                      <DatePicker
                        :disabled="item.tender > 0"
                        placeholder="DD/MM/YY"
                        required
                        borderColor="green"
                        hideDetail
                        rulse="fieldRequire"
                        :model="date_con[i].unload_load_date1"
                        @update-data="
                          updateDateContainer('unload_load_date1', i, $event)
                        "
                      ></DatePicker>
                    </v-flex>
                    <v-flex>
                      <TimePicker
                        :disabled="item.tender > 0"
                        required
                        placeholder="00:00"
                        borderColor="green"
                        :model="item.unload_load_time1"
                        hideDetail
                        @update-data="
                          updateDataContainer('unload_load_time1', i, $event)
                        "
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>

            <!-- Group 4 -->
            <v-flex mr-4 shrink>
              <v-layout>
                <v-flex xs4>
                  <v-layout>
                    <v-flex class="font-12  mr-2">
                      <label v-if="i == 0" class=" font-weight-bold">
                        WH/Factory2 :
                      </label>
                      <AutocompleteInput
                        :disabled="item.tender > 0"
                        clearable
                        hideDetail
                        placeholder="WH/Factory2"
                        :model="item.wh_factory_2_id"
                        rulse="fieldRequire"
                        :items="factoryList"
                        itemText="wh_factory_name"
                        itemValue="wh_factory_id"
                        @update-data="
                          updateDataContainerSelect('wh_factory_2', i, $event)
                        "
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs8 class="font-12 ">
                  <label v-if="i == 0" class=" font-weight-bold">
                    Unload / Load Date Time2 :
                  </label>
                  <v-layout class="align-center">
                    <v-flex class="mr-2">
                      <DatePicker
                        placeholder="DD/MM/YY"
                        :disabled="item.tender > 0"
                        borderColor="green"
                        hideDetail
                        rulse="fieldRequire"
                        :model="date_con[i].unload_load_date2"
                        @update-data="
                          updateDateContainer('unload_load_date2', i, $event)
                        "
                      ></DatePicker>
                    </v-flex>
                    <v-flex>
                      <TimePicker
                        :disabled="item.tender > 0"
                        placeholder="00:00"
                        borderColor="green"
                        :model="item.unload_load_time2"
                        hideDetail
                        @update-data="
                          updateDataContainer('unload_load_time2', i, $event)
                        "
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>

            <!-- btn add/remove -->
            <v-flex
              xs2
              class="d-flex align-center"
              :class="i == 0 ? 'mt-4' : ''"
            >
              <v-btn
                class="ml-3"
                width="20"
                height="20"
                depressed
                fab
                small
                outlined
                color="gray"
                :disabled="containers_form.length <= 1"
                @click="removeContainer(i)"
              >
                <v-icon dark>
                  mdi-minus
                </v-icon>
              </v-btn>
              <v-btn
                class="ml-3"
                width="20"
                height="20"
                depressed
                fab
                small
                outlined
                v-show="containers_form.length === i + 1"
                @click="addContainer"
              >
                <v-icon dark>
                  mdi-plus
                </v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-divider class="mt-6"></v-divider>

      <v-layout class="mb-n6">
        <v-flex xs6 class="d-flex justify-center align-center">
          <v-layout>
            <v-flex class="font-12 text-end xs3 font-weight-bold">
              Size Container :
            </v-flex>
            <v-flex xs9 ml-3>
              <v-layout mb-2>
                <v-flex
                  shrink
                  class="font-11 mr-4"
                  :style="
                    !containers.cn_20.isActive ? { color: '#C4C4C4' } : ''
                  "
                >
                  20' = {{ containers.cn_20.total }}
                </v-flex>
                <v-flex
                  shrink
                  class="font-11 mr-4"
                  :style="
                    !containers.cn_20_ot.isActive ? { color: '#C4C4C4' } : ''
                  "
                >
                  20'OT = {{ containers.cn_20_ot.total }}
                </v-flex>
                <v-flex
                  shrink
                  class="font-11 mr-4"
                  :style="
                    !containers.cn_20_fr.isActive ? { color: '#C4C4C4' } : ''
                  "
                >
                  20'FR = {{ containers.cn_20_fr.total }}
                </v-flex>
                <v-flex
                  shrink
                  class="font-11 mr-4"
                  :style="
                    !containers.cn_20_rf.isActive ? { color: '#C4C4C4' } : ''
                  "
                >
                  20'RF = {{ containers.cn_20_rf.total }}
                </v-flex>
                <v-flex
                  shrink
                  class="font-11 mr-4"
                  :style="
                    !containers.cn_20_ios_tank.isActive
                      ? { color: '#C4C4C4' }
                      : ''
                  "
                >
                  20'ISO TANK = {{ containers.cn_20_ios_tank.total }}
                </v-flex>
              </v-layout>
              <v-layout mb-2>
                <v-flex
                  shrink
                  class="font-11 mr-4"
                  :style="
                    !containers.cn_40.isActive ? { color: '#C4C4C4' } : ''
                  "
                >
                  40' = {{ containers.cn_40.total }}
                </v-flex>
                <v-flex
                  shrink
                  class="font-11 mr-4"
                  :style="
                    !containers.cn_40_hc.isActive ? { color: '#C4C4C4' } : ''
                  "
                >
                  40'HC = {{ containers.cn_40_hc.total }}
                </v-flex>
                <v-flex
                  shrink
                  class="font-11 mr-4"
                  :style="
                    !containers.cn_40_ot.isActive ? { color: '#C4C4C4' } : ''
                  "
                >
                  40'OT = {{ containers.cn_40_ot.total }}
                </v-flex>
                <v-flex
                  shrink
                  class="font-11 mr-4"
                  :style="
                    !containers.cn_40_fr.isActive ? { color: '#C4C4C4' } : ''
                  "
                >
                  40'FR = {{ containers.cn_40_fr.total }}
                </v-flex>
                <v-flex
                  shrink
                  class="font-11 mr-4"
                  :style="
                    !containers.cn_40_rf.isActive ? { color: '#C4C4C4' } : ''
                  "
                >
                  40'RF = {{ containers.cn_40_rf.total }}
                </v-flex>
                <v-flex
                  shrink
                  class="font-11 mr-4"
                  :style="
                    !containers.cn_45.isActive ? { color: '#C4C4C4' } : ''
                  "
                >
                  45' = {{ containers.cn_45.total }}
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex
                  shrink
                  class="font-11 mr-4"
                  :style="
                    !containers.cn_20_other.isActive ? { color: '#C4C4C4' } : ''
                  "
                >
                  20' Other= {{ containers.cn_20_other.total }}
                </v-flex>
                <v-flex
                  shrink
                  class="font-11 mr-4"
                  :style="
                    !containers.cn_40_other.isActive ? { color: '#C4C4C4' } : ''
                  "
                >
                  40' Other = {{ containers.cn_40_other.total }}
                </v-flex>
                <v-flex
                  shrink
                  class="font-11 mr-4"
                  :style="
                    !containers.cn_45_other.isActive ? { color: '#C4C4C4' } : ''
                  "
                >
                  45' Other = {{ containers.cn_45_other.total }}
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-divider vertical></v-divider>
        <v-flex
          xs1
          class="text-center d-flex justify-center align-center font-12 font-weight-bold px-3"
        >
          Total : {{ form.ct_total ? form.ct_total : 0 }}
        </v-flex>
        <v-divider vertical></v-divider>
        <v-flex xs5 mt-4 mb-6>
          <v-layout>
            <v-flex class="xs12">
              <v-layout>
                <v-flex
                  class="xs3 text-end font-12  font-weight-bold mr-3 pt-2"
                >
                  Remark :
                </v-flex>
                <v-flex class="xs10 text-start font-14 mr-3 pt-2">
                  <TextareaInput
                    row="2"
                    placeholder="หมายเหตุ"
                    hideDetail
                    rulse="fieldRequire"
                    :model="form.remark"
                    @update-data="updateDate('remark', $event)"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-form>

    <div class="text-end mt-6" v-if="!user.carrier_id">
      <v-btn
        @click="$router.push('/order')"
        class="mr-4 font-weight-bold text-capitalize"
        outlined
        width="150"
        color="#4B5768"
      >
        Cancel
      </v-btn>

      <v-btn
        class="mr-4 font-weight-bold text-capitalize"
        width="150"
        :disabled="!valid"
        :dark="valid"
        color="#338A68"
        @click="save"
      >
        Save
      </v-btn>
    </div>

    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
    />
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mapState } from "vuex";
import TextInput from "../../../../components/input/TextInput.vue";
import AutocompleteInput from "../../../../components/select/AutocompleteInput";
import DatePicker from "../../../../components/picker/DatePicker";
import TimePicker from "../../../../components/picker/TimePicker.vue";
import TextareaInput from "../../../../components/input/TextareaInput.vue";
export default {
  components: {
    TextInput,
    AutocompleteInput,
    DatePicker,
    TimePicker,
    TextareaInput,
    Loading,
  },
  props: {
    mode: String,
    statusForm: String,
  },
  data: () => ({
    isLoading: false,
    fullPage: true,
    valid: true,
    order_id: null,
    form: {
      user_id: "",
      user_name: "",
      customer_id: "",
      customer_name: "",
      exporter_id: "",
      exporter_name: "",
      inv_po: "",
      booking_no: "",
      return_place_id: "",
      return_place_name: "",
      cy_date: "",
      shipping_name: "",
      shipping_id: "",
      haulage_id: "",
      haulage_name: "",
      shore_id: "",
      shore_name: "",
      fumigate_id: "",
      fumigate_name: "",
      survey_id: "",
      survey_name: "",
      others_id: "",
      others_name: "",
      si_cut_off_date: "",
      si_cut_off_time: "",
      vgm_cut_off_date: "",
      vgm_cut_off_time: "",
      goods_id: "",
      goods_name: "",
      package_id: "",
      package_name: "",
      ton: "",
      forwarder_id: "",
      forwarder_name: "",
      shipping_agent_id: "",
      shipping_agent_name: "",
      port_country_id: "",
      port_country_name: "",
      feeder_vessel: "",
      ocean_vessel: "",
      frd: "",
      closing_date: "",
      closing_time: "",
      ct_total: "",
      container_status: "",
      remark: "",
      receipt_type_id: "",
      receipt_type_name: "",
      address_id: "",
      address_name: "",
      is_cost_for_lan: 0,
      is_cost_for_port: 0,
      is_cost_for_lifting: 0,
      is_cost_for_scale: 0,
      name_cost_for_lan: "",
      name_cost_for_port: "",
      name_cost_for_lifting: "",
      name_cost_for_scale: "",
    },
    containers_form: [
      {
        order_detail_id: null,
        cy_place_id: "",
        cy_place_name: "",
        container_size_id: "",
        container_size_name: "",
        container_isotype_id: "",
        container_isotype_name: "",
        qty: "0",
        wh_factory_1_id: "",
        wh_factory_1_name: "",
        wh_factory_2_id: "",
        wh_factory_2_name: "",
        unload_load_date1: "",
        unload_load_time1: "",
        unload_load_date2: "",
        unload_load_time2: "",
      },
    ],
    date: {
      load_date: "",
      closing_date: "",
      cy_date: "",
      si_cut_off_date: "",
      vgm_cut_off_date: "",
      frd: "",
      unload_load_date1: "",
      unload_load_date2: "",
    },
    date_con: [
      {
        unload_load_date1: "",
        unload_load_date2: "",
      },
    ],
    receiptTypeList: [
      { receipt_type_id: 1, receipt_type_name: "Customer" },
      { receipt_type_id: 2, receipt_type_name: "Exporter" },
    ],
    addressList: [
      { address_id: 1, address_name: "Address1" },
      { address_id: 2, address_name: "Address2" },
    ],
    customerList: [],
    factoryList: [],
    cyPlaceList: [],
    shippingList: [],
    shippingAgentList: [],
    haulageList: [],
    shoreList: [],
    fumigateList: [],
    surveyList: [],
    othersList: [],
    goodsList: [],
    forwarderList: [],
    packageList: [],
    exporterList: [],
    portCountryList: [],
    returnPlaceList: [],
    containerSizeList: [],
    containerIsoTypeList: [],
    containers: {
      cn_20: { total: 0, isActive: false },
      cn_20_ot: { total: 0, isActive: false },
      cn_20_fr: { total: 0, isActive: false },
      cn_20_rf: { total: 0, isActive: false },
      cn_20_ios_tank: { total: 0, isActive: false },
      cn_20_other: { total: 0, isActive: false },
      cn_40: { total: 0, isActive: false },
      cn_40_hc: { total: 0, isActive: false },
      cn_40_ot: { total: 0, isActive: false },
      cn_40_fr: { total: 0, isActive: false },
      cn_40_rf: { total: 0, isActive: false },
      cn_40_other: { total: 0, isActive: false },
      cn_45: { total: 0, isActive: false },
      cn_45_other: { total: 0, isActive: false },
    },
  }),
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  watch: {
    containers_form: {
      handler: function(newValue) {
        var total = 0;
        var cn_20 = 0;
        var cn_20_ot = 0;
        var cn_20_fr = 0;
        var cn_20_rf = 0;
        var cn_20_ios_tank = 0;
        var cn_20_other = 0;
        var cn_40 = 0;
        var cn_40_hc = 0;
        var cn_40_ot = 0;
        var cn_40_fr = 0;
        var cn_40_rf = 0;
        var cn_40_other = 0;
        var cn_45 = 0;
        var cn_45_other = 0;
        if (newValue) {
          newValue.forEach((data, i) => {
            total += parseInt(data.qty);
            switch (data.container_size_id) {
              case 1:
                if (data.container_isotype_id === 1) {
                  cn_20_ot += parseInt(data.qty);
                } else if (data.container_isotype_id === 2) {
                  cn_20_fr += parseInt(data.qty);
                } else if (data.container_isotype_id === 3) {
                  cn_20_rf += parseInt(data.qty);
                } else if (data.container_isotype_id === 4) {
                  cn_20_ios_tank += parseInt(data.qty);
                } else if (data.container_isotype_id === 5) {
                  cn_20_other += parseInt(data.qty);
                } else {
                  cn_20 += parseInt(data.qty);
                }
                break;

              case 2:
                if (data.container_isotype_id === 1) {
                  cn_40_ot += parseInt(data.qty);
                } else if (data.container_isotype_id === 2) {
                  cn_40_fr += parseInt(data.qty);
                } else if (data.container_isotype_id === 3) {
                  cn_40_rf += parseInt(data.qty);
                } else if (data.container_isotype_id === 4) {
                  cn_40_other += parseInt(data.qty);
                } else if (data.container_isotype_id === 5) {
                  cn_40_hc += parseInt(data.qty);
                } else {
                  cn_40 += parseInt(data.qty);
                }
                break;

              case 3:
                if (data.container_isotype_id === 1) {
                  cn_45_other += parseInt(data.qty);
                } else if (data.container_isotype_id === 2) {
                  cn_45_other += parseInt(data.qty);
                } else if (data.container_isotype_id === 3) {
                  cn_45_other += parseInt(data.qty);
                } else if (data.container_isotype_id === 4) {
                  cn_45_other += parseInt(data.qty);
                } else if (data.container_isotype_id === 5) {
                  cn_45_other += parseInt(data.qty);
                } else {
                  cn_45 += parseInt(data.qty);
                }
                break;
              default:
                return 0;
            }
          });

          this.form.ct_total = total;

          this.containers.cn_20.total = cn_20;
          this.containers.cn_20.isActive = cn_20 != 0 ? true : false;

          this.containers.cn_20_ot.total = cn_20_ot;
          this.containers.cn_20_ot.isActive = cn_20_ot != 0 ? true : false;

          this.containers.cn_20_fr.total = cn_20_fr;
          this.containers.cn_20_fr.isActive = cn_20_fr != 0 ? true : false;

          this.containers.cn_20_rf.total = cn_20_rf;
          this.containers.cn_20_rf.isActive = cn_20_rf != 0 ? true : false;

          this.containers.cn_20_ios_tank.total = cn_20_ios_tank;
          this.containers.cn_20_ios_tank.isActive =
            cn_20_ios_tank != 0 ? true : false;

          this.containers.cn_20_other.total = cn_20_other;
          this.containers.cn_20_other.isActive =
            cn_20_other != 0 ? true : false;

          this.containers.cn_40.total = cn_40;
          this.containers.cn_40.isActive = cn_40 != 0 ? true : false;

          this.containers.cn_40_hc.total = cn_40_hc;
          this.containers.cn_40_hc.isActive = cn_40_hc != 0 ? true : false;

          this.containers.cn_40_ot.total = cn_40_ot;
          this.containers.cn_40_ot.isActive = cn_40_ot != 0 ? true : false;

          this.containers.cn_40_fr.total = cn_40_fr;
          this.containers.cn_40_fr.isActive = cn_40_fr != 0 ? true : false;

          this.containers.cn_40_rf.total = cn_40_rf;
          this.containers.cn_40_rf.isActive = cn_40_rf != 0 ? true : false;

          this.containers.cn_40_other.total = cn_40_other;
          this.containers.cn_40_other.isActive =
            cn_40_other != 0 ? true : false;

          this.containers.cn_45.total = cn_45;
          this.containers.cn_45.isActive = cn_45 != 0 ? true : false;

          this.containers.cn_45_other.total = cn_45_other;
          this.containers.cn_45_other.isActive =
            cn_45_other != 0 ? true : false;
        }
      },
      deep: true,
    },
  },
  created() {
    this.isLoading = true;
    Promise.all([this.getMasterData(), this.getMasterCarrier()])
      .then((values) => {
        this.isLoading = false;
        if (this.statusForm === "editOrder") {
          this.getOrderById(this.$route.params.id);
        }
      })
      .catch((error) => {
        console.log(error.response.data.message);
        this.isLoading = false;
      });
  },
  methods: {
    getOrderById(id) {
      this.isLoading = true;
      this.$store
        .dispatch("order/getOrderById", id)
        .then((res) => {
          this.order_id = res.data.id;
          this.form.user_id = res.data.user_id;
          this.form.user_name = res.data.user_name;
          this.form.customer_id = res.data.customer_id;
          this.form.customer_name = res.data.customer_name;
          this.form.inv_po = res.data.inv_po;
          this.form.booking_no = res.data.booking_no;
          this.form.return_place_id = res.data.return_place_id;
          this.form.return_place_name = res.data.return_place_name;
          this.form.cy_date = res.data.cy_date;
          this.date.cy_date = res.data.cy_date
            ? this.customDate(res.data.cy_date)
            : "";
          this.form.shipping_name = res.data.shipping_name;
          this.form.shipping_id = res.data.shipping_id;
          this.form.haulage_id = res.data.haulage_id;
          this.form.haulage_name = res.data.haulage_name;
          this.form.shore_id = res.data.shore_id;
          this.form.shore_name = res.data.shore_name;
          this.form.fumigate_id = res.data.fumigate_id;
          this.form.fumigate_name = res.data.fumigate_name;
          this.form.survey_id = res.data.survey_id;
          this.form.survey_name = res.data.survey_name;
          this.form.others_id = res.data.others_id;
          this.form.others_name = res.data.others_name;
          this.form.si_cut_off_date = res.data.si_cut_off_date;
          this.date.si_cut_off_date = res.data.si_cut_off_date
            ? this.customDate(res.data.si_cut_off_date)
            : "";
          this.form.si_cut_off_time = res.data.si_cut_off_time
            ? this.subStringTime(res.data.si_cut_off_time)
            : "";
          this.form.vgm_cut_off_date = res.data.vgm_cut_off_date;
          this.date.vgm_cut_off_date = res.data.vgm_cut_off_date
            ? this.customDate(res.data.vgm_cut_off_date)
            : "";
          this.form.vgm_cut_off_time = res.data.vgm_cut_off_time
            ? this.subStringTime(res.data.vgm_cut_off_time)
            : "";
          this.form.goods_id = res.data.goods_id;
          this.form.goods_name = res.data.goods_name;
          this.form.package_id = res.data.package_id;
          this.form.package_name = res.data.package_name;
          this.form.ton = res.data.ton;
          this.form.forwarder_id = res.data.forwarder_id;
          this.form.forwarder_name = res.data.forwarder_name;
          this.form.shipping_agent_id = res.data.shipping_agent_id;
          this.form.shipping_agent_name = res.data.shipping_agent_name;
          this.form.port_country_id = res.data.port_country_id;
          this.form.port_country_name = res.data.port_country_name;
          this.form.feeder_vessel = res.data.feeder_vessel;
          this.form.ocean_vessel = res.data.ocean_vessel;
          this.form.frd = res.data.frd;
          this.date.frd = res.data.frd ? this.customDate(res.data.frd) : "";
          this.form.closing_date = res.data.closing_date;
          this.date.closing_date = res.data.closing_date
            ? this.customDate(res.data.closing_date)
            : "";
          this.form.closing_time = res.data.closing_time
            ? this.subStringTime(res.data.closing_time)
            : "";
          this.form.ct_total = res.data.ct_total;
          this.form.remark = res.data.remark;
          this.form.exporter_id = res.data.exporter_id;
          this.form.exporter_name = res.data.exporter_name;
          this.form.container_status = res.data.container_status;
          this.form.receipt_type_id = res.data.receipt_type_id;
          this.form.receipt_type_name = res.data.receipt_type_name;
          this.form.address_id = res.data.address_id;
          this.form.address_name = res.data.address_name;
          this.form.is_cost_for_lan = res.data.is_cost_for_lan;
          this.form.is_cost_for_port = res.data.is_cost_for_port;
          this.form.is_cost_for_lifting = res.data.is_cost_for_lifting;
          this.form.is_cost_for_scale = res.data.is_cost_for_scale;
          this.form.name_cost_for_lan = res.data.name_cost_for_lan;
          this.form.name_cost_for_port = res.data.name_cost_for_port;
          this.form.name_cost_for_lifting = res.data.name_cost_for_lifting;
          this.form.name_cost_for_scale = res.data.name_cost_for_scale;

          if (res.data.order_detail_with_tender.length > 0) {
            this.containers_form = [];
            this.date_con = [];
            res.data.order_detail_with_tender.forEach((data) => {
              this.date_con.push({
                unload_load_date1: data.unload_load_date1
                  ? this.customDate(data.unload_load_date1)
                  : "",
                unload_load_date2: data.unload_load_date2
                  ? this.customDate(data.unload_load_date2)
                  : "",
              });
              this.containers_form.push({
                tender: data.tender_carrier.length,
                order_detail_id: data.order_detail_id,
                cy_place_id: data.cy_place_id,
                cy_place_name: data.cy_place_name,
                container_size_id: data.container_size_id,
                container_size_name: data.container_size_name,
                container_isotype_id: data.container_isotype_id,
                container_isotype_name: data.container_isotype_name,
                qty: data.qty,
                wh_factory_1_id: data.wh_factory_1_id,
                wh_factory_1_name: data.wh_factory_1_name,
                wh_factory_2_id: data.wh_factory_2_id,
                wh_factory_2_name: data.wh_factory_2_name,
                unload_load_date1: data.unload_load_date1,
                unload_load_time1: data.unload_load_time1
                  ? this.subStringTime(data.unload_load_time1)
                  : "",
                unload_load_date2: data.unload_load_date2,
                unload_load_time2: data.unload_load_time2
                  ? this.subStringTime(data.unload_load_time2)
                  : "",
              });
            });
          }

          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    async getMasterData() {
      const table = [
        "customers",
        "goods",
        "forwarder",
        "shipping_agent",
        "port_discharge",
        "cy_place",
        "container_size",
        "container_isotype",
        "wh_factory",
        "package",
        "exporter",
        "port_country",
      ];
      const res = await this.$store.dispatch(
        "master_data/getMasterData",
        table
      );
      this.customerList = res.data[0].customers;
      this.goodsList = res.data[1].goods;
      this.forwarderList = res.data[2].forwarder;
      this.shippingAgentList = res.data[3].shipping_agent;
      this.portCountryList = res.data[11].port_country;
      this.returnPlaceList = res.data[4].port_discharge; // [fix] change res.data[5].cy_place => res.data[4].port_discharge
      this.cyPlaceList = res.data[5].cy_place;
      this.containerSizeList = res.data[6].container_size;
      this.containerIsoTypeList = res.data[7].container_isotype;
      this.factoryList = res.data[8].wh_factory;
      this.packageList = res.data[9].package;
      this.exporterList = res.data[10].exporter;

      return res.data;
    },
    async getMasterCarrier() {
      const fields = [
        "is_shipping",
        "is_shore",
        "is_survey",
        "is_haulage",
        "is_fumigate",
        "is_other",
      ];
      const res = await this.$store.dispatch(
        "master_data/getMasterCarrier",
        fields
      );
      this.shippingList = res.data[0].is_shipping;
      this.shoreList = res.data[1].is_shore;
      this.surveyList = res.data[2].is_survey;
      this.haulageList = res.data[3].is_haulage;
      this.fumigateList = res.data[4].is_fumigate;
      this.othersList = res.data[5].is_other;
      return res.data;
    },
    save() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;

        this.form["mode"] = this.mode;
        this.form["user_id"] = this.user.id;
        this.form["user_name"] = this.user.firstname;

        // Create
        if (this.statusForm !== "editOrder") {
          this.$store
            .dispatch("order/saveOrder", {
              form_data: this.form,
              form_container: this.containers_form,
            })
            .then((res) => {
              this.isLoading = false;
              if (!res.data.success) {
                for (const data in res.data.data.error) {
                  this.$swal.fire({
                    icon: "error",
                    title: "Save Failed",
                    text: res.data.data.error[data][0],
                  });
                }
              } else {
                this.$swal
                  .fire({
                    icon: "success",
                    title: "Save Order successfully!",
                    showConfirmButton: false,
                    timer: 1500,
                  })
                  .then((result) => {
                    this.$router.push("/order");
                  });
              }
            })
            .catch((error) => {
              this.isLoading = false;
              if (error.response.status === 500) {
                this.$swal.fire({
                  icon: "error",
                  title: "Save Failed",
                  text: error.response.data.message,
                });
              } else {
                this.$swal.fire({
                  icon: "error",
                  title: "Save Failed",
                  text: "กรุณาตรวจสอบฟอร์ม Order",
                });
              }
            });
        }

        // Update
        else {
          this.$store
            .dispatch("order/updateOrder", {
              id: this.order_id,
              form_data: this.form,
              form_container: this.containers_form,
            })
            .then((res) => {
              this.isLoading = false;
              if (!res.data.success) {
                for (const data in res.data.data.error) {
                  this.$swal.fire({
                    icon: "error",
                    title: "Save Failed",
                    text: res.data.data.error[data][0],
                  });
                }
              } else {
                this.$swal
                  .fire({
                    icon: "success",
                    title: "Update Order successfully!",
                    showConfirmButton: false,
                    timer: 1500,
                  })
                  .then((result) => {
                    this.$router.push("/order");
                  });
              }
            })
            .catch((error) => {
              this.isLoading = false;
              console.log(error.response.data.data);
              this.$swal.fire({
                icon: "error",
                title: "Save Failed",
                text: "กรุณาตรวจสอบฟอร์ม Order",
              });
            });
        }
      }
    },
    updateMode(val) {
      this.mode = val;
    },
    updateData(name, val) {
      this.form[name] = val;
    },
    // updateSelect(name, val) {
    //   if (name === "return_place") {
    //     this.form[name + "_id"] = val.cy_place_id;
    //     this.form[name + "_name"] = val.cy_place_name;
    //   } else if (name === "customer") {
    //     this.form[name + "_id"] = val[name + "_id"];
    //     this.form[name + "_name"] = val[name + "_code"];
    //   } else {
    //     this.form[name + "_id"] = val[name + "_id"];
    //     this.form[name + "_name"] = val[name + "_name"];
    //   }
    // },
    updateSelect(name, keyId, keyName, val) {
      this.form[name + "_id"] = val ? val[keyId] : "";
      this.form[name + "_name"] = val ? val[keyName] : "";
    },
    updateDate(name, val) {
      this.form[name] = val ? val : "";
      this.date[name] = val ? this.customDate(val) : "";
    },
    updateDataContainer(name, i, val) {
      this.containers_form[i][name] = val;
    },
    updateDateContainer(name, i, val) {
      this.containers_form[i][name] = val ? val : "";
      this.date_con[i][name] = val ? this.customDate(val) : "";
    },
    clearDateContainer(name, i) {
      this.containers_form[i][name] = "";
      this.date_con[i][name] = "";
    },
    updateDataContainerSelect(name, i, val) {
      if (name === "container_size") {
        this.containers_form[i][name + "_id"] = val
          ? val.container_size_id
          : "";
        this.containers_form[i][name + "_name"] = val ? val.size : "";
      } else if (name === "container_isotype") {
        this.containers_form[i][name + "_id"] = val
          ? val.container_isotype_id
          : "";
        this.containers_form[i][name + "_name"] = val ? val.isotype : "";
      } else if (name === "cy_place") {
        this.containers_form[i][name + "_id"] = val ? val.cy_place_id : "";
        this.containers_form[i][name + "_name"] = val ? val.cy_place_name : "";
      } else {
        this.containers_form[i][name + "_id"] = val ? val.wh_factory_id : "";
        this.containers_form[i][name + "_name"] = val
          ? val.wh_factory_name
          : "";
      }
    },
    customDate(val) {
      let sub_year = val.substring(0, 4);
      let sub_month = val.substring(5, 7);
      let sub_day = val.substring(8, 10);
      // sub_year = parseInt(sub_year) + 543;
      let changeDate = sub_day + "/" + sub_month + "/" + sub_year;
      return changeDate;
    },
    addContainer() {
      this.containers_form.push({
        order_detail_id: "",
        cy_place_id: "",
        cy_place_name: "",
        container_size_id: "",
        container_size_name: "",
        container_isotype_id: "",
        container_isotype_name: "",
        qty: "0",
        wh_factory_1_id: "",
        wh_factory_1_name: "",
        wh_factory_2_id: "",
        wh_factory_2_name: "",
        unload_load_date1: "",
        unload_load_time1: "",
        unload_load_date2: "",
        unload_load_time2: "",
      });
      this.date_con.push({
        unload_load_date1: "",
        unload_load_date2: "",
      });
    },
    removeContainer(i) {
      this.containers_form = this.containers_form.filter(
        (item, index) => index !== i
      );
    },

    onHandleAddress(val) {
      this.form.address_id = val ? val.address_id : "";

      if (this.form.receipt_type_id === 1) {
        this.customerList.find((data) => {
          if (data.customer_id == this.form.customer_id) {
            if (this.form.address_id == 1) {
              this.form.address_name =
                (data.master_ssuance_backup_receipts1 &&
                data.master_ssuance_backup_receipts1 !== "null"
                  ? data.master_ssuance_backup_receipts1
                  : "") +
                " " +
                (data.address_receipts1 && data.address_receipts1 !== "null"
                  ? data.address_receipts1
                  : "") +
                " " +
                (data.tax_no1 && data.tax_no1 !== "null" ? data.tax_no1 : "");
            } else {
              this.form.address_name =
                (data.master_ssuance_backup_receipts2 &&
                data.master_ssuance_backup_receipts2 !== "null"
                  ? data.master_ssuance_backup_receipts2
                  : "") +
                " " +
                (data.address_receipts2 && data.address_receipts2 !== "null"
                  ? data.address_receipts2
                  : "") +
                " " +
                (data.tax_no2 && data.tax_no2 !== "null" ? data.tax_no2 : "");
            }
          }
        });
      }

      if (this.form.receipt_type_id === 2) {
        this.exporterList.find((data) => {
          if (data.customer_id == this.form.exporter_id) {
            if (this.form.address_id == 1) {
              this.form.address_name =
                (data.master_ssuance_backup_receipts1 &&
                data.master_ssuance_backup_receipts1 !== "null"
                  ? data.master_ssuance_backup_receipts1
                  : "") +
                " " +
                (data.address_receipts1 && data.address_receipts1 !== "null"
                  ? data.address_receipts1
                  : "") +
                " " +
                (data.tax_no1 && data.tax_no1 !== "null" ? data.tax_no1 : "");
            } else {
              this.form.address_name =
                (data.master_ssuance_backup_receipts2 &&
                data.master_ssuance_backup_receipts2 !== "null"
                  ? data.master_ssuance_backup_receipts2
                  : "") +
                " " +
                (data.address_receipts2 && data.address_receipts2 !== "null"
                  ? data.address_receipts2
                  : "") +
                " " +
                (data.tax_no2 && data.tax_no2 !== "null" ? data.tax_no2 : "");
            }
          }
        });
      }
    },

    setData() {
      this.form.user_id = "";
      this.form.user_name = "";
      this.form.customer_id = 1;
      this.form.customer_name = "AAA";
      this.form.inv_po = "1111";
      this.form.booking_no = "B1111";
      this.form.return_place_id = 1;
      this.form.return_place_name = "BANGKOK CONTAINER DEPOT (BCDS)";
      this.form.cy_date = "2021-05-20";
      this.date.cy_date = this.customDate("2021-04-27");
      this.form.shipping_name = "บจก.แอ็คทีฟ โลจิสติกส์";
      this.form.shipping_id = 2;
      this.form.haulage_id = 3;
      this.form.haulage_name = "UDOMPATTANA SAMUTSAKON 1991 CO.;LTD.";
      this.form.shore_id = "";
      this.form.shore_name = "";
      this.form.fumigate_id = "";
      this.form.fumigate_name = "";
      this.form.survey_id = "";
      this.form.survey_name = "";
      this.form.others_id = "";
      this.form.others_name = "";
      this.form.si_cut_off_date = "2021-05-20";
      this.date.si_cut_off_date = this.customDate("2021-04-27");
      this.form.si_cut_off_time = "08:39";
      this.form.vgm_cut_off_date = "2021-05-20";
      this.date.vgm_cut_off_date = this.customDate("2021-04-27");
      this.form.vgm_cut_off_time = "08:40";
      this.form.goods_id = 1;
      this.form.goods_name = "Resin";
      this.form.package_id = 1;
      this.form.package_name = "BIG BAG";
      this.form.ton = "3";
      this.form.forwarder_id = 1;
      this.form.forwarder_name = "ADVANCE INTERFREIGHT";
      this.form.shipping_agent_id = 1;
      this.form.shipping_agent_name = "ADVANCE INTERFREIGHT";
      this.form.port_country_id = 2;
      this.form.port_country_name = "LCB";
      this.form.feeder_vessel = "NYK 1111";
      this.form.ocean_vessel = "OOCL 222";
      this.form.frd = "2021-05-20";
      this.date.frd = this.customDate("2021-04-27");
      this.form.closing_date = "2021-05-20";
      this.date.closing_date = this.customDate("2021-04-27");
      this.form.closing_time = "09:45";
      this.form.ct_total = 1;
      this.form.remark = "test";

      this.containers_form = [
        {
          cy_place_id: 1,
          cy_place_name: "BANGKOK CONTAINER DEPOT (BCDS)",
          container_size_id: 1,
          container_size_name: "20",
          container_isotype_id: 1,
          container_isotype_name: "OT",
          qty: "1",
          wh_factory_1_id: 1,
          wh_factory_1_name: "ALCOTT",
          wh_factory_2_id: 1,
          wh_factory_2_name: "ALCOTT",
          unload_load_date1: "2021-05-20",
          unload_load_time1: "09:45",
          unload_load_date2: "2021-05-20",
          unload_load_time2: "11:55",
        },
      ];
    },
  },
};
</script>

<style></style>
