<template>
  <div class="pa-6 mb-16">
    <div class="d-flex align-center">
      <v-icon color="#338A68" small class="mr-1">mdi-file</v-icon>
      <span class=" font-weight-bold text-uppercase">
        MONITORING
      </span>
    </div>

    <v-row class="my-1">
      <v-col cols="6">
        <v-card outlined class="px-3 py-6 filter-search">
          <v-layout>
            <v-flex xs6>
              <!-- Customer -->
              <v-layout class="align-center mb-3">
                <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
                  Customer :
                </v-flex>
                <v-flex class="xs9">
                  <v-autocomplete
                    height="37px"
                    append-icon="mdi-chevron-down"
                    menu-props="offsetY"
                    color="#98CA54"
                    outlined
                    dense
                    class="gray"
                    :items="customerList"
                    v-model="filter.customer_id"
                    hide-details
                    placeholder="Customer"
                    item-text="customer_code"
                    item-value="customer_id"
                    clearable
                  >
                  </v-autocomplete>
                </v-flex>
              </v-layout>

              <!-- Importer -->
              <v-layout class="align-center mb-3">
                <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
                  Importer <br />
                  /Exporter :
                </v-flex>
                <v-flex class="xs9">
                  <v-autocomplete
                    height="37px"
                    append-icon="mdi-chevron-down"
                    menu-props="offsetY"
                    color="#98CA54"
                    outlined
                    dense
                    class="gray"
                    :items="importerList"
                    v-model="filter.importer_exporter_id"
                    hide-details
                    placeholder=" Importer/Exporter"
                    item-text="customer_code"
                    item-value="customer_id"
                    clearable
                  >
                  </v-autocomplete>
                </v-flex>
              </v-layout>

              <!--  INV./PO : -->
              <v-layout class="align-center mb-3">
                <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
                  INV./PO :
                </v-flex>
                <v-flex xs9>
                  <TextInput
                    hideDetails
                    placeholder="INV./PO"
                    :model="filter.inv_po"
                    rulse="fieldRequire"
                    @update-data="filter.inv_po = $event"
                  />
                </v-flex>
              </v-layout>

              <!-- Booking -->
              <v-layout class="align-center mb-3">
                <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
                  Booking :
                </v-flex>
                <v-flex xs9>
                  <TextInput
                    hideDetails
                    placeholder="Booking"
                    :model="filter.booking_no"
                    rulse="fieldRequire"
                    @update-data="filter.booking_no = $event"
                  />
                </v-flex>
              </v-layout>

              <!-- Container -->
              <v-layout class="align-center mb-3">
                <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
                  Container :
                </v-flex>
                <v-flex class="xs9">
                  <v-autocomplete
                    height="37px"
                    append-icon="mdi-chevron-down"
                    menu-props="offsetY"
                    color="#98CA54"
                    outlined
                    dense
                    class="gray"
                    :items="containeSize"
                    v-model="filter.container"
                    hide-details
                    placeholder="Container"
                    item-text="size"
                    item-value="size"
                    clearable
                  >
                  </v-autocomplete>
                </v-flex>
              </v-layout>

              <!-- Vendor -->
              <v-layout class="align-center mb-3">
                <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
                  Vendor :
                </v-flex>
                <v-flex class="xs9">
                  <v-autocomplete
                    height="37px"
                    append-icon="mdi-chevron-down"
                    menu-props="offsetY"
                    color="#98CA54"
                    outlined
                    dense
                    class="gray"
                    :items="carriers"
                    v-model="filter.carrier_id"
                    hide-details
                    placeholder="Carrier"
                    item-text="name"
                    item-value="carrier_id"
                    clearable
                  >
                  </v-autocomplete>
                </v-flex>
              </v-layout>

              <!-- Job No -->
              <v-layout class="align-center mb-3">
                <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
                  Job No. :
                </v-flex>
                <v-flex xs9>
                  <TextInput
                    hideDetails
                    placeholder="Job No."
                    :model="filter.order_id"
                    rulse="fieldRequire"
                    @update-data="filter.order_id = $event"
                  />
                </v-flex>
              </v-layout>
            </v-flex>

            <v-flex xs6>
              <!-- Cy Place : -->
              <v-layout class="align-center mb-3">
                <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
                  Cy Place :
                </v-flex>
                <v-flex class="xs9">
                  <v-autocomplete
                    height="37px"
                    append-icon="mdi-chevron-down"
                    menu-props="offsetY"
                    color="#98CA54"
                    outlined
                    dense
                    class="gray"
                    :items="cyPlaceList"
                    v-model="filter.cy_place_id"
                    hide-details
                    placeholder="CY Place"
                    item-text="cy_place_name"
                    item-value="cy_place_id"
                    clearable
                  >
                  </v-autocomplete>
                </v-flex>
              </v-layout>

              <!--  CY Date : -->
              <v-layout class="align-center mb-3">
                <v-flex class="xs3 text-end font-12 pr-3 font-weight-bold">
                  CY Date :
                </v-flex>
                <v-flex class="xs4 px-0">
                  <DatePicker
                    placeholder="DD/MM/YY"
                    hideDetails
                    hideDetail
                    rulse="fieldRequire"
                    :model="date.cy_date_start"
                    :max="filter.cy_date_end"
                    @update-data="updateDate('cy_date_start', $event)"
                  ></DatePicker>
                </v-flex>
                <v-flex class="xs1 text-end font-12 pr-1 font-weight-bold">
                  To :
                </v-flex>
                <v-flex class=" xs4">
                  <DatePicker
                    placeholder="DD/MM/YY"
                    hideDetails
                    hideDetail
                    rulse="fieldRequire"
                    :model="date.cy_date_end"
                    :min="filter.cy_date_start"
                    @update-data="updateDate('cy_date_end', $event)"
                  ></DatePicker>
                </v-flex>
              </v-layout>

              <!-- WH Factory -->
              <v-layout class="align-center mb-3">
                <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
                  WH/<br />Factory :
                </v-flex>
                <v-flex xs9>
                  <v-autocomplete
                    height="37px"
                    append-icon="mdi-chevron-down"
                    menu-props="offsetY"
                    color="#98CA54"
                    outlined
                    dense
                    class="gray"
                    :items="factoryList"
                    v-model="filter.wh_factory_id"
                    hide-details
                    placeholder="WH/Factory"
                    item-text="wh_factory_name"
                    item-value="wh_factory_id"
                    clearable
                  >
                  </v-autocomplete>
                </v-flex>
              </v-layout>

              <!-- Load  Unload Date -->
              <v-layout class="align-center mb-3">
                <v-flex class="xs3 text-end font-12 pr-3 font-weight-bold">
                  Load/ <br />
                  Unload Date :
                </v-flex>
                <v-flex class="xs4 px-0">
                  <DatePicker
                    placeholder="DD/MM/YY"
                    hideDetails
                    hideDetail
                    rulse="fieldRequire"
                    :model="date.unload_date_start"
                    :max="filter.unload_date_end"
                    @update-data="updateDate('unload_date_start', $event)"
                  ></DatePicker>
                </v-flex>
                <v-flex class="xs1 text-end font-12 pr-1 font-weight-bold">
                  To :
                </v-flex>
                <v-flex class=" xs4">
                  <DatePicker
                    placeholder="DD/MM/YY"
                    hideDetails
                    hideDetail
                    rulse="fieldRequire"
                    :model="date.unload_date_end"
                    :min="filter.unload_date_start"
                    @update-data="updateDate('unload_date_end', $event)"
                  ></DatePicker>
                </v-flex>
              </v-layout>

              <!-- Port -->
              <v-layout class="align-center mb-3">
                <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
                  Port :
                </v-flex>
                <v-flex class="xs9">
                  <v-autocomplete
                    height="37px"
                    append-icon="mdi-chevron-down"
                    menu-props="offsetY"
                    color="#98CA54"
                    outlined
                    dense
                    class="gray"
                    :items="releasePortList"
                    v-model="filter.port_discharge_id"
                    hide-details
                    placeholder="PORT"
                    item-text="port_discharge_name"
                    item-value="port_discharge_id"
                    clearable
                  >
                  </v-autocomplete>
                </v-flex>
              </v-layout>

              <!--Closing Date -->
              <v-layout class="align-center mb-3">
                <v-flex class="xs3 text-end font-12 pr-3 font-weight-bold">
                  Closing Date :
                </v-flex>
                <v-flex class="xs4 px-0">
                  <DatePicker
                    placeholder="DD/MM/YY"
                    hideDetails
                    hideDetail
                    rulse="fieldRequire"
                    :model="date.closing_date_start"
                    :max="filter.closing_date_end"
                    @update-data="updateDate('closing_date_start', $event)"
                  ></DatePicker>
                </v-flex>
                <v-flex class="xs1 text-end font-12 pr-1 font-weight-bold">
                  To :
                </v-flex>
                <v-flex class=" xs4">
                  <DatePicker
                    placeholder="DD/MM/YY"
                    hideDetails
                    hideDetail
                    rulse="fieldRequire"
                    :model="date.closing_date_end"
                    :min="filter.closing_date_start"
                    @update-data="updateDate('closing_date_end', $event)"
                  ></DatePicker>
                </v-flex>
              </v-layout>

              <v-layout class="align-center mb-3">
                <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
                  Driver :
                </v-flex>
                <v-flex class="xs9">
                  <v-autocomplete
                    height="37px"
                    append-icon="mdi-chevron-down"
                    menu-props="offsetY"
                    color="#98CA54"
                    outlined
                    dense
                    class="gray"
                    :items="driverList"
                    v-model="filter.driver_id"
                    hide-details
                    placeholder="Driver"
                    item-text="full_name"
                    item-value="driver_id"
                    clearable
                  >
                  </v-autocomplete>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card
          outlined
          flat
          class="pa-3"
          min-height="330px"
          height="393px"
          style="overflow-x: scroll;"
        >
          <v-card width="250" flat class="text-center">
            <v-layout v-if="isShow">
              <v-flex shrink v-for="(data, i) in dataGraph" :key="i">
                <div
                  style="width: 100%;text-overflow: ellipsis;overflow: hidden;white-space: pre;"
                >
                  <div>{{ data.title }}</div>
                  <DoughnutChart :data="data" />
                </div>
              </v-flex>
            </v-layout>
          </v-card>
        </v-card>
      </v-col>
    </v-row>

    <v-card width="100%" flat outlined class="mt-4">
      <v-layout justify-space-between>
        <v-flex shrink>
          <v-layout class="align-center text-end pl-6">
            <v-flex shrink class=" font-weight-bold">Mode : </v-flex>
            <v-flex shrink pl-6>
              <v-layout>
                <v-flex shrink mr-4>
                  <v-checkbox
                    color="#338A68"
                    v-model="is_import"
                    label="Import"
                  ></v-checkbox>
                </v-flex>
                <v-flex shrink mr-4>
                  <v-checkbox
                    color="#338A68"
                    v-model="is_export"
                    label="Export"
                  ></v-checkbox>
                </v-flex>
                <v-flex shrink mr-4>
                  <v-checkbox
                    color="#338A68"
                    v-model="is_empty"
                    label="Empty"
                  ></v-checkbox>
                </v-flex>
                <v-flex shrink mr-4>
                  <v-checkbox
                    color="#338A68"
                    v-model="is_laden"
                    label="Laden"
                  ></v-checkbox>
                </v-flex>
                <v-flex shrink mr-4>
                  <v-checkbox
                    color="#338A68"
                    v-model="is_drop_load"
                    label="Drop Load"
                  ></v-checkbox>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex shrink class="text-end pr-6">
          <v-btn
            depressed
            color="#F7D25D"
            class="my-4 font-weight-bold"
            @click="onClickPagination(1)"
          >
            <v-icon class="mr-3">mdi-magnify</v-icon>
            <span class="text-capitalize">Search</span>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card>

    <div>
      <v-btn
        outlined
        depressed
        color="#338A68"
        dark
        class="shrink my-4 font-weight-bold mr-3"
        @click="exportExcel()"
      >
        <img
          width="20"
          src="../../../assets/images/menu-icons/excel.png"
          alt=""
        />
        <span class="ml-2 shrink text-capitalize">Export Excel </span>
      </v-btn>
    </div>

    <div style="width: 100%" class="table-order">
      <v-data-table
        v-model="selectedMain"
        :headers="headers"
        :items="monitoringList"
        item-key="order_id"
        class="elevation-1"
        show-expand
        :items-per-page="10"
        hide-default-footer
        show-select
      >
        <template v-slot:item.exporter_name="{ item }">
          <div>
            {{ item.exporter_name ? item.exporter_name : item.importer_name }}
          </div>
        </template>

        <template v-slot:item.closing_date="{ item }">
          <div>
            {{ item.closing_date | moment("DD/MM/YYYY") }}
            {{ item.closing_time }}
          </div>
        </template>

        <template v-slot:item.cy_place_name="{ item }">
          <div>
            {{ groupCyPlace(item.order_detail) }}
            <!-- {{
              groupCyPlace(item.order_detail)
                ? groupCyPlace(item.order_detail)
                : item.release_port_name
            }} -->
          </div>
        </template>

        <template v-slot:item.release_port_name="{ item }">
          <div>
            {{ item.release_port_name }}
          </div>
        </template>

        <template v-slot:item.release_date="{ item }">
          <div>
            {{ item.release_date | moment("DD/MM/YYYY") }}
          </div>
        </template>

        <template v-slot:item.frd="{ item }">
          <div>
            {{ item.frd | moment("DD/MM/YYYY") }}
          </div>
        </template>

        <template v-slot:item.shipping_agent_name="{ item }">
          <div>
            {{
              item.shipping_agent_name
                ? item.shipping_agent_name
                : item.shipping_name
            }}
          </div>
        </template>

        <template v-slot:item.cy_date="{ item }">
          <div v-if="item.cy_date">
            {{ item.cy_date | moment("DD/MM/YYYY") }}
          </div>
          <div v-else>
            {{ item.release_date | moment("DD/MM/YYYY") }}
          </div>
        </template>

        <template v-slot:item.qty="{ item }">
          <div>{{ groupQty(item.order_detail) }}</div>
        </template>

        <template v-slot:item.size_iso_type="{ item }">
          <div>{{ groupContainerSize(item.order_detail) }}</div>
        </template>

        <template v-slot:item.wh_factory1="{ item }">
          <div>{{ groupWHFactory1(item.order_detail) }}</div>
        </template>

        <template v-slot:item.unload_date1="{ item }">
          <div>{{ groupUnloadDate1(item.order_detail) }}</div>
        </template>

        <template v-slot:item.unload_time1="{ item }">
          <div>{{ groupUnloadTime1(item.order_detail) }}</div>
        </template>

        <template v-slot:item.wh_factory2="{ item }">
          <div>{{ groupWHFactory2(item.order_detail) }}</div>
        </template>

        <template v-slot:item.unload_date2="{ item }">
          <div>{{ groupUnloadDate2(item.order_detail) }}</div>
        </template>

        <template v-slot:item.unload_time2="{ item }">
          <div>{{ groupUnloadTime2(item.order_detail) }}</div>
        </template>

        <template v-slot:item.return_place_name="{ item }">
          <div>
            {{ groupReturnPlace(item.order_detail) }}
          </div>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pa-0 ma-0">
            <v-data-table
              :headers="headers_driver_job"
              :items="item.driver_jobs"
              item-key="order_detail"
              class="elevation-1 table-child"
              hide-default-footer
              :items-per-page="9999"
            >
              <template v-slot:header.action="{ header }">
                <v-btn
                  fab
                  depressed
                  small
                  text
                  icon
                  height="20px"
                  width="20px"
                  @click="viewMonitoringDetail(item.order_id)"
                >
                  <v-icon color="#62C4E3">mdi-pencil-outline</v-icon>
                </v-btn>
              </template>

              <template v-slot:header.accept_tender>
                <div>
                  Accept Tender ({{ item.driver_jobs.length }}/{{
                    item.driver_jobs_accept_tender.length
                  }})
                </div>
              </template>

              <template v-slot:header.receive_container>
                <div>
                  Receive Container ({{
                    item.driver_jobs_recieve_container.length
                  }}/{{ item.driver_jobs_accept_tender.length }})
                </div>
              </template>

              <template v-slot:header.arrived>
                <div>
                  Arrived ({{ item.driver_jobs_recieve_container.length }}/{{
                    item.driver_jobs_arrived.length
                  }})
                </div>
              </template>

              <template v-slot:header.start_load_unload>
                <div>
                  Start Load/unload ({{ item.driver_jobs_arrived.length }}/{{
                    item.driver_jobs_start_load.length
                  }})
                </div>
              </template>

              <template v-slot:header.end_load_unload>
                <div>
                  Load/unload End ({{ item.driver_jobs_start_load.length }}/{{
                    item.driver_jobs_end_load.length
                  }})
                </div>
              </template>

              <template v-slot:header.return>
                <div>
                  Return ({{ item.driver_jobs_end_load.length }}/{{
                    item.driver_jobs_return.length
                  }})
                </div>
              </template>

              <template v-slot:item.no="{ index }">
                {{ index + 1 }}
              </template>

              <template v-slot:item.cy_place="{ item }">
                <div
                  v-if="
                    item.accept_tender &&
                      item.accept_tender.tender &&
                      item.accept_tender.tender.order_detail &&
                      item.accept_tender.tender.order_detail
                  "
                >
                  {{
                    item.accept_tender.tender.order_detail.cy_place_name
                      ? item.accept_tender.tender.order_detail.cy_place_name
                      : item.accept_tender.tender.order_detail.return_place_name
                  }}
                </div>
              </template>

              <template v-slot:item.qty="{ item }">
                {{
                  item.accept_tender &&
                  item.accept_tender.tender &&
                  item.accept_tender.tender.order_detail &&
                  item.accept_tender.tender.order_detail.qty
                    ? 1
                    : 0
                }}
              </template>

              <template v-slot:item.size="{ item }">
                {{
                  item.accept_tender &&
                  item.accept_tender.tender &&
                  item.accept_tender.tender.order_detail &&
                  item.accept_tender.tender.order_detail.container_size_name
                    ? item.accept_tender.tender.order_detail.container_size_name
                    : ""
                }}
              </template>

              <template v-slot:item.iso_type="{ item }">
                {{
                  item.accept_tender &&
                  item.accept_tender.tender &&
                  item.accept_tender.tender.order_detail &&
                  item.accept_tender.tender.order_detail.container_isotype_name
                    ? item.accept_tender.tender.order_detail
                        .container_isotype_name
                    : ""
                }}
              </template>

              <template v-slot:item.wh_factory1="{ item }">
                {{
                  item.accept_tender &&
                  item.accept_tender.tender &&
                  item.accept_tender.tender.order_detail &&
                  item.accept_tender.tender.order_detail.wh_factory_1_name
                    ? item.accept_tender.tender.order_detail.wh_factory_1_name
                    : ""
                }}
              </template>

              <template v-slot:item.accept_tender="{ item }">
                <div v-if="item.accept_tender_date" class="d-flex align-center">
                  <div
                    :class="
                      checkTabColor('accept_tender', item) === 'green-tab'
                        ? 'green-tab'
                        : 'orange-tab'
                    "
                    class="px-2 font-12 font-weight-bold text-center d-flex align-center justify-center"
                  >
                    {{ item.accept_tender_date | moment("DD/MM H:mm") }}
                  </div>
                </div>
                <div v-else>
                  <div
                    class="px-2 font-12 font-weight-bold text-center  d-flex align-center justify-center gray-tab"
                  >
                    {{ item.accept_tender_date | moment("DD/MM H:mm") }}
                  </div>
                </div>
              </template>

              <template v-slot:item.receive_container="{ item }">
                <div v-if="item.receive_container" class="d-flex align-center">
                  <div
                    class="px-2 font-12 font-weight-bold text-center  d-flex align-center justify-center"
                    :class="
                      item.status === 'REJECT'
                        ? 'reject-tab'
                        : checkTabColor('receive_container', item) ===
                          'green-tab'
                        ? 'green-tab'
                        : 'orange-tab'
                    "
                  >
                    {{ item.receive_container | moment("DD/MM H:mm") }}
                  </div>
                </div>
                <div v-else>
                  <div
                    class="px-2 font-12 font-weight-bold text-center  d-flex align-center justify-center gray-tab"
                  ></div>
                </div>
              </template>

              <template v-slot:item.arrived="{ item }">
                <div v-if="item.arrived" class="d-flex align-center">
                  <div
                    :class="
                      checkTabColor('arrived', item) === 'green-tab'
                        ? 'green-tab'
                        : 'orange-tab'
                    "
                    class="px-2 font-12 font-weight-bold text-center  d-flex align-center justify-center"
                  >
                    {{ item.arrived | moment("DD/MM H:mm") }}
                  </div>
                </div>
                <div v-else>
                  <div
                    class="px-2 font-12 font-weight-bold text-center  d-flex align-center justify-center gray-tab"
                  ></div>
                </div>
              </template>

              <template v-slot:item.start_load_unload="{ item }">
                <div v-if="item.start_load_unload" class="d-flex align-center">
                  <div
                    :class="
                      checkTabColor('start_load_unload', item) === 'green-tab'
                        ? 'green-tab'
                        : 'orange-tab'
                    "
                    class="px-2 font-12 font-weight-bold text-center  d-flex align-center justify-center"
                  >
                    {{ item.start_load_unload | moment("DD/MM H:mm") }}
                  </div>
                </div>
                <div v-else>
                  <div
                    class="px-2 font-12 font-weight-bold text-center  d-flex align-center justify-center gray-tab"
                  ></div>
                </div>
              </template>

              <template v-slot:item.end_load_unload="{ item }">
                <div v-if="item.end_load_unload" class="d-flex align-center">
                  <div
                    :class="
                      checkTabColor('end_load_unload', item) === 'green-tab'
                        ? 'green-tab'
                        : 'orange-tab'
                    "
                    class="px-2 font-12 font-weight-bold text-center  d-flex align-center justify-center"
                  >
                    {{ item.end_load_unload | moment("DD/MM H:mm") }}
                  </div>
                </div>
                <div v-else>
                  <div
                    class="px-2 font-12 font-weight-bold text-center  d-flex align-center justify-center gray-tab"
                  ></div>
                </div>
              </template>

              <template v-slot:item.return="{ item }">
                <div v-if="item.return" class="d-flex align-center">
                  <div
                    :class="
                      checkTabColor('return', item) === 'green-tab'
                        ? 'green-tab'
                        : 'orange-tab'
                    "
                    class="px-2 font-12 font-weight-bold text-center  d-flex align-center justify-center"
                  >
                    {{ item.return | moment("DD/MM H:mm") }}
                  </div>
                </div>
                <div v-else>
                  <div
                    class="px-2 font-12 font-weight-bold text-center  d-flex align-center justify-center gray-tab"
                  ></div>
                </div>
              </template>

              <template v-slot:item.shipping="{ item }">
                <div v-if="item.ship_date" class="d-flex align-center">
                  <div
                    class="px-2 font-12 font-weight-bold text-center  d-flex align-center justify-center green-tab"
                  >
                    {{ item.ship_date | moment("DD/MM H:mm") }}
                  </div>
                </div>
                <div v-else>
                  <div
                    class="px-2 font-12 font-weight-bold text-center  d-flex align-center justify-center gray-tab"
                  ></div>
                </div>
              </template>

              <template v-slot:item.invoice_file="{ item }">
                <div v-if="item.invoice_file" class="d-flex align-center">
                  <div
                    style="background-color: #52A685; color: #fff;border-radius: 5px;width: 100px;height: 20px"
                    class="px-2 font-12 font-weight-bold text-center  d-flex align-center justify-center"
                  >
                    {{ item.invoice_file | moment("DD/MM H:mm") }}
                  </div>
                </div>
                <div v-else>
                  <div
                    style="background-color: #FFF0C2; color: #000;border-radius: 5px;width: 100px;height: 20px"
                    class="px-2 font-12 font-weight-bold text-center  d-flex align-center justify-center"
                  ></div>
                </div>
              </template>

              <template v-slot:item.shore="{ item }">
                <div v-if="item.shore_date" class="d-flex align-center">
                  <div
                    class="px-2 font-12 font-weight-bold text-center  d-flex align-center justify-center green-tab"
                  >
                    {{ item.shore_date | moment("DD/MM H:mm") }}
                  </div>
                </div>
                <div v-else>
                  <div
                    style="background-color: #FFF0C2; color: #000;border-radius: 5px;width: 100px;height: 20px"
                    class="px-2 font-12 font-weight-bold text-center  d-flex align-center justify-center gray-tab"
                  ></div>
                </div>
              </template>

              <template v-slot:item.receive_file="{ item }">
                <div v-if="item.receive_file" class="d-flex align-center">
                  <div
                    class="px-2 font-12 font-weight-bold text-center  d-flex align-center justify-center green-tab"
                  >
                    {{ item.receive_file | moment("DD/MM H:mm") }}
                  </div>
                </div>
                <div v-else>
                  <div
                    class="px-2 font-12 font-weight-bold text-center  d-flex align-center justify-center gray-tab"
                  ></div>
                </div>
              </template>

              <template v-slot:item.driver_finish="{ item, index }">
                <v-layout align-center v-if="!item.is_job_success_datetime">
                  <v-flex xs8>
                    <v-menu
                      v-model="date_driver_finish[index + item.id]"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="driverForm[index + item.id]"
                          readonly
                          outlined
                          clearable
                          height="37px"
                          placeholder="--"
                          hide-details
                          class="gray"
                          append-icon="mdi-calendar"
                          background-color="#fff"
                          item-color="#4B5768"
                          color="#4B5768"
                          filled
                          dense
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="driverForm[index + item.id]"
                        @input="date_driver_finish[index + item.id] = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs4 class="pl-2">
                    <v-btn
                      color="#59CB33"
                      :dark="driverForm[index + item.id] ? true : false"
                      depressed
                      :disabled="!driverForm[index + item.id]"
                      @click="saveJobFinish(item, driverForm[index + item.id])"
                    >
                      Save
                    </v-btn>
                  </v-flex>
                </v-layout>

                <div v-else>
                  {{ item.is_job_success_datetime | moment("DD/MM/YYYY") }}
                </div>
              </template>
            </v-data-table>
          </td>
        </template>
      </v-data-table>
    </div>

    <v-layout mt-4>
      <v-flex>
        <Paginate
          :current_page="paginate.current_page"
          :last_page="paginate.last_page"
          :nextPage="onClickPagination"
        />
      </v-flex>
    </v-layout>

    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="false"
    />
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import TextInput from "../../../components/input/TextInput.vue";
import DatePicker from "../../../components/picker/DatePicker.vue";
import Services from "../../../services";
import Paginate from "../../../components/pagination/Paginate.vue";
import moment from "moment";
import DoughnutChart from "../../../components/chart/DoughnutChart.vue";
import XLSX from "xlsx"; // import xlsx;
import { url_path } from "../../../services/Constants";

export default {
  components: {
    TextInput,
    Loading,
    DatePicker,
    Paginate,
    DoughnutChart,
  },
  data() {
    return {
      is_import: false,
      is_export: false,
      is_empty: false,
      is_laden: false,
      is_drop_load: false,

      date_driver_finish: [],
      isLoading: false,
      filter: {
        page: 1,
        mode: [],
        customer_id: "",
        importer_exporter_id: "",
        inv_po: "",
        booking_no: "",
        order_id: "",
        wh_factory_id: "",
        cy_date_start: "",
        cy_date_end: "",
        cy_place_id: "",
        unload_date_start: "",
        unload_date_end: "",
        port_discharge_id: "",
        carrier_id: "",
        driver_id: "",
        container: "",
        closing_date_start: "",
        closing_date_end: "",
      },
      customerList: [],
      cyPlaceList: [],
      importerList: [],
      factoryList: [],
      releasePortList: [],
      carriers: [],
      containeSize: [],
      driverList: [],
      date: {
        unload_date_start: "",
        unload_date_end: "",
        cy_date_start: "",
        cy_date_end: "",
        closing_date_start: "",
        closing_date_end: "",
      },
      driverForm: [],
      driverFormDate: [],
      selected: [],
      headers: [
        {
          text: "No.",
          value: "no",
          align: "start",
          sortable: false,
          width: "50px",
          class: "bg-gray",
        },
        {
          text: "Job No.",
          value: "order_id",
          align: "start",
          sortable: false,
          width: "150px",
          class: "bg-gray",
        },
        {
          text: "Customer",
          value: "customer_name",
          align: "start",
          sortable: false,
          width: "150px",
          class: "bg-gray",
        },
        {
          text: "Importer/Exporter",
          value: "exporter_name",
          align: "start",
          sortable: false,
          width: "150px",
          class: "bg-gray",
        },
        {
          text: "Inv./PO",
          value: "inv_po",
          align: "start",
          sortable: false,
          width: "150px",
          class: "bg-gray",
        },
        {
          text: "Booking",
          value: "booking_no",
          align: "start",
          sortable: false,
          width: "150px",
          class: "bg-gray",
        },
        {
          text: "CY Place",
          value: "cy_place_name",
          align: "start",
          sortable: false,
          width: "200px",
          class: "bg-gray",
        },
        {
          text: "CY Date",
          value: "cy_date",
          align: "start",
          sortable: false,
          width: "150px",
          class: "bg-gray",
        },
        {
          text: "Release Port",
          value: "release_port_name",
          align: "start",
          sortable: false,
          width: "200px",
          class: "bg-gray",
        },
        {
          text: "Release Date",
          value: "release_date",
          align: "start",
          sortable: false,
          width: "150px",
          class: "bg-gray",
        },
        {
          text: "Shipping Agent/Agent",
          value: "shipping_agent_name",
          align: "start",
          sortable: false,
          width: "200px",
          class: "bg-gray",
        },
        {
          text: "QTY",
          value: "qty",
          align: "start",
          sortable: false,
          width: "150px",
          class: "bg-gray",
        },
        {
          text: "Size+ISO type",
          value: "size_iso_type",
          align: "start",
          sortable: false,
          width: "150px",
          class: "bg-gray",
        },
        {
          text: "WH/Factory1*",
          value: "wh_factory1",
          align: "start",
          sortable: false,
          width: "200px",
          class: "bg-gray",
        },
        {
          text: "Unload Date1*",
          value: "unload_date1",
          align: "start",
          sortable: false,
          width: "150px",
          class: "bg-gray",
        },
        {
          text: "Unload Time1*",
          value: "unload_time1",
          align: "start",
          sortable: false,
          width: "150px",
          class: "bg-gray",
        },
        {
          text: "WH/Factory2*",
          value: "wh_factory2",
          align: "start",
          sortable: false,
          width: "150px",
          class: "bg-gray",
        },
        {
          text: "Unload Date2*",
          value: "unload_date2",
          align: "start",
          sortable: false,
          width: "150px",
          class: "bg-gray",
        },
        {
          text: "Unload Time2*",
          value: "unload_time2",
          align: "start",
          sortable: false,
          width: "150px",
          class: "bg-gray",
        },
        {
          text: "Return Place*",
          value: "return_place_name",
          align: "start",
          sortable: false,
          width: "200px",
          class: "bg-gray",
        },
        {
          text: "FRD*",
          value: "frd",
          align: "start",
          sortable: false,
          width: "150px",
          class: "bg-gray",
        },
        {
          text: "Closing Date/Time",
          value: "closing_date",
          align: "start",
          sortable: false,
          width: "150px",
          class: "bg-gray",
        },
      ],
      headers_driver_job: [
        {
          text: "",
          value: "action",
          align: "center",
          sortable: false,
          width: "20px",
          class: "bg-gray pa-0",
        },
        {
          text: "No.",
          value: "no",
          align: "start",
          sortable: false,
          width: "30px",
          class: "bg-gray",
        },
        {
          text: "Order Driver",
          value: "job_no",
          align: "center",
          sortable: false,
          width: "50px",
          class: "bg-gray",
        },
        {
          text: "Driver",
          value: "accept_tender.driver_name",
          align: "start",
          sortable: false,
          width: "150px",
          class: "bg-gray",
        },
        {
          text: "ทะเบียนรถ",
          value: "accept_tender.truck_license_name",
          align: "start",
          sortable: false,
          width: "100px",
          class: "bg-gray",
        },
        {
          text: "CY Place",
          value: "cy_place",
          align: "center",
          sortable: false,
          width: "150px",
          class: "bg-gray",
        },
        {
          text: "Qty",
          value: "qty",
          align: "center",
          sortable: false,
          width: "100px",
          class: "bg-gray",
        },
        {
          text: "Size",
          value: "size",
          align: "center",
          sortable: false,
          width: "100px",
          class: "bg-gray",
        },
        {
          text: "ISO Type",
          value: "iso_type",
          align: "center",
          sortable: false,
          width: "100px",
          class: "bg-gray",
        },
        {
          text: "WH/Factory 1",
          value: "wh_factory1",
          align: "center",
          sortable: false,
          width: "250px",
          class: "bg-gray",
        },
        {
          text: "Accept Tender",
          value: "accept_tender",
          align: "start",
          sortable: false,
          width: "250px",
          class: "bg-gray",
        },
        {
          text: "Receive Container",
          value: "receive_container",
          align: "start",
          sortable: false,
          width: "250px",
          class: "bg-gray",
        },
        {
          text: "Arrived",
          value: "arrived",
          align: "start",
          sortable: false,
          width: "250px",
          class: "bg-gray",
        },
        {
          text: "Start Load/unload",
          value: "start_load_unload",
          align: "start",
          sortable: false,
          width: "250px",
          class: "bg-gray",
        },
        {
          text: "Load/unload End",
          value: "end_load_unload",
          align: "start",
          sortable: false,
          width: "250px",
          class: "bg-gray",
        },
        {
          text: "Return",
          value: "return",
          align: "start",
          sortable: false,
          width: "250px",
          class: "bg-gray",
        },
        {
          text: "วิ่งงานเสร็จ",
          value: "driver_finish",
          align: "start",
          sortable: false,
          width: "250px",
          class: "bg-gray",
        },
        {
          text: "Shore ไฟล์ใบเสร็จ",
          value: "shore",
          align: "start",
          sortable: false,
          width: "250px",
          class: "bg-gray",
        },
        {
          text: "Shipping ไฟล์ใบกำกับ",
          value: "shipping",
          align: "start",
          sortable: false,
          width: "250px",
          class: "bg-gray",
        },
      ],
      monitoringList: [],
      paginate: {
        current_page: 1,
        from: 1,
        last_page: 1,
        per_page: 1,
        total: 1,
        to: 1,
      },
      dataGraph: [],
      isShow: false,
      chartData: {
        labels: ["Order ทั้งหมด", "Order ที่ทำไปแล้ว"],
        datasets: [
          {
            label: "Data One",
            backgroundColor: ["#338A68", "#EC8235"],
            data: [10, 5],
          },
        ],
      },
      selectedMain: [],
      dataExcel: [],
    };
  },
  created() {
    this.isLoading = true;
    Promise.all([
      this.getMasterData(),
      this.getDataGraph(),
      this.getMonitoringList(),
    ])
      .then((values) => {
        this.isLoading = false;
      })
      .catch((error) => {
        this.isLoading = false;
      });
  },
  watch: {
    "date.cy_date_start": function(val) {
      if (!val) {
        this.filter.cy_date_end = "";
        this.date.cy_date_end = "";
      }
    },
    "date.cy_date_end": function(val) {
      if (!val) {
        this.filter.cy_date_start = "";
        this.date.cy_date_start = "";
      }
    },
    "date.unload_date_start": function(val) {
      if (!val) {
        this.filter.unload_date_end = "";
        this.date.unload_date_end = "";
      }
    },
    "date.unload_date_end": function(val) {
      if (!val) {
        this.filter.unload_date_start = "";
        this.date.unload_date_start = "";
      }
    },
    selectedMain: function(val) {
      // this.dataExcel = val;
      this.setDataExcel(val);
    },
    is_import: {
      handler: function(val) {
        if (val) {
          if (this.filter.mode.indexOf("Import") === -1) {
            this.filter.mode.push("Import");
          }
        } else {
          this.filter.mode.splice(this.filter.mode.indexOf("Import"), 1);
        }
      },
    },
    is_export: {
      handler: function(val) {
        if (val) {
          if (this.filter.mode.indexOf("Export") === -1) {
            this.filter.mode.push("Export");
          }
        } else {
          this.filter.mode.splice(this.filter.mode.indexOf("Export"), 1);
        }
      },
    },
    is_empty: {
      handler: function(val) {
        if (val) {
          if (this.filter.mode.indexOf("Empty") === -1) {
            this.filter.mode.push("Empty");
          }
        } else {
          this.filter.mode.splice(this.filter.mode.indexOf("Empty"), 1);
        }
      },
    },
    is_laden: {
      handler: function(val) {
        if (val) {
          if (this.filter.mode.indexOf("Laden") === -1) {
            this.filter.mode.push("Laden");
          }
        } else {
          this.filter.mode.splice(this.filter.mode.indexOf("Laden"), 1);
        }
      },
    },
    is_drop_load: {
      handler: function(val) {
        if (val) {
          if (this.filter.mode.indexOf("Drop Load") === -1) {
            this.filter.mode.push("Drop Load");
          }
        } else {
          this.filter.mode.splice(this.filter.mode.indexOf("Drop Load"), 1);
        }
      },
    },
  },
  methods: {
    // Get Master data
    async getMasterData() {
      const table = [
        "importer",
        "customers",
        "wh_factory",
        "cy_place",
        "port_discharge",
        "container_size",
      ];
      const res = await this.$store.dispatch(
        "master_data/getMasterData",
        table
      );
      this.importerList = res.data[0].importer;
      this.customerList = res.data[1].customers;
      this.factoryList = res.data[2].wh_factory;
      this.cyPlaceList = res.data[3].cy_place;
      this.releasePortList = res.data[4].port_discharge;
      this.containeSize = res.data[5].container_size;
      this.driverList = res.data["drivers"];
      this.carriers = res.data["carriers"];
      return res.data;
    },
    async getMonitoringList() {
      const res = await Services.Monitoring.getMonitoringList(this.filter);
      res.data.data = res.data.data.map((data, i) => ({
        ...data,
        no: (res.data.current_page - 1) * res.data.per_page + i + 1,
      }));
      this.monitoringList = res.data.data;
      this.paginate.current_page = res.data.current_page;
      this.paginate.last_page = res.data.last_page;

      return res.data;
    },
    async getDataGraph() {
      const res = await Services.Monitoring.getDataGraph(this.filter);
      this.dataGraph = res.data;
      this.isShow = true;
      return res.data;
    },
    onClickPagination(page) {
      this.isLoading = true;
      this.filter.page = page;
      this.getMonitoringList().then((res) => {
        this.isLoading = false;
      });
    },
    updateDate(name, val) {
      this.filter[name] = val ? val : "";
      this.date[name] = val ? this.customDate(val) : "";
    },
    customDate(val) {
      let sub_year = val.substring(0, 4);
      let sub_month = val.substring(5, 7);
      let sub_day = val.substring(8, 10);
      let changeDate = sub_day + "/" + sub_month + "/" + sub_year;
      return changeDate;
    },
    clearForm() {
      this.filter = {
        mode: "",
        customer_id: "",
        importer_exporter_id: "",
        inv_po: "",
        booking_no: "",
        wh_factory_id: "",
        cy_date_start: "",
        cy_date_end: "",
        cy_place_id: "",
        unload_date_start: "",
        unload_date_end: "",
        carrier_id: "",
        driver_id: "",
        container: "",
      };
    },
    updateSelect(key1, key2, value) {
      this.filter[key1] = value[key2];
    },
    onClearable(key) {
      this.filter[key] = "";
    },
    viewMonitoringDetail(id) {
      this.$router.push(`monitoring/${id}?tab=accept-tender`);
    },
    groupQty(containers) {
      var total = 0;
      containers.forEach((item) => {
        if (item.qty) {
          total += parseInt(item.qty);
        }
      });
      return total;
    },
    groupSize(containers) {
      var total = "";
      containers.forEach((item, i) => {
        if (item.container_size_name) {
          total +=
            item.container_size_name +
            (containers.length == i + 1 ? "" : " / ");
        }
      });
      return total;
    },
    groupIsoType(containers) {
      var total = "";
      containers.forEach((item, i) => {
        if (item.container_isotype_name) {
          total +=
            item.container_isotype_name +
            (containers.length == i + 1 ? "" : " / ");
        }
      });
      return total;
    },
    groupContainerSize(containers) {
      var text = "";
      containers.forEach((item, i) => {
        text +=
          item.container_size_name +
          item.container_isotype_name +
          (containers.length == i + 1 ? "" : " / ");
      });
      return text;
    },
    groupWHFactory1(containers) {
      var text = "";
      containers.forEach((item, i) => {
        if (item.wh_factory_1_name) {
          text +=
            item.wh_factory_1_name + (containers.length == i + 1 ? "" : " / ");
        }
      });
      return text;
    },
    groupUnloadDate1(containers) {
      var text = "";
      containers.forEach((item, i) => {
        if (item.unload_load_date1) {
          text +=
            moment(item.unload_load_date1).format("DD/MM/YYYY") +
            (containers.length == i + 1 ? "" : " / ");
        }
      });
      return text;
    },
    groupUnloadTime1(containers) {
      var text = "";
      containers.forEach((item, i) => {
        if (item.unload_load_time1) {
          text +=
            item.unload_load_time1 + (containers.length == i + 1 ? "" : " / ");
        }
      });
      return text;
    },
    groupWHFactory2(containers) {
      var text = "";
      containers.forEach((item, i) => {
        if (item.wh_factory_2_name) {
          text +=
            item.wh_factory_2_name + (containers.length == i + 1 ? "" : " / ");
        }
      });
      return text;
    },
    groupUnloadDate2(containers) {
      var text = "";
      containers.forEach((item, i) => {
        if (item.unload_load_date2) {
          text +=
            moment(item.unload_load_date2).format("DD/MM/YYYY") +
            (containers.length == i + 1 ? "" : " / ");
        }
      });
      return text;
    },
    groupUnloadTime2(containers) {
      var text = "";
      containers.forEach((item, i) => {
        if (item.unload_load_time2) {
          text +=
            item.unload_load_time2 + (containers.length == i + 1 ? "" : " / ");
        }
      });
      return text;
    },

    groupCyPlace(containers) {
      var text = "";
      containers.forEach((item, i) => {
        if (item.cy_place_name) {
          text +=
            item.cy_place_name + (containers.length == i + 1 ? "" : " / ");
        }
      });
      return text;
    },
    groupReturnPlace(containers) {
      var text = "";
      containers.forEach((item, i) => {
        if (item.return_place_name) {
          text +=
            item.return_place_name + (containers.length == i + 1 ? "" : " / ");
        }
      });
      return text;
    },

    saveJobFinish(item, val) {
      if (!val) {
        this.$swal({
          icon: "warning",
          title: "Warning!",
          text: 'กรุณากรอกข้อมูล "Job Finish"',
          showConfirmButton: true,
        });

        return;
      }
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Save it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            Services.Monitoring.saveJobFinish({
              id: item.id,
              is_job_success_datetime: val,
            })
              .then((res) => {
                this.isLoading = false;
                this.$swal({
                  icon: "success",
                  title: "Success!",
                  text: 'บันทึก "วิ่งงานเสร็จ" เรียบร้อย',
                  showConfirmButton: true,
                }).then(() => {
                  this.isLoading = true;
                  this.isShow = false;
                  Promise.all([this.getDataGraph(), this.getMonitoringList()])
                    .then((values) => {
                      this.isLoading = false;
                      this.isShow = true;
                    })
                    .catch((error) => {
                      this.isLoading = false;
                    });
                });
              })
              .catch((err) => {
                this.isLoading = false;
                console.log(err);
                this.$swal({
                  icon: "error",
                  title: "Error!",
                  text: 'บันทึก "วิ่งงานเสร็จ" ไม่สำเร็จ',
                  showConfirmButton: true,
                });
              });
          }
        });
    },

    //* =====> Export Excel
    exportExcel() {
      if (this.selectedMain.length === 0) {
        this.$swal.fire({
          title: "Something went wrong!",
          text: "Please, Select at least 1 item",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      } else {
        this.$swal
          .fire({
            title: "Are you sure?",
            text: `Do you want to export Order to Excel?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Export!",
          })
          .then((result) => {
            if (result.isConfirmed) {
              const dataWS = XLSX.utils.json_to_sheet(this.dataExcel);
              const wb = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(wb, dataWS);
              const fileName = `monitoring_${moment(new Date()).format(
                "DD_MM_YYYY_h_mm_ss"
              )}.xlsx`;
              XLSX.writeFile(wb, fileName);
              this.selectedMain = [];
              this.dataExcel = [];
            } else {
              this.selectedMain = [];
              this.dataExcel = [];
            }
          });
      }
    },

    setDataExcel(items) {
      const mode = items.length > 0 ? items[0].mode : "";
      let no = 1;
      if (mode === "Import") {
        items.forEach((data) => {
          if (mode !== data.mode) {
            this.$swal
              .fire({
                title: "Something went wrong!",
                text: `Please select the same mode. (${mode})`,
                icon: "warning",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK",
              })
              .then(() => {
                this.selectedMain = [];
                this.dataExcel = [];
              });
          } else {
            data.order_detail.forEach((item) => {
              this.dataExcel.push({
                "No.": no,
                "Mode of Transport ": data.mode,
                "User ": data.user_name,
                "Remark ": data.remark,
                "CUSTOMER ": data.customer_name,
                "IMPORTER* ": data.importer_name,
                "Goods* ": data.goods_name,
                "Package ": data.package_name,
                "Inv./PO NO.*": data.inv_po,
                "ATA ": data.ata ? moment(data.ata).format("DD/MM/YYYY") : "",
                "วันที่ตู้ครบ ": data.discharge_complete
                  ? moment(data.discharge_complete).format("DD/MM/YYYY")
                  : "",
                "AGENT* ": data.shipping_agent_name,
                "Discharge PORT* ": data.port_discharge_name,
                "Release Place* ": data.release_port_name,
                "Release Date* ": data.release_date
                  ? moment(data.release_date).format("DD/MM/YYYY")
                  : "",
                "Qty ": item.qty,
                "Size+ISO Type ":
                  item.container_size_name + "'" + item.container_isotype_name,
                "Shipping ": data.shipping_name,
                "Status Container": data.container_status,
                "WH/Factory1* ": item.wh_factory_1_name,
                "UnloadDate1* ": item.unload_load_date1
                  ? moment(item.unload_load_date1).format("DD/MM/YYYY")
                  : "",
                "UnloadTime1* ": this.subStringTime(item.unload_load_time1),
                "WH/Factory2* ": item.wh_factory_2_name,
                "UnloadDate2* ": item.unload_load_date2
                  ? moment(item.unload_load_date2).format("DD/MM/YYYY")
                  : "",
                "UnloadTime2* ": this.subStringTime(item.unload_load_time2),
                "overtime card ": url_path + data.overtime_card_path,
                "return card ": url_path + data.return_card_path,
              });
            });
            no++;
          }
        });
      } else {
        items.forEach((data) => {
          if (mode !== data.mode) {
            this.$swal
              .fire({
                title: "Something went wrong!",
                text: `Please select the same mode. (${mode})`,
                icon: "warning",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK",
              })
              .then(() => {
                this.selectedMain = [];
                this.dataExcel = [];
              });
          } else {
            data.order_detail.forEach((item) => {
              this.dataExcel.push({
                "No.": no,
                "Mode of Transport ": data.mode,
                "User ": data.user_name,
                "Remark ": data.remark,
                "CUSTOMER ": data.customer_name,
                "IMPORTER* ": data.importer_name,
                "Goods* ": data.goods_name,
                "Package ": data.package_name,
                "Inv./PO NO.*": data.inv_po,
                "Booking no.* ": data.booking_no,
                "CY PLACE* ": item.cy_place_name,
                "CY DATE* ": data.cy_date
                  ? moment(data.cy_date).format("DD/MM/YYYY")
                  : "",
                "Shipping Agent* ": data.shipping_agent_name,
                "Qty ": item.qty,
                "Size+ISO Type ":
                  item.container_size_name + "'" + item.container_isotype_name,
                "Status Container": data.container_status,
                "WH/Factory1* ": item.wh_factory_1_name,
                "load Date1* ": item.unload_load_date1
                  ? moment(item.unload_load_date1).format("DD/MM/YYYY")
                  : "",
                "load Time1* ": this.subStringTime(item.unload_load_time1),
                "WH/Factory2* ": item.wh_factory_2_name,
                "load Date2* ": item.unload_load_date2
                  ? moment(item.unload_load_date2).format("DD/MM/YYYY")
                  : "",
                "load Time2* ": this.subStringTime(item.unload_load_time2),
                "Ton ": item.ton,
                "Forwarder ": data.forwarder_name,
                "FEEDER VESSEL* ": data.feeder_vessel,
                "OCEAN VESSEL ": data.ocean_vessel,
                "PORT/Country* ": data.port_discharge_name
                  ? data.port_discharge_name
                  : data.port_country_name,
                "RETURN PLACE* ": item.return_place_name,
                "CLOSING DATE* ": item.closing_date
                  ? moment(item.closing_date).format("DD/MM/YYYY")
                  : "",
                "CLOSING TIME* ": this.subStringTime(item.closing_time),
                "FRD* ": item.frd ? moment(item.frd).format("DD/MM/YYYY") : "",
                "SHIPPING ": data.shipping_name,
              });
            });
            no++;
          }
        });
      }
    },

    checkTabColor(tab, item) {
      switch (tab) {
        case "accept_tender":
          // accept_tender: ถ้า accept หลังจาก สร้าง driver_job ไปแล้วไม่เกิน 6 ชั่วโมง ให้เป็นสีเขียว ถ้าเกิน 6 ชั่วโมงให้เป็นสีส้ม
          // หา date diff ระหว่าง status_date กับ created_at
          var start_date = moment(item.status_date);
          var end_date = moment(item.created_at);
          var duration = moment.duration(start_date.diff(end_date));
          // แปลงเป็น ชั่วโมง
          var hours = duration.asHours();
          if (hours <= 6) {
            return "green-tab";
          } else {
            return "orange-tab";
          }

        case "receive_container":
          if (item.status == "REJECT") {
            return "gray-tab";
          } else {
            // receive: เงื่อนไข mode empty, drop load รับตู้(receive_container) ภายใน cy date ถ้าใช่เป็นสีเขียว ถ้าไม่ใช่เป็นสีส้ม
            if (
              item.order.mode === "Empty" ||
              item.order.mode === "Drop Load"
            ) {
              if (
                moment(item.receive_container).format("YYYY-MM-DD") <=
                moment(item.order.cy_date).format("YYYY-MM-DD")
              ) {
                return "green-tab";
              } else {
                return "orange-tab";
              }
            }
            // ถ้า mode อื่นเป็นสีเขียว
            else {
              return "green-tab";
            }
          }

        case "arrived":
          // Arrived: เงื่อนไข ทุก mode ถึงโรงงานในวันที่ load/unload date ถ้าใช่จะแสดงเป็นสีเขียว ถ้าไม่ใช่เป็นสีส้ม
          var arrived = moment(item.arrived).format("YYYY-MM-DD");
          var unload_load_date1 = moment(
            item.accept_tender.tender.order_detail.unload_load_date1
          ).format("YYYY-MM-DD");

          // ทุก mode ถึงโรงงานในวันที่ load/unload date ถ้าใช่จะแสดงเป็นสีเขียว ถ้าไม่ใช่เป็นสีส้ม
          if (arrived == unload_load_date1) {
            return "green-tab";
          } else {
            return "orange-tab";
          }

        case "start_load_unload":
          // Start load/unload: เงื่อนไข ทุก mode การถึงโรงงาน +/- ไม่เกิน 1 ชม จะแสดงสีเขียว หากไม่ใช่จะเป็นสีส้ม
          var factory_success_date = moment(item.factory_success_date);
          var start_unload_load_date = moment(
            item.accept_tender.tender.order_detail.unload_load_date1 +
              " " +
              item.accept_tender.tender.order_detail.unload_load_time1
          );

          // หา date diff ระหว่าง factory_success_date กับ start_unload_load_date แล้วแปลงเป็นชั่วโมง ถ้า +/- ไม่เกิน 1 ชั่วโมง ให้เป็นสีเขียว ถ้าเกิน 1 ชั่วโมงให้เป็นสีส้ม
          var duration_start_load_unload = moment.duration(
            factory_success_date.diff(start_unload_load_date)
          );
          // แปลงเป็น ชั่วโมง
          var hours_start_load_unload = duration_start_load_unload.asHours();

          // check การถึงโรงงาน +/- ไม่เกิน 1 ชม จะแสดงสีเขียว หากไม่ใช่จะเป็นสีส้ม
          if (hours_start_load_unload <= 1 && hours_start_load_unload >= -1) {
            return "green-tab";
          } else {
            return "orange-tab";
          }

        case "end_load_unload":
          // load/unload End: เงื่อนไข การออกจาก โรงงาน mode Import, export, drop load จะเริ่มบรรจุไม่เกิน 3 ชม ถ้าไม่เกินจะแสดง สีเขียว และหากเกินจะแสดงสีส้ม
          // เอา ถึงโรงงาน(factory_success_date) มา diff กับ วันที่เริ่มบรรจุ(start_product_success_date) แล้วแปลงเป็นชั่วโมง
          var duration_end_load_unload = moment.duration(
            moment(item.factory_success_date).diff(
              moment(item.start_product_success_date)
            )
          );
          // แปลงเป็น ชั่วโมง
          var hours_end_load_unload = duration_end_load_unload.asHours();

          if (hours_end_load_unload <= 3) {
            return "green-tab";
          } else {
            return "orange-tab";
          }
        case "return":
          // return: เงื่อนไข ทุก mode ยกเว้น Empty ปิดงานไม่เกิน 1 วัน จาก load/unload date จะแสดงสีเขียว และหากเกินจะแสดงสีส้ม
          if (item.order.mode !== "Empty") {
            // วันที่ปิดงาน
            var is_job_success_datetime = moment(
              item.is_job_success_datetime
            ).format("YYYY-MM-DD");

            // วัน unload_load_date ที่ได้มาจาก order_detail
            var return_unload_load_date = moment(
              item.accept_tender.tender.order_detail.unload_load_date1 +
                " " +
                item.accept_tender.tender.order_detail.unload_load_time1
            ).format("YYYY-MM-DD");

            // ปิดงานไม่เกิน 1 วัน จาก load/unload date จะแสดงสีเขียว และหากเกินจะแสดงสีส้ม
            if (is_job_success_datetime <= return_unload_load_date) {
              return "green-tab";
            } else {
              return "orange-tab";
            }
          } else {
            return "green-tab";
          }
      }
    },
  },
};
</script>

<style>
.filter-search {
  min-height: 350px;
  border: 150px solid #c4c4c4;
  display: flex !important;
  align-items: center;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: #e3f8cf !important;
}
.green-tab {
  background-color: #338a68 !important;
  color: #fff;
  border-radius: 5px;
  width: 100px;
  height: 20px;
}
.orange-tab {
  background-color: #ec8235 !important;
  color: #fff;
  border-radius: 5px;
  width: 100px;
  height: 20px;
}
.reject-tab {
  background-color: #707070 !important;
  color: #ffffff;
  border-radius: 5px;
  width: 100px;
  height: 20px;
}
.gray-tab {
  background-color: #d8d8d8 !important;
  color: #fff;
  border-radius: 5px;
  width: 100px;
  height: 20px;
}
</style>
