import Service from "../../services";

const state = {
  access_token: null,
  user: null,
  permission: [],
};

const getters = {};

const actions = {
  login({ commit }, data) {
    return Service.auth.login(data).then((res) => {
      if (res.success === true) {
        commit("SET_ACCESS_TOKEN", res.data);
      }
      return res;
    });
  },
  logout({ commit }) {
    return Service.auth.logout().then((res) => {
      window.localStorage.removeItem("vuex");
      return res;
    });
  },
  register({ commit }, data) {
    return Service.auth.register(data).then((res) => {
      return res;
    });
  },
  changePassword({ commit }, data) {
    return Service.auth.changePassword(data).then((res) => {
      return res;
    });
  },
  forgotPassword({ commit }, data) {
    return Service.auth.forgotPassword(data).then((res) => {
      return res;
    });
  },
  resetPassword({ commit }, data) {
    return Service.auth.resetPassword(data).then((res) => {
      return res;
    });
  },
};

const mutations = {
  SET_ACCESS_TOKEN(state, data) {
    state.access_token = data.token;
    state.user = data.user;

    if (data.user.user_group_id == 3) {
      for (var i = 0; i < data.permisson.length; i++) {
        if (data.permisson[i].name === "Order") {
          data.permisson.splice(i, 1);
        }
      }
    }
    state.permission = data.permisson;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
