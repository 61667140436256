<template>
  <div>
    <v-autocomplete
      :height="height"
      append-icon="mdi-chevron-down"
      menu-props="offsetY"
      color="#98CA54"
      outlined
      dense
      :clearable="clearable"
      :disabled="disabled"
      :readonly="readonly"
      :class="borderColor"
      :items="items"
      :value="model"
      :rules="required ? [(v) => !!v || 'Field is required'] : []"
      :label="label"
      :required="required"
      :hide-details="hideDetails"
      :placeholder="placeholder"
      :item-text="itemText"
      :item-value="itemValue"
      return-object
      :background-color="disabled ? '#E5E7E9' : '#fff'"
      @input="onChange"
      @click:clear="onClearable"
    >
    </v-autocomplete>
    <!-- {{ model.toString() }} -->
  </div>
</template>

<script>
export default {
  props: {
    model: null,
    required: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    onClearable: {
      type: Function,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    label: { default: "" },
    placeholder: { default: "" },
    items: Array,
    itemText: {
      default: "",
    },
    itemValue: {
      default: "",
    },
    height: {
      default: "37px",
    },
    borderColor: {
      default: "gray",
    },
  },

  data() {
    return {
      select: null,
      selectRules: [(v) => !!v || "Item is required"],
    };
  },
  methods: {
    onChange(val) {
      this.$emit("update-data", val);
    },
  },
};
</script>

<style></style>
