import Service from "../../services";

const state = {};

const getters = {};

const actions = {
  save({ commit }, data) {
    return Service.truck_supply.save(data).then((res) => {
      return res;
    });
  },
  update({ commit }, data) {
    return Service.truck_supply.update(data).then((res) => {
      return res;
    });
  },
  delete({ commit }, id) {
    return Service.truck_supply.delete(id).then((res) => {
      return res;
    });
  },
  duplicate({ commit }, id) {
    return Service.truck_supply.duplicate(id).then((res) => {
      return res;
    });
  },
  getMasterDataForSearch({ commit }, ) {
    return Service.truck_supply.getMasterDataForSearch().then((res) => {
      return res;
    });
  },
  exportExcel({ commit }, item) {
    return Service.truck_supply.exportExcel(item).then((res) => {
      return res;
    });
  },
  getData({ commit }, page) {
    return Service.truck_supply.getData(page).then((res) => {
      return res;
    });
  },
  getDataById({ commit }, id) {
    return Service.truck_supply.getDataById(id).then((res) => {
      return res;
    });
  },
  search({ commit }, data) {
    return Service.truck_supply.search(data).then((res) => {
      return res;
    });
  },
  changeStatus({ commit }, data) {
    return Service.truck_supply.changeStatus(data).then((res) => {
      return res;
    });
  },
  getTruckSupplyActive({ commit }) {
    return Service.truck_supply.getTruckSupplyActive().then((res) => {
      return res;
    });
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
