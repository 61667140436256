<template>
  <div>
    <v-card width="240" flat class="text-center">
      <div class=" font-weight-black">
        No.{{ data.graph_no }} {{ data.carrier_code }}
      </div>
      <div
        style="width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;"
      >
        {{ data.carrier_name }}
      </div>
      <div>Total: {{ data.commit }}</div>
      <DoughnutChart :data="chartData" :options="chartOptions" />
    </v-card>
  </div>
</template>

<script>
import DoughnutChart from "./DoughnutChart.vue";
export default {
  components: { DoughnutChart },
  props: {
    data: null,
  },
  data() {
    return {
      chartOptions: {
        hoverBorderWidth: 20,
      },
      chartData: {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: [
          // "Commit 10",
          // "Available 8",
          // "Past 8",
          // "Not Free 4",
          // "Available 658",
        ],
        datasets: [
          {
            label: "Data One",
            backgroundColor: [
              // "#4F4F4F",
              "#EB5757",
              "#F7D25D",
              "#EC8235",
              "#338A68",
            ],
            data: [10, 5, 2, 7, 11.9],
          },
        ],
      },
    };
  },
  created() {
    this.chartData.labels = this.data.labels;
    this.chartData.datasets[0].data = this.data.status;
  },
};
</script>

<style></style>
