import AuthService from "../AuthService";

const ControlTower = {
  getShipmentControlFilter() {
    return AuthService.get(`/api/v1/shipment-control-filter`).then((res) => {
      return res;
    });
  },
  getShipmentControl(payload) {
    return AuthService.get(
      `/api/v1/shipment-control?customer_id=${
        payload.customer_id ? JSON.stringify(payload.customer_id) : ""
      }
      &cy_date_start=${payload.cy_date_start ? payload.cy_date_start : ""}
      &cy_date_end=${payload.cy_date_end ? payload.cy_date_end : ""}
      &closing_date_start=${
        payload.closing_date_start ? payload.closing_date_start : ""
      }
      &closing_date_end=${
        payload.closing_date_end ? payload.closing_date_end : ""
      }
      &importer_exporter_id=${
        payload.importer_exporter_id
          ? JSON.stringify(payload.importer_exporter_id)
          : ""
      }
      &unload_date_start=${
        payload.unload_date_start ? payload.unload_date_start : ""
      }
      &unload_date_end=${payload.unload_date_end ? payload.unload_date_end : ""}
      &carrier_id=${payload.carrier_id ? payload.carrier_id : ""}
      &driver_id=${payload.driver_id ? payload.driver_id : ""}
      &mode=${payload.mode ? JSON.stringify(payload.mode) : ""}`
    ).then((res) => {
      return res;
    });
  },
  getShipmentControlExport(payload) {
    return AuthService.get(
      `/api/v1/shipment-control-export?customer_id=${
        payload.customer_id ? JSON.stringify(payload.customer_id) : ""
      }
      &cy_date_start=${payload.cy_date_start ? payload.cy_date_start : ""}
      &cy_date_end=${payload.cy_date_end ? payload.cy_date_end : ""}
      &closing_date_start=${
        payload.closing_date_start ? payload.closing_date_start : ""
      }
      &closing_date_end=${
        payload.closing_date_end ? payload.closing_date_end : ""
      }
      &importer_exporter_id=${
        payload.importer_exporter_id
          ? JSON.stringify(payload.importer_exporter_id)
          : ""
      }_date_start=${payload.unload_date_start ? payload.unload_date_start : ""}
      &unload_date_end=${payload.unload_date_end ? payload.unload_date_end : ""}
      &carrier_id=${payload.carrier_id ? payload.carrier_id : ""}
      &driver_id=${payload.driver_id ? payload.driver_id : ""}
      &mode=${payload.mode ? JSON.stringify(payload.mode) : ""}`
    ).then((res) => {
      return res;
    });
  },
  getShipmentControlImport(payload) {
    return AuthService.get(
      `/api/v1/shipment-control-import?customer_id=${
        payload.customer_id ? JSON.stringify(payload.customer_id) : ""
      }
      &cy_date_start=${payload.cy_date_start ? payload.cy_date_start : ""}
      &cy_date_end=${payload.cy_date_end ? payload.cy_date_end : ""}
      &closing_date_start=${
        payload.closing_date_start ? payload.closing_date_start : ""
      }
      &closing_date_end=${
        payload.closing_date_end ? payload.closing_date_end : ""
      }
      &importer_exporter_id=${
        payload.importer_exporter_id
          ? JSON.stringify(payload.importer_exporter_id)
          : ""
      }
      &unload_date_start=${
        payload.unload_date_start ? payload.unload_date_start : ""
      }
      &unload_date_end=${payload.unload_date_end ? payload.unload_date_end : ""}
      &carrier_id=${payload.carrier_id ? payload.carrier_id : ""}
      &driver_id=${payload.driver_id ? payload.driver_id : ""}
      `
    ).then((res) => {
      return res;
    });
  },
  getDataTruckSupply(payload) {
    return AuthService.get(
      `/api/v1/control-trucksupply?date_start=${
        payload.date_start ? payload.date_start : ""
      }
      &date_end=${payload.date_end ? payload.date_end : ""}
      &carrier_id=${payload.carrier_id ? payload.carrier_id : ""}
      &driver_id=${payload.driver_id ? payload.driver_id : ""}
      &truck_license_id=${
        payload.truck_license_id ? payload.truck_license_id : ""
      }
      `
    ).then((res) => {
      return res;
    });
  },
  getDataShipmentControl(payload) {
    return AuthService.get(
      `/api/v1/data-shipment-control?type=${payload.type}&customer_id=${
        payload.customer_id ? JSON.stringify(payload.customer_id) : ""
      }
      &cy_date_start=${payload.cy_date_start ? payload.cy_date_start : ""}
      &cy_date_end=${payload.cy_date_end ? payload.cy_date_end : ""}
      &closing_date_start=${
        payload.closing_date_start ? payload.closing_date_start : ""
      }
      &closing_date_end=${
        payload.closing_date_end ? payload.closing_date_end : ""
      }
      &importer_exporter_id=${
        payload.importer_exporter_id
          ? JSON.stringify(payload.importer_exporter_id)
          : ""
      }
      &unload_date_start=${
        payload.unload_date_start ? payload.unload_date_start : ""
      }
      &unload_date_end=${payload.unload_date_end ? payload.unload_date_end : ""}
      &carrier_id=${payload.carrier_id ? payload.carrier_id : ""}
      &driver_id=${payload.driver_id ? payload.driver_id : ""}
      &mode=${payload.mode ? JSON.stringify(payload.mode) : ""}`
    ).then((res) => {
      return res;
    });
  },
  getFilterTruckSupply() {
    return AuthService.get(`/api/v1/get-filter-trucksupply`).then((res) => {
      return res;
    });
  },
  getDataControlTruckSupply(payload) {
    return AuthService.get(
      `/api/v1/get-data-control-trucksupply?date=${payload.date}&date_start=${
        payload.date_start ? payload.date_start : ""
      }
      &date_end=${payload.date_end ? payload.date_end : ""}
      &carrier_id=${payload.carrier_id ? payload.carrier_id : ""}
      &driver_id=${payload.driver_id ? payload.driver_id : ""}
      &truck_license_id=${
        payload.truck_license_id ? payload.truck_license_id : ""
      }`
    ).then((res) => {
      return res;
    });
  },
};

export default ControlTower;
