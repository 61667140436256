import AuthService from "../AuthService";
import Service from "../Service";

const auth = {
  getMonitoringList(payload) {
    return AuthService.get(
      `/api/v1/monitoring/list?page=${payload.page ? payload.page : ""}&mode=${
        payload.mode ? JSON.stringify(payload.mode) : []
      }&customer_id=${
        payload.customer_id ? payload.customer_id : ""
      }&importer_exporter_id=${
        payload.importer_exporter_id ? payload.importer_exporter_id : ""
      }&inv_po=${payload.inv_po ? payload.inv_po : ""}&booking_no=${
        payload.booking_no ? payload.booking_no : ""
      }&cy_date_start=${
        payload.cy_date_start ? payload.cy_date_start : ""
      }&cy_date_end=${
        payload.cy_date_end ? payload.cy_date_end : ""
      }&wh_factory_id=${
        payload.wh_factory_id ? payload.wh_factory_id : ""
      }&cy_place_id=${
        payload.cy_place_id ? payload.cy_place_id : ""
      }&unload_date_start=${
        payload.unload_date_start ? payload.unload_date_start : ""
      }&unload_date_end=${
        payload.unload_date_end ? payload.unload_date_end : ""
      }&carrier=${payload.carrier ? payload.carrier : ""}&driver_id=${
        payload.driver_id ? payload.driver_id : ""
      }&port_discharge_id=${
        payload.port_discharge_id ? payload.port_discharge_id : ""
      }&container_size=${
        payload.container ? payload.container : ""
      }&closing_date_start=${
        payload.closing_date_start ? payload.closing_date_start : ""
      }&closing_date_end=${
        payload.closing_date_end ? payload.closing_date_end : ""
      }&carrier_id=${payload.carrier_id ? payload.carrier_id : ""}&order_id=${
        payload.order_id ? payload.order_id : ""
      }`
    ).then((res) => res);
  },
  getCustomerList(payload) {
    return AuthService.get(
      `/api/v1/customer/list?page=${payload.page ? payload.page : ""}&mode=${
        payload.mode ? JSON.stringify(payload.mode) : []
      }&customer_id=${
        payload.customer_id ? payload.customer_id : ""
      }&importer_exporter_id=${
        payload.importer_exporter_id ? payload.importer_exporter_id : ""
      }&cy_place_id=${
        payload.cy_place_id ? payload.cy_place_id : ""
      }&cy_date_start=${
        payload.cy_date_start ? payload.cy_date_start : ""
      }&cy_date_end=${
        payload.cy_date_end ? payload.cy_date_end : ""
      }&closing_date_start=${
        payload.closing_date_start ? payload.closing_date_start : ""
      }&closing_date_end=${
        payload.closing_date_end ? payload.closing_date_end : ""
      }&wh_factory1_id=${
        payload.wh_factory1_id ? payload.wh_factory1_id : ""
      }&wh_factory2_id=${
        payload.wh_factory2_id ? payload.wh_factory2_id : ""
      }&unload_date1_start=${
        payload.unload_date1_start ? payload.unload_date1_start : ""
      }&unload_date1_end=${
        payload.unload_date1_end ? payload.unload_date1_end : ""
      }&unload_date2_start=${
        payload.unload_date2_start ? payload.unload_date2_start : ""
      }&unload_date2_end=${
        payload.unload_date2_end ? payload.unload_date2_end : ""
      }&driver_id=${
        payload.driver_id ? payload.driver_id : ""
      }&return_place_id=${
        payload.return_place_id ? payload.return_place_id : ""
      }&release_port_id=${
        payload.release_port_id ? payload.release_port_id : ""
      }&truck_license_id=${
        payload.truck_license_id ? payload.truck_license_id : ""
      }&order_id=${payload.order_id ? payload.order_id : ""}&is_order_pending=${
        payload.is_order_pending ? payload.is_order_pending : ""
      }&is_order_complete=${
        payload.is_order_complete ? payload.is_order_complete : ""
      }`
    ).then((res) => res);
  },
  getMonitoringDetail(id, payload) {
    return AuthService.get(
      `/api/v1/monitoring/detail/${id}?page=${payload.page}&tab=${payload.tab}`
    ).then((res) => res);
  },
  getOrderById(id) {
    return AuthService.get(`/api/v1/monitoring/order?id=${id}`).then(
      (res) => res
    );
  },
  uploadFileShipping(data) {
    return AuthService.post(
      `/api/v1/monitoring/upload-file-shipping`,
      data
    ).then((res) => res);
  },
  cancelFileShipping(data) {
    return AuthService.post(
      `/api/v1/monitoring/cancel-file-shipping`,
      data
    ).then((res) => res);
  },
  uploadFileShore(data) {
    return AuthService.post(`/api/v1/monitoring/upload-file-shore`, data).then(
      (res) => res
    );
  },
  cancelFileShore(data) {
    return AuthService.post(`/api/v1/monitoring/cancel-file-shore`, data).then(
      (res) => res
    );
  },
  step1(data) {
    return AuthService.post(`/api/v1/driver/step1`, data);
  },
  step2(data) {
    return AuthService.post(`/api/v1/driver/step2`, data);
  },
  step3(data) {
    return AuthService.post(`/api/v1/driver/step3`, data);
  },
  step4(data) {
    return AuthService.post(`/api/v1/driver/step4`, data);
  },
  statusStep1(data) {
    return AuthService.post(`/api/v1/driver/status-step1`, data);
  },
  statusStep2(data) {
    return AuthService.post(`/api/v1/driver/status-step2`, data);
  },
  saveJobFinish(data) {
    return AuthService.post(`/api/v1/monitoring/save-job-finish`, data);
  },
  getDataGraph(payload) {
    return AuthService.get(`/api/v1/monitoring/data-graph`).then((res) => res);
  },
};

export default auth;
