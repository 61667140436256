import auth from "./api/Auth.Service";
import order from "./api/Order.Service";
import order_detail from "./api/OrderDetail.Service";
import tender from "./api/Tender.Service";
import truck_supply from "./api/TruckSupply.Service";
import master_data from "./api/MasterData.Service";
import accept_tender from "./api/AcceptTender.Service";
import tolls_scale from "./api/TollsScale.Service";
import lan_pass_lifting_fee from "./api/LanPassLiftingFee.Service";
import cost_per_truck from "./api/CostPerTruck.Service";
import ControlTower from "./api/ControlTower.Service";
import Monitoring from "./api/Monitoring.Service";

export default {
  auth,
  order,
  order_detail,
  tender,
  master_data,
  truck_supply,
  accept_tender,
  tolls_scale,
  lan_pass_lifting_fee,
  cost_per_truck,
  ControlTower,
  Monitoring,
};
