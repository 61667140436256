import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import AcceptTender from "../views/pages/accept_tender/AcceptTender.vue";
import Admin from "../views/pages/admin/Admin.vue";
import TollsScale from "../views/pages/admin/TollsScale.vue";
import LanPassLiftingFee from "../views/pages/admin/LanPassLiftingFee.vue";
import CostTruck from "../views/pages/cost_truck/CostTruck.vue";
import Home from "../views/pages/home/Home.vue";
// import MasterData from "../views/pages/master_data/MasterData.vue";
import Order from "../views/pages/order/Order.vue";
import OrderForm from "../views/pages/order/OrderForm.vue";
import ViewOrder from "../views/pages/order/form/ViewOrder.vue";
import Tender from "../views/pages/tender/Tender.vue";
import TruckSupply from "../views/pages/truck_supply/TruckSupply.vue";
import Login from "../views/pages/auth/Login.vue";
import Main from "../views/layout/Main.vue";
import TruckSupplyForm from "../views/pages/truck_supply/form/TruckSupplyForm.vue";
import ChangePassword from "../views/pages/auth/ChangePassword.vue";
import ForgotPassword from "../views/pages/auth/ForgotPassword.vue";
import ResetPassword from "../views/pages/auth/ResetPassword.vue";
import ShipmentControl from "../views/pages/control_tower/ShipmentControl.vue";
import ShipmentControlExport from "../views/pages/control_tower/ShipmentControlExport.vue";
import ShipmentControlImport from "../views/pages/control_tower/ShipmentControlImport.vue";
import MonitoringList from "../views/pages/monitoring/MonitoringList.vue";
import MonitoringDetail from "../views/pages/monitoring/MonitoringDetail.vue";
import CustomerList from "../views/pages/customer/CustomerList.vue";
import ControlTruckSupply from "../views/pages/control_tower/TruckSupply.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    component: ForgotPassword,
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    component: ResetPassword,
  },
  {
    path: "/",
    name: "Main",
    component: Main,
    children: [
      {
        path: "/",
        name: "Home",
        component: Home,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/home",
        name: "Home",
        component: Home,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/change-password",
        name: "Change Password",
        component: ChangePassword,
        meta: {
          requireAuth: true,
        },
      },

      {
        path: "/accept-tender",
        name: "Accept Tender",
        component: AcceptTender,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/admin",
        name: "Admin",
        component: Admin,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/tolls-scales",
        name: "Toll Scale",
        component: TollsScale,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/lanpass-lifting-fee",
        name: "Lan Pass Lifting",
        component: LanPassLiftingFee,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/cost-truck",
        name: "Cost/Truck",
        component: CostTruck,
        meta: {
          requireAuth: true,
        },
      },
      // {
      //   path: "/master-data",
      //   name: "Master Data",
      //   component: MasterData,
      //   meta: {
      //     requireAuth: true,
      //   },
      // },
      {
        path: "/order",
        name: "Order",
        component: Order,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/order/create",
        name: "createOrder",
        component: OrderForm,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/order/edit/:id",
        name: "editOrder",
        component: OrderForm,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/order/view/:id",
        name: "viewOrder",
        component: ViewOrder,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/tender",
        name: "Tender",
        component: Tender,
        meta: {
          requireAuth: false,
        },
      },
      {
        path: "/truck-supply",
        name: "Truck Supply",
        component: TruckSupply,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/truck-supply/create",
        name: "Create Truck Supply",
        component: TruckSupplyForm,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/truck-supply/edit/:id",
        name: "Edit Truck Supply",
        component: TruckSupplyForm,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/shipment-control",
        name: "Shipment Control",
        component: ShipmentControl,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/shipment-control-export",
        name: "Shipment Control Export",
        component: ShipmentControlExport,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/shipment-control-import",
        name: "Shipment Control Import",
        component: ShipmentControlImport,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/control-trucksupply",
        name: "ControlTruckSupply",
        component: ControlTruckSupply,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/monitoring",
        name: "MonitoringList",
        component: MonitoringList,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/monitoring/:id",
        name: "MonitoringDetail",
        component: MonitoringDetail,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/customers",
        name: "CustomerList",
        component: CustomerList,
        meta: {
          requireAuth: true,
        },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requireAuth)) {
    if (!store.state.auth.access_token) {
      window.localStorage.removeItem("vuex");
      next({ name: "Login" });
    } else {
      if (to.name === "Login" && store.state.auth.access_token) {
        next({ name: "Home" });
      } else {
        // Order ==> Roles:Manager|Customer|Carrier|Operation|Admin|Account
        if (
          (to.name === "Order" ||
            to.name === "createOrder" ||
            to.name === "editOrder") &&
          (store.state.auth?.user?.user_group_id == 5 ||
            store.state.auth?.user?.user_group_id == 3)
        ) {
          window.history.back();
        }
        // Check carrier for order form
        else if (
          (to.name === "createOrder" || to.name === "editOrder") &&
          (store.state.auth?.user?.carrier_id ||
            store.state.auth?.user?.user_group_id == 2)
        ) {
          window.history.back();
        }
        // Tender ==> Roles:Manager|Operation|Admin
        else if (
          to.name === "Tender" &&
          (store.state.auth.user.user_group_id == 2 ||
            store.state.auth.user.user_group_id == 3 ||
            store.state.auth.user.user_group_id == 5 ||
            store.state.auth.user.user_group_id == 6)
        ) {
          window.history.back();
        }
        // Truck Supply &  Accept Tender & Cost/Truck  ==> Roles:Manager|Customer|Carrier|Operation|Admin
        else if (
          (to.name === "Truck Supply" ||
            to.name === "Create Truck Supply" ||
            to.name === "Accept Tender" ||
            to.name === "Cost/Truck" ||
            to.name === "Edit Truck Supply") &&
          (store.state.auth.user.user_group_id == 5 ||
            store.state.auth.user.user_group_id == 6)
        ) {
          window.history.back();
        }
        // Cost/Truck ==> Roles:Manager|Customer|Carrier|Operation|Admin
        else if (
          to.name === "Cost/Truck" &&
          (store.state.auth.user.user_group_id == 5 ||
            store.state.auth.user.user_group_id == 6)
        ) {
          window.history.back();
        }
        //
        else {
          next();
        }
      }
    }
  } else {
    if (to.name === "Login" && store.state.auth.access_token) {
      next({ name: "Home" });
    } else {
      // 1:Manager
      // 2:Customer
      // 3:Carrier
      // 4:Operation
      // 5:Driver
      // 6:Account
      // 7:Admin

      // Order ==> Roles:Manager|Customer|Carrier|Operation|Admin|Account
      if (
        (to.name === "Order" ||
          to.name === "createOrder" ||
          to.name === "editOrder") &&
        (store.state.auth.user.user_group_id == 5 ||
          store.state.auth?.user?.user_group_id == 3)
      ) {
        window.history.back();
      }
      // Check carrier for order form
      else if (
        (to.name === "createOrder" || to.name === "editOrder") &&
        (store.state.auth?.user?.carrier_id ||
          store.state.auth?.user?.user_group_id == 2)
      ) {
        window.history.back();
      }
      // Tender ==> Roles:Manager|Operation|Admin 1,4,7
      else if (
        to.name === "Tender" &&
        (store.state.auth.user.user_group_id == 2 ||
          store.state.auth.user.user_group_id == 3 ||
          store.state.auth.user.user_group_id == 5 ||
          store.state.auth.user.user_group_id == 6)
      ) {
        window.history.back();
      }
      // Truck Supply &  Accept Tender & Cost/Truck  ==> Roles:Manager|Customer|Carrier|Operation|Admin
      else if (
        (to.name === "Truck Supply" ||
          to.name === "Create Truck Supply" ||
          to.name === "Accept Tender" ||
          to.name === "Cost/Truck" ||
          to.name === "Edit Truck Supply") &&
        (store.state.auth.user.user_group_id == 5 ||
          store.state.auth.user.user_group_id == 6)
      ) {
        window.history.back();
      }
      // Cost/Truck ==> Roles:Manager|Customer|Carrier|Operation|Admin
      else if (
        to.name === "Cost/Truck" &&
        (store.state.auth.user.user_group_id == 5 ||
          store.state.auth.user.user_group_id == 6)
      ) {
        window.history.back();
      }
      //
      else {
        next();
      }
    }
  }
});

export default router;
