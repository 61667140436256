<template>
  <div>
    <v-radio-group :value="model" row style="font-12">
      <v-radio
        @click="onHandle(item.label)"
        color="#338A68"
        v-for="(item, i) in radioList"
        :key="i"
        :label="item.label"
        :value="item.label"
      ></v-radio>
    </v-radio-group>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      default: "",
    },
    value: {
      default: "",
    },
    label: {
      default: "",
    },
    radioList: Array,
  },
  data() {
    return {
      data: "",
    };
  },
  methods: {
    onHandle(val) {
      this.$emit("update-radio", val);
    },
  },
};
</script>

<style></style>
