<template>
  <div style="background-color: #F7F4E9;" class="fill-height">
    <img
      style="position: absolute;top: 1rem;right: 1rem"
      width="200"
      src="../../../assets/images/logo.png"
    />
    <v-layout justify-center align-center class="fill-height">
      <v-flex xs6>
        <v-card elevation="4">
          <v-card-title class="justify-center">
            <h3 class="headline">
              Transportation Management System
            </h3>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pa-12">
            <div class="font-16 font-weight-bold">Forgot Password</div>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                color="#338A68"
                outline
                :rules="[(v) => !!v || 'field is required']"
                label="Enter email address"
                type="text"
                required
                v-model="email"
              ></v-text-field>
            </v-form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="px-12 pt-6">
            <v-btn
              :disabled="!valid"
              :dark="valid"
              block
              color="#50A86C"
              @click="forgotPassword"
            >
              Forgot Password
            </v-btn>
          </v-card-actions>
          <v-card-actions class="justify-center pb-4 px-12">
            <v-btn
              text
              block
              style="color: #EBA63F;"
              @click="$router.push('/login')"
            >
              <v-icon color="#EBA63F">mdi-login</v-icon> Login
            </v-btn>
          </v-card-actions>
          <Loading
            :active.sync="isLoading"
            :can-cancel="false"
            :is-full-page="fullPage"
          />
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import { domain } from "../../../services/Constants";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  data() {
    return {
      isLoading: false,
      fullPage: true,
      valid: true,
      email: null,
    };
  },
  components: {
    Loading,
  },
  methods: {
    forgotPassword() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        this.$store
          .dispatch("auth/forgotPassword", { email: this.email, url: domain })
          .then((res) => {
            this.isLoading = false;
            this.$swal.fire({
              icon: "success",
              title: "Please Check the email",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
              timer: 1500,
            });
            this.$router.push("/login");
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error.response);
          });
      }
    },
  },
};
</script>

<style>
.v-btn,
.v-card {
  border-radius: 4px;
}

.v-card__title {
  text-transform: uppercase;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
