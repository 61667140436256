<template>
  <div>
    <div class="ml-8 mb-4 mt-1">
      <v-icon small class="mr-2">mdi-file</v-icon>
      <span class=" font-weight-bold text-uppercase" @click="clearForm()">
        Order
      </span>
    </div>

    <div class="mx-8">
      <!-- Form -->
      <v-card flat outlined>
        <v-form ref="form" v-model="valid" lazy-validation class="px-3 py-6">
          <v-layout wrap mb-3>
            <v-flex xs2 class="d-flex justify-center align-center">
              <v-layout class="align-center">
                <v-flex class="xs5 text-end  font-12 mr-3 font-weight-bold">
                  Job no. :
                </v-flex>
                <v-flex class="xs7 px-0">
                  <TextInput
                    hideDetails
                    placeholder="Job no."
                    :model="form.order_id"
                    rulse="fieldRequire"
                    @update-data="updateData('order_id', $event)"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs2 class="d-flex justify-center align-center">
              <v-layout class="align-center">
                <v-flex class="xs5 text-end font-12 mr-3 font-weight-bold">
                  Importer <br />
                  /Exporter :
                </v-flex>
                <v-flex class="xs7 px-0">
                  <AutocompleteInput
                    clearable
                    hideDetails
                    placeholder="Importer/Exporter"
                    :model="form.importer_exporter_id"
                    rulse="fieldRequire"
                    :items="importerList"
                    itemText="customer_code"
                    itemValue="customer_id"
                    @update-data="
                      updateSelect('importer_exporter', 'customer_id', $event)
                    "
                  />
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs4 class="d-flex justify-center align-center">
              <v-layout class="align-center">
                <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
                  Load <br />
                  /Unload Date :
                </v-flex>
                <v-flex class="xs4 px-0">
                  <DatePicker
                    hideDetails
                    hideDetail
                    rulse="fieldRequire"
                    :model="date.load_and_unload_date_from"
                    @update-data="
                      updateDate('load_and_unload_date_from', $event)
                    "
                  ></DatePicker>
                </v-flex>
                <v-flex class="xs1 text-end font-12 mx-3 font-weight-bold">
                  To :
                </v-flex>
                <v-flex class="xs4 px-0">
                  <DatePicker
                    hideDetails
                    hideDetail
                    rulse="fieldRequire"
                    :model="date.load_and_unload_date_to"
                    @update-data="updateDate('load_and_unload_date_to', $event)"
                  ></DatePicker>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs3 class="d-flex justify-center align-center">
              <v-layout class="align-center">
                <v-flex
                  class="xs5 text-end font-12 ml-n3 mr-3 font-weight-bold"
                >
                  CY Place :
                </v-flex>
                <v-flex class="xs5 px-0">
                  <AutocompleteInput
                    clearable
                    hideDetails
                    :model="form.cy_place_id"
                    rulse="fieldRequire"
                    :items="cyPlaceList"
                    itemText="cy_place_name"
                    itemValue="cy_place_id"
                    @update-data="
                      form.cy_place_id = $event ? $event.cy_place_id : ''
                    "
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

          <v-layout wrap mb-3>
            <v-flex xs2 class="d-flex justify-center align-center">
              <v-layout class="align-center">
                <v-flex class="xs5 text-end font-12 mr-3 font-weight-bold">
                  Customer :
                </v-flex>
                <v-flex class="xs7 px-0">
                  <AutocompleteInput
                    clearable
                    hideDetails
                    placeholder="Customer"
                    :model="form.customer_id"
                    rulse="fieldRequire"
                    :items="customerList"
                    itemText="customer_code"
                    itemValue="customer_id"
                    @update-data="
                      updateSelect('customer', 'customer_id', $event)
                    "
                  />
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs2 class="d-flex justify-center align-center">
              <v-layout class="align-center">
                <v-flex class="xs5 text-end  font-12 mr-3 font-weight-bold">
                  Booking :
                </v-flex>
                <v-flex class="xs7 px-0">
                  <TextInput
                    hideDetails
                    placeholder="Booking"
                    :model="form.booking_no"
                    rulse="fieldRequire"
                    @update-data="updateData('booking_no', $event)"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs4 class="d-flex justify-center align-center">
              <v-layout class="align-center">
                <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
                  Create <br />
                  /Update Order :
                </v-flex>
                <v-flex class="xs4 px-0">
                  <DatePicker
                    hideDetails
                    hideDetail
                    rulse="fieldRequire"
                    :model="date.create_update_order_from"
                    @update-data="
                      updateDate('create_update_order_from', $event)
                    "
                  ></DatePicker>
                </v-flex>
                <v-flex class="xs1 text-end font-12 mx-3 font-weight-bold">
                  To :
                </v-flex>
                <v-flex class="xs4 px-0">
                  <DatePicker
                    hideDetails
                    hideDetail
                    rulse="fieldRequire"
                    :model="date.create_update_order_to"
                    @update-data="updateDate('create_update_order_to', $event)"
                  ></DatePicker>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs4 class="d-flex justify-center align-center">
              <v-layout class="align-center">
                <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
                  CY Date :
                </v-flex>
                <v-flex class="xs4 px-0">
                  <DatePicker
                    hideDetails
                    hideDetail
                    rulse="fieldRequire"
                    :model="date.cy_date_from"
                    @update-data="updateDate('cy_date_from', $event)"
                  ></DatePicker>
                </v-flex>
                <v-flex class="xs1 text-end font-12 mx-3 font-weight-bold">
                  To :
                </v-flex>
                <v-flex class="xs4 px-0">
                  <DatePicker
                    hideDetails
                    hideDetail
                    rulse="fieldRequire"
                    :model="date.cy_date_to"
                    @update-data="updateDate('cy_date_to', $event)"
                  ></DatePicker>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

          <v-layout wrap>
            <v-flex xs2 class="d-flex justify-center align-center">
              <v-layout class="align-center">
                <v-flex class="xs5 text-end  font-12 mr-3 font-weight-bold">
                  INV./PO :
                </v-flex>
                <v-flex class="xs7 px-0">
                  <TextInput
                    hideDetails
                    placeholder="INV./PO"
                    :model="form.inv_po"
                    rulse="fieldRequire"
                    @update-data="updateData('inv_po', $event)"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs2 class="d-flex justify-center align-center">
              <v-layout class="align-center">
                <v-flex class="xs5 text-end font-12  font-weight-bold mr-3">
                  WH/Factory :
                </v-flex>
                <v-flex class="xs7">
                  <AutocompleteInput
                    clearable
                    hideDetail
                    placeholder="WH/Factory"
                    :model="form.wh_factory_id"
                    rulse="fieldRequire"
                    :items="factoryList"
                    itemText="wh_factory_name"
                    itemValue="wh_factory_id"
                    @update-data="
                      updateSelect('wh_factory', 'wh_factory_id', $event)
                    "
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card>

      <!-- Search -->
      <v-card width="100%" flat outlined class="mt-4">
        <v-layout justify-space-between>
          <v-flex shrink>
            <v-layout class="align-center text-end pl-6">
              <v-flex shrink class=" font-weight-bold">Mode : </v-flex>
              <v-flex shrink pl-6>
                <v-layout>
                  <v-flex shrink mr-4>
                    <v-checkbox
                      color="#338A68"
                      v-model="is_import"
                      label="Import"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex shrink mr-4>
                    <v-checkbox
                      color="#338A68"
                      v-model="is_export"
                      label="Export"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex shrink mr-4>
                    <v-checkbox
                      color="#338A68"
                      v-model="is_empty"
                      label="Empty"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex shrink mr-4>
                    <v-checkbox
                      color="#338A68"
                      v-model="is_laden"
                      label="Laden"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex shrink mr-4>
                    <v-checkbox
                      color="#338A68"
                      v-model="is_drop_load"
                      label="Drop Load"
                    ></v-checkbox>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex shrink class="text-end pr-6">
            <v-btn
              depressed
              color="#F7D25D"
              class="my-4 font-weight-bold"
              @click="search(1)"
            >
              <v-icon class="mr-3">mdi-magnify</v-icon>
              <span class="text-capitalize">Search</span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>

      <!-- button Add order -->
      <v-layout>
        <v-flex shrink v-if="!user.carrier_id && user.user_group_id != 2">
          <v-btn
            depressed
            color="#64C8E7"
            dark
            class="shrink my-4 font-weight-bold mr-3"
            @click="duplicateOrder"
          >
            <v-icon class="mr-3">mdi-content-copy</v-icon>
            <span class="shrink text-capitalize">Duplicate</span>
          </v-btn>
        </v-flex>
        <v-flex shrink>
          <v-btn
            depressed
            color="#64C8E7"
            dark
            class="my-4 font-weight-bold mr-3"
            @click="printOrder"
          >
            <v-icon class="mr-3">mdi-printer</v-icon>
            <span class="text-capitalize">Print</span>
          </v-btn>
        </v-flex>
        <v-flex shrink>
          <v-btn
            outlined
            depressed
            color="#338A68"
            dark
            class="my-4 font-weight-bold"
            @click="exportExcel()"
          >
            <v-icon class="mr-3">mdi-file-excel</v-icon>
            <span class="text-capitalize">Export Excel</span>
          </v-btn>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex shrink>
          <v-btn
            v-if="!user.carrier_id && user.user_group_id != 2"
            depressed
            color="#338A68"
            dark
            class="my-4 shrink font-weight-bold"
            @click="addOrder"
          >
            <v-icon class="mr-3">mdi-plus-circle-outline</v-icon>
            <span class="text-capitalize">Add</span>
          </v-btn>
        </v-flex>
      </v-layout>

      <!-- Table -->
      <v-layout>
        <v-flex style="width: 1px" v-if="reloadTable">
          <DataTableOrder
            :editItem="editItem"
            :viewItem="viewItem"
            :deleteItem="deleteItem"
            :selectOrder="selectOrder"
            :headers="headers"
            :isClearSelected="isClearSelected"
            :items="order_data"
          />
        </v-flex>
        <v-flex style="width: 1px" v-else>
          <DataTableOrder :headers="headers" :items="[]" />
        </v-flex>
      </v-layout>
    </div>

    <v-layout mt-4>
      <v-flex>
        <Paginate
          :current_page="paginate.current_page"
          :last_page="paginate.last_page"
          :nextPage="search"
        />
      </v-flex>
    </v-layout>

    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="false"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import XLSX from "xlsx"; // import xlsx;
import moment from "moment";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import TextInput from "../../../components/input/TextInput.vue";
import AutocompleteInput from "../../../components/select/AutocompleteInput";
import DataTableOrder from "../../../components/table/DataTableOrder.vue";
import DatePicker from "../../../components/picker/DatePicker";
import Paginate from "../../../components/pagination/Paginate.vue";
import { eventBus } from "../../../services/Constants";

export default {
  components: {
    Loading,
    TextInput,
    DataTableOrder,
    AutocompleteInput,
    DatePicker,
    Paginate,
  },
  data: () => ({
    isLoading: false,
    fullPage: true,
    isClearSelected: false,
    valid: true,
    selectItemOrder: [],
    form: {
      order_id: "",
      importer_exporter_id: "",
      load_and_unload_date_from: "",
      load_and_unload_date_to: "",
      cy_place_id: "",
      cy_date_from: "",
      cy_date_to: "",
      customer_id: "",
      booking_no: "",
      create_update_order_from: "",
      create_update_order_to: "",
      inv_po: "",
      wh_factory_id: "",
      status: "",
      // is_import: false,
      // is_export: false,
      // is_empty: false,
      // is_laden: false,
      // is_drop_load: false,
      mode: [],
    },
    is_import: false,
    is_export: false,
    is_empty: false,
    is_laden: false,
    is_drop_load: false,
    date: {
      load_and_unload_date_from: "",
      load_and_unload_date_to: "",
      create_update_order_from: "",
      create_update_order_to: "",
      cy_date_from: "",
      cy_date_to: "",
    },

    paginate: {
      current_page: 1,
      from: 1,
      last_page: 1,
      per_page: 1,
      total: 1,
      to: 1,
    },

    cyPlaceList: [],
    importerList: [],
    customerList: [],
    listStatus: [{ label: "Approve" }, { label: "Cancel" }],
    listMode: [
      { label: "Import" },
      { label: "Export" },
      { label: "Empty" },
      { label: "Laden" },
      { label: "Drop Load" },
    ],
    factoryList: [{ id: 1, name: "GSC SITE 2" }],

    headers: [
      // {
      //   text: "Select",
      //   value: "select",
      //   sortable: false,
      //   width: "50px",
      //   class: "border-right",
      // },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        width: "100px",
        class: "border-right border-left",
      },
      {
        text: "NO.",
        value: "no",
        align: "start",
        sortable: false,
        width: "50px",
        class: "font-weight-bold",
      },
      {
        text: "Job No.",
        value: "order_id",
        align: "start",
        sortable: false,
        width: "150px",
        class: "font-weight-bold",
      },
      {
        text: "Inv./PO NO.*",
        value: "inv_po",
        align: "start",
        sortable: false,
        width: "120px",
        class: "font-weight-bold",
      },
      {
        text: "CY Date",
        value: "cy_date",
        align: "start",
        sortable: false,
        width: "120px",
        class: "font-weight-bold",
      },
      // {
      //   text: "CY Place",
      //   value: "cy_place",
      //   align: "start",
      //   sortable: false,
      //   width: "100px",
      //   class: "font-weight-bold",
      // },
      {
        text: "Qty",
        value: "qty",
        align: "start",
        sortable: false,
        width: "20px",
        class: "font-weight-bold",
      },
      {
        text: "Size Container",
        align: "start",
        sortable: false,
        width: "120px",
        class: "font-weight-bold",
      },
      {
        text: "Isotype",
        align: "start",
        sortable: false,
        width: "130px",
        class: "font-weight-bold",
      },
      {
        text: "Customer",
        value: "customer",
        align: "start",
        sortable: false,
        width: "100px",
        class: "font-weight-bold",
      },
      {
        text: "Booking",
        value: "booking",
        align: "start",
        sortable: false,
        width: "100px",
        class: "font-weight-bold",
      },
      {
        text: "Haulage (est.)",
        value: "haulage",
        align: "start",
        sortable: false,
        width: "250px",
        class: "font-weight-bold",
      },
      {
        text: "Return Place",
        value: "return_place",
        align: "start",
        sortable: false,
        width: "250px",
        class: "font-weight-bold",
      },
      {
        text: "Closing Date*",
        value: "closing_date",
        align: "start",
        sortable: false,
        width: "120px",
        class: "font-weight-bold",
      },
      {
        text: "Closing Time",
        value: "closing_date",
        align: "start",
        sortable: false,
        width: "120px",
        class: "font-weight-bold",
      },
      {
        text: "FRD",
        value: "closing_date",
        align: "start",
        sortable: false,
        width: "120px",
        class: "font-weight-bold",
      },
      {
        text: "Agent",
        value: "agent",
        align: "start",
        sortable: false,
        width: "250px",
        class: "font-weight-bold",
      },
      {
        text: "Create /Update Order",
        value: "create_update_order",
        align: "start",
        sortable: false,
        width: "120px",
        class: "font-weight-bold",
      },
      {
        text: "User Update",
        value: "user_update",
        align: "start",
        sortable: false,
        width: "120px",
        class: "font-weight-bold",
      },
      {
        text: "Importer",
        value: "importer",
        align: "start",
        sortable: false,
        width: "120px",
        class: "font-weight-bold",
      },
      {
        text: "Exporter",
        value: "exporter",
        align: "start",
        sortable: false,
        width: "120px",
        class: "font-weight-bold",
      },
    ],

    desserts: [],
    order_data: [],
    dataExcel: [],
    checkData: true,
    reloadTable: true,
  }),

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  created() {
    this.isLoading = true;
    Promise.all([this.getOrders(1), this.getMasterData()])
      .then((values) => {
        this.isLoading = false;
      })
      .catch((error) => {
        console.log(error.response.data.message);
        this.isLoading = false;
      });
  },
  watch: {
    selectItemOrder: {
      handler: function(newValue) {
        if (newValue.length == 0) {
          this.isClearSelected = false;
          this.checkData = true;
          this.dataExcel = [];
        }
      },
    },
    is_import: {
      handler: function(val) {
        if (val) {
          if (this.form.mode.indexOf("Import") === -1) {
            this.form.mode.push("Import");
          }
        } else {
          this.form.mode.splice(this.form.mode.indexOf("Import"), 1);
        }
      },
    },
    is_export: {
      handler: function(val) {
        if (val) {
          if (this.form.mode.indexOf("Export") === -1) {
            this.form.mode.push("Export");
          }
        } else {
          this.form.mode.splice(this.form.mode.indexOf("Export"), 1);
        }
      },
    },
    is_empty: {
      handler: function(val) {
        if (val) {
          if (this.form.mode.indexOf("Empty") === -1) {
            this.form.mode.push("Empty");
          }
        } else {
          this.form.mode.splice(this.form.mode.indexOf("Empty"), 1);
        }
      },
    },
    is_laden: {
      handler: function(val) {
        if (val) {
          if (this.form.mode.indexOf("Laden") === -1) {
            this.form.mode.push("Laden");
          }
        } else {
          this.form.mode.splice(this.form.mode.indexOf("Laden"), 1);
        }
      },
    },
    is_drop_load: {
      handler: function(val) {
        if (val) {
          if (this.form.mode.indexOf("Drop Load") === -1) {
            this.form.mode.push("Drop Load");
          }
        } else {
          this.form.mode.splice(this.form.mode.indexOf("Drop Load"), 1);
        }
      },
    },
  },
  methods: {
    clearForm() {
      this.form = {
        order_id: "",
        importer_exporter_id: "",
        load_and_unload_date_from: "",
        load_and_unload_date_to: "",
        cy_place_id: "",
        cy_date_from: "",
        cy_date_to: "",
        customer_id: "",
        booking_no: "",
        create_update_order_from: "",
        create_update_order_to: "",
        inv_po: "",
        wh_factory_id: "",
        status: "",
        mode: "",
      };
    },
    async getOrders(page) {
      this.form["page"] = page;
      const res = await this.$store.dispatch("order/getOrders", this.form);
      //! เลข no table
      res.data.data = res.data.data.map((data, i) => ({
        ...data,
        no: (res.data.current_page - 1) * res.data.per_page + i + 1,
      }));
      this.order_data = res.data.data;
      this.paginate.current_page = res.data.current_page;
      this.paginate.last_page = res.data.last_page;
      return res.data;
    },
    updateData(name, val) {
      this.form[name] = val;
    },
    updateDate(name, val) {
      this.form[name] = val ? val : "";
      this.date[name] = val ? this.customDate(val) : "";
    },
    updateSelect(name, key, val) {
      if (val) {
        this.form[name + "_id"] = val[key];
      } else {
        this.form[name + "_id"] = "";
      }
    },
    customDate(val) {
      let sub_year = val.substring(0, 4);
      let sub_month = val.substring(5, 7);
      let sub_day = val.substring(8, 10);
      // sub_year = parseInt(sub_year) + 543;
      let changeDate = sub_day + "/" + sub_month + "/" + sub_year;
      return changeDate;
    },
    addOrder() {
      this.$store.commit("order/SET_MODE", "Import");
      this.$store.commit("order/SET_ORDER_ID", "");
      this.$router.push("order/create");
    },
    editItem(item) {
      this.$store.commit("order/SET_MODE", item.mode);
      this.$store.commit("order/SET_ORDER_ID", item.order_id);
      this.$router.push({
        name: "editOrder",
        params: { id: item.id },
      });
    },
    viewItem(item) {
      this.$store.commit("order/SET_MODE", item.mode);
      this.$store.commit("order/SET_ORDER_ID", item.order_id);
      this.$router.push({
        name: "viewOrder",
        params: { id: item.id },
      });
    },
    deleteItem(item) {
      let count = 0;
      item.order_detail_with_tender.forEach((data) => {
        count += data.tender_carrier.length;
      });
      if (count !== 0) {
        this.$swal.fire({
          title: "Waring! can't delete",
          text: "มีข้อมมูล Tender ถูกสร้างใน Order นี้",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      } else {
        this.$swal
          .fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.isLoading = true;
              this.$store
                .dispatch("order/deleteOrder", item.id)
                .then((res) => {
                  this.getOrders();
                  this.isLoading = false;
                  this.$swal.fire({
                    icon: "success",
                    title: "Delete successfully!",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                })
                .catch((error) => {
                  this.isLoading = false;
                });
            }
          });
      }
    },
    selectOrder(items) {
      this.selectItemOrder = items;
    },
    duplicateOrder() {
      if (
        this.selectItemOrder.length > 1 ||
        this.selectItemOrder.length === 0
      ) {
        this.$swal.fire({
          title: "Something went wrong!",
          text: "Please, Select 1 order before duplicate",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      } else {
        this.$swal
          .fire({
            title: "Are you sure?",
            text: "You need to Duplicate Order this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Duplicate it!",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.isLoading = true;
              this.$store
                .dispatch("order/duplicateOrder", this.selectItemOrder[0].id)
                .then((res) => {
                  this.getOrders();
                  this.isLoading = false;
                  this.isClearSelected = true;
                  this.$swal.fire({
                    icon: "success",
                    title: "Duplicate Order successfully!",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                })
                .catch((error) => {
                  this.isLoading = false;
                  this.isClearSelected = true;
                });
            }
          });
      }
    },
    printOrder() {
      let data = this.selectItemOrder.map((item) => ({
        id: item.id,
        mode: item.mode,
      }));
      if (this.selectItemOrder.length === 1) {
        this.$swal
          .fire({
            title: "Are you sure?",
            text: "You need to Print Order of your select. !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Print it!",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.isLoading = true;
              this.$store
                .dispatch("order/printOrder", data)
                .then((res) => {
                  this.isLoading = false;
                  this.isClearSelected = true;
                  window.open(res.data);
                })
                .catch((error) => {
                  this.isClearSelected = true;
                  this.isLoading = false;
                });
            }
          });
      } else {
        this.$swal.fire({
          title: "Something went wrong!",
          text: "Please, Select 1 order before Print",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      }
    },
    // Search Order
    search(page) {
      this.isLoading = true;
      this.reloadTable = false;
      this.form["page"] = page;
      this.$store
        .dispatch("order/search", this.form)
        .then((res) => {
          this.order_data = [];
          this.isLoading = false;
          this.isClearSelected = true;
          //! เลข no table
          res.data.data = res.data.data.map((data, i) => ({
            ...data,
            no: (res.data.current_page - 1) * res.data.per_page + i + 1,
          }));
          this.order_data = res.data.data;
          this.paginate.current_page = res.data.current_page;
          this.paginate.last_page = res.data.last_page;
          // eventBus.$emit("onloadTable");
          this.reloadTable = true;
        })
        .catch((error) => {
          console.log(error);
          this.isClearSelected = true;
          this.isLoading = false;
          this.reloadTable = true;
        });
    },
    // Get Master data
    async getMasterData() {
      const table = ["importer", "customers", "wh_factory", "cy_place"];
      const res = await this.$store.dispatch(
        "master_data/getMasterData",
        table
      );
      this.importerList = res.data[0].importer;
      this.customerList = res.data[1].customers;
      this.factoryList = res.data[2].wh_factory;
      this.cyPlaceList = res.data[3].cy_place;
      return res.data;
    },

    exportExcel() {
      if (this.selectItemOrder.length === 0) {
        this.$swal.fire({
          title: "Something went wrong!",
          text: "Please, Select at least 1 item",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      } else {
        //! set data and check mode
        this.setDataExcel(this.selectItemOrder);

        if (this.checkData) {
          this.$swal
            .fire({
              title: "Are you sure?",
              text: `Do you want to export Order to Excel?`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, Export!",
            })
            .then((result) => {
              if (result.isConfirmed) {
                const dataWS = XLSX.utils.json_to_sheet(this.dataExcel);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, dataWS);
                const fileName = `Order_${
                  this.selectItemOrder[0].mode
                }_${moment(new Date()).format("DD_MM_YYYY_h_mm_ss")}.xlsx`;
                XLSX.writeFile(wb, fileName);
                this.isClearSelected = true;
              } else {
                this.dataExcel = [];
                this.isClearSelected = true;
              }
            });
        }
      }
    },
    setDataExcel(items) {
      const mode = items[0].mode;
      let no = 1;
      if (mode === "Import") {
        try {
          items.forEach((data) => {
            if (mode !== data.mode) {
              this.$swal.fire({
                title: "Something went wrong!",
                text: `Please select the same mode. (${mode})`,
                icon: "warning",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK",
              });
              this.isClearSelected = true;
              throw "error";
            } else {
              data.order_detail_with_tender.forEach((item) => {
                this.dataExcel.push({
                  "No.": no,
                  "Mode of Transport ": data.mode,
                  "CS* ": data.user_name,
                  "Remark ": data.remark,
                  "CUSTOMER ": data.customer_name,
                  "IMPORTER* ": data.importer_name,
                  "Goods* ": data.goods_name,
                  "Inv./PO NO.*": data.inv_po,
                  "ATA ": data.ata ? moment(data.ata).format("DD/MM/YYYY") : "",
                  "วันที่ตู้ครบ ": data.discharge_complete
                    ? moment(data.discharge_complete).format("DD/MM/YYYY")
                    : "",
                  "AGENT* ": data.shipping_agent_name,
                  "Discharge PORT* ": data.port_discharge_name,
                  "Release Place* ": data.release_port_name,
                  "Release Date* ": data.release_date
                    ? moment(data.release_date).format("DD/MM/YYYY")
                    : "",
                  "Qty ": item.qty,
                  "Size+ISO Type ":
                    item.container_size_name +
                    "'" +
                    item.container_isotype_name,
                  "Shipping ": data.shipping_name,
                  "Status Container": data.container_status,
                  "WH/Factory1* ": item.wh_factory_1_name,
                  "UnloadDate1* ": item.unload_load_date1
                    ? moment(item.unload_load_date1).format("DD/MM/YYYY")
                    : "",
                  "UnloadTime1* ": this.subStringTime(item.unload_load_time1),
                  "WH/Factory2* ": item.wh_factory_2_name,
                  "UnloadDate2* ": item.unload_load_date2
                    ? moment(item.unload_load_date2).format("DD/MM/YYYY")
                    : "",
                  "UnloadTime2* ": this.subStringTime(item.unload_load_time2),
                  "Haulage (est.) ": data.haulage_name,
                  "Tender Date ": "",
                  "Driver ": "",
                  "Assign Driver Date ": "",
                  "RETURN PLACE* ": item.return_place_name,
                  "RENT ": data.rent
                    ? moment(data.rent).format("DD/MM/YYYY")
                    : "",
                  "DEM ": data.demurage
                    ? moment(data.demurage).format("DD/MM/YYYY")
                    : "",
                  "DET ": data.detention
                    ? moment(data.detention).format("DD/MM/YYYY")
                    : "",
                  "DET(Days) ": data.detention_days,
                  "Status (Pending,Complete) ": "",
                  "Status (Complete) Date ": "",
                });
              });
              no++;
            }
          });
        } catch (e) {
          this.checkData = false;
        }
      } else {
        try {
          items.forEach((data) => {
            if (mode !== data.mode) {
              this.$swal.fire({
                title: "Something went wrong!",
                text: `Please select the same mode. (${mode})`,
                icon: "warning",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK",
              });
              this.isClearSelected = true;
              throw "error";
            } else {
              data.order_detail_with_tender.forEach((item) => {
                this.dataExcel.push({
                  "No.": no,
                  "Mode of Transport ": data.mode,
                  "CS* ": data.user_name,
                  "Remark ": data.remark,
                  "CUSTOMER ": data.customer_name,
                  // "IMPORTER* ": data.importer_name,
                  "Exporter* ": data.exporter_name,
                  "Goods* ": data.goods_name,
                  "Package ": data.package_name,
                  "Inv./PO NO.*": data.inv_po,
                  "Booking no.*": data.booking_no,
                  "CY PLACE*": item.cy_place_name,
                  "CY DATE*": data.cy_date
                    ? moment(data.cy_date).format("DD/MM/YYYY")
                    : "",
                  "Shipping Agent* ": data.shipping_agent_name,
                  "Qty ": item.qty,
                  "Size+ISO Type ":
                    item.container_size_name +
                    "'" +
                    item.container_isotype_name,
                  "Status Container": data.container_status,
                  "WH/Factory1* ": item.wh_factory_1_name,
                  "UnloadDate1* ": item.unload_load_date1
                    ? moment(item.unload_load_date1).format("DD/MM/YYYY")
                    : "",
                  "UnloadTime1* ": this.subStringTime(item.unload_load_time1),
                  "WH/Factory2* ": item.wh_factory_2_name,
                  "UnloadDate2* ": item.unload_load_date2
                    ? moment(item.unload_load_date2).format("DD/MM/YYYY")
                    : "",
                  "UnloadTime2* ": this.subStringTime(item.unload_load_time2),
                  "Ton ": data.ton,
                  "Forwarder ": data.forwarder_name,
                  "FEEDER VESSEL* ": data.feeder_vessel,
                  "OCEAN VESSEL ": data.ocean_vessel,
                  "PORT/Country* ": data.port_country_name,
                  "RETURN PLACE* ": data.return_place_name,
                  "CLOSING DATE* ": data.closing_date
                    ? moment(data.closing_date).format("DD/MM/YYYY")
                    : "",
                  "CLOSING TIME* ": this.subStringTime(data.closing_time),
                  "FRD* ": data.frd
                    ? moment(data.frd).format("DD/MM/YYYY")
                    : "",
                  "SHIPPING ": data.shipping_name,
                  "Haulage (est.) ": data.haulage_name,
                  "Tender Date ": "",
                  "Driver ": "",
                  "Assign Driver Date ": "",
                  "SHORE ": data.shore_name,
                  "Fumigate ": data.fumigate_name,
                  "Survey ": data.survey_name,
                  "Others ": data.others_name,
                  "SI CUT OFF DATE ": data.si_cut_off_date
                    ? moment(data.si_cut_off_date).format("DD/MM/YYYY")
                    : "",
                  "SI CUT OFF TIME ": this.subStringTime(data.si_cut_off_time),
                  "VGM CUT OFF DATE ": data.vgm_cut_off_date
                    ? moment(data.vgm_cut_off_date).format("DD/MM/YYYY")
                    : "",
                  "VGM CUT OFF TIME ": this.subStringTime(
                    data.vgm_cut_off_time
                  ),
                  "Status (Pending,Complete) ": "",
                  "Status (Complete) Date ": "",
                });
              });
              no++;
            }
          });
        } catch (e) {
          this.checkData = false;
        }
      }
    },
  },
};
</script>

<style>
.pdf-content {
  width: 100%;
  background: #fff;
}

.report-info {
  display: flex;
  padding: 20px;
  padding-bottom: 0px;
}

.image-container {
  width: 75px;
  height: 45px;
  /* border-radius: 50%; */
  /* overflow: hidden; */
  position: relative;
  margin-right: 25px;
  /* border: 1px solid #dadada; */
}
.user-image {
  height: 100%;
  min-width: 100%;
  margin-left: 50%;
  transform: translateX(-50%);
}
.header-pdf {
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: column;
  /* flex-shrink: 0; */
}
.user-name {
  margin-bottom: 10px;
  flex-shrink: 0;
}
.detail-container {
  font-size: 13px;
  flex-shrink: 0;
}
.detail-label {
  font-weight: 600;
  margin-right: 5px;
  font-size: 13px;
}
.chart-container {
  width: 100%;
  height: 320px;
  padding: 20px;
  pointer-events: none;
}
</style>
