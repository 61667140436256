<template>
  <div class="table-tender">
    <!-- Main Table -->
    <v-data-table
      :headers="headers"
      :items="items"
      hide-default-footer
      class="elevation-1"
    >
      <!-- Header Table -->
      <template v-slot:top>
        <v-card flat tile color="#C4C4C4">
          <v-form class="px-6" ref="form" v-model="valid" lazy-validation>
            <v-layout class="pa-6">
              <v-flex>
                <v-layout>
                  <v-flex xs3> </v-flex>
                  <v-flex xs3>
                    ท่าเรือ :
                  </v-flex>
                  <v-flex xs3>
                    ค่าผ่านท่า :
                  </v-flex>
                  <v-flex xs3>
                    ค่าชั่ง :
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs3>
                    <v-btn color="#338A68" dark @click="add()">
                      <v-icon class="mr-2">mdi-plus-circle-outline</v-icon>
                      <span class="text-capitalize font-weight-black">Add</span>
                    </v-btn>
                  </v-flex>
                  <v-flex xs3>
                    <AutocompleteInput
                      hideDetails
                      :model="form.port_discharge_id"
                      rulse="fieldRequire"
                      :items="portDichargeList"
                      itemText="port_discharge_name"
                      itemValue="port_discharge_id"
                      @update-data="
                        form.port_discharge_id = $event
                          ? $event.port_discharge_id
                          : '';
                        form.port_discharge_name = $event
                          ? $event.port_discharge_name
                          : '';
                      "
                      class="pr-12"
                    />
                  </v-flex>
                  <v-flex xs3>
                    <TextInput
                      type="number"
                      hideDetails
                      :model="form.port_fee"
                      rulse="fieldRequire"
                      @update-data="form.port_fee = $event"
                      class="pr-12"
                    />
                  </v-flex>
                  <v-flex xs3>
                    <TextInput
                      type="number"
                      hideDetails
                      :model="form.scale_fee"
                      rulse="fieldRequire"
                      @update-data="form.scale_fee = $event"
                      class="pr-12"
                    />
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card>
      </template>

      <!-- Custom Main Table Item rows -->
      <template v-slot:item="{ item, index }">
        <!-- Edit -->
        <tr v-if="isEdit && index == indexItem">
          <td class="text-center ">
            <v-layout>
              <v-flex>
                <v-btn fab depressed small text @click="cancle()">
                  <v-icon mx-2 color="red">
                    mdi-close-circle-outline
                  </v-icon>
                </v-btn>
              </v-flex>
              <v-flex>
                <v-btn fab depressed small text @click="update(item)">
                  <v-icon color="#338A68" class="mx-2">
                    mdi-content-save-outline
                  </v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </td>
          <td class=" border-left">{{ item.no }}</td>
          <td class="">
            <AutocompleteInput
              hideDetails
              placeholder="Customer"
              :model="item.port_discharge_id"
              rulse="fieldRequire"
              :items="portDichargeList"
              itemText="port_discharge_name"
              itemValue="port_discharge_id"
              @update-data="
                item.port_discharge_id = $event ? $event.port_discharge_id : '';
                item.port_discharge_name = $event
                  ? $event.port_discharge_name
                  : '';
              "
              class="pr-12"
            />
          </td>
          <td class="">
            <TextInput
              hideDetails
              placeholder="Job no."
              :model="item.port_fee"
              rulse="fieldRequire"
              @update-data="item.port_fee = $event"
              class="pr-12"
            />
          </td>
          <td class="">
            <TextInput
              inputRule="number"
              hideDetails
              placeholder="Job no."
              :model="item.scale_fee"
              rulse="fieldRequire"
              @update-data="item.scale_fee = $event"
              class="pr-12"
            />
          </td>
        </tr>

        <!-- Show -->
        <tr v-else>
          <td class="text-center">
            <v-layout>
              <v-flex>
                <v-btn
                  fab
                  depressed
                  small
                  text
                  @click="deleteItem(item, index)"
                >
                  <v-icon :disabled="isEdit" color="#000">
                    mdi-delete-outline
                  </v-icon>
                </v-btn>
              </v-flex>
              <v-flex>
                <v-btn
                  fab
                  depressed
                  small
                  color="#62C4E3"
                  text
                  @click="edit(item, index)"
                >
                  <v-icon :disabled="isEdit">
                    mdi-pencil-outline
                  </v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </td>
          <td class=" border-left">{{ item.no }}</td>
          <td class="">{{ item.port_discharge_name }}</td>
          <td class="">{{ item.port_fee }}</td>
          <td class="">{{ item.scale_fee }}</td>
        </tr>
      </template>
    </v-data-table>

    <v-layout mt-4>
      <v-flex>
        <Paginate
          :current_page="paginate.current_page"
          :last_page="paginate.last_page"
          :nextPage="onHandlePaginate"
        />
      </v-flex>
    </v-layout>

    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="false"
    />
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import TextInput from "../input/TextInput.vue";
import Paginate from "../pagination/Paginate.vue";
import AutocompleteInput from "../select/AutocompleteInput.vue";
export default {
  props: {
    selectItem: Function,
    nameTable: String,
  },
  data() {
    return {
      valid: true,
      isEdit: false,
      indexItem: null,
      headers: [
        {
          text: "Action",
          value: "action",
          align: "center",
          sortable: false,
          width: "10%",
          class: "font-weight-bold",
        },
        {
          text: "No.",
          value: "no",
          align: "start",
          sortable: false,
          width: "23%",
          class: "font-weight-bold border-left",
        },
        {
          text: "ท่าเรือ",
          value: "no",
          align: "start",
          sortable: false,
          width: "23%",
          class: "font-weight-bold ",
        },
        {
          text: "ค่าผ่านท่า",
          align: "start",
          sortable: false,
          width: "23%",
          class: "font-weight-bold ",
        },
        {
          text: "ค่าชั่ง",
          value: "no",
          align: "start",
          sortable: false,
          width: "23%",
          class: "font-weight-bold ",
        },
      ],
      items: [],
      portDichargeList: [],
      form: {
        port_discharge_id: "",
        port_discharge_name: "",
        port_fee: "",
        scale_fee: "",
      },
      paginate: {
        current_page: 1,
        from: 1,
        last_page: 1,
        per_page: 1,
        total: 1,
        to: 1,
      },
      isLoading: false,
      fullPage: true,
    };
  },
  components: { AutocompleteInput, TextInput, Paginate, Loading },
  created() {
    this.isLoading = true;
    Promise.all([this.getData(1), this.getMasterData()])
      .then((values) => {
        this.isLoading = false;
      })
      .catch((error) => {
        console.log(error.response.data.message);
        this.isLoading = false;
      });
  },
  methods: {
    async getData(page) {
      const res = await this.$store.dispatch("tolls_scale/getData", page);
      //! เลข no table
      res.data.data = res.data.data.map((data, i) => ({
        ...data,
        no: (res.data.current_page - 1) * res.data.per_page + i + 1,
      }));
      this.items = res.data.data;
      this.paginate.current_page = res.data.current_page;
      this.paginate.last_page = res.data.last_page;
    },
    async getMasterData() {
      const table = ["port_discharge"];
      const res = await this.$store.dispatch(
        "master_data/getMasterData",
        table
      );
      this.portDichargeList = res.data[0].port_discharge;
      return res.data;
    },
    onHandlePaginate(page) {
      this.isLoading = true;
      this.$store
        .dispatch("tolls_scale/getData", page)
        .then((res) => {
          this.isLoading = false;
          //! เลข no table
          res.data.data = res.data.data.map((data, i) => ({
            ...data,
            no: (res.data.current_page - 1) * res.data.per_page + i + 1,
          }));
          this.items = res.data.data;
          this.paginate.current_page = res.data.current_page;
          this.paginate.last_page = res.data.last_page;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },

    add() {
      if (
        this.form.port_discharge_id &&
        this.form.port_fee &&
        this.form.scale_fee
      ) {
        this.isLoading = true;
        this.$store
          .dispatch("tolls_scale/save", this.form)
          .then((res) => {
            this.isLoading = false;
            this.$store
              .dispatch("tolls_scale/getData", this.paginate.current_page)
              .then((res) => {
                //! เลข no table
                res.data.data = res.data.data.map((data, i) => ({
                  ...data,
                  no: (res.data.current_page - 1) * res.data.per_page + i + 1,
                }));
                this.items = res.data.data;
                this.paginate.current_page = res.data.current_page;
                this.paginate.last_page = res.data.last_page;
                this.form = {
                  port_discharge_id: "",
                  port_discharge_name: "",
                  port_fee: "",
                  scale_fee: "",
                };
              })
              .catch((error) => {
                this.isLoading = false;
              });
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
      } else {
        this.$swal.fire({
          icon: "warning",
          title: "Waring! ",
          text: "กรุณากรอกข้อมูลให้ครบ",
        });
      }
    },
    edit(item, index) {
      this.isEdit = true;
      this.indexItem = index;
    },
    update(item) {
      if (item.port_discharge_id && item.port_fee && item.scale_fee) {
        this.isLoading = true;
        this.$store
          .dispatch("tolls_scale/update", item)
          .then((res) => {
            this.isLoading = false;
            this.$swal
              .fire({
                icon: "success",
                title: "Update successfully!",
                showConfirmButton: false,
                timer: 1500,
              })
              .then(() => {
                this.isEdit = false;
              });
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
      } else {
        this.$swal.fire({
          icon: "warning",
          title: "Waring! ",
          text: "กรุณากรอกข้อมูลให้ครบ",
        });
      }
    },
    deleteItem(item, id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            this.$store
              .dispatch("tolls_scale/delete", item.tolls_scale_id)
              .then((res) => {
                this.isLoading = false;
                this.items = this.items.filter((data, i) => i != id);
                this.$swal.fire({
                  icon: "success",
                  title: "Delete successfully!",
                  showConfirmButton: false,
                  timer: 1500,
                });
              })
              .catch((error) => {
                this.isLoading = false;
              });
          }
        });
    },
    cancle() {
      this.isEdit = false;
      this.isLoading = true;
      this.getData(1)
        .then((res) => {
          this.isLoading = false;
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scope>
/* .test thead.v-data-table-header {
  background-color: #4b5768;
} */
.table-color {
  background-color: #99ca545e;
}
</style>
