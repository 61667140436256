<template>
  <v-navigation-drawer
    class=" fill-height"
    v-model="drawer"
    :mini-variant.sync="mini"
    permanent
    color="#4F4F4F"
    app
  >
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    />
    <v-list-item class="px-2">
      <v-list-item-avatar v-if="mini">
        <v-btn
          width="30"
          height="30"
          fab
          small
          depressed
          color="#fff"
          @click="showSideMenu()"
        >
          <v-icon small>mdi-chevron-right</v-icon>
        </v-btn>
      </v-list-item-avatar>
      <v-list-item-avatar v-else>
        <!-- <v-img src="https://randomuser.me/api/portraits/men/85.jpg"></v-img> -->
        <v-btn class=" font-weight-black" width="50" height="50">
          {{ logoUser(user.firstname) }}
        </v-btn>
      </v-list-item-avatar>

      <v-list-item-action>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <!-- <v-btn depressed color="#fff" v-bind="attrs" v-on="on"> -->
            <v-list-item-title
              class=" font-weight-black"
              v-bind="attrs"
              v-on="on"
              style="color: #828282"
            >
              {{ user.firstname }}
            </v-list-item-title>
            <v-icon color="#828282" v-bind="attrs" v-on="on">
              mdi-menu-down
            </v-icon>
            <!-- </v-btn> -->
          </template>
          <v-list>
            <v-list-item
              link
              v-for="(item, index) in menuLists"
              :key="index"
              @click="onHandleProfileMenu(item.link)"
            >
              <v-list-item-title class="font-14">{{
                item.title
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item-action>

      <v-spacer v-if="!mini"></v-spacer>
      <v-btn
        width="30"
        height="30"
        fab
        small
        depressed
        color="#fff"
        d
        @click.stop="showSideMenu()"
      >
        <v-icon small v-if="!mini">mdi-chevron-left</v-icon>
        <v-icon small v-else>mdi-chevron-right</v-icon>
      </v-btn>
    </v-list-item>

    <v-divider></v-divider>

    <v-list nav dense class="active-side-menu pa-0">
      <v-list-item-group color="#F5F5F5" :value="activeMenu" mandatory>
        <div v-for="(item, i) in permission" :key="i">
          <v-list-item
            class="cursor-pointer"
            :class="item.name === $route.name ? 'active-select-menu' : ''"
            @click="onHandleMenu(item, i, 'main')"
          >
            <template v-if="item.name === 'Admin'" v-slot>
              <v-list-group
                :value="activeMenu === 6 ? true : false"
                no-action
                class=" pa-0 ma-0"
              >
                <template v-slot:activator>
                  <v-list-item-icon class="mr-8">
                    <v-img
                      width="25"
                      height="25"
                      :src="
                        require('../../assets/images/menu-icons/UserCircle.png')
                      "
                      alt=""
                    ></v-img>
                  </v-list-item-icon>
                  <v-list-item-content
                    style="width: 120px"
                    class="font-14 font-weight-bold"
                    :style="
                      item.name === $route.name
                        ? { color: '#338A68' }
                        : { color: '#828282' }
                    "
                  >
                    Admin
                  </v-list-item-content>
                </template>

                <v-list-item
                  class="pl-10"
                  v-for="(subMenu, x) in item.subMenu"
                  :key="x"
                  link
                  mandatory
                  @click="onHandleMenu(subMenu, i, 'sub')"
                >
                  <v-list-item-icon class="mr-3">
                    <v-img
                      v-if="subMenu.icon === 'mdi-settings'"
                      width="25"
                      height="25"
                      :src="require('../../assets/images/menu-icons/Gear.png')"
                      alt=""
                    ></v-img>
                    <v-img
                      v-if="subMenu.icon === 'mdi-file-document'"
                      width="25"
                      height="25"
                      :src="
                        require('../../assets/images/menu-icons/NewspaperColor.png')
                      "
                      alt=""
                    ></v-img>
                  </v-list-item-icon>
                  <v-list-item-content
                    class="font-14 font-weight-bold"
                    :style="
                      subMenu.link === checkSubActiveMenu(subMenu)
                        ? { color: '#338A68' }
                        : { color: '#828282' }
                    "
                    v-text="subMenu.name"
                  ></v-list-item-content>
                </v-list-item>
              </v-list-group>
            </template>
            <template v-else v-slot>
              <v-list-item-icon class="pl-2">
                <v-img
                  v-if="item.icon === 'mdi-home'"
                  width="25"
                  height="25"
                  :src="require('../../assets/images/menu-icons/HouseLine.png')"
                />
                <v-img
                  v-if="item.icon === 'mdi-file-document'"
                  width="25"
                  height="25"
                  :src="require('../../assets/images/menu-icons/File.png')"
                />
                <v-img
                  v-if="item.icon === 'mdi-cube'"
                  width="25"
                  height="25"
                  :src="require('../../assets/images/menu-icons/Cube.png')"
                />
                <v-img
                  v-if="item.icon === 'mdi-truck'"
                  width="25"
                  height="25"
                  :src="require('../../assets/images/menu-icons/Truck.png')"
                />
                <v-img
                  v-if="item.icon === 'mdi-checkbox-marked-circle'"
                  width="25"
                  height="25"
                  :src="
                    require('../../assets/images/menu-icons/CheckCircle.png')
                  "
                />
                <v-img
                  v-if="item.icon === 'mdi-coin'"
                  width="25"
                  height="25"
                  :src="
                    require('../../assets/images/menu-icons/CurrencyBtc.png')
                  "
                />
              </v-list-item-icon>
              <v-list-item-content>
                <span
                  :style="
                    item.name === $route.name
                      ? { color: '#338A68' }
                      : { color: '#828282' }
                  "
                  class="font-14 font-weight-bold"
                >
                  {{ item.name }}
                </span>
              </v-list-item-content>
            </template>
          </v-list-item>
        </div>

        <div v-if="user.user_group_id != 2">
          <v-list-item
            @click="goTo('/monitoring')"
            class="cursor-pointer"
            :class="
              $route.name === 'MonitoringList' ? 'active-select-menu' : ''
            "
            link
            mandatory
          >
            <v-list-item-icon class="pl-2">
              <v-img
                width="25"
                height="25"
                :src="require('../../assets/images/menu-icons/task.png')"
              />
            </v-list-item-icon>
            <v-list-item-content>
              <span
                :style="
                  'MonitoringList' === $route.name
                    ? { color: '#338A68' }
                    : { color: '#828282' }
                "
                class="font-14 font-weight-bold"
              >
                Monitoring
              </span>
            </v-list-item-content>
          </v-list-item>
        </div>
        <div v-if="user.user_group_id != 2">
          <v-list-item
            class="cursor-pointer"
            :class="
              $route.name === 'Shipment Control' ||
              $route.name === 'Shipment Control Export' ||
              $route.name === 'Shipment Control Import' ||
              $route.name === 'ControlTruckSupply'
                ? 'active-select-menu'
                : ''
            "
          >
            <v-list-group
              :value="
                $route.name === 'Shipment Control' ||
                $route.name === 'Shipment Control Export'
                  ? true
                  : false
              "
              no-action
              class=""
            >
              <template v-slot:activator>
                <v-list-item-icon class="mr-8">
                  <v-icon
                    :color="
                      $route.name === 'Shipment Control' ||
                      $route.name === 'Shipment Control Export' ||
                      $route.name === 'Shipment Control Import' ||
                      $route.name === 'ControlTruckSupply'
                        ? '#338A68'
                        : '#338A68'
                    "
                  >
                    mdi-chart-arc
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content
                  style="width: 120px"
                  class="font-14 font-weight-bold"
                  :style="
                    $route.name === 'Shipment Control' ||
                    $route.name === 'Shipment Control Export' ||
                    $route.name === 'Shipment Control Import' ||
                    $route.name === 'ControlTruckSupply'
                      ? { color: '#338A68' }
                      : { color: '#828282' }
                  "
                >
                  Control Tower
                </v-list-item-content>
              </template>

              <v-list-item
                @click="goTo('/shipment-control')"
                class="cursor-pointer"
                :class="
                  $route.name === 'Shipment Control' ? 'active-select-menu' : ''
                "
                link
                mandatory
              >
                <v-list-item-icon class="mr-3">
                  <v-icon color="#338a68">mdi-chart-bar</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <span
                    :style="
                      $route.name === 'Shipment Control'
                        ? { color: '#338A68' }
                        : { color: '#828282' }
                    "
                    class="font-14 font-weight-bold"
                  >
                    Shipment Control
                  </span>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                @click="goTo('/shipment-control-export')"
                class="cursor-pointer"
                :class="
                  $route.name === 'Shipment Control Export'
                    ? 'active-select-menu'
                    : ''
                "
                link
                mandatory
              >
                <v-list-item-icon class="mr-3">
                  <v-icon color="#338a68">mdi-chart-histogram</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <span
                    :style="
                      $route.name === 'Shipment Control Export'
                        ? { color: '#338A68' }
                        : { color: '#828282' }
                    "
                    class="font-14 font-weight-bold"
                  >
                    Shipment Control Export
                  </span>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                @click="goTo('/shipment-control-import')"
                class="cursor-pointer"
                :class="
                  $route.name === 'Shipment Control Import'
                    ? 'active-select-menu'
                    : ''
                "
                link
                mandatory
              >
                <v-list-item-icon class="mr-3">
                  <v-icon color="#338a68">mdi-chart-line</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <span
                    :style="
                      $route.name === 'Shipment Control Import'
                        ? { color: '#338A68' }
                        : { color: '#828282' }
                    "
                    class="font-14 font-weight-bold"
                  >
                    Shipment Control Import
                  </span>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                @click="goTo('/control-trucksupply')"
                class="cursor-pointer"
                :class="
                  $route.name === 'ControlTruckSupply'
                    ? 'active-select-menu'
                    : ''
                "
                link
                mandatory
              >
                <v-list-item-icon class="mr-3">
                  <v-icon color="#338a68">mdi-chart-pie</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <span
                    :style="
                      $route.name === 'ControlTruckSupply'
                        ? { color: '#338A68' }
                        : { color: '#828282' }
                    "
                    class="font-14 font-weight-bold"
                  >
                    Truck Supply
                  </span>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list-item>
        </div>

        <div>
          <v-list-item
            @click="goTo('/customers')"
            class="cursor-pointer"
            :class="$route.name === 'CustomerList' ? 'active-select-menu' : ''"
            link
            mandatory
          >
            <v-list-item-icon class="pl-2">
              <v-img
                width="25"
                height="25"
                :src="require('../../assets/images/menu-icons/Handshake.png')"
              />
            </v-list-item-icon>
            <v-list-item-content>
              <span
                :style="
                  'CustomerList' === $route.name
                    ? { color: '#338A68' }
                    : { color: '#828282' }
                "
                class="font-14 font-weight-bold"
              >
                Customers
              </span>
            </v-list-item-content>
          </v-list-item>
        </div>

        <div class="version-control" v-if="!mini">
          <div><span class=" font-weight-bold">Version:</span> 0.9.2</div>
          <div>
            <span class=" font-weight-bold">Release date:</span>
            22/04/2024
          </div>
        </div>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      drawer: true,
      mini: true,
      subMenu: true,
      cruds: [
        ["Create", "mdi-plus-outline"],
        ["Read", "mdi-file-outline"],
        ["Update", "mdi-update"],
        ["Delete", "mdi-delete"],
      ],
      items: [
        { title: "Home", icon: "mdi-home-city" },
        { title: "My Account", icon: "mdi-account" },
        { title: "Users", icon: "mdi-account-group-outline" },
      ],
      menuLists: [
        {
          title: "Password Setting",
          link: "change-password",
          icon: "mdi-password-outline",
        },
        { title: "Logout", link: "logout", icon: "mdi-logout-variant" },
      ],
    };
  },
  computed: {
    ...mapState({
      activeMenu: (state) => state.menu.activeMenu,
      permission: (state) => state.auth.permission,
      user: (state) => state.auth.user,
    }),
  },

  created() {
    // this.permission = this.permission.splice(1, 1);
  },
  methods: {
    goTo(link) {
      this.$router.push(link).catch((err) => {
        if (err.name !== "NavigationDuplicated") {
          throw err;
        }
      });
    },
    logoUser(text) {
      return text.charAt(0);
    },
    checkSubActiveMenu(item) {
      return this.$route.path.split("/")[1];
    },
    onHandleProfileMenu(link) {
      switch (link) {
        case "change-password":
          this.$router.push(link);
          break;
        case "logout":
          this.isLoading = true;
          // this.fullPage = true;
          this.$store.dispatch("auth/logout").then((res) => {
            this.isLoading = false;
            // this.fullPage = false;
            window.location = "/";
          });
          break;
        default:
      }
    },
    onHandleMenu(item, index, type) {
      if (item.name !== this.$route.name) {
        if (item.name !== "Admin") {
          if (item.name === "Master Data") {
            // todo change to prod
            // window.open("https://tnl-admin.ksta.co/");
            window.open("https://tnlogistic.com:8443/");
          } else {
            this.$store.commit("menu/SET_ACTIVE_MENU", index);
            this.$router.push("/" + item.link);
          }
        }
      }
    },
    checkIcon(icon) {
      switch (icon) {
        case "mdi-home":
          return "../../assets/images/menu-icons/HouseLine.png";
        case "mdi-file-document":
          return "../../assets/images/menu-icons/File.png";
        case "mdi-cube":
          return "../../assets/images/menu-icons/Cube.png";
        case "mdi-truck":
          return "../../assets/images/menu-icons/Truck.png";
        case "mdi-checkbox-marked-circle":
          return "../../assets/images/menu-icons/CheckCircle.png";
        case "mdi-coin":
          return "../../assets/images/menu-icons/CurrentBtc.png";
        // case "mdi-home":
        //   return "../../assets/images/menu-icons/CheckCircle.png";
        // case "mdi-home":
        //   return "../../assets/images/menu-icons/CheckCircle.png";
      }
    },
    showSideMenu() {
      this.mini = !this.mini;
      this.$store.commit("menu/SET_MINI", this.mini);
    },
  },
};
</script>

<style lang="css">
.active-menu {
  background-color: #ffffff;
}
.active-select-menu {
  background-color: #f5f5f5;
}
.active-select-sub-menu {
  color: #338a68;
}

.v-list--nav .v-list-item,
.v-list--nav .v-list-item:before {
  border-radius: unset !important;
}
</style>
