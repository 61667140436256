<template>
  <div>
    <div class="ml-8 mb-4 mt-1 d-flex">
      <img
        width="22"
        src="../../../assets/images/menu-icons/CurrencyBtc.png"
        alt=""
      />
      <span class=" font-weight-bold text-uppercase">Cost/Truck</span>
    </div>

    <div class="mx-8">
      <!-- Form -->
      <v-card flat outlined>
        <v-form ref="form" v-model="valid" lazy-validation class="px-6 py-6">
          <v-layout wrap mb-3>
            <v-flex xs5 class="d-flex justify-center align-center">
              <v-layout class="align-center">
                <v-flex class="xs5 text-end  font-14 mr-3 font-weight-bold">
                  Load/Unload Date From:
                </v-flex>
                <v-flex class="px-0">
                  <DatePicker
                    hideDetails
                    placeholder="DD/MM/YY"
                    hideDetail
                    rulse="fieldRequire"
                    :model="date.load_and_unload_date_from"
                    @update-data="
                      form.load_and_unload_date_from = $event ? $event : '';
                      date.load_and_unload_date_from = $event
                        ? customDate($event)
                        : '';
                    "
                  />
                </v-flex>
                <v-flex class="xs1 font-14 mx-1 text-center font-weight-bold">
                  To:
                </v-flex>
                <v-flex class="px-0">
                  <DatePicker
                    hideDetails
                    placeholder="DD/MM/YY"
                    hideDetail
                    rulse="fieldRequire"
                    :model="date.load_and_unload_date_to"
                    @update-data="
                      form.load_and_unload_date_to = $event ? $event : '';
                      date.load_and_unload_date_to = $event
                        ? customDate($event)
                        : '';
                    "
                  />
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs3 class="d-flex justify-center align-center">
              <v-layout class="align-center">
                <v-flex class="xs5 text-end font-14 mr-3 font-weight-bold">
                  Truck License No. :
                </v-flex>
                <v-flex class="xs7 px-0">
                  <AutocompleteInput
                    clearable
                    hideDetails
                    :model="form.truck_license_no"
                    rulse="fieldRequire"
                    :items="truckLicenseList"
                    itemText="plate_no"
                    itemValue="plate_no"
                    @update-data="
                      form.truck_license_no = $event ? $event.plate_no : ''
                    "
                  />
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs3 class="d-flex justify-center align-center">
              <v-layout class="align-center">
                <v-flex class="xs4 text-end font-14 mr-3 font-weight-bold">
                  Province:
                </v-flex>
                <v-flex class="xs7 px-0">
                  <AutocompleteInput
                    clearable
                    hideDetails
                    :model="form.province_code"
                    rulse="fieldRequire"
                    :items="provinceList"
                    itemText="name_th"
                    itemValue="code"
                    @update-data="
                      form.province_code = $event ? $event.code : ''
                    "
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

          <v-layout wrap mb-3>
            <v-flex xs5 class="d-flex justify-center align-center">
              <v-layout class="align-center">
                <v-flex class="xs5 text-end  font-14 mr-3 font-weight-bold">
                  Tender Date From:
                </v-flex>
                <v-flex class="px-0">
                  <DatePicker
                    hideDetails
                    placeholder="DD/MM/YY"
                    hideDetail
                    rulse="fieldRequire"
                    :model="date.tender_date_from"
                    @update-data="
                      form.tender_date_from = $event ? $event : '';
                      date.tender_date_from = $event ? customDate($event) : '';
                    "
                  />
                </v-flex>
                <v-flex class="xs1 font-14 mx-1 text-center font-weight-bold">
                  To:
                </v-flex>
                <v-flex class="px-0">
                  <DatePicker
                    hideDetails
                    placeholder="DD/MM/YY"
                    hideDetail
                    rulse="fieldRequire"
                    :model="date.tender_date_to"
                    @update-data="
                      form.tender_date_to = $event ? $event : '';
                      date.tender_date_to = $event ? customDate($event) : '';
                    "
                  />
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs3 class="d-flex justify-center align-center">
              <v-layout class="align-center">
                <v-flex class="xs5 text-end font-14 mr-3 font-weight-bold">
                  First Name:
                </v-flex>
                <v-flex class="xs7 px-0">
                  <TextInput
                    hideDetails
                    :model="form.first_name"
                    rulse="fieldRequire"
                    @update-data="form.first_name = $event"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs3 class="d-flex justify-center align-center">
              <v-layout class="align-center">
                <v-flex class="xs4 text-end font-14 mr-3 font-weight-bold">
                  Last Name:
                </v-flex>
                <v-flex class="xs7 px-0">
                  <TextInput
                    hideDetails
                    :model="form.last_name"
                    rulse="fieldRequire"
                    @update-data="form.last_name = $event"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

          <v-layout wrap mb-3>
            <v-flex xs5 class="d-flex justify-center align-center">
              <v-layout class="align-center">
                <v-flex class="xs3 text-end font-14 mr-3 font-weight-bold">
                  Carrier:
                </v-flex>
                <v-flex class=" px-0">
                  <AutocompleteInput
                    clearable
                    hideDetails
                    :model="form.carrier_id"
                    rulse="fieldRequire"
                    :items="carrierList"
                    itemText="name"
                    itemValue="carrier_id"
                    @update-data="
                      form.carrier_id = $event ? $event.carrier_id : ''
                    "
                  />
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs3 class="d-flex justify-center align-center">
              <v-layout class="align-center">
                <v-flex class="xs5 text-end font-14 mr-3 font-weight-bold">
                  CY Place:
                </v-flex>
                <v-flex class="xs7 px-0">
                  <AutocompleteInput
                    clearable
                    hideDetails
                    :model="form.cy_place_id"
                    rulse="fieldRequire"
                    :items="cyPlaceList"
                    itemText="cy_place_name"
                    itemValue="cy_place_id"
                    @update-data="
                      form.cy_place_id = $event ? $event.cy_place_id : ''
                    "
                  />
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs3 class="d-flex justify-center align-center">
              <v-layout class="align-center">
                <v-flex class="xs4 text-end font-14 mr-3 font-weight-bold">
                  CY Date:
                </v-flex>
                <v-flex class="xs7 px-0">
                  <DatePicker
                    hideDetails
                    placeholder="DD/MM/YY"
                    hideDetail
                    rulse="fieldRequire"
                    :model="date.cy_date"
                    @update-data="
                      form.cy_date = $event ? $event : '';
                      date.cy_date = $event ? customDate($event) : $event;
                    "
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

          <v-layout wrap mb-3>
            <v-flex xs5 class="d-flex justify-center align-center">
              <v-layout class="align-center">
                <v-flex class="xs3 text-end font-14 mr-3 font-weight-bold">
                  Job No.
                </v-flex>
                <v-flex class=" px-0">
                  <TextInput
                    hideDetails
                    :model="form.order_id"
                    rulse="fieldRequire"
                    @update-data="form.order_id = $event"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs3 class="d-flex justify-center align-center"></v-flex>
            <v-flex xs3 class="d-flex justify-end align-center">
              <v-btn
                depressed
                color="#62C4E3"
                class=" mr-3 font-weight-bold"
                @click="onHandleNewCostPerTruck()"
              >
                <span class="text-capitalize" style="color: #fff">
                  New Cost/Truck Today
                </span>
              </v-btn>
              <v-btn
                depressed
                color="#F7D25D"
                class="font-weight-bold mr-3"
                @click="onHandleSearch()"
              >
                <v-icon class="mr-3">mdi-magnify</v-icon>
                <span class="text-capitalize">Search</span>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card>

      <v-layout>
        <v-flex shrink>
          <v-btn
            outlined
            depressed
            color="#338A68"
            dark
            class="my-4 shrink font-weight-bold mr-3"
            @click="changeStatus('Paid')"
          >
            <span class="text-capitalize">Paid</span>
          </v-btn>
        </v-flex>

        <v-flex shrink>
          <v-btn
            outlined
            depressed
            color="#EB5757"
            dark
            class="my-4 shrink font-weight-bold mr-3"
            @click="changeStatus('Void')"
          >
            <span class="text-capitalize">Void</span>
          </v-btn>
        </v-flex>
        <v-flex shrink>
          <v-btn
            outlined
            depressed
            color="#EB5757"
            dark
            class="my-4 shrink font-weight-bold mr-3"
            @click="changeStatus('Cancel')"
          >
            <span class="text-capitalize">Cancel</span>
          </v-btn>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex shrink>
          <v-btn
            depressed
            color="#338A68"
            dark
            class="my-4 shrink font-weight-bold mr-3"
            @click="setTrip()"
          >
            <span class="text-capitalize">Set Trip</span>
          </v-btn>
        </v-flex>
        <v-flex shrink>
          <v-btn
            depressed
            color="#338A68"
            dark
            class="my-4 shrink font-weight-bold mr-3"
            @click="removeTrip()"
          >
            <span class="text-capitalize">Remove Trip</span>
          </v-btn>
        </v-flex>
        <v-flex shrink>
          <v-btn
            outlined
            depressed
            color="#338A68"
            dark
            class="shrink my-4 font-weight-bold mr-3"
            @click="exportExcel()"
          >
            <img
              width="20"
              src="../../../assets/images/menu-icons/excel.png"
              alt=""
            />
            <span class="ml-2 shrink text-capitalize">Export Excel </span>
          </v-btn>
        </v-flex>
        <v-flex shrink>
          <v-btn
            outlined
            depressed
            color="#338A68"
            dark
            class="my-4 font-weight-bold"
            @click="exportBank()"
          >
            <img
              width="20"
              src="../../../assets/images/menu-icons/kbank.png"
              alt=""
            />
            <span class="ml-1 text-capitalize">Export For Bank</span>
          </v-btn>
        </v-flex>
      </v-layout>

      <!-- Table -->
      <v-layout>
        <v-flex style="width: 1px">
          <DataTableCostTruck
            :clearSubSelect="clearSubSelect"
            :clearSelected="clearSelected"
            :selectedItem="selectedItem"
            :onSelectSubItem="onSelectSubItem"
            :onClearSubSelect="onClearSubSelect"
            :onClearSelect="onClearSelect"
            :items="items"
          />
        </v-flex>
      </v-layout>
    </div>

    <v-layout mt-4>
      <v-flex>
        <Paginate
          :current_page="paginate.current_page"
          :last_page="paginate.last_page"
          :nextPage="search"
        />
      </v-flex>
    </v-layout>

    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="false"
    />
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import TextInput from "../../../components/input/TextInput.vue";
import AutocompleteInput from "../../../components/select/AutocompleteInput";
import DataTableCostTruck from "../../../components/table/DataTableCostTruck.vue";
import DatePicker from "../../../components/picker/DatePicker";
import Paginate from "../../../components/pagination/Paginate.vue";
import XLSX from "xlsx"; // import xlsx;
import moment from "moment";
export default {
  components: {
    Loading,
    TextInput,
    DataTableCostTruck,
    AutocompleteInput,
    DatePicker,
    Paginate,
  },
  data: () => ({
    isLoading: false,
    fullPage: true,
    valid: true,
    selectItems: [],
    selectSubItems: [],
    form: {
      load_and_unload_date_from: "",
      load_and_unload_date_to: "",
      truck_license_no: "",
      province_code: "",
      tender_date_from: "",
      tender_date_to: "",
      first_name: "",
      last_name: "",
      carrier_id: "",
      cy_place_id: "",
      cy_date: "",
      order_id: "",
      new_costtruck_today: "",
    },
    date: {
      load_and_unload_date_from: "",
      load_and_unload_date_to: "",
      tender_date_from: "",
      tender_date_to: "",
      cy_date: "",
    },

    paginate: {
      current_page: 1,
      from: 1,
      last_page: 1,
      per_page: 1,
      total: 1,
      to: 1,
    },
    provinceList: [],
    carrierList: [],
    cyPlaceList: [],
    desserts: [],
    truckLicenseList: [],
    items: [],
    data_excel: [],
    clearSubSelect: false,
    clearSelected: false,
  }),
  created() {
    this.getMasterData();
    this.isLoading = true;
    Promise.all([this.getData(1)])
      .then((values) => {
        this.isLoading = false;
      })
      .catch((error) => {
        console.log(error.response.data.message);
        this.isLoading = false;
      });
  },

  methods: {
    async getData(page) {
      const res = await this.$store.dispatch("cost_per_truck/getData", page);
      //! เลข no table
      res.data.data = res.data.data.map((data, i) => ({
        ...data,
        no: (res.data.current_page - 1) * res.data.per_page + i + 1,
      }));
      this.items = res.data.data;
      this.paginate.current_page = res.data.current_page;
      this.paginate.last_page = res.data.last_page;
      return res.data;
    },

    updateData(name, val) {
      this.form[name] = val;
    },
    updateDate(name, val) {
      this.form[name] = val ? val : "";
      this.date[name] = val ? this.customDate(val) : "";
    },
    updateSelect(name, key, val) {
      this.form[name + "_id"] = val[key];
    },
    customDate(val) {
      let sub_year = val.substring(0, 4);
      let sub_month = val.substring(5, 7);
      let sub_day = val.substring(8, 10);
      // sub_year = parseInt(sub_year) + 543;
      let changeDate = sub_day + "/" + sub_month + "/" + sub_year;
      return changeDate;
    },

    selectedItem(items) {
      this.selectItems = items;
    },
    onSelectSubItem(items) {
      this.selectSubItems = items;
      this.data_excel = [];
      items.forEach((data) => {
        const cost_truck_lv1 = this.items.find(
          (i) => i.id == data.cost_truck_lv1_id
        );
        this.data_excel.push({
          "Mode ": data.mod,
          "INV/PO": data.inv_po,
          "Booking ": data.booking_no,
          "Customer ": data.customer_name,
          "Goods ": data.goods_name,
          "CY place": data.cy_place_name,
          "Agent ": data.shipping_agent_name,
          "Cy date": data.cy_date,
          "สถานะตู้ ": data.container_status,
          "Qty ": data.qty,
          "size ": data.container_size_name,
          "Isotype ": data.container_isotype_name,
          "ค่าผ่านลาน ": data.lan_pass_fee.toFixed(2),
          "ค่ายกตู้ ": data.lifting_fee.toFixed(2),
          "ค่าผ่านท่า ": data.entrance_fee.toFixed(2),
          "ค่าชั่ง ": data.scale_fee.toFixed(2),
          "ที่อยู่ออกใบเสร็จค่าผ่านลาน ": data.address_lanpass,
          "ที่อยู่ออกใบเสร็จค่ายกตู้ ": data.address_liftingfee,
          "ที่อยู่ออกใบเสร็จ ค่าผ่านท่า ": data.address_port,
          "ที่อยู่ออกใบเสร็จ ค่าชั่ง ": data.address_scale,
          "WH/factory1": data.wh_factory_1_name,
          "Load/Unload Date1": data.unload_load_date1
            ? moment(data.unload_load_date1).format("DD/MM/YYYY")
            : "",
          "Load/Unload Time1": this.subStringTime(data.unload_load_time1),
          "WH/factory2": data.wh_factory_2_name,
          "Load/Unload Date2": data.unload_load_date2
            ? moment(data.unload_load_date2).format("DD/MM/YYYY")
            : "",
          "Load/Unload Time2": this.subStringTime(data.unload_load_time2),
          "Return place": data.return_place_name,
          "Closting Date": data.closing_date
            ? moment(data.closing_date).format("DD/MM/YYYY")
            : "",
          "Closting Time": this.subStringTime(data.closing_time),
          "Status ": data.status,
          "งานวันที่ ": data.job_date
            ? moment(data.job_date).format("DD/MM/YYYY")
            : "",
          "ทะเบียนรถ ": data.plate_no,
          "ชื่อ พขร.":
            data.driver && data.driver.f_name
              ? data.driver.f_name + " " + data.driver.l_name
              : "",
          "ค่าผ่านลาน(รวม)": cost_truck_lv1.total_lan_pass_fee,
          "ค่ายกตู้(รวม)": cost_truck_lv1.total_lifting_fee,
          "ค่าผ่านท่า(รวม)": cost_truck_lv1.total_entrance_fee,
          "ค่าชั่ง(รวม)": cost_truck_lv1.total_scale_fee,
          "ยอดยกมา ": cost_truck_lv1.spending_amount,
          "รวม ": cost_truck_lv1.total,
        });
      });
    },
    onClearSubSelect(val) {
      this.clearSubSelect = val;
    },
    onClearSelect(val) {
      this.clearSelected = val;
    },
    calTotal(data, key) {
      let total = 0;
      data.forEach((data) => {
        total += data[key];
      });
      return total;
    },

    setTrip() {
      if (this.selectSubItems.length == 1) {
        this.$swal
          .fire({
            title: "Are you sure?",
            text: "Do You Want to Set Trip ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Set Trip!",
          })
          .then((result) => {
            if (result.isConfirmed) {
              if (
                this.selectSubItems[0].lan_pass_fee_pre ===
                this.selectSubItems[0].lan_pass_fee
              ) {
                this.$swal.fire({
                  title: "Something went wrong!",
                  text: "ค่าผ่านลานเป็นค่าล่าสุดแล้ว",
                  icon: "warning",
                  showCancelButton: false,
                  confirmButtonColor: "#3085d6",
                  confirmButtonText: "OK",
                });
              } else {
                this.isLoading = true;
                this.$store
                  .dispatch("cost_per_truck/setTrip", {
                    cost_per_truck_id: this.selectSubItems[0].cost_per_truck_id,
                    lan_pass_fee_pre: this.selectSubItems[0].lan_pass_fee_pre,
                    cost_truck_lv1_id: this.selectSubItems[0].cost_truck_lv1_id,
                  })
                  .then((res) => {
                    this.clearSubSelect = false;
                    this.isLoading = false;
                    this.$swal
                      .fire({
                        icon: "success",
                        title: "Set Trip Successfully!",
                        showConfirmButton: false,
                        timer: 1500,
                      })
                      .then(() => {
                        this.isLoading = true;
                        this.getData(1).then((res) => {
                          this.isLoading = false;
                        });
                      });
                  });
              }
            }
          });
      } else {
        this.$swal.fire({
          title: "Something went wrong!",
          text: "Please, Select 1 row before click button",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      }
    },
    removeTrip() {
      if (this.selectSubItems.length == 1) {
        this.$swal
          .fire({
            title: "Are you sure?",
            text: "Do You Want to Remove Trip ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Remove Trip!",
          })
          .then((result) => {
            if (result.isConfirmed) {
              if (this.selectSubItems[0].lan_pass_fee_type_pre === "ตู้") {
                this.$swal.fire({
                  title: "Something went wrong!",
                  text: "ไม่สามารถ Remove Trip ของ Cost/Truck ที่เป็นตู้ได้",
                  icon: "warning",
                  showCancelButton: false,
                  confirmButtonColor: "#3085d6",
                  confirmButtonText: "OK",
                });
              } else if (this.selectSubItems[0].lan_pass_fee == 0) {
                this.$swal.fire({
                  title: "Something went wrong!",
                  text: "ค่าผ่านลานเป็น 0 ไม่สามารถ Remove Trip ได้",
                  icon: "warning",
                  showCancelButton: false,
                  confirmButtonColor: "#3085d6",
                  confirmButtonText: "OK",
                });
              } else {
                this.isLoading = true;
                this.$store
                  .dispatch("cost_per_truck/removeTrip", {
                    cost_per_truck_id: this.selectSubItems[0].cost_per_truck_id,
                    lan_pass_fee_pre: this.selectSubItems[0].lan_pass_fee,
                    cost_truck_lv1_id: this.selectSubItems[0].cost_truck_lv1_id,
                  })
                  .then((res) => {
                    this.clearSubSelect = false;
                    this.isLoading = false;
                    this.$swal
                      .fire({
                        icon: "success",
                        title: "Remove Trip Successfully!",
                        showConfirmButton: false,
                        timer: 1500,
                      })
                      .then(() => {
                        this.isLoading = true;
                        this.getData(1).then((res) => {
                          this.isLoading = false;
                        });
                      });
                  });
              }
            }
          });
      } else {
        this.$swal.fire({
          title: "Something went wrong!",
          text: "Please, Select 1 row before click button",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      }
    },
    exportExcel() {
      if (this.data_excel.length === 0) {
        this.$swal.fire({
          title: "Something went wrong!",
          text: "Please, Select at least 1 item",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      } else {
        this.$swal
          .fire({
            title: "Are you sure?",
            text: `Do you want to Export Truck Supply ${this.data_excel.length} rows to Excel?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Export!",
          })
          .then((result) => {
            if (result.isConfirmed) {
              const dataWS = XLSX.utils.json_to_sheet(this.data_excel);
              const wb = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(wb, dataWS);
              const fileName = `cost_per_truck_${moment(new Date()).format(
                "DD_MM_YYYY_h_mm_ss"
              )}.xlsx`;
              XLSX.writeFile(wb, fileName);
              this.clearSubSelect = false;
            }
          });
      }
    },
    changeStatus(text) {
      if (this.selectItems.length == 1) {
        this.$swal
          .fire({
            title: "Are you sure?",
            text: "Do You Want to Change Status Cost/Truck ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Change Status!",
          })
          .then((result) => {
            if (result.isConfirmed) {
              //? if Void
              if (text === "Void") {
                if (this.selectItems[0].status === "Paid") {
                  this.isLoading = true;
                  this.$store
                    .dispatch("cost_per_truck/changeStatus", {
                      id: this.selectItems[0].id,
                      status: text,
                    })
                    .then((res) => {
                      this.isLoading = false;
                      this.clearSelected = false;
                      this.$swal
                        .fire({
                          icon: "success",
                          title: "Change Status Successfully!",
                          showConfirmButton: false,
                          timer: 1500,
                        })
                        .then(() => {
                          this.isLoading = true;
                          this.getData(1).then((res) => {
                            this.isLoading = false;
                          });
                        });
                    });
                } else {
                  this.$swal.fire({
                    title: "Something went wrong!",
                    text: "Can't Change Status",
                    icon: "warning",
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "OK",
                  });
                }
              }

              //? if Paid
              else if (text === "Paid") {
                if (this.selectItems[0].status === "Unpaid") {
                  this.isLoading = true;
                  this.$store
                    .dispatch("cost_per_truck/changeStatus", {
                      id: this.selectItems[0].id,
                      status: text,
                    })
                    .then((res) => {
                      this.isLoading = false;
                      this.clearSelected = false;
                      this.$swal
                        .fire({
                          icon: "success",
                          title: "Change Status Successfully!",
                          showConfirmButton: false,
                          timer: 1500,
                        })
                        .then(() => {
                          this.isLoading = true;
                          this.getData(1).then((res) => {
                            this.isLoading = false;
                          });
                        });
                    });
                } else {
                  this.$swal.fire({
                    title: "Something went wrong!",
                    text: "Can't Change Status",
                    icon: "warning",
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "OK",
                  });
                }
              }

              //? if Cancel
              else if (text === "Cancel") {
                if (this.selectItems[0].status === "Unpaid") {
                  this.isLoading = true;
                  this.$store
                    .dispatch("cost_per_truck/changeStatus", {
                      id: this.selectItems[0].id,
                      status: text,
                    })
                    .then((res) => {
                      this.isLoading = false;
                      this.clearSelected = false;
                      this.$swal
                        .fire({
                          icon: "success",
                          title: "Change Status Successfully!",
                          showConfirmButton: false,
                          timer: 1500,
                        })
                        .then(() => {
                          this.isLoading = true;
                          this.getData(1).then((res) => {
                            this.isLoading = false;
                          });
                        });
                    });
                } else {
                  this.$swal.fire({
                    title: "Something went wrong!",
                    text: "Can't Change Status",
                    icon: "warning",
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "OK",
                  });
                }
              } else {
                console.log("error");
              }
            }
          });
      } else {
        this.$swal.fire({
          title: "Something went wrong!",
          text: "Please, Select 1 row before click button",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      }
    },

    exportBank() {
      if (this.selectItems.length === 0) {
        this.$swal.fire({
          title: "Something went wrong!",
          text: "Please, Select at least 1 item",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      } else {
        // payroll
        //* loop check ว่า รายการที่เลือกมา status ต้อง Paid เท่านั้น
        for (const data of this.selectItems) {
          //? อาจจะต้องหาวิธี loop ให้ไวขึ้น
          if (data.status !== "Paid") {
            this.$swal.fire({
              title: "Something went wrong!",
              text: "Please select a row whose status is all Paid.",
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            });
            return false;
          }
        }
        this.isLoading = true;
        let data = this.selectItems.map((data) => {
          return data.id;
        });
        this.$store.dispatch("cost_per_truck/payroll", data).then((res) => {
          this.isLoading = false;
          let blob = new Blob([res], { type: "text/csv" });
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          var url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = `kBank_PCT_${moment(new Date()).format("DMY")}.txt`;
          a.click();
          window.URL.revokeObjectURL(url);
        });
      }
    },

    onHandleSearch() {
      this.form.new_costtruck_today = "";
      this.search(1);
    },
    search(page) {
      this.isLoading = true;
      this.form["page"] = page;
      this.$store
        .dispatch("cost_per_truck/search", this.form)
        .then((res) => {
          //! เลข no table
          res.data.data = res.data.data.map((data, i) => ({
            ...data,
            no: (res.data.current_page - 1) * res.data.per_page + i + 1,
          }));
          this.items = res.data.data;
          this.paginate.current_page = res.data.current_page;
          this.paginate.last_page = res.data.last_page;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    onHandleNewCostPerTruck() {
      this.form = {
        load_and_unload_date_from: "",
        load_and_unload_date_to: "",
        truck_license_no: "",
        province_code: "",
        tender_date_from: "",
        tender_date_to: "",
        first_name: "",
        last_name: "",
        carrier_id: "",
        cy_place_id: "",
        cy_date: "",
        order_id: "",
        new_costtruck_today: "new_costtruck_today",
      };
      this.search(1);
    },
    // Get Master data
    getMasterData() {
      this.$store.dispatch("cost_per_truck/getMasterData").then((res) => {
        this.provinceList = res.data.province;
        this.carrierList = res.data.carrier;
        this.cyPlaceList = res.data.cy_place;
        this.truckLicenseList = res.data.trucks;
      });
    },
  },
};
</script>

<style></style>
