<template>
  <div>
    <div class="ml-8 mb-4 mt-1">
      <v-icon color="#338A68" class="mr-2">
        mdi-account-key
      </v-icon>
      <span class=" font-weight-bold text-uppercase">{{ $route.name }}</span>
    </div>

    <div class=" mx-8">
      <v-card outlined class="pa-8">
        <Loading
          :active.sync="isLoading"
          :can-cancel="false"
          :is-full-page="false"
        />
        <v-row>
          <v-col cols="5">
            <label for="password">Password :</label>
            <v-text-field
              v-model="form.password"
              style=""
              outlined
              dense
              color="#338A68"
              type="password"
            />
          </v-col>
          <v-col cols="5">
            <label for="password">Confirm Password :</label>
            <v-text-field
              v-model="form.password_confirmation"
              outlined
              dense
              color="#338A68"
              type="password"
            />
          </v-col>
          <v-col cols="2" class="d-flex align-center">
            <v-btn
              color="#338A68"
              dark
              class="mr-3 font-weight-bold"
              @click="changePassword()"
            >
              <span class="text-capitalize font-11">Change Password</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mapState } from "vuex";

export default {
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: false,
      form: {
        password: "",
        password_confirmation: "",
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    changePassword() {
      this.isLoading = true;
      this.form["id"] = this.user.id;
      this.$store
        .dispatch("auth/changePassword", this.form)
        .then((res) => {
          this.isLoading = false;
          if (res.data.success === true) {
            this.$swal.fire({
              icon: "success",
              title: "Change Password successfully!",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            });
          } else {
            this.$swal.fire({
              icon: "error",
              title: "Failed!",
              text: res.response.data.data.password,
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            });
          }
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style></style>
