<template>
  <div class="pa-8">
    <div class="mb-6 mt-1">
      <v-icon small class="mr-2">mdi-file</v-icon>
      <span
        @click="$router.push('/order')"
        class="cursor-pointer font-weight-bold text-uppercase"
        >Order</span
      >
      <v-icon color="#338A68">mdi-chevron-right</v-icon>
      <span style="color: #338A68">View</span>
    </div>

    <!-- Impoer -->
    <v-card v-if="form.mode === 'Import'" class="py-6" flat color="#F5F5F5">
      <v-layout mb-3>
        <v-flex xs3>
          <v-layout class="align-center">
            <v-flex class="xs6 text-end font-12  font-weight-bold mr-3">
              Mode :
            </v-flex>
            <v-flex class="xs6 font-12">
              {{ form.mode }}
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs3>
          <v-layout class="align-center">
            <v-flex class="xs4 text-end font-12  font-weight-bold mr-3">
              CS :
            </v-flex>
            <v-flex class="xs7 font-12">
              {{ form.user_name }}
            </v-flex>
          </v-layout>
        </v-flex>
        <v-spacer />
      </v-layout>

      <v-layout wrap px-6 mb-3>
        <v-flex xs3>
          <v-layout class="align-center">
            <v-flex class="xs6 text-end font-12  font-weight-bold mr-3">
              Customer :
            </v-flex>
            <v-flex class="xs7 font-12">
              {{ form.customer_name }}
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs3>
          <v-layout class="align-center">
            <v-flex class="xs4 text-end font-12  font-weight-bold mr-3">
              Receipt Type :
            </v-flex>
            <v-flex class="xs7 font-12">
              {{ form.receipt_type_name }}
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs6>
          <v-layout>
            <v-flex class="xs3 text-end font-12  font-weight-bold mr-3">
              Address :
            </v-flex>
            <v-flex class="xs9 font-12">
              {{ form.address_name }}
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-layout wrap px-6>
        <v-flex xs3>
          <v-layout class="align-center">
            <v-flex class="xs6 text-end  font-12  font-weight-bold mr-3">
              IMPORTER :
            </v-flex>
            <v-flex class="xs7 font-12">
              {{ form.importer_name }}
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs3>
          <v-layout class="align-center">
            <v-flex class="xs4 text-end font-12  font-weight-bold mr-3">
              INV/.PO :
            </v-flex>
            <v-flex class="xs7 font-12">
              {{ form.inv_po }}
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs6>
          <v-layout class="">
            <v-flex class="xs3 pt-2 text-end  font-12  font-weight-bold mr-3">
              Cost For :
            </v-flex>
            <v-flex class="xs9 font-12">
              <v-layout>
                <v-flex xs3>
                  <v-checkbox
                    readonly
                    class="ma-0"
                    hide-details
                    label="ค่ายกตู้"
                    color="#338A68"
                    v-model="form.is_cost_for_lifting"
                  >
                    <template v-slot:label>
                      <div class="font-12 font-weight-bold">
                        ค่ายกตู้
                      </div>
                    </template>
                  </v-checkbox>
                </v-flex>
                <v-flex xs3>
                  <v-checkbox
                    readonly
                    class="ma-0"
                    hide-details
                    label="ค่าชั่ง"
                    color="#338A68"
                    v-model="form.is_cost_for_scale"
                  >
                    <template v-slot:label>
                      <div class="font-12 font-weight-bold">
                        ค่าชั่ง
                      </div>
                    </template>
                  </v-checkbox>
                </v-flex>

                <v-flex xs3>
                  <v-checkbox
                    readonly
                    class="ma-0"
                    hide-details
                    label="ค่าผ่านลาน"
                    color="#338A68"
                    v-model="form.is_cost_for_lan"
                  >
                    <template v-slot:label>
                      <div class="font-12 font-weight-bold">
                        ค่าผ่านลาน
                      </div>
                    </template>
                  </v-checkbox>
                </v-flex>
                <v-flex xs3>
                  <v-checkbox
                    readonly
                    class="ma-0"
                    hide-details
                    label="ค่าผ่านท่า"
                    color="#338A68"
                    v-model="form.is_cost_for_port"
                  >
                    <template v-slot:label>
                      <div class="font-12 font-weight-bold">
                        ค่าผ่านท่า
                      </div>
                    </template>
                  </v-checkbox>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-divider class="my-6"></v-divider>

      <v-layout px-6>
        <v-flex xs4>
          <v-layout class="align-center mb-3">
            <v-flex class="xs4 text-end font-12  font-weight-bold mr-3">
              AGEN :
            </v-flex>
            <v-flex class="xs7 font-12">
              {{ form.shipping_agent_name }}
            </v-flex>
          </v-layout>

          <v-layout class="align-center mb-3">
            <v-flex class="xs4 text-end font-12  font-weight-bold mr-3">
              Goods :
            </v-flex>
            <v-flex class="xs7 font-12">
              {{ form.goods_name }}
            </v-flex>
          </v-layout>

          <v-layout class="align-center mb-3">
            <v-flex class="xs4 text-end font-12  font-weight-bold mr-3">
              Port Discharge :
            </v-flex>
            <v-flex class="xs7 font-12">
              {{ form.port_discharge_name }}
            </v-flex>
          </v-layout>

          <v-layout class="align-center mb-3">
            <v-flex class="xs4 text-end  font-12  font-weight-bold mr-3">
              ATA :
            </v-flex>
            <v-flex class="xs4 font-12">
              {{ form.ata | moment("DD/MM/YYYY") }}
            </v-flex>
          </v-layout>

          <v-layout class="align-center mb-3">
            <v-flex class="xs4 text-end  font-12  font-weight-bold mr-3">
              Discharge Complete :
            </v-flex>
            <v-flex class="xs4 font-12">
              {{ form.discharge_complete | moment("DD/MM/YYYY") }}
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex xs4>
          <v-layout class="align-center mb-3">
            <v-flex class="xs4 text-end font-12  font-weight-bold mr-3">
              Shipping :
            </v-flex>
            <v-flex class="xs7 font-12">
              {{ form.shipping_name }}
            </v-flex>
          </v-layout>

          <v-layout class="align-center mb-3">
            <v-flex class="xs4 text-end font-12  font-weight-bold mr-3">
              Haulage :
            </v-flex>
            <v-flex class="xs7 font-12">
              {{ form.haulage_name }}
            </v-flex>
          </v-layout>

          <v-layout class="align-center mb-3">
            <v-flex class="xs4 text-end font-12  font-weight-bold mr-3">
              Release Port :
            </v-flex>
            <v-flex class="xs7 font-12">
              {{ form.release_port_name }}
            </v-flex>
          </v-layout>

          <v-layout class="align-center mb-3">
            <v-flex class="xs4 text-end  font-12  font-weight-bold mr-3">
              Release Date :
            </v-flex>
            <v-flex class="xs4 font-12">
              {{ form.release_date | moment("DD/MM/YYYY") }}
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex xs4>
          <v-layout class="mb-3">
            <v-flex xs6>
              <v-layout class="align-center">
                <v-flex class="xs5 text-end font-12  font-weight-bold mr-3">
                  RENT :
                </v-flex>
                <v-flex class="xs7 font-12">
                  {{ form.rent | moment("DD/MM/YYYY") }}
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs6>
              <v-layout class="align-center">
                <v-flex class="xs5 text-end font-12  font-weight-bold mr-3">
                  Demurage :
                </v-flex>
                <v-flex class="xs7 font-12">
                  {{ form.demurage | moment("DD/MM/YYYY") }}
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

          <v-layout class="mb-3">
            <v-flex xs6>
              <v-layout class="align-center">
                <v-flex class="xs5 text-end font-12  font-weight-bold mr-3">
                  Detention :
                </v-flex>
                <v-flex class="xs7 font-12">
                  {{ form.detention | moment("DD/MM/YYYY") }}
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs6>
              <v-layout class="align-center">
                <v-flex class="xs5 text-end font-12  font-weight-bold mr-3">
                  Detention <br />
                  (Days) :
                </v-flex>
                <v-flex class="xs7 font-12">
                  {{ form.detention_days }}
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

          <v-layout class="align-center">
            <v-flex class="xs2 text-end font-12  font-weight-bold mr-3">
              Container Status :
            </v-flex>
            <v-flex class="xs7 pl-2">
              {{ form.container_status }}
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-divider class="my-6"></v-divider>

      <v-layout wrap px-6>
        <v-flex>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Qty</th>
                  <th class="text-left">Size</th>
                  <th class="text-left">Isotype</th>
                  <th class="text-left">WH/Factory1</th>
                  <th class="text-left">Unload / Load Date Time1</th>
                  <th class="text-left">WH/Factory2</th>
                  <th class="text-left">Unload / Load Date Time2</th>
                  <th class="text-left">Return Place</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in containers_form" :key="i">
                  <td>{{ item.qty }}</td>
                  <td>{{ item.container_size_name }}</td>
                  <td>{{ item.container_isotype_name }}</td>
                  <td>{{ item.wh_factory_1_name }}</td>
                  <td>
                    {{ date_con[i].unload_load_date1 }}
                    {{ item.unload_load_time1 }}
                  </td>
                  <td>{{ item.wh_factory_2_name }}</td>
                  <td>
                    {{ date_con[i].unload_load_date2 }}
                    {{ item.unload_load_time2 }}
                  </td>
                  <td>{{ item.return_place_name }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-flex>
      </v-layout>
    </v-card>

    <!-- Other -->
    <v-card v-else class="py-6" flat color="#F5F5F5">
      <v-layout mb-3>
        <v-flex xs3>
          <v-layout class="align-center">
            <v-flex class="xs6 text-end font-12  font-weight-bold mr-3">
              Mode :
            </v-flex>
            <v-flex class="xs6 font-12">
              {{ form.mode }}
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs3>
          <v-layout class="align-center">
            <v-flex class="xs4 text-end font-12  font-weight-bold mr-3">
              CS :
            </v-flex>
            <v-flex class="xs7 font-12">
              {{ form.user_name }}
            </v-flex>
          </v-layout>
        </v-flex>
        <v-spacer />
      </v-layout>

      <v-layout wrap px-6 mb-3>
        <v-flex xs3>
          <v-layout class="align-center">
            <v-flex class="xs6 text-end font-12 mr-3 font-weight-bold ">
              Customer :
            </v-flex>
            <v-flex class="xs7 font-12">
              {{ form.customer_name }}
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs3>
          <v-layout class="align-center">
            <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold ">
              Exporter :
            </v-flex>
            <v-flex class="xs7 font-12">
              {{ form.exporter_name }}
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs3>
          <v-layout class="align-center">
            <v-flex class="xs4 text-end font-12  font-weight-bold mr-3">
              Receipt Type :
            </v-flex>
            <v-flex class="xs7 font-12">
              {{ form.receipt_type_name }}
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs3>
          <v-layout class="align-center">
            <v-flex class="xs6 text-end font-12  font-weight-bold mr-3">
              Address :
            </v-flex>
            <v-flex class="xs7 font-12"> </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-layout wrap px-6>
        <v-flex xs3>
          <v-layout class="align-center">
            <v-flex class="xs6 text-end font-12 mr-3 font-weight-bold ">
              INV/.PO :
            </v-flex>
            <v-flex class="xs7 font-12">
              {{ form.inv_po }}
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs3>
          <v-layout class="align-center">
            <v-flex class="xs4 text-end  font-12 mr-3 font-weight-bold ">
              Booking no. :
            </v-flex>
            <v-flex class="xs7 font-12">
              {{ form.booking_no }}
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex xs3>
          <v-layout class="">
            <v-flex class="xs4 pt-2 text-end  font-12  font-weight-bold mr-3">
              Cost For :
            </v-flex>
            <v-flex class="xs7 font-12">
              <v-layout>
                <v-flex>
                  <v-checkbox
                    readonly
                    class="ma-0"
                    hide-details
                    label="ค่ายกตู้"
                    color="#338A68"
                    v-model="form.is_cost_for_lifting"
                  >
                    <template v-slot:label>
                      <div class="font-12 font-weight-bold">
                        ค่ายกตู้
                      </div>
                    </template>
                  </v-checkbox>
                </v-flex>
                <v-flex>
                  <v-checkbox
                    readonly
                    class="ma-0"
                    hide-details
                    label="ค่าชั่ง"
                    color="#338A68"
                    v-model="form.is_cost_for_scale"
                  >
                    <template v-slot:label>
                      <div class="font-12 font-weight-bold">
                        ค่าชั่ง
                      </div>
                    </template>
                  </v-checkbox>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex>
                  <v-checkbox
                    readonly
                    class="ma-0"
                    hide-details
                    label="ค่าผ่านลาน"
                    color="#338A68"
                    v-model="form.is_cost_for_lan"
                  >
                    <template v-slot:label>
                      <div class="font-12 font-weight-bold">
                        ค่าผ่านลาน
                      </div>
                    </template>
                  </v-checkbox>
                </v-flex>
                <v-flex>
                  <v-checkbox
                    readonly
                    class="ma-0"
                    hide-details
                    label="ค่าผ่านท่า"
                    color="#338A68"
                    v-model="form.is_cost_for_port"
                  >
                    <template v-slot:label>
                      <div class="font-12 font-weight-bold">
                        ค่าผ่านท่า
                      </div>
                    </template>
                  </v-checkbox>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex class="xs3">
          <v-layout class="align-center">
            <v-flex class="xs3 text-end font-12  font-weight-bold mr-3">
            </v-flex>
            <v-flex class="xs9 font-12">
              {{ form.address_name }}
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-divider class="my-6"></v-divider>

      <v-layout wrap px-6 mb-3>
        <v-flex xs4>
          <v-layout class="align-center">
            <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
              Goods :
            </v-flex>
            <v-flex class="xs7 font-12">
              {{ form.goods_name }}
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs4>
          <v-layout class="align-center">
            <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
              Package :
            </v-flex>
            <v-flex class="xs7 font-12">
              {{ form.package_name }}
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs4>
          <v-layout class="align-center">
            <v-flex class="xs4 text-end  font-12 mr-3 font-weight-bold">
              TON :
            </v-flex>
            <v-flex class="xs4 font-12">
              {{ form.ton }}
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-divider class="my-6"></v-divider>

      <v-layout wrap px-6 mb-3>
        <v-flex xs4>
          <v-layout class="align-center mb-3">
            <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
              Forwarder :
            </v-flex>
            <v-flex class="xs7 font-12">
              {{ form.forwarder_name }}
            </v-flex>
          </v-layout>

          <v-layout class="align-center mb-3">
            <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
              FEEDER VESSEL :
            </v-flex>
            <v-flex class="xs7 font-12">
              {{ form.feeder_vessel }}
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs4>
          <v-layout class="align-center mb-3">
            <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
              Shipping Agent :
            </v-flex>
            <v-flex class="xs7 font-12">
              {{ form.shipping_agent_name }}
            </v-flex>
          </v-layout>

          <v-layout class="align-center">
            <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
              OCEAN VESSEL :
            </v-flex>
            <v-flex class="xs7 font-12">
              {{ form.ocean_vessel }}
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex xs4>
          <v-layout class="align-center">
            <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
              Port/Country :
            </v-flex>
            <v-flex class="xs7 font-12">
              {{ form.port_country_name }}
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-divider class="my-6"></v-divider>

      <v-layout wrap px-6 mb-3>
        <v-flex xs4>
          <v-layout class="align-center mb-3">
            <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
              Shipping :
            </v-flex>
            <v-flex class="xs7 font-12">
              {{ form.shipping_name }}
            </v-flex>
          </v-layout>

          <v-layout class="align-center mb-3">
            <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
              Haulage :
            </v-flex>
            <v-flex class="xs7 font-12">
              {{ form.haulage_name }}
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex xs4>
          <v-layout class="align-center mb-3">
            <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
              Shore :
            </v-flex>
            <v-flex class="xs7 font-12">
              {{ form.shore_name }}
            </v-flex>
          </v-layout>

          <v-layout class="align-center mb-3">
            <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
              Fumigate :
            </v-flex>
            <v-flex class="xs7 font-12">
              {{ form.fumigate_name }}
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex xs4>
          <v-layout class="align-center mb-3">
            <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
              Survey :
            </v-flex>
            <v-flex class="xs7 font-12">
              {{ form.survey_name }}
            </v-flex>
          </v-layout>

          <v-layout class="align-center mb-3">
            <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
              Others :
            </v-flex>
            <v-flex class="xs7 font-12">
              {{ form.others_name }}
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-divider class="my-6"></v-divider>

      <v-layout wrap px-6 mb-3>
        <v-flex>
          <v-layout>
            <v-flex xs4>
              <v-layout class="align-center mb-3">
                <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
                  FRD :
                </v-flex>
                <v-flex class="xs4 font-12">
                  {{ form.frd | moment("DD/MM/YYYY") }}
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

          <v-layout>
            <v-flex xs4>
              <v-layout class="align-center">
                <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
                  Return place :
                </v-flex>
                <v-flex class="xs7 font-12">
                  {{ form.return_place_name }}
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs4>
              <v-layout class="align-center mb-3">
                <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
                  Closing <br />
                  Date Time :
                </v-flex>
                <v-flex class="xs4 font-12">
                  {{ form.closing_date | moment("DD/MM/YYYY") }}
                </v-flex>
                <v-flex class="xs4 pl-2 font-12 ">
                  {{ subStringTime(form.closing_time) }}
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-divider class="my-6"></v-divider>

      <v-layout wrap px-6 mb-3>
        <v-flex>
          <v-layout mb-6>
            <v-flex xs4>
              <v-layout class="align-center">
                <v-flex
                  class="xs4 text-end font-12 mr-3 ml-n1 font-weight-bold"
                >
                  CY Date :
                </v-flex>
                <v-flex class="xs4 pr-1 font-12">
                  {{ form.cy_date | moment("DD/MM/YYYY") }}
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs4>
              <v-layout class="align-center">
                <v-flex
                  class="xs4 text-end font-12 mr-3 ml-n1 font-weight-bold"
                >
                  Container Status :
                </v-flex>
                <v-flex class="xs8 pr-1">
                  {{ form.container_status }}
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

          <v-layout>
            <v-flex xs4>
              <v-layout class="align-center mb-3">
                <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
                  SI Cut Off <br />
                  Date Time :
                </v-flex>
                <v-flex class="xs4 font-12">
                  {{ form.si_cut_off_date | moment("DD/MM/YYYY") }}
                </v-flex>
                <v-flex class="xs4 pl-2 font-12">
                  {{ subStringTime(form.si_cut_off_time) }}
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs4>
              <v-layout class="align-center mb-3">
                <v-flex class="xs4 text-end font-12 mr-3 font-weight-bold">
                  VGM Cut Off <br />
                  Date Time :
                </v-flex>
                <v-flex class="xs4 font-12">
                  {{ form.vgm_cut_off_date | moment("DD/MM/YYYY") }}
                </v-flex>
                <v-flex class="xs4 pl-2 font-12">
                  {{ subStringTime(form.vgm_cut_off_time) }}
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-divider class="my-6"></v-divider>

      <v-layout px-6>
        <v-flex>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">CY Place</th>
                  <th class="text-left">Qty</th>
                  <th class="text-left">Size</th>
                  <th class="text-left">Isotype</th>
                  <th class="text-left">WH/Factory1</th>
                  <th class="text-left">Unload / Load Date Time1</th>
                  <th class="text-left">WH/Factory2</th>
                  <th class="text-left">Unload / Load Date Time2</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in containers_form" :key="i">
                  <td>{{ item.cy_place_name }}</td>
                  <td>{{ item.qty }}</td>
                  <td>{{ item.container_size_name }}</td>
                  <td>{{ item.container_isotype_name }}</td>
                  <td>{{ item.wh_factory_1_name }}</td>
                  <td>
                    {{ date_con[i].unload_load_date1 }}
                    {{ item.unload_load_time1 }}
                  </td>
                  <td>{{ item.wh_factory_2_name }}</td>
                  <td>
                    {{ date_con[i].unload_load_date2 }}
                    {{ item.unload_load_time2 }}
                  </td>
                  <td>{{ item.return_place_name }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-flex>
      </v-layout>
    </v-card>

  
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="false"
    />
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: false,
      form: {
        user_id: "",
        user_name: "",
        user_mode: "",
        customer_id: "",
        customer_name: "",
        inv_po: "",
        importer_id: "",
        importer_name: "",
        shipping_agent_id: "",
        shipping_agent_name: "",
        shipping_id: "",
        shipping_name: "",
        goods_id: "",
        goods_name: "",
        haulage_id: "",
        haulage_name: "",
        port_discharge_id: "",
        port_discharge_name: "",
        release_port_id: "",
        release_port_name: "",
        ata: "",
        release_date: "",
        discharge_complete: "",
        rent: "",
        demurage: "",
        detention: "",
        detention_days: "",
        ct_total: "",
        remark: "",
        status: "",
        container_status: "",
        receipt_type_id: "",
        receipt_type_name: "",
        address_id: "",
        address_name: "",
        is_cost_for_lan: 0,
        is_cost_for_port: 0,
        is_cost_for_lifting: 0,
        is_cost_for_scale: 0,
        name_cost_for_lan: "",
        name_cost_for_port: "",
        name_cost_for_lifting: "",
        name_cost_for_scale: "",
        exporter_id: "",
        exporter_name: "",
        booking_no: "",
        return_place_id: "",
        return_place_name: "",
        cy_date: "",
        shore_id: "",
        shore_name: "",
        fumigate_id: "",
        fumigate_name: "",
        survey_id: "",
        survey_name: "",
        others_id: "",
        others_name: "",
        si_cut_off_date: "",
        si_cut_off_time: "",
        vgm_cut_off_date: "",
        vgm_cut_off_time: "",
        package_id: "",
        package_name: "",
        ton: "",
        forwarder_id: "",
        forwarder_name: "",
        port_country_id: "",
        port_country_name: "",
        feeder_vessel: "",
        ocean_vessel: "",
        frd: "",
        closing_date: "",
        closing_time: "",
      },
      containers_form: [
        {
          order_detail_id: null,
          return_place_id: "",
          return_place_name: "",
          container_size_id: "",
          container_size_name: "",
          container_isotype_id: "",
          container_isotype_name: "",
          qty: "0",
          wh_factory_1_id: "",
          wh_factory_1_name: "",
          wh_factory_2_id: "",
          wh_factory_2_name: "",
          unload_load_date1: "",
          unload_load_time1: "",
          unload_load_date2: "",
          unload_load_time2: "",
        },
      ],
      date: {
        unload_date: "",
        ata: "",
        rent: "",
        demurage: "",
        detention: "",
        discharge_complete: "",
        release_date: "",
      },
      date_con: [
        {
          unload_load_date1: "",
          unload_load_date2: "",
        },
      ],
    };
  },
  created() {
    this.getOrderById(this.$route.params.id);
  },
  methods: {
    async getOrderById(id) {
      this.isLoading = true;
      const res = await this.$store.dispatch("order/getOrderById", id);
      this.isLoading = false;
      if (res) {
        this.form = res.data;
        if (res.data.order_detail_with_tender.length > 0) {
          this.containers_form = [];
          this.date_con = [];
          res.data.order_detail_with_tender.forEach((data) => {
            this.date_con.push({
              unload_load_date1: data.unload_load_date1
                ? moment(data.unload_load_date1).format("DD/MM/YYYY")
                : "",
              unload_load_date2: data.unload_load_date2
                ? moment(data.unload_load_date2).format("DD/MM/YYYY")
                : "",
            });
            this.containers_form.push({
              tender: data.tender_carrier.length,
              order_detail_id: data.order_detail_id,
              return_place_id: data.return_place_id,
              return_place_name: data.return_place_name,
              cy_place_id: data.cy_place_id,
              cy_place_name: data.cy_place_name,
              container_size_id: data.container_size_id,
              container_size_name: data.container_size_name,
              container_isotype_id: data.container_isotype_id,
              container_isotype_name: data.container_isotype_name,
              qty: data.qty,
              wh_factory_1_id: data.wh_factory_1_id,
              wh_factory_1_name: data.wh_factory_1_name,
              wh_factory_2_id: data.wh_factory_2_id,
              wh_factory_2_name: data.wh_factory_2_name,
              unload_load_date1: data.unload_load_date1,
              unload_load_time1: data.unload_load_time1
                ? this.subStringTime(data.unload_load_time1)
                : "",
              unload_load_date2: data.unload_load_date2,
              unload_load_time2: data.unload_load_time2
                ? this.subStringTime(data.unload_load_time2)
                : "",
            });
          });
        }
      } else {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style></style>
