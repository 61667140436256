import AuthService from "../AuthService";
import Service from "../Service";

const lanPass = {
  getData(page) {
    return AuthService.get(`/api/lan-pass-lifting-fee?page=${page}`)
      .then((res) => res)
      // .catch((err) => {
      //   AuthService.error(err);
      // });
  },
  getDataById(id) {
    return AuthService.get(`/api/lan-pass-lifting-fee/${id}`)
      .then((res) => res)
      // .catch((err) => {
      //   AuthService.error(err);
      // });
  },
  save(data) {
    return AuthService.post("/api/lan-pass-lifting-fee/save", data)
      .then((res) => res)
      // .catch((err) => {
      //   AuthService.error(err);
      // });
  },
  update(data) {
    return AuthService.post(
      `/api/lan-pass-lifting-fee/update/${data.lan_pass_lifting_fee_id}`,
      data
    )
      .then((res) => res)
      // .catch((err) => {
      //   AuthService.error(err);
      // });
  },
  delete(id) {
    return AuthService.post(`/api/lan-pass-lifting-fee/delete/${id}`)
      .then((res) => res)
      // .catch((err) => {
      //   AuthService.error(err);
      // });
  },
};

export default lanPass;
