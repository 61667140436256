<template>
  <div class="pa-6">
    <v-layout align-center mb-3>
      <v-flex shrink mr-2>
        <v-img
          aspect-ratio="1"
          width="20"
          height="20"
          :src="require('../../../assets/images/menu-icons/graph-ts.png')"
        ></v-img>
      </v-flex>
      <v-flex shrink>
        <div class="font-18 font-w-900">Truck Supply</div>
      </v-flex>
    </v-layout>

    <v-card
      class=" pa-6"
      flat
      style="border: 1px solid #C4C4C4; border-radius: 0px;"
    >
      <v-row>
        <!--  Date : -->
        <v-col cols="5">
          <v-layout class="align-center mb-3">
            <v-flex class="xs3 text-end font-12 pr-3 font-weight-bold">
              Date :
            </v-flex>
            <v-flex class="xs4 px-0">
              <DatePicker
                placeholder="DD/MM/YY"
                hideDetails
                hideDetail
                rulse="fieldRequire"
                :model="date.date_start"
                :max="filter.date_end"
                @update-data="updateDate('date_start', $event)"
              ></DatePicker>
            </v-flex>
            <v-flex class="xs1 text-end font-12 pr-1 font-weight-bold">
              To :
            </v-flex>
            <v-flex class=" xs4">
              <DatePicker
                placeholder="DD/MM/YY"
                hideDetails
                hideDetail
                rulse="fieldRequire"
                :model="date.date_end"
                :min="filter.date_start"
                :max="maxDate()"
                @update-data="updateDate('date_end', $event)"
              ></DatePicker>
            </v-flex>
          </v-layout>
        </v-col>

        <!-- Vendor -->
        <v-col>
          <v-layout class="align-center mb-3">
            <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
              Vendor :
            </v-flex>
            <v-flex class="xs9">
              <v-autocomplete
                height="37px"
                append-icon="mdi-chevron-down"
                menu-props="offsetY"
                color="#98CA54"
                outlined
                dense
                class="gray"
                :items="carriers"
                v-model="filter.carrier_id"
                hide-details
                placeholder="Carrier"
                item-text="name"
                item-value="carrier_id"
                clearable
              >
              </v-autocomplete>
            </v-flex>
          </v-layout>
        </v-col>

        <!-- Driver -->
        <v-col>
          <v-layout class="align-center mb-3">
            <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
              Driver :
            </v-flex>
            <v-flex class="xs9">
              <v-autocomplete
                height="37px"
                append-icon="mdi-chevron-down"
                menu-props="offsetY"
                color="#98CA54"
                outlined
                dense
                class="gray"
                :items="drivers"
                v-model="filter.driver_id"
                hide-details
                placeholder="Driver"
                item-text="full_name"
                item-value="driver_id"
                clearable
              >
              </v-autocomplete>
            </v-flex>
          </v-layout>
        </v-col>

        <!-- ทะเบียนรถ : -->
        <v-col>
          <v-layout class="align-center mb-3">
            <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
              ทะเบียนรถ :
            </v-flex>
            <v-flex class="xs9">
              <v-autocomplete
                height="37px"
                append-icon="mdi-chevron-down"
                menu-props="offsetY"
                color="#98CA54"
                outlined
                dense
                class="gray"
                :items="truckLicenseList"
                v-model="filter.truck_license_id"
                hide-details
                placeholder="ทะเบียนรถ"
                item-text="plate_no"
                item-value="plate_no"
                clearable
              >
              </v-autocomplete>
            </v-flex>
          </v-layout>
        </v-col>
      </v-row>
    </v-card>

    <v-layout justify-space-between>
      <v-flex shrink>
        <v-btn
          outlined
          depressed
          color="#338A68"
          dark
          class="shrink my-4 font-weight-bold mr-3"
          @click="exportExcel()"
        >
          <img
            width="20"
            src="../../../assets/images/menu-icons/excel.png"
            alt=""
          />
          <span class="ml-2 shrink text-capitalize">Export Excel </span>
        </v-btn>
      </v-flex>
      <v-flex shrink class="text-end pr-6">
        <v-btn
          depressed
          color="#F7D25D"
          class="my-4 font-weight-bold"
          @click="onSearch(1)"
        >
          <v-icon class="mr-3">mdi-magnify</v-icon>
          <span class="text-capitalize">Search</span>
        </v-btn>
      </v-flex>
    </v-layout>

    <v-layout
      justify-center
      wrap
      style="box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);"
    >
      <v-flex xs12 class="pa-6" style="position: relative">
        <div
          v-if="isGraph"
          style="position: absolute;
    top: 65px;
    left: 30px;
    font-weight: 900;font-size: 14px"
        >
          ชั่วโมง
        </div>
        <apexchart
          v-if="isGraph"
          ref="chart"
          width="100%"
          height="500"
          type="bar"
          :options="options"
          :series="series"
        ></apexchart>
      </v-flex>
    </v-layout>

    <div class="mt-6">
      <v-layout justify-center class="mt-3">
        <v-flex xs12>
          <div style="width: 100%" class="table-order">
            <v-data-table :headers="headers" :items="desserts" :search="search">
              <template v-slot:item.no="{ index }">
                {{ index + 1 }}
              </template>
            </v-data-table>
          </div>
        </v-flex>
      </v-layout>
    </div>

    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="false"
    />
  </div>
</template>

<script>
import ControlTower from "../../../services/api/ControlTower.Service";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import DatePicker from "../../../components/picker/DatePicker.vue";
import XLSX from "xlsx"; // import xlsx;
import moment from "moment";

export default {
  components: {
    Loading,
    DatePicker,
  },
  data() {
    return {
      isLoading: false,
      options: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          events: {
            dataPointSelection: function(event, chartContext, config) {
              this.showDetail(
                config.w.config.xaxis.categories[config.dataPointIndex]
              );
            }.bind(this),
          },
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "60%",
            dataLabels: {
              position: "top",
            },
          },
        },
        colors: ["#338A68", "#59CB33", "#D8D8D8"],
        legend: {
          show: true,
          position: "top",
        },
        title: {
          text: "Truck Supply",
          align: "center",
          style: {
            fontSize: "20px",
            fontWeight: "bold",
            fontFamily: undefined,
            color: "#828282",
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            // text: "ชั่วโมง",
          },
          labels: {
            show: true,
            align: "right",
          },
        },
      },

      series: [],

      search: "",
      headers: [
        {
          text: "No.",
          align: "center",
          sortable: false,
          value: "no",
        },
        {
          text: "Driver",
          align: "start",
          sortable: false,
          value: "driver_name",
        },
        {
          text: "ทะเบียนรถ",
          align: "start",
          sortable: false,
          value: "truck_license_id",
        },
        {
          text: "จำนวนตู้",
          align: "center",
          sortable: false,
          value: "qty",
        },
        {
          text: "จำนวน Job",
          align: "center",
          sortable: false,
          value: "total_job",
        },
        {
          text: "Working hr.",
          align: "center",
          sortable: false,
          value: "working",
        },
        {
          text: "Empty hr.",
          align: "center",
          sortable: false,
          value: "empty",
        },
      ],
      desserts: [],
      table_title: "",
      carriers: [],
      drivers: [],
      truckLicenseList: [],
      filter: {
        date_start: "",
        date_end: "",
        carrier_id: "",
        driver_id: "",
        truck_license_id: "",
      },
      date: {
        date_start: "",
        date_end: "",
      },
      dataExcel: [],
      isGraph: false,
      data: null,
      workingPerDay: 8,
    };
  },
  watch: {},
  created() {
    this.isLoading = true;
    Promise.all([this.getFilterTruckSupply(), this.getData()]).then((res) => {
      if (res.length > 0) {
        this.carriers = res[0].carriers;
        this.drivers = res[0].drivers;
        this.truckLicenseList = res[0].trucks;
        this.workingPerDay = res[1].data.workingPerDay;
        this.series = res[1].data.graph.datasets;
        this.options.xaxis.categories = res[1].data.graph.labels;
        this.data = res[1].data.table;
        // this.$refs.chart.updateSeries(this.series);
        this.isGraph = true;
      }
      this.isLoading = false;
    });
  },
  methods: {
    async getFilterTruckSupply() {
      const res = await ControlTower.getFilterTruckSupply();
      return res;
    },
    async getData() {
      const res = await ControlTower.getDataTruckSupply(this.filter);

      return res;
    },
    maxDate() {
      // add 31 days to filter.date_start
      let date = moment(this.filter.date_start)
        .add(30, "days")
        .format("YYYY-MM-DD");
      return date;
    },
    updateDate(name, val) {
      this.filter[name] = val ? val : "";
      this.date[name] = val ? this.customDate(val) : "";
    },
    customDate(val) {
      let sub_year = val.substring(0, 4);
      let sub_month = val.substring(5, 7);
      let sub_day = val.substring(8, 10);
      let changeDate = sub_day + "/" + sub_month + "/" + sub_year;
      return changeDate;
    },
    onSearch() {
      this.isLoading = true;
      this.isGraph = false;
      this.dataExcel = [];
      this.desserts = [];
      this.getData().then((res) => {
        this.series = res.data.graph.datasets;
        this.options.xaxis.categories = res.data.graph.labels;
        this.isLoading = false;
        this.isGraph = true;
      });
    },
    showDetail(seriesIndex) {
      this.desserts = [];
      this.dataExcel = [];
      this.table_title = seriesIndex;
      this.isLoading = true;

      let date = moment(seriesIndex, "DD/MM/YYYY").format("YYYY-MM-DD");
      this.filter.date = date;
      ControlTower.getDataControlTruckSupply(this.filter)
        .then((res) => {
          for (const [key, value] of Object.entries(res.data)) {
            this.desserts.push({
              no: key,
              driver_name: value.driver_name,
              truck_license_id: value.truck_license_id,
              qty: value.qty,
              total_job: value.total_job,
              working: value.working,
              empty: value.empty,
            });
          }

          this.setDataExcel(this.desserts);
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
    exportExcel() {
      if (this.dataExcel.length === 0) {
        this.$swal.fire({
          title: "Something went wrong!",
          text: "Please, Choose Data",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      } else {
        this.$swal
          .fire({
            title: "Are you sure?",
            text: `Do you want to export Order to Excel?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Export!",
          })
          .then((result) => {
            if (result.isConfirmed) {
              const dataWS = XLSX.utils.json_to_sheet(this.dataExcel);
              const wb = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(wb, dataWS);
              const fileName = `TruckSupply_${moment(new Date()).format(
                "DD_MM_YYYY_h_mm_ss"
              )}.xlsx`;
              XLSX.writeFile(wb, fileName);
            }
          });
      }
    },
    setDataExcel(items) {
      items.forEach((data) => {
        this.dataExcel.push({
          Driver: data.driver_name,
          ทะเบียนรถ: data.truck_license_id,
          จำนวนตู้: data.qty,
          "จำนวน Job": data.total_job,
          "Working hr.": data.working,
          "Empty hr.": data.empty,
        });
      });
    },
  },
};
</script>

<style>
.v-input__append-inner,
.v-input__prepend-inner {
  margin-top: 4px !important;
}
span.apexcharts-legend-marker {
  width: 35px !important;
  height: 12px !important;
}
.apexcharts-legend-series {
  margin: 2px 10px !important;
}
</style>
