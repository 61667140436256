<template>
  <div class="px-6 pt-6">
    <div class="d-flex align-center mb-3">
      <v-icon style="font-size: 20px" color="#338A68" small class="mr-2">
        mdi-format-list-bulleted
      </v-icon>
      <span class=" font-weight-bold text-uppercase">
        MONITORING
      </span>
    </div>

    <OrderForm :order="order" />

    <v-layout class="mt-6">
      <v-flex lg9 md9 sm8>
        <Tab
          :mode="order.mode"
          :count="driver_job_count"
          :onClickTab="onClickTab"
        />

        <div
          style="width: 100%;border-right:1px solid rgb(196 196 196 / 70%)"
          class="table-order"
        >
          <v-data-table
            v-model="selected"
            :headers="header_table"
            :items="monitoringDetailList"
            item-key="id"
            class="elevation-1"
            :items-per-page="10"
            hide-default-footer
            show-select
            :single-select="checkSingleSelect"
          >
            <template v-slot:item.driver_name="{ item }">
              {{ item.accept_tender.driver_name }}
            </template>

            <template v-slot:item.truck_license_id="{ item }">
              {{ item.accept_tender.truck_license_id }}
            </template>

            <template v-slot:item.cy_place="{ item }">
              <span v-if="item.accept_tender.tender.order_detail.cy_place_name">
                {{ item.accept_tender.tender.order_detail.cy_place_name }}
              </span>
              <div
                v-else-if="
                  item.accept_tender.tender.order_detail.order.release_port_name
                "
              >
                {{
                  item.accept_tender.tender.order_detail.order.release_port_name
                }}
              </div>
              <div v-else>
                --
              </div>
            </template>

            <template v-slot:item.qty="{ item }">
              <span v-if="item.accept_tender.tender.order_detail">
                1
              </span>
              <div v-else>
                0
              </div>
            </template>

            <template v-slot:item.container_size="{ item }">
              <span
                v-if="
                  item.accept_tender.tender.order_detail.container_size_name
                "
              >
                {{ item.accept_tender.tender.order_detail.container_size_name }}
              </span>
              <div v-else>
                --
              </div>
            </template>

            <template v-slot:item.isotype="{ item }">
              <span
                v-if="
                  item.accept_tender.tender.order_detail.container_isotype_name
                "
              >
                {{
                  item.accept_tender.tender.order_detail.container_isotype_name
                }}
              </span>
              <div v-else>
                --
              </div>
            </template>

            <template v-slot:item.wh_factory="{ item }">
              <span
                v-if="item.accept_tender.tender.order_detail.wh_factory_1_name"
              >
                {{ item.accept_tender.tender.order_detail.wh_factory_1_name }}
              </span>
              <div v-else>
                --
              </div>
            </template>

            <template v-slot:item.load_unload_date="{ item }">
              <span
                v-if="item.accept_tender.tender.order_detail.unload_load_date1"
              >
                {{
                  item.accept_tender.tender.order_detail.unload_load_date1
                    | moment("DD/MM/YYYY")
                }}
              </span>
              <div v-else>
                --
              </div>
            </template>

            <template v-slot:item.load_unload_time="{ item }">
              <span
                v-if="item.accept_tender.tender.order_detail.unload_load_time1"
              >
                {{
                  convertTime(
                    item.accept_tender.tender.order_detail.unload_load_time1
                  )
                }}
              </span>
              <div v-else>
                --
              </div>
            </template>

            <template v-slot:item.status="{ item }">
              <span :class="classStatus(item.status)">
                {{ txtStatus(item.status) }}
              </span>
            </template>

            <template v-slot:item.pickup_container_no="{ item }">
              {{ item.pickup_container_no ? item.pickup_container_no : "--" }}
            </template>

            <template v-slot:item.seal_no="{ item }">
              {{ item.seal_no ? item.seal_no : "--" }}
            </template>

            <template v-slot:item.pickup_container_image="{ item }">
              <div class="d-flex justify-center pa-1">
                <v-img
                  v-if="item.pickup_container_image"
                  :src="url_path + item.pickup_container_image"
                  max-width="60"
                  max-height="60"
                  aspect-ratio="1"
                  style="cursor: pointer;"
                  @click="showImage(item.pickup_container_image)"
                >
                  <template v-slot:placeholder>
                    <v-sheet
                      color="#FAFAFA"
                      min-height="100%"
                      min-width="100%"
                    ></v-sheet>
                  </template>
                </v-img>
                <div v-else>
                  --
                </div>
              </div>
            </template>

            <template v-slot:item.cabinet_weight="{ item }">
              {{ item.cabinet_weight ? item.cabinet_weight : "--" }}
            </template>

            <template v-slot:item.closing_date="{ item }">
              <span
                v-if="
                  item.accept_tender &&
                    item.accept_tender.tender &&
                    item.accept_tender.tender.order_detail &&
                    item.accept_tender.tender.order_detail.order.closing_date
                "
              >
                {{
                  item.accept_tender.tender.order_detail.order.closing_date
                    | moment("DD/MM/YYYY")
                }}
                {{
                  convertTime(
                    item.accept_tender.tender.order_detail.order.closing_time
                  )
                }}
              </span>
              <div v-else>
                --
              </div>
            </template>

            <template v-slot:item.date_now> {{ date_now }} น. </template>

            <template v-slot:item.truck_trailer_license="{ item }">
              {{ item.truck_license_id }}, {{ item.trailer_license_id }}
            </template>

            <template v-slot:item.closing_date_time="{ item }">
              <span v-if="item.closing_date_time">
                {{ item.closing_date_time | moment("H:mm") }} น.
              </span>
            </template>

            <template v-slot:item.factory_success_date_diff="{ item }">
              <span v-if="item.factory_success_date">
                <!-- ถึงโรงงาน - UNLOAD DATE   -->
                {{
                  dateDiff(
                    item.factory_success_date,
                    item.accept_tender.tender.order_detail.unload_load_date1 +
                      " " +
                      item.accept_tender.tender.order_detail.unload_load_time1
                  )
                }}
              </span>
              <div v-else>
                --
              </div>
            </template>

            <template v-slot:item.start_product_success_date_diff="{ item }">
              <span v-if="item.start_product_success_date">
                <!-- เริ่มบรรจุสินค้า - ถึงโรงงาน   -->
                {{
                  dateDiff(
                    item.start_product_success_date,
                    item.factory_success_date
                  )
                }}
              </span>
              <div v-else>
                --
              </div>
            </template>

            <template v-slot:item.is_finish_success_datetime_diff="{ item }">
              <span v-if="item.is_finish_success_datetime">
                <!-- บรรจุสินค้าเสร็จ - ถึงโรงงาน   -->
                {{
                  dateDiff(
                    item.is_finish_success_datetime,
                    item.factory_success_date
                  )
                }}
              </span>
              <div v-else>
                -
              </div>
            </template>

            <template v-slot:item.return_place="{ item }">
              <div
                v-if="
                  item.accept_tender.tender.order_detail.order.mode == 'Import'
                "
              >
                <span
                  v-if="
                    item.accept_tender.tender.order_detail.return_place_name
                  "
                >
                  {{ item.accept_tender.tender.order_detail.return_place_name }}
                </span>
                <div v-else>
                  --
                </div>
              </div>
              <div v-else>
                <span
                  v-if="
                    item.accept_tender.tender.order_detail.order
                      .return_place_name
                  "
                >
                  {{
                    item.accept_tender.tender.order_detail.order
                      .return_place_name
                  }}
                </span>
                <div v-else>
                  --
                </div>
              </div>
            </template>

            <template v-slot:item.frd="{ item }">
              <span
                v-if="
                  item.accept_tender &&
                    item.accept_tender.tender &&
                    item.accept_tender.tender.order_detail &&
                    item.accept_tender.tender.order_detail.order &&
                    item.accept_tender.tender.order_detail.order.frd
                "
              >
                {{
                  item.accept_tender.tender.order_detail.order.frd
                    | moment("DD/MM/YYYY")
                }}
              </span>
              <div v-else>
                --
              </div>
            </template>

            <template v-slot:item.is_job_success_datetime="{ item }">
              <span v-if="item.is_job_success_datetime">
                {{ item.is_job_success_datetime | moment("DD/MM/YYYY H:mm") }}
              </span>
              <div v-else>
                -
              </div>
            </template>

            <template v-slot:item.datetime_arrive="{ item }">
              <span v-if="item.datetime_arrive">
                {{ item.datetime_arrive | moment("DD/MM/YYYY H:mm") }}
              </span>
              <div v-else>
                -
              </div>
            </template>

            <template v-slot:item.factory_success_date="{ item }">
              <span v-if="item.factory_success_date">
                {{ item.factory_success_date | moment("DD/MM/YYYY H:mm") }}
              </span>
              <div v-else>
                --
              </div>
            </template>

            <template v-slot:item.start_product_success_date="{ item }">
              <span v-if="item.start_product_success_date">
                {{
                  item.start_product_success_date | moment("DD/MM/YYYY H:mm")
                }}
              </span>
              <div v-else>
                --
              </div>
            </template>

            <template v-slot:item.is_finish_success_datetime="{ item }">
              <span v-if="item.is_finish_success_datetime">
                {{
                  item.is_finish_success_datetime | moment("DD/MM/YYYY H:mm")
                }}
              </span>
              <div v-else>
                --
              </div>
            </template>

            <template v-slot:item.is_pickup_success_datetime="{ item }">
              <span v-if="item.is_pickup_success_datetime">
                {{
                  item.is_pickup_success_datetime | moment("DD/MM/YYYY H:mm")
                }}
              </span>
              <div v-else>
                --
              </div>
            </template>

            <template v-slot:item.recipient_signature="{ item }">
              <v-img
                v-if="item.recipient_signature"
                :src="url_path + item.recipient_signature"
                max-width="50"
                max-height="50"
                contain
                style="cursor: pointer;"
                @click="showImage(item.recipient_signature)"
              >
                <template v-slot:placeholder>
                  <v-sheet
                    color="#FAFAFA"
                    min-height="100%"
                    min-width="100%"
                  ></v-sheet>
                </template>
              </v-img>
              <div v-else>
                --
              </div>
            </template>

            <template v-slot:item.container_return_image="{ item }">
              <div class="d-flex justify-center pa-1">
                <v-img
                  v-if="item.container_return_image"
                  :src="url_path + item.container_return_image"
                  max-width="60"
                  max-height="60"
                  aspect-ratio="1"
                  style="cursor: pointer;"
                  @click="showImage(item.container_return_image)"
                >
                  <template v-slot:placeholder>
                    <v-sheet
                      color="#FAFAFA"
                      min-height="100%"
                      min-width="100%"
                    ></v-sheet>
                  </template>
                </v-img>
                <div v-else>
                  --
                </div>
              </div>
            </template>

            <template v-slot:item.job_done_container_image="{ item }">
              <div class="d-flex justify-center pa-1">
                <v-img
                  v-if="item.job_done_container_image"
                  :src="url_path + item.job_done_container_image"
                  max-width="60"
                  max-height="60"
                  aspect-ratio="1"
                  style="cursor: pointer;"
                  @click="showImage(item.job_done_container_image)"
                >
                  <template v-slot:placeholder>
                    <v-sheet
                      color="#FAFAFA"
                      min-height="100%"
                      min-width="100%"
                    ></v-sheet>
                  </template>
                </v-img>
                <div v-else>
                  --
                </div>
              </div>
            </template>

            <template v-slot:item.deliver_container_image="{ item }">
              <div class="d-flex justify-center pa-1">
                <v-img
                  v-if="item.deliver_container_image"
                  :src="url_path + item.deliver_container_image"
                  @click="showImage(item.deliver_container_image)"
                  max-width="60"
                  max-height="60"
                  aspect-ratio="1"
                  style="cursor: pointer;"
                >
                  <template v-slot:placeholder>
                    <v-sheet
                      color="#FAFAFA"
                      min-height="100%"
                      min-width="100%"
                    ></v-sheet>
                  </template>
                </v-img>
                <div v-else>
                  --
                </div>
              </div>
            </template>

            <template v-slot:item.container_invoice="{ item }">
              <div class="d-flex justify-center pa-1">
                <span
                  v-if="item.container_invoice"
                  @click="showPdf(item.container_invoice)"
                  style="cursor: pointer;"
                >
                  ใบกำกับตู้.pdf
                </span>
                <div v-else>
                  --
                </div>
              </div>
            </template>

            <template v-slot:item.tax_invoice_file="{ item }">
              <div class="d-flex justify-center pa-1">
                <span
                  v-if="item.tax_invoice_file"
                  @click="showPdf(item.tax_invoice_file)"
                  style="cursor: pointer;"
                >
                  ใบเสร็จ.pdf
                </span>
                <div v-else>
                  --
                </div>
              </div>
            </template>

            <template v-slot:item.ship_date="{ item }">
              <span v-if="item.ship_date">
                {{ item.ship_date | moment("DD/MM/YYYY H:mm") }}
              </span>
              <div v-else>
                --
              </div>
            </template>

            <template v-slot:item.shore_date="{ item }">
              <span v-if="item.shore_date">
                {{ item.shore_date | moment("DD/MM/YYYY H:mm") }}
              </span>
              <div v-else>
                --
              </div>
            </template>
          </v-data-table>
        </div>

        <v-layout mt-4>
          <v-flex>
            <Paginate
              :current_page="paginate.current_page"
              :last_page="paginate.last_page"
              :nextPage="onClickPagination"
            />
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex lg3 md3 sm4 pt-9>
        <div class="form-step">
          <v-layout class="action-form">
            <v-flex shrink class="font-24 font-w-900">Action</v-flex>
            <v-spacer />
            <v-flex shrink class="font-14 font-w-900">
              {{ action_name }}({{ action_total }})
            </v-flex>
          </v-layout>
          <div class="pa-3" v-if="showForm">
            <v-form ref="form" v-model="valid" lazy-validation>
              <!-- Accept tender -->
              <div v-if="$route.query.tab === 'accept-tender'">
                <v-layout wrap justify-center align-center>
                  <v-flex xs12 mb-3>
                    <v-btn
                      height="86"
                      block
                      color="#338A68"
                      tile
                      depressed
                      :dark="isShowBtnAcceptTender"
                      :disabled="!isShowBtnAcceptTender"
                      @click="onSubmitAcceptTender('ACCEPT')"
                    >
                      <span style="color: #fff" class="font-w-900 font-16">
                        รับงาน
                      </span>
                    </v-btn>
                  </v-flex>
                  <v-flex xs12>
                    <v-btn
                      height="86"
                      block
                      color="#E5E5E5"
                      tile
                      depressed
                      :dark="isShowBtnAcceptTender"
                      :disabled="!isShowBtnAcceptTender"
                      @click="onSubmitAcceptTender('REJECT')"
                    >
                      <span
                        :style="
                          isShowBtnAcceptTender
                            ? { color: '#828282' }
                            : { color: '#fff' }
                        "
                        class="font-w-900 font-16"
                      >
                        ปฏิเสธ
                      </span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </div>

              <!-- Receive Container -->
              <div v-if="$route.query.tab === 'receive-container'">
                <div class="card-form">
                  <div style="width: 100%">
                    <v-layout v-if="mode != 'Laden'">
                      <v-flex
                        class="xs3 text-end font-12 pt-2 pr-2 font-weight-bold"
                      >
                        เบอร์ตู้* :
                      </v-flex>
                      <v-flex xs9>
                        <v-text-field
                          v-model="form_receive_container.pickup_container_no"
                          outlined
                          dense
                          color="#338A68"
                          placeholder="กรุณากรอกเบอร์ตู้"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>

                    <v-layout class="mb-3">
                      <v-flex
                        class="xs3 text-end font-12 pt-2 pr-2 font-weight-bold"
                      >
                        รูปท้ายตู้ :
                      </v-flex>
                      <v-flex xs9>
                        <input
                          type="file"
                          hidden
                          ref="pickup_container_image"
                          @change="
                            onFileChange($event, 'pickup_container_image')
                          "
                        />
                        <v-btn
                          v-if="!form_receive_container.pickup_container_image"
                          color="#338A68"
                          @click="$refs.pickup_container_image.click()"
                          outlined
                          width="100"
                          height="100"
                        >
                          <v-icon>mdi-camera</v-icon>
                        </v-btn>

                        <div
                          v-if="form_receive_container.pickup_container_image"
                          style="position: relative"
                        >
                          <v-img
                            :src="
                              checkPreviewImage(
                                form_receive_container.pickup_container_image
                              )
                            "
                            max-width="100"
                            max-height="100"
                            cover
                            style="border-radius: 10px;"
                          >
                            <div
                              class="d-flex justify-center align-center fill-height"
                            >
                              <v-btn
                                class="ma-2"
                                color="error"
                                fab
                                width="20"
                                height="20"
                                @click="clearImage('pickup_container_image')"
                              >
                                <v-icon small>mdi-close</v-icon>
                              </v-btn>
                            </div>
                          </v-img>
                        </div>
                      </v-flex>
                    </v-layout>

                    <v-layout v-if="mode != 'Laden'">
                      <v-flex
                        class="xs3 text-end font-12 pt-2 pr-2 font-weight-bold"
                      >
                        เบอร์ซีล* :
                      </v-flex>
                      <v-flex xs9>
                        <v-text-field
                          v-model="form_receive_container.seal_no"
                          outlined
                          dense
                          color="#338A68"
                          placeholder="กรุณากรอกเบอร์ซีล"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>

                    <v-layout class="mb-3">
                      <v-flex
                        class="xs3 text-end font-12 pt-2 pr-2 font-weight-bold"
                      >
                        รูปซีล/ ใบ EIR :
                      </v-flex>
                      <v-flex xs9>
                        <input
                          type="file"
                          hidden
                          ref="seal_no_image"
                          @change="onFileChange($event, 'seal_no_image')"
                        />

                        <v-btn
                          v-if="!form_receive_container.seal_no_image"
                          color="#338A68"
                          @click="$refs.seal_no_image.click()"
                          outlined
                          width="100"
                          height="100"
                        >
                          <v-icon>mdi-camera</v-icon>
                        </v-btn>

                        <div
                          v-if="form_receive_container.seal_no_image"
                          style="position: relative"
                        >
                          <v-img
                            :src="
                              checkPreviewImage(
                                form_receive_container.seal_no_image
                              )
                            "
                            max-width="100"
                            max-height="100"
                            cover
                            style="border-radius: 10px;"
                          >
                            <div
                              class="d-flex justify-center align-center fill-height"
                            >
                              <v-btn
                                class="ma-2"
                                color="error"
                                fab
                                width="20"
                                height="20"
                                @click="clearImage('seal_no_image')"
                              >
                                <v-icon small>mdi-close</v-icon>
                              </v-btn>
                            </div>
                          </v-img>
                        </div>
                      </v-flex>
                    </v-layout>

                    <v-layout v-if="mode == 'Export' || mode == 'Empty'">
                      <v-flex
                        class="xs3 text-end font-12 pt-2 pr-2 font-weight-bold"
                      >
                        น้ำหนักตู้ :
                      </v-flex>
                      <v-flex xs7>
                        <v-text-field
                          v-model="form_receive_container.cabinet_weight"
                          outlined
                          dense
                          color="#338A68"
                          placeholder="กรุณากรอกน้ำหนักตู้"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs2 class="pl-2 pt-2 font-12 font-weight-bold">
                        ton
                      </v-flex>
                    </v-layout>
                  </div>

                  <div style="width: 100%">
                    <div>
                      <v-btn
                        :disabled="!valid"
                        color="#338A68"
                        class="mr-4"
                        @click="onSubmitReceiveContainerForm"
                        block
                        height="71"
                        :dark="valid"
                      >
                        <span class="font-20 font-w-900">
                          ส่งข้อมูลและดำเนินการต่อ
                        </span>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>

              <!-- arrived -->
              <div v-if="$route.query.tab === 'arrived'">
                <div class="card-form">
                  <div style="width: 100%"></div>

                  <div style="width: 100%">
                    <v-btn
                      :disabled="!check_btn_checkin"
                      color="#338A68"
                      class="pr-8"
                      @click="onSubmitArrived"
                      block
                      height="71"
                      :dark="check_btn_checkin"
                    >
                      <span class="font-20 font-w-900">
                        ถึงโรงงาน
                      </span>
                    </v-btn>
                  </div>
                </div>
              </div>

              <!-- start-load-unload -->
              <div v-if="$route.query.tab === 'start-load-unload'">
                <div class="card-form">
                  <div style="width: 100%"></div>

                  <div style="width: 100%">
                    <v-btn
                      :disabled="!check_btn_checkin"
                      color="#338A68"
                      class="mr-4"
                      @click="onSubmitLoadUnloadStart"
                      block
                      height="71"
                      :dark="check_btn_checkin"
                    >
                      <span class="font-20 font-w-900">
                        เริ่มบรรจุสินค้า
                      </span>
                    </v-btn>
                  </div>
                </div>
              </div>

              <!-- end-load-unload -->
              <div v-if="$route.query.tab === 'end-load-unload'">
                <div class="card-form">
                  <div style="width: 100%">
                    <v-layout class="mb-3">
                      <v-flex
                        class="xs3 text-end font-12 pt-2 pr-2 font-weight-bold"
                      >
                        รูปท้ายตู้ :
                      </v-flex>
                      <v-flex xs9>
                        <input
                          type="file"
                          hidden
                          ref="deliver_container_image"
                          @change="
                            onFileChange($event, 'deliver_container_image')
                          "
                        />
                        <v-btn
                          v-if="!form_load_unload_end.deliver_container_image"
                          color="#338A68"
                          @click="$refs.deliver_container_image.click()"
                          outlined
                          width="100"
                          height="100"
                        >
                          <v-icon>mdi-camera</v-icon>
                        </v-btn>

                        <div
                          v-if="form_load_unload_end.deliver_container_image"
                          style="position: relative"
                        >
                          <v-img
                            :src="
                              checkPreviewImage(
                                form_load_unload_end.deliver_container_image
                              )
                            "
                            max-width="100"
                            max-height="100"
                            cover
                            style="border-radius: 10px;"
                          >
                            <div
                              class="d-flex justify-center align-center fill-height"
                            >
                              <v-btn
                                class="ma-2"
                                color="error"
                                fab
                                width="20"
                                height="20"
                                @click="clearImage('deliver_container_image')"
                              >
                                <v-icon small>mdi-close</v-icon>
                              </v-btn>
                            </div>
                          </v-img>
                        </div>
                      </v-flex>
                    </v-layout>

                    <v-layout class="mb-3" v-if="mode == 'Import'">
                      <v-flex
                        class="xs3 text-end font-12 pt-2 pr-2 font-weight-bold"
                      >
                        ใบส่งสินค้า :
                      </v-flex>
                      <v-flex xs9>
                        <input
                          type="file"
                          hidden
                          ref="goods_delivery_image"
                          @change="onFileChange($event, 'goods_delivery_image')"
                        />

                        <v-btn
                          v-if="!form_load_unload_end.goods_delivery_image"
                          color="#338A68"
                          @click="$refs.goods_delivery_image.click()"
                          outlined
                          width="100"
                          height="100"
                        >
                          <v-icon>mdi-camera</v-icon>
                        </v-btn>

                        <div
                          v-if="form_load_unload_end.goods_delivery_image"
                          style="position: relative"
                        >
                          <v-img
                            :src="
                              checkPreviewImage(
                                form_load_unload_end.goods_delivery_image
                              )
                            "
                            max-width="100"
                            max-height="100"
                            cover
                            style="border-radius: 10px;"
                          >
                            <div
                              class="d-flex justify-center align-center fill-height"
                            >
                              <v-btn
                                class="ma-2"
                                color="error"
                                fab
                                width="20"
                                height="20"
                                @click="clearImage('goods_delivery_image')"
                              >
                                <v-icon small>mdi-close</v-icon>
                              </v-btn>
                            </div>
                          </v-img>
                        </div>
                      </v-flex>
                    </v-layout>

                    <v-layout
                      class="mb-3"
                      v-if="mode == 'Export' || mode == 'Drop Load'"
                    >
                      <v-flex
                        class="xs3 text-end font-12 pt-2 pr-2 font-weight-bold"
                      >
                        ใบ EIR :
                      </v-flex>
                      <v-flex xs9>
                        <input
                          type="file"
                          hidden
                          ref="eir_image"
                          @change="onFileChange($event, 'eir_image')"
                        />

                        <v-btn
                          v-if="!form_load_unload_end.eir_image"
                          color="#338A68"
                          @click="$refs.eir_image.click()"
                          outlined
                          width="100"
                          height="100"
                        >
                          <v-icon>mdi-camera</v-icon>
                        </v-btn>

                        <div
                          v-if="form_load_unload_end.eir_image"
                          style="position: relative"
                        >
                          <v-img
                            :src="
                              checkPreviewImage(form_load_unload_end.eir_image)
                            "
                            max-width="100"
                            max-height="100"
                            cover
                            style="border-radius: 10px;"
                          >
                            <div
                              class="d-flex justify-center align-center fill-height"
                            >
                              <v-btn
                                class="ma-2"
                                color="error"
                                fab
                                width="20"
                                height="20"
                                @click="clearImage('eir_image')"
                              >
                                <v-icon small>mdi-close</v-icon>
                              </v-btn>
                            </div>
                          </v-img>
                        </div>
                      </v-flex>
                    </v-layout>

                    <v-layout
                      class="mb-3"
                      v-if="mode == 'Import' || mode == 'Export'"
                    >
                      <v-flex
                        class="xs3 text-end font-12 pt-2 pr-2 font-weight-bold"
                      >
                        ลายเซ็น ผู้รับสินค้า:
                      </v-flex>
                      <v-flex xs9>
                        <input
                          type="file"
                          hidden
                          ref="recipient_signature"
                          @change="onFileChange($event, 'recipient_signature')"
                        />

                        <v-btn
                          v-if="!form_load_unload_end.recipient_signature"
                          color="#338A68"
                          @click="$refs.recipient_signature.click()"
                          outlined
                          width="100"
                          height="100"
                        >
                          <v-icon>mdi-camera</v-icon>
                        </v-btn>

                        <div
                          v-if="form_load_unload_end.recipient_signature"
                          style="position: relative"
                        >
                          <v-img
                            :src="
                              checkPreviewImage(
                                form_load_unload_end.recipient_signature
                              )
                            "
                            width="100"
                            height="100"
                            cover
                            style="border-radius: 10px;"
                          >
                            <div
                              class="d-flex justify-center align-center fill-height"
                            >
                              <v-btn
                                class="ma-2"
                                color="error"
                                fab
                                width="20"
                                height="20"
                                @click="clearImage('recipient_signature')"
                              >
                                <v-icon small>mdi-close</v-icon>
                              </v-btn>
                            </div>
                          </v-img>
                        </div>
                      </v-flex>
                    </v-layout>
                  </div>

                  <div style="width: 100%">
                    <div>
                      <v-btn
                        color="#338A68"
                        class="mr-4"
                        @click="onSubmitLoadUnloadEnd"
                        block
                        height="71"
                        dark
                      >
                        <span class="font-20 font-w-900">
                          ส่งข้อมูลและดำเนินการต่อ
                        </span>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>

              <!-- return -->
              <div v-if="$route.query.tab === 'return'">
                <div class="card-form">
                  <div style="width: 100%">
                    <v-layout
                      class="mb-3"
                      wrap
                      justify-start
                      v-if="mode == 'Import'"
                    >
                      <v-flex
                        class="xs3 text-end font-12 pt-2 pr-2 font-weight-bold"
                      >
                        รูปท้ายตู้ :
                      </v-flex>
                      <v-flex xs9>
                        <input
                          type="file"
                          hidden
                          ref="job_done_container_image"
                          @change="
                            onFileChange($event, 'job_done_container_image')
                          "
                        />
                        <v-btn
                          v-if="!form_return.job_done_container_image"
                          color="#338A68"
                          @click="$refs.job_done_container_image.click()"
                          outlined
                          width="100"
                          height="100"
                        >
                          <v-icon>mdi-camera</v-icon>
                        </v-btn>

                        <div
                          v-if="form_return.job_done_container_image"
                          style="position: relative"
                        >
                          <v-img
                            :src="
                              checkPreviewImage(
                                form_return.job_done_container_image
                              )
                            "
                            max-width="100"
                            max-height="100"
                            cover
                            style="border-radius: 10px;"
                          >
                            <div
                              class="d-flex justify-center align-center fill-height"
                            >
                              <v-btn
                                class="ma-2"
                                color="error"
                                fab
                                width="20"
                                height="20"
                                @click="clearImage('job_done_container_image')"
                              >
                                <v-icon small>mdi-close</v-icon>
                              </v-btn>
                            </div>
                          </v-img>
                        </div>
                      </v-flex>
                    </v-layout>

                    <v-layout
                      class="mb-3"
                      wrap
                      justify-start
                      v-if="mode !== 'Emmpty'"
                    >
                      <v-flex
                        class="mt-4 xs3 text-end font-12 pt-2 pr-2 font-weight-bold"
                      >
                        ใบคืนตู้ (EIR) :
                      </v-flex>
                      <v-flex xs9 class="mt-4 ">
                        <input
                          type="file"
                          hidden
                          ref="container_return_image"
                          @change="
                            onFileChange($event, 'container_return_image')
                          "
                        />
                        <v-btn
                          v-if="!form_return.container_return_image"
                          color="#338A68"
                          @click="$refs.container_return_image.click()"
                          outlined
                          width="100"
                          height="100"
                        >
                          <v-icon>mdi-camera</v-icon>
                        </v-btn>

                        <div
                          v-if="form_return.container_return_image"
                          style="position: relative"
                        >
                          <v-img
                            :src="
                              checkPreviewImage(
                                form_return.container_return_image
                              )
                            "
                            max-width="100"
                            max-height="100"
                            cover
                            style="border-radius: 10px;"
                          >
                            <div
                              class="d-flex justify-center align-center fill-height"
                            >
                              <v-btn
                                class="ma-2"
                                color="error"
                                fab
                                width="20"
                                height="20"
                                @click="clearImage('container_return_image')"
                              >
                                <v-icon small>mdi-close</v-icon>
                              </v-btn>
                            </div>
                          </v-img>
                        </div>
                      </v-flex>
                    </v-layout>
                  </div>
                  <div style="width: 100%">
                    <div>
                      <v-btn
                        color="#338A68"
                        class="mr-4"
                        @click="onSubmitReturn"
                        block
                        height="71"
                        dark
                      >
                        <span class="font-20 font-w-900">
                          เสร้จสิ้น
                        </span>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>

              <!-- shipping -->
              <div v-if="$route.query.tab === 'shipping'">
                <div class="card-form">
                  <div style="width: 100%">
                    <div>
                      <v-layout>
                        <v-flex
                          class="xs3 text-end font-12 pt-2 pr-2 font-weight-bold"
                        >
                          เลขที่ใบขน :
                        </v-flex>
                        <v-flex xs9>
                          <v-text-field
                            v-model="form_shipping.transport_no"
                            outlined
                            dense
                            color="#338A68"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>

                      <v-layout>
                        <v-flex
                          class="xs3 text-end font-12 pt-2 pr-2 font-weight-bold"
                        >
                          อัปโหลดไฟล์ :
                        </v-flex>
                        <v-flex xs9>
                          <v-btn
                            dark
                            block
                            color="#59CB33"
                            @click="$refs.container_invoice.click()"
                          >
                            <v-icon>mdi-upload</v-icon>
                            <span class="font-weight-bold">อัปโหลดไฟล์</span>
                          </v-btn>
                        </v-flex>
                      </v-layout>

                      <input
                        type="file"
                        hidden
                        accept="application/pdf"
                        ref="container_invoice"
                        @change="onFileChange($event, 'container_invoice')"
                      />

                      <v-layout align-center mt-4 pl-6>
                        <v-flex>
                          <span style="color: #828282">{{
                            shipping_name
                          }}</span>
                        </v-flex>
                        <v-flex
                          v-if="shipping_name"
                          shrink
                          class=" text-end font-12 pl-2 font-weight-bold"
                        >
                          <v-btn
                            depressed
                            dark
                            fab
                            width="20"
                            height="20"
                            color="#828282"
                            @click="clearImage('shipping_name')"
                          >
                            <v-icon small>mdi-close</v-icon>
                          </v-btn>
                        </v-flex>
                      </v-layout>

                      <div class="mt-3" v-if="shipping_name">
                        <v-img
                          max-height="230"
                          :src="
                            require('../../../assets/images/menu-icons/pdf-upload.png')
                          "
                        ></v-img>
                      </div>
                    </div>
                  </div>
                  <div style="width: 100%">
                    <v-layout align-end mt-3>
                      <v-flex xs6 class="pr-2">
                        <v-btn
                          height="50"
                          dark
                          block
                          color="#E5E5E5"
                          @click="onCancelShipping"
                        >
                          <span
                            style="color: #828282"
                            class="font-w-900 font-20"
                          >
                            ยกเลิก
                          </span>
                        </v-btn>
                      </v-flex>
                      <v-flex xs6 class="pl-2">
                        <v-btn
                          height="50"
                          dark
                          block
                          color="#338A68"
                          @click="onSubmitShipping"
                        >
                          <span class="font-w-900 font-20">บันทึก</span>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </div>
                </div>
              </div>

              <!-- shore -->
              <div v-if="$route.query.tab === 'shore'">
                <div class="card-form">
                  <div style="width: 100%">
                    <div>
                      <v-layout>
                        <v-flex
                          class="xs3 text-end font-12 pt-2 pr-2 font-weight-bold"
                        >
                          อัปโหลดไฟล์ :
                        </v-flex>
                        <v-flex xs9>
                          <v-btn
                            dark
                            block
                            color="#59CB33"
                            @click="$refs.tax_invoice_file.click()"
                          >
                            <v-icon>mdi-upload</v-icon>
                            <span class="font-weight-bold">อัปโหลดไฟล์</span>
                          </v-btn>
                        </v-flex>
                      </v-layout>

                      <input
                        type="file"
                        hidden
                        accept="image/jpeg,image/gif,image/png,image/webp,application/pdf,image/x-eps"
                        ref="tax_invoice_file"
                        @change="onFileChange($event, 'tax_invoice_file')"
                      />

                      <v-layout align-center mt-4 pl-6>
                        <v-flex>
                          <span style="color: #828282">{{
                            tax_invoice_file_name
                          }}</span>
                        </v-flex>
                        <v-flex
                          v-if="tax_invoice_file_name"
                          shrink
                          class=" text-end font-12 pl-2 font-weight-bold"
                        >
                          <v-btn
                            depressed
                            dark
                            fab
                            width="20"
                            height="20"
                            color="#828282"
                            @click="clearImage('tax_invoice_file_name')"
                          >
                            <v-icon small>mdi-close</v-icon>
                          </v-btn>
                        </v-flex>
                      </v-layout>

                      <div class="mt-3" v-if="tax_invoice_file_name">
                        <v-img
                          v-if="tax_invoice_file_type === 'application/pdf'"
                          max-height="230"
                          :src="
                            require('../../../assets/images/menu-icons/pdf-upload.png')
                          "
                        ></v-img>

                        <div
                          class="d-flex justify-center"
                          v-else
                          style="height: 220px;background: #F5F5F5"
                        >
                          <v-icon style="font-size: 100px" color="#338A68">
                            mdi-file-image
                          </v-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="width: 100%">
                    <v-layout align-end mt-3>
                      <v-flex xs6 class="pr-2">
                        <v-btn
                          height="50"
                          dark
                          block
                          color="#E5E5E5"
                          @click="onCancelShore"
                        >
                          <span
                            style="color: #828282"
                            class="font-w-900 font-20"
                          >
                            ยกเลิก
                          </span>
                        </v-btn>
                      </v-flex>
                      <v-flex xs6 class="pl-2">
                        <v-btn
                          height="50"
                          dark
                          block
                          color="#338A68"
                          @click="onSubmitShore"
                        >
                          <span class="font-w-900 font-20">บันทึก</span>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </div>
                </div>
              </div>
            </v-form>
          </div>
          <div
            v-else
            style="color: #C4C4C4;"
            class="d-flex justify-center align-center"
          >
            <div style="margin-top: 7rem">เลือกรายการเพื่อดำเนินการ</div>
          </div>
        </div>
      </v-flex>
    </v-layout>

    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="false"
    />
  </div>
</template>

<script>
import Services from "../../../services";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Paginate from "../../../components/pagination/Paginate.vue";
import moment from "moment";
import { url_path } from "../../../services/Constants";
import {
  headers_accept_tender,
  headers_receive_container,
  headers_arrived,
  headers_start_load_unload,
  headers_end_load_unload,
  headers_return,
  headers_shipping,
  headers_shore,
} from "./header-table";
import OrderForm from "../../../components/monitoring/detail/OrderForm.vue";
import Tab from "../../../components/monitoring/detail/Tab.vue";

export default {
  components: {
    Loading,
    Paginate,
    OrderForm,
    Tab,
  },
  data() {
    return {
      mode: "",
      url_path: url_path,
      date_now: moment().format("DD/MM/YYYY H:mm"),
      isLoading: false,
      selected: [],
      order: {},
      header_table: [],
      monitoringDetailList: [],
      paginate: {
        current_page: 1,
        last_page: 1,
      },
      filter: {
        page: 1,
        mode: "",
        customer_id: "",
        importer_exporter_id: "",
        inv_po: "",
        booking_no: "",
        wh_factory_id: "",
        cy_date_start: "",
        cy_date_end: "",
        cy_place_id: "",
        unload_date_start: "",
        unload_date_end: "",
        carrier_id: "",
        driver_id: "",
        container: "",
      },
      driver_job_count: [],

      // FORM
      valid: true,
      action_name: "",
      action_total: 0,
      showForm: false,

      isShowBtnAcceptTender: false,

      // FORM RECEIVE CONTAINER
      form_receive_container: {
        driver_job_id: null,
        pickup_container_image: null,
        pickup_container_no: null,
        seal_no: null,
        seal_no_image: null,
        cabinet_weight: null,
      },

      // FORM ARRIVED / FORM START LOAD UNLOAD
      form_checkin: {
        driver_job_id: null,
        datetime: null,
        latitude: null,
        longitude: null,
      },
      check_btn_checkin: true,

      // FORM LOAD UNLOAD END
      form_load_unload_end: {
        driver_job_id: null,
        mode: null,
        deliver_container_image: null,
        eir_image: null,
        goods_delivery_image: null,
        recipient_signature: null,
      },

      // FORM RETURN
      form_return: {
        driver_job_id: null,
        mode: null,
        job_done_container_image: null, // รูปท้ายตู้
        container_return_image: null, // ใบคืนตู้ (EIR)
      },

      // FORM SHIPPING
      form_shipping: {
        transport_no: null,
        container_invoice: null,
      },
      shipping_name: "",

      // FORM SHORE
      form_shore: {
        tax_invoice_file: null,
      },
      tax_invoice_file_name: "",
      tax_invoice_file_type: "",
    };
  },
  computed: {
    checkSingleSelect() {
      switch (this.action_name) {
        case "Accept Tender":
          return false;
        case "Receive Container":
          return true;
        case "Arrived":
          return true;
        case "Start Load/Unload":
          return true;
        case "End Load/Unload":
          return true;
        case "Return":
          return true;
        case "Shipping":
          return false;
        case "Shore":
          return false;
        default:
          return false;
      }
    },
    itemData() {
      return this.selected.length > 0 ? this.selected[0] : {};
    },
  },
  watch: {
    // watch route query changes
    $route(to, from) {
      this.checkHeaderTable(to.query.tab);
      this.isLoading = true;
      this.getMonitoringDetail().then(() => {
        this.isLoading = false;
      });
    },

    selected: {
      handler: function(item) {
        this.onSelectItem(item);
      },
    },
  },
  created() {
    this.isLoading = true;
    this.checkHeaderTable(this.$route.query.tab);
    Promise.all([this.getOrderById(), this.getMonitoringDetail()])
      .then((values) => {
        this.isLoading = false;
      })
      .catch((error) => {
        console.log(error);
        this.isLoading = false;
      });
  },
  methods: {
    checkHeaderTable(val) {
      if (val == "accept-tender") {
        this.header_table = headers_accept_tender;
        this.action_name = "Accept Tender";
      } else if (val == "receive-container") {
        this.header_table = headers_receive_container;
        this.action_name = "Receive Container";
      } else if (val == "arrived") {
        this.header_table = headers_arrived;
        this.action_name = "Arrived";
      } else if (val == "start-load-unload") {
        this.header_table = headers_start_load_unload;
        this.action_name = "Start Load/Unload";
      } else if (val == "end-load-unload") {
        this.header_table = headers_end_load_unload;
        this.action_name = "End Load/Unload";
      } else if (val == "return") {
        this.header_table = headers_return;
        this.action_name = "Return";
      } else if (val == "shipping") {
        this.header_table = headers_shipping;
        this.action_name = "Shipping";
      } else if (val == "shore") {
        this.header_table = headers_shore;
        this.action_name = "Shore";
      }
    },
    async getOrderById() {
      const res = await Services.Monitoring.getOrderById(this.$route.params.id);
      this.order = res.data;

      return res;
    },
    async getMonitoringDetail() {
      const res = await Services.Monitoring.getMonitoringDetail(
        this.$route.params.id,
        {
          page: this.paginate.current_page,
          tab: this.$route.query.tab,
        }
      );
      res.data.driver_job.data = res.data.driver_job.data.map((data, i) => ({
        ...data,
        no:
          (res.data.driver_job.current_page - 1) *
            res.data.driver_job.per_page +
          i +
          1,
      }));
      this.monitoringDetailList = res.data.driver_job.data;
      this.driver_job_count = res.data.count;
      this.paginate.current_page = res.data.driver_job.current_page;
      this.paginate.last_page = res.data.driver_job.last_page;

      return res.data;
    },
    onClickPagination(page) {
      this.isLoading = true;
      this.paginate.current_page = page;
      this.getMonitoringDetail().then((res) => {
        this.isLoading = false;
      });
    },
    convertTime(time) {
      return time.slice(0, -3);
    },
    onClickTab(tab) {
      this.selected = [];
      this.$router
        .push({
          query: {
            tab: tab,
          },
        })
        .catch(() => {});
    },

    // CONDITION ON SELECT FOR FORM
    onSelectItem(item) {
      this.action_total = item.length;

      if (item.length > 0) {
        this.showForm = true;

        // ถ้า checkSingleSelect เป็น true แสดงว่าให้เลือกเฉพาะ 1 รายการ
        if (this.checkSingleSelect) {
          item = item[0];
          this.mode = item.accept_tender.tender.order_detail.order.mode;

          switch (this.$route.query.tab) {
            case "receive-container":
              this.form_receive_container = {
                mode: item.accept_tender.tender.order_detail.order.mode,
                driver_job_id: item.id,
                pickup_container_image: item.pickup_container_image,
                pickup_container_no: item.pickup_container_no,
                seal_no: item.seal_no,
                seal_no_image: item.seal_no_image,
                cabinet_weight: item.cabinet_weight,
              };
              break;
            case "arrived":
              this.form_checkin = {
                driver_job_id: item.id,
                latitude: item.cy_place_latitude,
                longitude: item.cy_place_longitude,
              };
              if (item.factory_success_date) {
                this.check_btn_checkin = false;
              } else {
                this.check_btn_checkin = true;
              }
              break;
            case "start-load-unload":
              this.form_checkin = {
                driver_job_id: item.id,
                latitude: item.cy_place_latitude,
                longitude: item.cy_place_longitude,
              };
              if (item.start_product_success_date) {
                this.check_btn_checkin = false;
              } else {
                this.check_btn_checkin = true;
              }
              break;
            case "end-load-unload":
              this.form_load_unload_end = {
                mode: item.accept_tender.tender.order_detail.order.mode,
                driver_job_id: item.id,
                deliver_container_image: item.deliver_container_image,
                eir_image: item.eir_image,
                goods_delivery_image: item.goods_delivery_image,
                recipient_signature: item.recipient_signature,
              };
              break;
            case "return":
              this.form_return = {
                driver_job_id: item.id,
                mode: item.accept_tender.tender.order_detail.order.mode,
                job_done_container_image: item.job_done_container_image,
                container_return_image: item.container_return_image,
              };
              break;
          }
        } else {
          switch (this.$route.query.tab) {
            case "shipping":
              var last_item_shipping = item[item.length - 1];
              if (last_item_shipping) {
                this.form_shipping.transport_no =
                  last_item_shipping.transport_no;
                this.form_shipping.container_invoice =
                  last_item_shipping.container_invoice;
                this.shipping_name = last_item_shipping.container_invoice
                  ? last_item_shipping.container_invoice.split("/").pop()
                  : "";
              }
              break;
            case "shore":
              var last_item_shore = item[item.length - 1];
              if (last_item_shore) {
                this.form_shore.tax_invoice_file =
                  last_item_shore.tax_invoice_file;
                this.tax_invoice_file_name = last_item_shore.tax_invoice_file
                  ? last_item_shore.tax_invoice_file.split("/").pop()
                  : "";

                // split . แล้วเอาตัวสุดท้าย
                var type_exe = last_item_shore.tax_invoice_file
                  ? last_item_shore.tax_invoice_file.split(".").pop()
                  : "";
                if (type_exe == "pdf") {
                  this.tax_invoice_file_type = "application/pdf";
                } else {
                  this.tax_invoice_file_type = "application/image";
                }
              }

              break;
            case "accept-tender":
              // check ว่าเป็นรายการที่เลือกทั้งหมดมี status = New ทั้งหมดหรือไม่
              var check_status = item.every((e) => {
                return e.status == "NEW";
              });

              // ถ้าเป็น true แสดงว่าเป็นรายการที่เลือกทั้งหมดมี status = New ทั้งหมด ให้แสดงปุ่ม Accept Tender
              if (check_status) {
                this.isShowBtnAcceptTender = true;
              } else {
                this.isShowBtnAcceptTender = false;
              }
              break;
          }
        }
      } else {
        this.showForm = false;
        this.shipping_name = "";
        this.tax_invoice_file_name = "";
      }
    },

    // FORM RECEIVE CONTAINER
    async onSubmitAcceptTender(status) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            for (let i = 0; i < this.selected.length; i++) {
              const res = await Services.Monitoring.step1({
                driver_job_id: this.selected[i].id,
                status: status,
              });
            }
            // update number tab
            switch (this.action_name) {
              case "Accept Tender":
                this.driver_job_count.accept_tender_count -= this.selected.length;
                break;
              case "Receive Container":
                this.driver_job_count.receive_container_count -= this.selected.length;
                break;
              case "Arrived":
                this.driver_job_count.arrived_count -= this.selected.length;
                break;
              case "Start Load/Unload":
                this.driver_job_count.start_load_unload_count -= this.selected.length;
                break;
              case "End Load/Unload":
                this.driver_job_count.end_load_unload_count -= this.selected.length;
                break;
              case "Return":
                this.driver_job_count.return_count -= this.selected.length;
                break;
              case "Shipping":
                this.driver_job_count.shipping_count -= this.selected.length;
                break;
              case "Shore":
                this.driver_job_count.shore_count -= this.selected.length;
                break;
            }
            this.isLoading = false;
            this.selected = [];
            this.$swal({
              icon: "success",
              title: "Save successfully!",
              showConfirmButton: true,
            }).then((result) => {
              this.showForm = false;
              this.selected = [];
              this.isLoading = true;
              this.getMonitoringDetail().then((res) => {
                this.isLoading = false;
              });
            });
          }
        });
    },

    // FORM RECEIVE CONTAINER
    onSubmitReceiveContainerForm() {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            const { valid, msg } = this.validateContainerNo(
              this.form_receive_container.pickup_container_no
            );

            if (valid) {
              this.isLoading = true;
              Services.Monitoring.step2(this.form_receive_container)
                .then((res) => {
                  this.isLoading = false;
                  this.$swal({
                    icon: "success",
                    title: "Save successfully!",
                    showConfirmButton: true,
                  }).then((result) => {
                    // this.showForm = false;
                    // this.selected = [];
                    this.isLoading = true;
                    this.getMonitoringDetail().then((res) => {
                      this.isLoading = false;
                    });
                  });
                })
                .catch((err) => {
                  this.isLoading = false;
                  this.$swal({
                    icon: "error",
                    title: `Error! ${err.response.status} ${err.response.statusText}`,
                    html: err.response.data.message.join("<br>"),
                    showConfirmButton: true,
                  });
                });
            } else {
              this.$swal({
                icon: "warning",
                title: "Warning!",
                text: msg,
                showConfirmButton: true,
              });
            }
          }
        });
    },

    // FORM ARRIVED
    onSubmitArrived() {
      this.form_checkin.datetime = moment().format("YYYY-MM-DD HH:mm:ss");
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            Services.Monitoring.statusStep1(this.form_checkin)
              .then((res) => {
                this.isLoading = false;
                this.$swal({
                  icon: "success",
                  title: "Save successfully!",
                  showConfirmButton: true,
                }).then((result) => {
                  //   this.showForm = false;
                  //   this.selected = [];
                  this.isLoading = true;
                  this.getMonitoringDetail().then((res) => {
                    this.isLoading = false;
                  });
                });
              })
              .catch((err) => {
                this.isLoading = false;
                this.$swal({
                  icon: "error",
                  title: `Error! ${err.response.status} ${err.response.statusText}`,
                  html: err.response.data.message.join("<br>"),
                  showConfirmButton: true,
                });
              });
          }
        });
    },

    // FORM LOAD UNLOAD START
    onSubmitLoadUnloadStart() {
      this.form_checkin.datetime = moment().format("YYYY-MM-DD HH:mm:ss");
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            Services.Monitoring.statusStep2(this.form_checkin)
              .then((res) => {
                this.isLoading = false;
                this.$swal({
                  icon: "success",
                  title: "Save successfully!",
                  showConfirmButton: true,
                }).then((result) => {
                  //   this.showForm = false;
                  //   this.selected = [];
                  this.isLoading = true;
                  this.getMonitoringDetail().then((res) => {
                    this.isLoading = false;
                  });
                });
              })
              .catch((err) => {
                this.isLoading = false;
                this.$swal({
                  icon: "error",
                  title: `Error! ${err.response.status} ${err.response.statusText}`,
                  html: err.response.data.message.join("<br>"),
                  showConfirmButton: true,
                });
              });
          }
        });
    },

    // FORM LOAD UNLOAD END
    onSubmitLoadUnloadEnd() {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            Services.Monitoring.step3(this.form_load_unload_end)
              .then((res) => {
                this.isLoading = false;
                this.$swal({
                  icon: "success",
                  title: "Save successfully!",
                  showConfirmButton: true,
                }).then((result) => {
                  this.showForm = false;
                  this.selected = [];
                  this.isLoading = true;
                  this.getMonitoringDetail().then((res) => {
                    this.isLoading = false;
                  });
                });
              })
              .catch((err) => {
                this.isLoading = false;
                this.$swal({
                  icon: "error",
                  title: `Error! ${err.response.status} ${err.response.statusText}`,
                  html: err.response.data.message.join("<br>"),
                  showConfirmButton: true,
                });
              });
          }
        });
    },

    // FORM LOAD UNLOAD END
    onSubmitReturn() {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            Services.Monitoring.step4(this.form_return)
              .then((res) => {
                this.isLoading = false;
                this.$swal({
                  icon: "success",
                  title: "Save successfully!",
                  showConfirmButton: true,
                }).then((result) => {
                  this.showForm = false;
                  this.selected = [];
                  this.isLoading = true;
                  this.getMonitoringDetail().then((res) => {
                    this.isLoading = false;
                  });
                });
              })
              .catch((err) => {
                this.isLoading = false;
                this.$swal({
                  icon: "error",
                  title: `Error! ${err.response.status} ${err.response.statusText}`,
                  html: err.response.data.message.join("<br>"),
                  showConfirmButton: true,
                });
              });
          }
        });
    },

    // FORM SHIPPING
    onSubmitShipping() {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            var driver_job_ids = [];
            this.selected.forEach((driver_job) => {
              driver_job_ids.push(driver_job.id);
            });

            this.form_shipping["driver_job_ids"] = driver_job_ids;

            this.isLoading = true;
            Services.Monitoring.uploadFileShipping(this.form_shipping)
              .then((res) => {
                this.isLoading = false;
                this.$swal({
                  icon: "success",
                  title: "Save successfully!",
                  showConfirmButton: true,
                }).then((result) => {
                  this.showForm = false;
                  this.selected = [];
                  this.isLoading = true;
                  this.getMonitoringDetail().then((res) => {
                    this.isLoading = false;
                  });
                });
              })
              .catch((err) => {
                this.isLoading = false;
                this.$swal({
                  icon: "error",
                  title: `Error! ${err.response.status} ${err.response.statusText}`,
                  html: err.response.data.message.join("<br>"),
                  showConfirmButton: true,
                });
              });
          }
        });
    },
    onCancelShipping() {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            var driver_job_ids = [];
            this.selected.forEach((driver_job) => {
              driver_job_ids.push(driver_job.id);
            });

            this.form_shipping["driver_job_ids"] = driver_job_ids;

            this.isLoading = true;
            Services.Monitoring.cancelFileShipping(this.form_shipping)
              .then((res) => {
                this.isLoading = false;
                this.$swal({
                  icon: "success",
                  title: "Save successfully!",
                  showConfirmButton: true,
                }).then((result) => {
                  this.showForm = false;
                  this.selected = [];
                  this.isLoading = true;
                  this.getMonitoringDetail().then((res) => {
                    this.isLoading = false;
                  });
                });
              })
              .catch((err) => {
                this.isLoading = false;
                this.$swal({
                  icon: "error",
                  title: `Error! ${err.response.status} ${err.response.statusText}`,
                  html: err.response.data.message.join("<br>"),
                  showConfirmButton: true,
                });
              });
          }
        });
    },

    // FORM SHORE
    onSubmitShore() {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            var driver_job_ids = [];
            this.selected.forEach((driver_job) => {
              driver_job_ids.push(driver_job.id);
            });

            this.form_shore["driver_job_ids"] = driver_job_ids;

            this.isLoading = true;
            Services.Monitoring.uploadFileShore(this.form_shore)
              .then((res) => {
                this.isLoading = false;
                this.$swal({
                  icon: "success",
                  title: "Save successfully!",
                  showConfirmButton: true,
                }).then((result) => {
                  this.showForm = false;
                  this.selected = [];
                  this.isLoading = true;
                  this.getMonitoringDetail().then((res) => {
                    this.isLoading = false;
                  });
                });
              })
              .catch((err) => {
                this.isLoading = false;
                this.$swal({
                  icon: "error",
                  title: `Error! ${err.response.status} ${err.response.statusText}`,
                  html: err.response.data.message.join("<br>"),
                  showConfirmButton: true,
                });
              });
          }
        });
    },
    onCancelShore() {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            var driver_job_ids = [];
            this.selected.forEach((driver_job) => {
              driver_job_ids.push(driver_job.id);
            });

            this.form_shipping["driver_job_ids"] = driver_job_ids;

            this.isLoading = true;
            Services.Monitoring.cancelFileShore(this.form_shipping)
              .then((res) => {
                this.isLoading = false;
                this.$swal({
                  icon: "success",
                  title: "Save successfully!",
                  showConfirmButton: true,
                }).then((result) => {
                  this.showForm = false;
                  this.selected = [];
                  this.isLoading = true;
                  this.getMonitoringDetail().then((res) => {
                    this.isLoading = false;
                  });
                });
              })
              .catch((err) => {
                this.isLoading = false;
                this.$swal({
                  icon: "error",
                  title: `Error! ${err.response.status} ${err.response.statusText}`,
                  html: err.response.data.message.join("<br>"),
                  showConfirmButton: true,
                });
              });
          }
        });
    },

    // OTHERS FUNCTION
    validateContainerNo(text) {
      let msg = "";
      let valid = true;
      var reg1 = /^[a-zA-Z]{4}/;
      var reg2 = /[0-9]{7}$/;

      if (this.mode == "Laden") {
        return { valid, msg: msg };
      }
      if (!text) {
        valid = false;
        msg = "ระบุเบอร์ตู้";
        return { msg, valid };
      } else if (text.length != 11) {
        valid = false;
        msg = "เบอร์ตู้ ต้องมี 11 หลัก";
        return { valid, msg: msg };
      } else if (!reg1.test(text)) {
        valid = false;
        msg =
          "4 ตัวแรกต้องเป็นตัวอีกษร(3 ตัวแรกเป็นรหัสบริษัท และตัวที่ 4 ต้องเป็นอักษร U)";
        return { valid, msg: msg };
      } else if (text.charAt(3) != "U" && text.charAt(3) != "u") {
        valid = false;
        msg = "เบอร์ตู้ ตัวอักษรตำแหน่งที่ 4 ต้องเป็นตัวอักษร U";
        return { valid, msg: msg };
      } else if (!reg2.test(text)) {
        valid = false;
        msg =
          "เบอร์ตู้ ตัวอักษรตำแหน่งที่ 5 ถึงตัวสุดท้ายต้องเป็นตัวเลข 7 หลัก";
        return { valid, msg: msg };
      } else {
        return { valid, msg: msg };
      }
    },
    onFileChange(e, type) {
      if (type == "pickup_container_image") {
        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (e) => {
          this.form_receive_container.pickup_container_image = e.target.result;
        };
      } else if (type == "seal_no_image") {
        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (e) => {
          this.form_receive_container.seal_no_image = e.target.result;
        };
      } else if (type == "deliver_container_image") {
        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (e) => {
          this.form_load_unload_end.deliver_container_image = e.target.result;
        };
      } else if (type == "eir_image") {
        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (e) => {
          this.form_load_unload_end.eir_image = e.target.result;
        };
      } else if (type == "goods_delivery_image") {
        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (e) => {
          this.form_load_unload_end.goods_delivery_image = e.target.result;
        };
      } else if (type == "recipient_signature") {
        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (e) => {
          this.form_load_unload_end.recipient_signature = e.target.result;
        };
      } else if (type == "container_return_image") {
        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (e) => {
          this.form_return.container_return_image = e.target.result;
        };
      } else if (type == "container_invoice") {
        let reader = new FileReader();
        this.shipping_name = e.target.files[0].name;
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (e) => {
          this.form_shipping.container_invoice = e.target.result;
        };
      } else if (type == "tax_invoice_file") {
        let reader = new FileReader();
        this.tax_invoice_file_name = e.target.files[0].name;
        this.tax_invoice_file_type = e.target.files[0].type;
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (e) => {
          this.form_shore.tax_invoice_file = e.target.result;
        };
      } else if (type == "job_done_container_image") {
        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (e) => {
          this.form_return.job_done_container_image = e.target.result;
        };
      }
    },
    clearImage(type) {
      if (type === "pickup_container_image") {
        this.form_receive_container.pickup_container_image = null;
        this.$refs.pickup_container_image.value = "";
      } else if (type === "seal_no_image") {
        this.form_receive_container.seal_no_image = null;
        this.$refs.seal_no_image.value = "";
      } else if (type === "deliver_container_image") {
        this.form_load_unload_end.deliver_container_image = null;
        this.$refs.deliver_container_image.value = "";
      } else if (type === "eir_image") {
        this.form_load_unload_end.eir_image = null;
        this.$refs.eir_image.value = "";
      } else if (type === "goods_delivery_image") {
        this.form_load_unload_end.goods_delivery_image = null;
        this.$refs.goods_delivery_image.value = "";
      } else if (type === "recipient_signature") {
        this.form_load_unload_end.recipient_signature = null;
        this.$refs.recipient_signature.value = "";
      } else if (type === "container_return_image") {
        this.form_return.container_return_image = null;
        this.$refs.container_return_image.value = "";
      } else if (type === "shipping_name") {
        this.shipping_name = null;
        this.form_shipping.container_invoice = null;
        this.$refs.container_invoice.value = "";
      } else if (type === "tax_invoice_file_name") {
        this.tax_invoice_file_name = null;
        this.tax_invoice_file_type = null;
        this.$refs.tax_invoice_file.value = "";
        this.form_shore.tax_invoice_file = null;
      } else if (type === "job_done_container_image") {
        this.form_return.job_done_container_image = null;
        this.$refs.job_done_container_image.value = "";
      }
    },
    checkPreviewImage(url) {
      // check url is base64 or not
      if (url && url.indexOf("data:image") > -1) {
        return url;
      } else {
        return url_path + url;
      }
    },
    showImage(url) {
      this.$swal({
        imageUrl: this.checkPreviewImage(url),
        imageWidth: "100%",
        imageHeight: "100%",
      });
    },
    showPdf(url) {
      window.open(url_path + url, "_blank");
    },

    txtStatus(status) {
      if (status == "ACCEPT") {
        return "รับงาน";
      } else if (status == "REJECT") {
        return "ปฏิเสธ";
      } else if (status == "NEW") {
        return "รอยืนยัน";
      }
    },
    classStatus(status) {
      if (status == "ACCEPT") {
        return "txt-status-accept";
      } else if (status == "REJECT") {
        return "txt-status-reject";
      } else if (status == "NEW") {
        return "txt-status-new";
      }
    },
    dateDiff(d1, d2) {
      // if (d1 <= d2) {
      //   return "ถึงก่อนเวลา";
      // }
      let date1 = new Date(d1);
      let date2 = new Date(d2);

      // หาส่วนต่างเวลา
      let diff = date1.getTime() - date2.getTime();
      // หาจำนวนวัน
      let days = diff / (1000 * 60 * 60 * 24);
      // หาจำนวนชั่วโมง
      let hours = (diff / (1000 * 60 * 60)) % 24;
      // หาจำนวนนาที
      let minutes = (diff / (1000 * 60)) % 60;
      // หาจำนวนวินาที
      let seconds = (diff / 1000) % 60;
      // แสดงผล
      // return (
      //   Math.floor(days) +
      //   " วัน " +
      //   Math.floor(hours) +
      //   " ชั่วโมง " +
      //   Math.floor(minutes) +
      //   " นาที " +
      //   Math.floor(seconds) +
      //   " วินาที"
      // );
      return (
        (days >= 1 || days < 0 ? Math.floor(days) + " วัน " : "") +
        (hours >= 1 || hours < 0 ? Math.floor(hours) + " ชั่วโมง " : "") +
        (minutes >= 1 || minutes < 0 ? Math.floor(minutes) + " นาที " : "") +
        (minutes < 1 || minutes < 0 ? Math.floor(seconds) + " วินาที" : "")
      );
    },
  },
};
</script>

<style>
.txt-status-accept {
  color: #59cb33;
}
.txt-status-reject {
  color: #828282;
}
.txt-status-new {
  color: #fd0505;
}
.form-step {
  position: relative;
  border: 1px solid #e0e0e0;
  /* min-height: calc(100vh - 490px); */
  min-height: calc(100vh - 365px);
  -webkit-box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.25);
  box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.25);
}
.bg-yellow {
  background: #fff0c2 !important;
  /* border-left: 1px solid; */
}
.action-form {
  background: #4b5768;
  box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.25);
  color: #fff;
  padding: 0px 10px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.card-form {
  height: calc(100vh - 570px);
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}
</style>
