<template>
  <div class="pa-6">
    <div class="font-18 font-w-600 mb-3">
      <v-icon color="#338A68">
        mdi-chart-arc
      </v-icon>
      Shipment Control (Import)
    </div>

    <v-card
      class=" pa-6"
      flat
      style="border: 1px solid #C4C4C4; border-radius: 0px;"
    >
      <v-row>
        <!-- Customer -->
        <v-col cols="3">
          <v-layout class="mb-3">
            <v-flex class="xs3 pt-3 text-end font-12 pr-2 font-weight-bold">
              Customer :
            </v-flex>
            <v-flex class="xs9">
              <v-autocomplete
                height="37px"
                append-icon="mdi-chevron-down"
                menu-props="offsetY"
                color="#98CA54"
                outlined
                dense
                multiple
                class="gray"
                :items="customers"
                v-model="filter.customer_id"
                hide-details
                placeholder="Customer"
                item-text="customer_code"
                item-value="customer_id"
                clearable
              >
              </v-autocomplete>
            </v-flex>
          </v-layout>
        </v-col>

        <!--  CY Date : -->
        <v-col cols="3">
          <v-layout class="align-center mb-3">
            <v-flex class="xs3 text-end font-12 pr-3 font-weight-bold">
              CY Date/<br />Relase Date :
            </v-flex>
            <v-flex class="xs4 px-0">
              <DatePicker
                placeholder="DD/MM/YY"
                hideDetails
                hideDetail
                rulse="fieldRequire"
                :model="date.cy_date_start"
                :max="filter.cy_date_end"
                @update-data="updateDate('cy_date_start', $event)"
              ></DatePicker>
            </v-flex>
            <v-flex class="xs1 text-end font-12 pr-1 font-weight-bold">
              To :
            </v-flex>
            <v-flex class=" xs4">
              <DatePicker
                placeholder="DD/MM/YY"
                hideDetails
                hideDetail
                rulse="fieldRequire"
                :model="date.cy_date_end"
                :min="filter.cy_date_start"
                @update-data="updateDate('cy_date_end', $event)"
              ></DatePicker>
            </v-flex>
          </v-layout>
        </v-col>

        <!--Closing Date -->
        <v-col cols="3">
          <v-layout class="align-center mb-3">
            <v-flex class="xs3 text-end font-12 pr-3 font-weight-bold">
              Closing Date :
            </v-flex>
            <v-flex class="xs4 px-0">
              <DatePicker
                placeholder="DD/MM/YY"
                hideDetails
                hideDetail
                rulse="fieldRequire"
                :model="date.closing_date_start"
                :max="filter.closing_date_end"
                @update-data="updateDate('closing_date_start', $event)"
              ></DatePicker>
            </v-flex>
            <v-flex class="xs1 text-end font-12 pr-1 font-weight-bold">
              To :
            </v-flex>
            <v-flex class=" xs4">
              <DatePicker
                placeholder="DD/MM/YY"
                hideDetails
                hideDetail
                rulse="fieldRequire"
                :model="date.closing_date_end"
                :min="filter.closing_date_start"
                @update-data="updateDate('closing_date_end', $event)"
              ></DatePicker>
            </v-flex>
          </v-layout>
        </v-col>
        <v-col cols="3"></v-col>
      </v-row>

      <v-row>
        <!-- Importer -->
        <v-col cols="3">
          <v-layout class="align-center mb-3">
            <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
              Importer <br />
              /Exporter :
            </v-flex>
            <v-flex class="xs9">
              <v-autocomplete
                height="37px"
                append-icon="mdi-chevron-down"
                menu-props="offsetY"
                color="#98CA54"
                outlined
                dense
                class="gray"
                :items="importerList"
                v-model="filter.importer_exporter_id"
                hide-details
                placeholder=" Importer/Exporter"
                item-text="customer_code"
                item-value="customer_id"
                clearable
                multiple
              >
              </v-autocomplete>
            </v-flex>
          </v-layout>
        </v-col>

        <!-- Load  Unload Date -->
        <v-col cols="3">
          <v-layout class="align-center mb-3">
            <v-flex class="xs3 text-end font-12 pr-3 font-weight-bold">
              Load/ <br />
              Unload Date :
            </v-flex>
            <v-flex class="xs4 px-0">
              <DatePicker
                placeholder="DD/MM/YY"
                hideDetails
                hideDetail
                rulse="fieldRequire"
                :model="date.unload_date_start"
                :max="filter.unload_date_end"
                @update-data="updateDate('unload_date_start', $event)"
              ></DatePicker>
            </v-flex>
            <v-flex class="xs1 text-end font-12 pr-1 font-weight-bold">
              To :
            </v-flex>
            <v-flex class=" xs4">
              <DatePicker
                placeholder="DD/MM/YY"
                hideDetails
                hideDetail
                rulse="fieldRequire"
                :model="date.unload_date_end"
                :min="filter.unload_date_start"
                @update-data="updateDate('unload_date_end', $event)"
              ></DatePicker>
            </v-flex>
          </v-layout>
        </v-col>

        <!-- Vendor -->
        <v-col cols="3">
          <v-layout class="align-center mb-3">
            <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
              Vendor :
            </v-flex>
            <v-flex class="xs9">
              <v-autocomplete
                height="37px"
                append-icon="mdi-chevron-down"
                menu-props="offsetY"
                color="#98CA54"
                outlined
                dense
                class="gray"
                :items="carriers"
                v-model="filter.carrier_id"
                hide-details
                placeholder="Carrier"
                item-text="name"
                item-value="carrier_id"
                clearable
              >
              </v-autocomplete>
            </v-flex>
          </v-layout>
        </v-col>

        <!-- Driver : -->
        <v-col cols="3">
          <v-layout class="align-center mb-3">
            <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
              Driver :
            </v-flex>
            <v-flex class="xs9">
              <v-autocomplete
                height="37px"
                append-icon="mdi-chevron-down"
                menu-props="offsetY"
                color="#98CA54"
                outlined
                dense
                class="gray"
                :items="drivers"
                v-model="filter.driver_id"
                hide-details
                placeholder="Driver"
                item-text="full_name"
                item-value="driver_id"
                clearable
              >
              </v-autocomplete>
            </v-flex>
          </v-layout>
        </v-col>
      </v-row>
    </v-card>

    <v-card
      width="100%"
      outlined
      class="mt-4"
      flat
      style="border: 1px solid #C4C4C4; border-radius: 0px;"
    >
      <v-layout justify-space-between align-center>
        <v-flex shrink> </v-flex>
        <v-flex shrink class="text-end pr-6">
          <v-btn
            depressed
            color="#F7D25D"
            class="my-4 font-weight-bold"
            @click="onSearch()"
          >
            <v-icon class="mr-3">mdi-magnify</v-icon>
            <span class="text-capitalize">Search</span>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card>

    <div>
      <v-btn
        outlined
        depressed
        color="#338A68"
        dark
        class="shrink my-4 font-weight-bold mr-3"
        @click="exportExcel()"
      >
        <img
          width="20"
          src="../../../assets/images/menu-icons/excel.png"
          alt=""
        />
        <span class="ml-2 shrink text-capitalize">Export Excel </span>
      </v-btn>
    </div>

    <v-layout justify-center wrap>
      <v-flex
        xs12
        class="font-16 font-w-600 text-center"
        style="color: #828282"
      >
        Free Time
      </v-flex>
      <v-flex xs12>
        <v-layout class="mt-2">
          <v-spacer></v-spacer>
          <v-flex shrink class="d-flex align-center mx-2">
            <div
              style="width: 25px; height: 10px; background-color: #EB5757"
            ></div>
            <span class="font-10 ml-1">ภายใน 12 ชม.</span>
          </v-flex>
          <v-flex shrink class="d-flex align-center mx-2">
            <div
              style="width: 25px; height: 10px; background-color: #6296E3"
            ></div>
            <span class="font-10 ml-1">ภายใน 48 ชม.</span>
          </v-flex>
          <v-flex shrink class="d-flex align-center mx-2">
            <div
              style="width: 25px; height: 10px; background-color: #D8D8D8"
            ></div>
            <span class="font-10 ml-1">มากกว่า 48 ชม.</span>
          </v-flex>
          <v-spacer></v-spacer>
        </v-layout>
      </v-flex>
      <v-flex xs12 class="">
        <apexchart
          ref="chart"
          width="100%"
          height="500"
          type="bar"
          :options="options"
          :series="series"
        ></apexchart>
      </v-flex>
    </v-layout>

    <div class="mt-6">
      <!-- <v-layout align-center justify-center class=" search">
        <v-flex class=" title font-weight-bold">{{ table_title }}</v-flex>
        <v-spacer></v-spacer>
        <v-flex xs4 class="d-flex ">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            placeholder="Search"
            outlined
            hide-details
          ></v-text-field>
        </v-flex>
      </v-layout> -->

      <div class="table-order mt-4">
        <v-data-table :headers="headers" :items="desserts" :search="search">
          <template v-slot:item.unload_load_date1="{ item }">
            <div v-if="item.unload_load_date1" class="text-center">
              <div>
                {{ item.unload_load_date1 | moment("DD/MM/YYYY") }}
              </div>
            </div>
            <div v-else>
              --
            </div>
          </template>

          <template v-slot:item.unload_load_time1="{ item }">
            <div v-if="item.unload_load_time1" class="text-center">
              <div>
                {{ convertTime(item.unload_load_time1) }}
              </div>
            </div>
            <div v-else>
              --
            </div>
          </template>
        </v-data-table>
      </div>
    </div>

    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="false"
    />
  </div>
</template>

<script>
import ControlTower from "../../../services/api/ControlTower.Service";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import XLSX from "xlsx"; // import xlsx;
import moment from "moment";
import DatePicker from "../../../components/picker/DatePicker.vue";

export default {
  components: {
    Loading,
    DatePicker,
  },
  data() {
    return {
      dialogTable: false,
      isLoading: false,
      // Table
      search: "",
      headers: [
        {
          text: "No.",
          align: "center",
          sortable: false,
          value: "no",
        },
        {
          text: "Job No.",
          align: "start",
          sortable: false,
          value: "order_id",
          width: "150px",
        },
        {
          text: "Customer",
          align: "start",
          sortable: false,
          value: "customer_name",
          width: "150px",
        },
        {
          text: "Import",
          align: "start",
          sortable: false,
          value: "importer_name",
          width: "150px",
        },
        {
          text: "Inv./PO.",
          align: "start",
          sortable: false,
          value: "inv_po",
          width: "100px",
        },
        {
          text: "Qty",
          align: "center",
          sortable: false,
          value: "qty",
          width: "50px",
        },
        {
          text: "Size",
          align: "center",
          sortable: false,
          value: "container_size_name",
          width: "50px",
        },
        {
          text: "Type",
          align: "center",
          sortable: false,
          value: "container_isotype_name",
          width: "50px",
        },
        {
          text: "Vendor",
          align: "start",
          sortable: false,
          value: "carrier_name",
          width: "200px",
        },
        {
          text: "Driver",
          align: "start",
          sortable: false,
          value: "driver_name",
          width: "120px",
        },
        {
          text: "ทะเบียนรถ",
          align: "center",
          sortable: false,
          value: "truck_license_id",
          width: "120px",
        },
        {
          text: "Release Port",
          align: "start",
          sortable: false,
          value: "release_port_name",
          width: "200px",
        },
        {
          text: "เลขที่ตู้",
          align: "start",
          sortable: false,
          value: "pickup_container_no",
          width: "200px",
        },
        {
          text: "W/H Factory 1",
          align: "start",
          sortable: false,
          value: "wh_factory_name",
          width: "200px",
        },
        {
          text: "Unload Date 1",
          align: "center",
          sortable: false,
          value: "unload_load_date1",
          width: "120px",
        },
        {
          text: "Unload Time 1",
          align: "center",
          sortable: false,
          value: "unload_load_time1",
          width: "120px",
        },
      ],
      desserts: [],

      series: [],
      options: {
        chart: {
          type: "bar",
          height: 650,
          events: {
            dataPointSelection: function(event, chartContext, config) {
              this.showDetail(
                config.w.config.xaxis.categories[config.dataPointIndex]
              );
            }.bind(this),
          },
        },
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            height: "100%",
            borderRadius: 4,
            columnWidth: "10%",
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: "top",
            },
          },
        },
        legend: {
          show: false,
        },
        stroke: {
          width: 2,
          colors: ["#fff"],
        },
        dataLabels: {
          enabled: true,
          offsetX: -6,
          style: {
            fontSize: "14px",
            colors: ["#fff"],
          },
        },
        xaxis: {
          categories: [],
        },
        colors: ["#1ab394"],
      },

      orders: [],
      customers: [],
      importerList: [],
      carriers: [],
      drivers: [],
      filter: {
        mode: [],
        customer_id: [],
        cy_date_start: "",
        cy_date_end: "",
        closing_date_start: "",
        closing_date_end: "",
        importer_exporter_id: [],
        unload_date_start: "",
        unload_date_end: "",
        carrier_id: "",
        driver_id: "",
      },
      date: {
        unload_date_start: "",
        unload_date_end: "",
        cy_date_start: "",
        cy_date_end: "",
        closing_date_start: "",
        closing_date_end: "",
      },
      dataExcel: [],
      table_title: "",
    };
  },

  created() {
    this.isLoading = true;
    Promise.all([
      this.getShipmentControlFilter(),
      this.getShipmentControlImport(),
      this.getMasterData(),
    ]).then((res) => {
      if (res.length > 0) {
        this.customers = res[0].customers;
        this.carriers = res[0].carriers;
        this.drivers = res[0].drivers;
      }
      this.isLoading = false;
    });
  },

  methods: {
    async getShipmentControlFilter() {
      return await ControlTower.getShipmentControlFilter();
    },

    async getMasterData() {
      const table = ["importer"];
      const res = await this.$store.dispatch(
        "master_data/getMasterData",
        table
      );
      this.importerList = res.data[0].importer;
      return res.data;
    },

    showDetail(seriesIndex) {
      this.desserts = [];
      this.dataExcel = [];
      this.isLoading = true;
      for (var key in this.orders) {
        if (key == seriesIndex) {
          this.desserts = this.orders[key];
          break;
        }
      }
      if (this.desserts.length > 0) {
        // map data to table format
        this.desserts = this.desserts.map((item, i) => {
          return {
            no: i + 1,
            order_id: item.order.order_id,
            customer_name: item.order.customer_name,
            importer_name: item.order.importer_name,
            inv_po: item.order.inv_po,
            qty: item.accept_tender.tender.order_detail.qty > 0 ? 1 : 0,
            container_size_name:
              item.accept_tender.tender.order_detail.container_size_name,
            container_isotype_name:
              item.accept_tender.tender.order_detail.container_isotype_name,
            carrier_name: item.accept_tender.tender.carrier_name,
            driver_name: item.accept_tender.driver_name,
            truck_license_id: item.accept_tender.truck_license_id,
            release_port_name: item.order.release_port_name,
            pickup_container_no: item.pickup_container_no,
            wh_factory_name:
              item.accept_tender.tender.order_detail.wh_factory_1_name,
            unload_load_date1:
              item.accept_tender.tender.order_detail.unload_load_date1,
            unload_load_time1:
              item.accept_tender.tender.order_detail.unload_load_time1,
          };
        });
        this.setDataExcel(this.desserts);
        this.dialogTable = true;
      }
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    },

    async getShipmentControlImport() {
      const res = await ControlTower.getShipmentControlImport(this.filter);
      if (res.data) {
        this.orders = res.data.orders ? res.data.orders : [];
        this.$refs.chart.updateSeries(res.data.graph.datasets);
        this.$refs.chart.updateOptions({
          xaxis: {
            categories: res.data.graph.labels,
          },
          colors: res.data.graph.backgroundColor,
        });
      }
      return res;
    },

    onSearch() {
      this.desserts = [];
      this.isLoading = true;
      ControlTower.getShipmentControlImport(this.filter)
        .then((res) => {
          if (res.data) {
            this.orders = res.data.orders;
            this.$refs.chart.updateSeries(res.data.graph.datasets);
            this.$refs.chart.updateOptions({
              xaxis: {
                categories: res.data.graph.labels,
              },
              colors: res.data.graph.backgroundColor,
            });
          } else {
            this.orders = [];
            this.$refs.chart.updateSeries([]);
            this.$refs.chart.updateOptions({});
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          console.log("onSearch", err);
        });
    },

    updateDate(name, val) {
      this.filter[name] = val ? val : "";
      this.date[name] = val ? this.customDate(val) : "";
    },

    customDate(val) {
      let sub_year = val.substring(0, 4);
      let sub_month = val.substring(5, 7);
      let sub_day = val.substring(8, 10);
      let changeDate = sub_day + "/" + sub_month + "/" + sub_year;
      return changeDate;
    },

    convertTime(time) {
      return time.slice(0, -3);
    },

    exportExcel() {
      if (this.dataExcel.length === 0) {
        this.$swal.fire({
          title: "Something went wrong!",
          text: "Please, Choose Data",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      } else {
        this.$swal
          .fire({
            title: "Are you sure?",
            text: `Do you want to export Order to Excel?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Export!",
          })
          .then((result) => {
            if (result.isConfirmed) {
              const dataWS = XLSX.utils.json_to_sheet(this.dataExcel);
              const wb = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(wb, dataWS);
              const fileName = `ShipmentControlExport_${moment(
                new Date()
              ).format("DD_MM_YYYY_h_mm_ss")}.xlsx`;
              XLSX.writeFile(wb, fileName);
            }
          });
      }
    },

    setDataExcel(items) {
      items.forEach((data) => {
        this.dataExcel.push({
          No: data.no,
          "Job No.": data.order_id,
          Customer: data.customer_name,
          Import: data.importer_name ? data.importer_name : data.importer_name,
          "Inv./PO.": data.inv_po,
          Qty: data.qty,
          "Container Size": data.container_size_name,
          "Container Type": data.container_isotype_name,
          Vendor: data.carrier_name,
          Driver: data.driver_name,
          ทะเบียนรถ: data.truck_license_id,
          "Release Port": data.release_port_name,
          เลขที่ตู้: data.pickup_container_no,
          "W/H Factory 1": data.wh_factory_name,
          "Return Place": data.return_place_name,
          "Unload Date 1": data.unload_load_date1,
          "Unload Time 1": data.unload_load_time1,
        });
      });
    },
  },
};
</script>

<style></style>
