<template>
  <div>
    <div class="ml-8 mb-4 mt-1">
      <v-icon small class="mr-2">mdi-file</v-icon>
      <span
        @click="$router.push('/order')"
        class="cursor-pointer font-weight-bold text-uppercase"
        >Order</span
      >
      <v-icon color="#338A68">mdi-chevron-right</v-icon>
      <span style="color: #338A68">{{
        $route.name === "editOrder" ? "Edit" : "Create"
      }}</span>
    </div>

    <div class="pb-12 mx-8">
      <!-- Search -->
      <v-card flat color="#F5F5F5" class="mb-6">
        <v-layout>
          <v-flex xs8>
            <v-layout class="align-center text-end">
              <v-flex xs2>Mode : </v-flex>
              <v-flex xs10 pl-6>
                <RadioInput
                  :model="mode"
                  @update-radio="updateMode($event)"
                  :radioList="listMode"
                />
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs4 class="align-center d-flex justify-end pr-6">
            <div>
              <span class="job-text">Job No.</span>
              <span class="job-no">{{ order_id ? order_id : "--" }}</span>
            </div>
          </v-flex>
        </v-layout>
      </v-card>

      <!-- Form -->
      <v-card flat>
        <!-- Form Import -->
        <ImportForm
          :statusForm="statusForm"
          :mode="mode"
          v-if="mode === 'Import'"
        />

        <!-- Form Export -->
        <ExportForm :statusForm="statusForm" :mode="mode" v-else />
      </v-card>
    </div>
  </div>
</template>

<script>
import RadioInput from "../../../components/input/RadioInput.vue";
import ExportForm from "./form/ExportForm.vue";
import ImportForm from "./form/ImportForm.vue";
import { mapState } from "vuex";
export default {
  components: {
    RadioInput,
    ImportForm,
    ExportForm,
  },
  data: () => ({
    statusForm: "createOrder",
    valid: true,
    listMode: [
      { label: "Import" },
      { label: "Export" },
      { label: "Empty" },
      { label: "Laden" },
      { label: "Drop Load" },
    ],
  }),
  created() {
    // if (this.user.carrier_id) {
    //   window.history.back();
    // }
    this.statusForm = this.$route.name;
  },
  computed: {
    ...mapState({
      mode: (state) => state.order.mode,
      order_id: (state) => state.order.order_id,
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    updateMode(val) {
      if (this.statusForm === "editOrder") {
        this.statusForm = "createOrder";
        this.$router.push("/order/create");
      }
      this.$store.commit("order/SET_MODE", val);
      this.$store.commit("order/SET_ORDER_ID", "");
    },
  },
};
</script>

<style>
.job-text {
  background-color: #f7d25d;
  border: 1px solid #f7d25d;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 4px 5px;
  color: #000;
  font-size: 12px;
}
.job-no {
  padding: 4px 5px;
  font-size: 12px;
  border: 1px solid #f7d25d;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
</style>
