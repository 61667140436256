<template>
  <div class="px-8" style="">
    <div class="mb-4 mt-1">
      <v-icon color="#338A68" class="mr-1">mdi-file</v-icon>
      <span
        @click="$router.push('/truck-supply')"
        class="cursor-pointer font-weight-bold"
      >
        Truck Supply
      </span>
      <v-icon color="#338A68">mdi-chevron-right</v-icon>

      <span style="color: #338A68">Create</span>
    </div>

    <v-form ref="form" v-model="valid" lazy-validation>
      <v-layout align-center mb-4>
        <v-flex shrink class="font-14 font-weight-black mr-3">
          Company <span style="color: #EB5757">*</span> :
        </v-flex>
        <v-flex xs4>
          <div v-if="user.user_group_id != 3">
            <AutocompleteInput
              required
              clearable
              :model="driver.carrier_id"
              rulse="fieldRequire"
              :items="carrierList"
              itemText="name"
              itemValue="carrier_id"
              @update-data="
                carrier_id = $event ? $event.carrier_id : '';
                carrier_name = $event ? $event.name : '';
                driver.carrier_id = $event ? $event.carrier_id : '';
                driver.carrier_code = $event ? $event.name : '';
              "
            />
          </div>
          <div v-else class="font-14 pt-1">
            {{ driver.carrier_code }}
          </div>
        </v-flex>
      </v-layout>

      <div>
        <!-- Form Driver -->
        <v-card flat class="mb-4" outlined>
          <div class="py-3 pl-3" style="background-color: #E8F3F6">
            <v-layout>
              <v-flex
                class="font-weight-bold shrink d-flex justify-lg-center align-center mr-6"
              >
                <v-switch
                  class="ma-0 pa-0"
                  hide-details
                  v-model="is_driver"
                  color="#338A67"
                  label="Driver"
                ></v-switch>
              </v-flex>
              <v-flex xs2>
                <AutocompleteInput
                  :model="selectd_driver"
                  :disabled="!is_driver"
                  placeholder="ค้นหารายชื่อ"
                  rulse="fieldRequire"
                  :items="driverList"
                  itemText="fullname"
                  itemValue="driver_id"
                  @update-data="mapDataDriver($event)"
                />
              </v-flex>
            </v-layout>
          </div>

          <v-layout pa-6>
            <v-flex class="xs10">
              <v-layout mb-3>
                <v-flex pr-3 xs3>
                  <div class="font-14 font-weight-black">
                    Driver Code <span style="color: #EB5757">*</span> :
                  </div>
                  <TextInput
                    :required="is_driver"
                    :disabled="!is_driver"
                    :model="driver.driver_code"
                    rulse="fieldRequire"
                    @update-data="updateData('driver', 'driver_code', $event)"
                  />
                </v-flex>
                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    Title <span style="color: #EB5757">*</span> :
                  </div>
                  <AutocompleteInput
                    clearable
                    :required="is_driver"
                    :disabled="!is_driver"
                    borderColor="green"
                    :model="driver.title_id"
                    rulse="fieldRequire"
                    :items="titleList"
                    itemText="title_name"
                    itemValue="title_id"
                    @update-data="
                      driver.title_id = $event ? $event.title_id : '';
                      driver.title_name = $event ? $event.title_name : '';
                    "
                  />
                </v-flex>
                <v-flex xs3 pr-3>
                  <div class="font-14 font-weight-black">
                    F Name <span style="color: #EB5757">*</span> :
                  </div>
                  <TextInput
                    :required="is_driver"
                    :disabled="!is_driver"
                    :model="driver.f_name"
                    rulse="fieldRequire"
                    @update-data="updateData('driver', 'f_name', $event)"
                  />
                </v-flex>
                <v-flex xs3 pr-3>
                  <div class="font-14 font-weight-black">
                    L Name <span style="color: #EB5757">*</span> :
                  </div>
                  <TextInput
                    :required="is_driver"
                    :disabled="!is_driver"
                    :model="driver.l_name"
                    rulse="fieldRequire"
                    @update-data="updateData('driver', 'l_name', $event)"
                  />
                </v-flex>
                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    Tel <span style="color: #EB5757">*</span> :
                  </div>
                  <TextInput
                    :required="is_driver"
                    :disabled="!is_driver"
                    :model="driver.tel"
                    rulse="fieldRequire"
                    @update-data="updateData('driver', 'tel', $event)"
                  />
                </v-flex>
                <v-flex xs2>
                  <div class="font-14 font-weight-black">
                    Age :
                  </div>
                  <div class="pt-1">
                    {{ !driver.age ? "" : driver.age }}
                  </div>
                </v-flex>
              </v-layout>

              <v-layout mb-12>
                <v-flex pr-3 xs3>
                  <div class="font-14 font-weight-black">
                    Sex <span style="color: #EB5757">*</span> :
                  </div>
                  <AutocompleteInput
                    :required="is_driver"
                    :disabled="!is_driver"
                    borderColor="green"
                    :model="driver.sex_id"
                    rulse="fieldRequire"
                    :items="sexList"
                    itemText="sex_name"
                    itemValue="sex_id"
                    clearable
                    @update-data="
                      driver.sex_id = $event ? $event.sex_id : '';
                      driver.sex_name = $event ? $event.sex_name : '';
                    "
                  />
                </v-flex>
                <v-flex pr-3 xs3>
                  <div class="font-14 font-weight-black">
                    Nickname <span style="color: #EB5757">*</span> :
                  </div>
                  <TextInput
                    :required="is_driver"
                    :disabled="!is_driver"
                    :model="driver.n_name"
                    rulse="fieldRequire"
                    @update-data="updateData('driver', 'n_name', $event)"
                  />
                </v-flex>

                <v-flex xs3></v-flex>
                <v-flex xs3></v-flex>
                <v-flex xs3></v-flex>
              </v-layout>

              <v-layout mb-3>
                <v-flex pr-3 xs3>
                  <div class="font-14 font-weight-black">
                    Personal ID <span style="color: #EB5757">*</span> :
                  </div>
                  <TextInput
                    :required="is_driver"
                    :disabled="!is_driver"
                    :model="driver.personal_id"
                    rulse="fieldRequire"
                    @update-data="updateData('driver', 'personal_id', $event)"
                  />
                </v-flex>
                <v-flex pr-3 xs2>
                  <div class="font-14 font-weight-black">
                    Birth Date <span style="color: #EB5757">*</span> :
                  </div>
                  <DatePicker
                    :required="is_driver"
                    :disabled="!is_driver"
                    placeholder="DD/MM/YY"
                    hideDetail
                    rulse="fieldRequire"
                    :model="date.birth_date"
                    @update-data="
                      date.birth_date = $event ? customDate($event) : '';
                      driver.birth_date = $event ? $event : '';
                    "
                  />
                </v-flex>
                <v-flex pr-3 xs3>
                  <div class="font-14 font-weight-black">
                    Issue By :
                  </div>
                  <AutocompleteInput
                    clearable
                    :disabled="!is_driver"
                    :model="driver.issue_by_code"
                    rulse="fieldRequire"
                    :items="provinceList"
                    itemText="name_th"
                    itemValue="code"
                    @update-data="
                      driver.issue_by_code = $event ? $event.code : '';
                      driver.issue_by_name = $event ? $event.name_th : '';
                    "
                  />
                </v-flex>
                <v-flex pr-10 xs3>
                  <div class="font-14 font-weight-black">
                    Personal ID exp <span style="color: #EB5757">*</span> :
                  </div>
                  <DatePicker
                    :required="is_driver"
                    :disabled="!is_driver"
                    placeholder="DD/MM/YY"
                    hideDetail
                    rulse="fieldRequire"
                    :model="date.personal_id_expri_date"
                    @update-data="
                      date.personal_id_expri_date = $event
                        ? customDate($event)
                        : '';
                      driver.personal_id_expri_date = $event ? $event : '';
                    "
                  />
                </v-flex>
                <v-flex xs2></v-flex>
                <v-flex xs2></v-flex>
              </v-layout>

              <v-layout mb-10>
                <v-flex pr-14 xs5>
                  <div class="font-14 font-weight-black">
                    Address 1 <span style="color: #EB5757">*</span> :
                  </div>
                  <TextareaInput
                    :required="is_driver"
                    :disabled="!is_driver"
                    row="3"
                    hideDetail
                    rulse="fieldRequire"
                    :model="driver.address1"
                    @update-data="updateData('driver', 'address1', $event)"
                  />
                </v-flex>
                <v-flex pr-3 xs4 ml-n10>
                  <div class="font-14 font-weight-black">
                    Address 2 :
                  </div>
                  <TextareaInput
                    :disabled="!is_driver"
                    row="3"
                    hideDetail
                    rulse="fieldRequire"
                    :model="driver.address2"
                    @update-data="updateData('driver', 'address2', $event)"
                  />
                </v-flex>
              </v-layout>

              <v-layout>
                <v-flex xs3 pr-5>
                  <div class="font-14 font-weight-black">
                    Education <span style="color: #EB5757">*</span> :
                  </div>
                  <AutocompleteInput
                    :required="is_driver"
                    :disabled="!is_driver"
                    :model="driver.education_id"
                    rulse="fieldRequire"
                    :items="educationList"
                    itemText="education_name"
                    itemValue="education_id"
                    clearable
                    @update-data="
                      driver.education_id = $event ? $event.education_id : '';
                      driver.education_name = $event
                        ? $event.education_name
                        : '';
                    "
                  />
                </v-flex>
                <v-flex xs3></v-flex>
                <v-flex xs3></v-flex>
                <v-flex xs3></v-flex>
                <v-flex xs3></v-flex>
              </v-layout>
            </v-flex>
            <v-flex class="xs2">
              <v-layout mb-3>
                <v-flex>
                  <div class="font-14 font-weight-black">
                    Status <span style="color: #EB5757">*</span> :
                  </div>
                  <AutocompleteInput
                    :required="is_driver"
                    :disabled="!is_driver"
                    :model="driver.status_id"
                    rulse="fieldRequire"
                    :items="statusList"
                    itemText="status_name"
                    itemValue="status_id"
                    clearable
                    @update-data="
                      driver.status_id = $event ? $event.status_id : '';
                      driver.status_name = $event ? $event.status_name : '';
                    "
                  />
                </v-flex>
              </v-layout>

              <v-layout mb-3>
                <v-flex>
                  <div class="font-14 font-weight-black">
                    Driver Type :
                  </div>
                  <AutocompleteInput
                    :disabled="!is_driver"
                    :model="driver.driver_type_id"
                    rulse="fieldRequire"
                    :items="driverTypeList"
                    itemText="driver_type_name"
                    itemValue="driver_type_id"
                    clearable
                    @update-data="
                      driver.driver_type_id = $event
                        ? $event.driver_type_id
                        : '';
                      driver.driver_type_name = $event
                        ? $event.driver_type_name
                        : '';
                    "
                  />
                </v-flex>
              </v-layout>

              <v-layout mb-3>
                <v-flex>
                  <div class="font-14 font-weight-black">
                    หมายเหตุ :
                  </div>
                  <TextareaInput
                    :disabled="!is_driver"
                    row="4"
                    hideDetail
                    rulse="fieldRequire"
                    :model="driver.note"
                    @update-data="updateData('driver', 'note', $event)"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

          <v-layout pa-6>
            <v-flex>
              <v-layout mb-6>
                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    License Type <span style="color: #EB5757">*</span> :
                  </div>
                  <AutocompleteInput
                    clearable
                    :required="is_driver"
                    :disabled="!is_driver"
                    :model="driver.license_type_id"
                    rulse="fieldRequire"
                    :items="licenseTypeList"
                    itemText="license_type_name"
                    itemValue="license_type_id"
                    @update-data="
                      updateSelect(
                        'driver',
                        'license_type_id',
                        'license_type_name',
                        $event
                      )
                    "
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    License No. <span style="color: #EB5757">*</span> :
                  </div>
                  <TextInput
                    :required="is_driver"
                    :disabled="!is_driver"
                    :model="driver.license_no"
                    rulse="fieldRequire"
                    @update-data="updateData('driver', 'license_no', $event)"
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    L Issue Date <span style="color: #EB5757">*</span> :
                  </div>
                  <DatePicker
                    :required="is_driver"
                    :disabled="!is_driver"
                    placeholder="DD/MM/YY"
                    hideDetail
                    rulse="fieldRequire"
                    :model="date.lissued_date"
                    @update-data="
                      date.lissued_date = $event ? customDate($event) : '';
                      driver.lissued_date = $event ? $event : '';
                    "
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    L Issue By :
                  </div>
                  <AutocompleteInput
                    clearable
                    :disabled="!is_driver"
                    :model="driver.lissued_by_code"
                    rulse="fieldRequire"
                    :items="provinceList"
                    itemText="name_th"
                    itemValue="code"
                    @update-data="
                      updateProvinceSelect(
                        'driver',
                        'lissued_by_code',
                        'lissued_by_name',
                        $event
                      )
                    "
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    License EXP <span style="color: #EB5757">*</span> :
                  </div>
                  <DatePicker
                    :required="is_driver"
                    :disabled="!is_driver"
                    placeholder="DD/MM/YY"
                    hideDetail
                    rulse="fieldRequire"
                    :model="date.driver_license_expir"
                    @update-data="
                      date.driver_license_expir = $event
                        ? customDate($event)
                        : '';
                      driver.driver_license_expir = $event ? $event : '';
                    "
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    ประวัติราชการ :
                  </div>
                  <TextareaInput
                    :disabled="!is_driver"
                    row="2"
                    hideDetail
                    rulse="fieldRequire"
                    :model="driver.government_history"
                    @update-data="
                      updateData('driver', 'government_history', $event)
                    "
                  />
                </v-flex>
              </v-layout>

              <v-layout>
                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    Working Date :
                  </div>
                  <DatePicker
                    :disabled="!is_driver"
                    placeholder="DD/MM/YY"
                    hideDetail
                    rulse="fieldRequire"
                    :model="date.working_date"
                    @update-data="
                      date.working_date = $event ? customDate($event) : '';
                      driver.working_date = $event ? $event : '';
                    "
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    Driving EXP :
                  </div>
                  <TextInput
                    :disabled="!is_driver"
                    :model="driver.driving_exp"
                    rulse="fieldRequire"
                    @update-data="updateData('driver', 'driving_exp', $event)"
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    ที่ทำงานล่าสุด :
                  </div>
                  <TextInput
                    :disabled="!is_driver"
                    :model="driver.latest_work_place"
                    rulse="fieldRequire"
                    @update-data="
                      updateData('driver', 'latest_work_place', $event)
                    "
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    สาเหตุที่ออก :
                  </div>
                  <TextInput
                    :disabled="!is_driver"
                    :model="driver.reason_resignation"
                    rulse="fieldRequire"
                    @update-data="
                      updateData('driver', 'reason_resignation', $event)
                    "
                  />
                </v-flex>
              </v-layout>

              <div class="mt-3 mb-8 font-14">
                <span class=" font-weight-black"> Working EXP</span> :
                {{ driver.work_exp ? driver.work_exp : "-" }} Years
              </div>

              <v-layout mb-10>
                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    เลขที่บัญชี :
                  </div>
                  <TextInput
                    :disabled="!is_driver"
                    :model="driver.account_number"
                    rulse="fieldRequire"
                    @update-data="
                      updateData('driver', 'account_number', $event)
                    "
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    Bank :
                  </div>
                  <AutocompleteInput
                    clearable
                    :disabled="!is_driver"
                    :model="driver.bank_id"
                    rulse="fieldRequire"
                    :items="bankList"
                    itemText="bank_name"
                    itemValue="bank_id"
                    @update-data="
                      updateSelect('driver', 'bank_id', 'bank_name', $event)
                    "
                  />
                </v-flex>
              </v-layout>

              <v-layout mb-8>
                <!-- <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    Company : <span style="color: #EB5757">*</span> :
                  </div>
                  <div v-if="user.user_group_id != 3">
                    <AutocompleteInput
                      required
                      clearable
                      :disabled="!is_driver"
                      :model="driver.carrier_id"
                      rulse="fieldRequire"
                      :items="carrierList"
                      itemText="name"
                      itemValue="carrier_id"
                      @update-data="
                        carrier_id = $event ? $event.carrier_id : '';
                        carrier_name = $event ? $event.name : '';
                        driver.carrier_id = $event ? $event.carrier_id : '';
                        driver.carrier_code = $event ? $event.name : '';
                      "
                    />
                  </div>
                  <div v-else class="font-14 pt-1">
                    {{ driver.carrier_code }}
                  </div>
                </v-flex> -->

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    Contact Person <span style="color: #EB5757">*</span> :
                  </div>
                  <TextInput
                    :required="is_driver"
                    :disabled="!is_driver"
                    :model="driver.contact_person"
                    rulse="fieldRequire"
                    @update-data="
                      updateData('driver', 'contact_person', $event)
                    "
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    Contact Rel <span style="color: #EB5757">*</span> :
                  </div>
                  <TextInput
                    :required="is_driver"
                    :disabled="!is_driver"
                    :model="driver.contact_rel"
                    rulse="fieldRequire"
                    @update-data="updateData('driver', 'contact_rel', $event)"
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    Contact Tel <span style="color: #EB5757">*</span> :
                  </div>
                  <TextInput
                    :required="is_driver"
                    :disabled="!is_driver"
                    :model="driver.contact_tel"
                    rulse="fieldRequire"
                    @update-data="updateData('driver', 'contact_tel', $event)"
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    Email :
                  </div>
                  <TextInput
                    :disabled="!is_driver"
                    :model="driver.email"
                    rulse="fieldRequire"
                    @update-data="updateData('driver', 'email', $event)"
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    ID Line :
                  </div>
                  <TextInput
                    :disabled="!is_driver"
                    :model="driver.line_id"
                    rulse="fieldRequire"
                    @update-data="updateData('driver', 'line_id', $event)"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>

        <!-- Form Truck -->
        <v-card flat class="mb-4" outlined>
          <v-layout
            class="py-3 pl-3 font-weight-black"
            style="background-color: #E8F3F6"
          >
            <v-flex
              shrink
              class="font-weight-bold shrink d-flex justify-lg-center align-center mr-6"
            >
              <v-switch
                class="ma-0 pa-0"
                hide-details
                v-model="is_truck"
                color="#338A67"
                label="Truck"
              ></v-switch>
            </v-flex>

            <v-flex xs2>
              <AutocompleteInput
                :model="selectd_truck"
                :disabled="!is_truck"
                placeholder="ค้นหาราย Truck"
                rulse="fieldRequire"
                :items="truckList"
                itemText="plate_no"
                itemValue="truck_id"
                @update-data="mapDataTruck($event)"
              />
            </v-flex>
          </v-layout>

          <v-layout pa-6 v-if="truck">
            <v-flex>
              <v-layout mb-3>
                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    รหัสรถ <span style="color: #EB5757">*</span> :
                  </div>
                  <TextInput
                    :required="is_truck"
                    :disabled="!is_truck"
                    :model="truck.car_code"
                    rulse="fieldRequire"
                    @update-data="updateData('truck', 'car_code', $event)"
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    Plate No. <span style="color: #EB5757">*</span> :
                  </div>
                  <TextInput
                    :required="is_truck"
                    :disabled="!is_truck"
                    :model="truck.plate_no"
                    rulse="fieldRequire"
                    @update-data="updateData('truck', 'plate_no', $event)"
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    Province Code <span style="color: #EB5757">*</span> :
                  </div>
                  <AutocompleteInput
                    clearable
                    :required="is_truck"
                    :disabled="!is_truck"
                    :model="truck.province_code"
                    rulse="fieldRequire"
                    :items="provinceList"
                    itemText="name_th"
                    itemValue="code"
                    @update-data="
                      updateProvinceSelect(
                        'truck',
                        'province_code',
                        'province_name',
                        $event
                      )
                    "
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    ยี่ห้อ :
                  </div>
                  <TextInput
                    :disabled="!is_truck"
                    :model="truck.car_brand"
                    rulse="fieldRequire"
                    @update-data="updateData('truck', 'car_brand', $event)"
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    ประเภทรถ :
                  </div>
                  <AutocompleteInput
                    clearable
                    :disabled="!is_truck"
                    :model="truck.car_type_id"
                    rulse="fieldRequire"
                    :items="carTypeList"
                    itemText="car_type_name"
                    itemValue="car_type_id"
                    @update-data="
                      updateSelect(
                        'truck',
                        'car_type_id',
                        'car_type_name',
                        $event
                      )
                    "
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    ประเภทรถย่อย :
                  </div>
                  <AutocompleteInput
                    clearable
                    :disabled="!is_truck"
                    :model="truck.car_sub_type_id"
                    rulse="fieldRequire"
                    :items="carSubTypeList"
                    itemText="car_sub_type_name"
                    itemValue="car_sub_type_id"
                    @update-data="
                      updateSelect(
                        'truck',
                        'car_sub_type_id',
                        'car_sub_type_name',
                        $event
                      )
                    "
                  />
                </v-flex>
              </v-layout>

              <v-layout mb-8>
                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    รหัสตัวถังรถ :
                  </div>
                  <TextInput
                    :disabled="!is_truck"
                    :model="truck.car_body_code"
                    rulse="fieldRequire"
                    @update-data="updateData('truck', 'car_body_code', $event)"
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    รหัสเครื่องยนต์. :
                  </div>
                  <TextInput
                    :disabled="!is_truck"
                    :model="truck.car_engine_code"
                    rulse="fieldRequire"
                    @update-data="
                      updateData('truck', 'car_engine_code', $event)
                    "
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    Year :
                  </div>
                  <DatePicker
                    :disabled="!is_truck"
                    placeholder="DD/MM/YY"
                    hideDetail
                    rulse="fieldRequire"
                    :model="date.car_year"
                    @update-data="
                      date.car_year = $event ? customDate($event) : '';
                      truck.car_year = $event ? $event : '';
                    "
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    น้ำหนักรถ <span style="color: #EB5757">*</span> :
                  </div>
                  <TextInput
                    :required="is_truck"
                    :disabled="!is_truck"
                    :model="truck.car_weight"
                    rulse="fieldRequire"
                    @update-data="updateData('truck', 'car_weight', $event)"
                  />
                </v-flex>
              </v-layout>

              <v-layout mb-3>
                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    วันที่จดทะเบียน <span style="color: #EB5757">*</span> :
                  </div>
                  <DatePicker
                    :required="is_truck"
                    :disabled="!is_truck"
                    placeholder="DD/MM/YY"
                    hideDetail
                    rulse="fieldRequire"
                    :model="date.register_date"
                    @update-data="
                      date.register_date = $event ? customDate($event) : '';
                      truck.register_date = $event ? $event : '';
                    "
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    วันที่ต่อ พรบ. <span style="color: #EB5757">*</span> :
                  </div>
                  <DatePicker
                    :required="is_truck"
                    :disabled="!is_truck"
                    placeholder="DD/MM/YY"
                    hideDetail
                    rulse="fieldRequire"
                    :model="date.truck_date_per_act"
                    @update-data="
                      date.truck_date_per_act = $event
                        ? customDate($event)
                        : '';
                      truck.date_per_act = $event ? $event : '';
                    "
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    วันที่ต่อ ภาษี <span style="color: #EB5757">*</span> :
                  </div>
                  <DatePicker
                    :required="is_truck"
                    :disabled="!is_truck"
                    placeholder="DD/MM/YY"
                    hideDetail
                    rulse="fieldRequire"
                    :model="date.tax_date"
                    @update-data="
                      date.tax_date = $event ? customDate($event) : '';
                      truck.tax_date = $event ? $event : '';
                    "
                  />
                </v-flex>
              </v-layout>

              <v-layout mb-3>
                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    บริษัทไฟแนนซ์ :
                  </div>
                  <TextInput
                    :disabled="!is_truck"
                    :model="truck.finance_company"
                    rulse="fieldRequire"
                    @update-data="
                      updateData('truck', 'finance_company', $event)
                    "
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    เลขที่สัญญาเช่าซื้อ :
                  </div>
                  <TextInput
                    :disabled="!is_truck"
                    :model="truck.hire_purchase_no"
                    rulse="fieldRequire"
                    @update-data="
                      updateData('truck', 'hire_purchase_no', $event)
                    "
                  />
                </v-flex>
              </v-layout>

              <v-layout mb-3>
                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    Vendor GPS :
                  </div>
                  <TextInput
                    :disabled="!is_truck"
                    :model="truck.vender_gps"
                    rulse="fieldRequire"
                    @update-data="updateData('truck', 'vender_gps', $event)"
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    GPS Box :
                  </div>
                  <TextInput
                    :disabled="!is_truck"
                    :model="truck.gps_box"
                    rulse="fieldRequire"
                    @update-data="updateData('truck', 'gps_box', $event)"
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    Phone GPS :
                  </div>
                  <TextInput
                    :disabled="!is_truck"
                    :model="truck.phone_gps"
                    rulse="fieldRequire"
                    @update-data="updateData('truck', 'phone_gps', $event)"
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    Contact GPS :
                  </div>
                  <TextInput
                    :disabled="!is_truck"
                    :model="truck.gps_contract"
                    rulse="fieldRequire"
                    @update-data="updateData('truck', 'gps_contract', $event)"
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    วันที่ติดตั้ง GPS :
                  </div>
                  <DatePicker
                    :disabled="!is_truck"
                    placeholder="DD/MM/YY"
                    hideDetail
                    rulse="fieldRequire"
                    :model="date.gps_install_date"
                    @update-data="
                      date.gps_install_date = $event ? customDate($event) : '';
                      truck.gps_install_date = $event ? $event : '';
                    "
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    วันที่หมดสัญญา GPS :
                  </div>
                  <DatePicker
                    :disabled="!is_truck"
                    placeholder="DD/MM/YY"
                    hideDetail
                    rulse="fieldRequire"
                    :model="date.gps_contract_expir_date"
                    @update-data="
                      date.gps_contract_expir_date = $event
                        ? customDate($event)
                        : '';
                      truck.gps_contract_expir_date = $event ? $event : '';
                    "
                  />
                </v-flex>
              </v-layout>

              <v-layout mb-3>
                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    บริษัทประกันสินค้า :
                  </div>
                  <TextInput
                    :disabled="!is_truck"
                    :model="truck.insurance_company"
                    rulse="fieldRequire"
                    @update-data="
                      updateData('truck', 'insurance_company', $event)
                    "
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    วงเงินประกันสินค้า :
                  </div>
                  <TextInput
                    :disabled="!is_truck"
                    :model="truck.product_insurance_limit"
                    rulse="fieldRequire"
                    @update-data="
                      updateData('truck', 'product_insurance_limit', $event)
                    "
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    วันที่คุ้มครอง <span style="color: #EB5757">*</span> :
                  </div>
                  <DatePicker
                    :required="is_truck"
                    :disabled="!is_truck"
                    placeholder="DD/MM/YY"
                    hideDetail
                    rulse="fieldRequire"
                    :model="date.protect_start_date"
                    @update-data="
                      date.protect_start_date = $event
                        ? customDate($event)
                        : '';
                      truck.protect_start_date = $event ? $event : '';
                    "
                  />
                </v-flex>
              </v-layout>

              <v-layout mb-3>
                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    บริษัทประกันรถ :
                  </div>
                  <TextInput
                    :disabled="!is_truck"
                    :model="truck.car_insurance_company"
                    rulse="fieldRequire"
                    @update-data="
                      updateData('truck', 'car_insurance_company', $event)
                    "
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    ทุนประกัน :
                  </div>
                  <TextInput
                    :disabled="!is_truck"
                    :model="truck.insurance_funds"
                    rulse="fieldRequire"
                    @update-data="
                      updateData('truck', 'insurance_funds', $event)
                    "
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    วันที่เริ่มประกัน <span style="color: #EB5757">*</span> :
                  </div>
                  <DatePicker
                    :required="is_truck"
                    :disabled="!is_truck"
                    placeholder="DD/MM/YY"
                    hideDetail
                    rulse="fieldRequire"
                    :model="date.insurance_start_date_truck"
                    @update-data="
                      date.insurance_start_date_truck = $event
                        ? customDate($event)
                        : '';
                      truck.insurance_start_date = $event ? $event : '';
                    "
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    ประเภทประกันรถ :
                  </div>
                  <AutocompleteInput
                    clearable
                    :disabled="!is_truck"
                    :model="truck.car_insurance_type_id"
                    rulse="fieldRequire"
                    :items="carInsuranceTypeList"
                    itemText="car_insurance_type_name"
                    itemValue="car_insurance_type_id"
                    @update-data="
                      updateSelect(
                        'truck',
                        'car_insurance_type_id',
                        'car_insurance_type_name',
                        $event
                      )
                    "
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>

        <!-- Form Trailer -->
        <v-card flat outlined>
          <v-layout
            class="py-3 pl-3 font-weight-black"
            style="background-color: #E8F3F6"
          >
            <v-flex
              shrink
              class="font-weight-bold shrink d-flex justify-lg-center align-center mr-6"
            >
              <v-switch
                class="ma-0 pa-0"
                hide-details
                v-model="is_trailer"
                color="#338A67"
                label="Trailer"
              ></v-switch>
            </v-flex>
            <v-flex xs2>
              <AutocompleteInput
                :model="selectd_trailer"
                :disabled="!is_trailer"
                placeholder="ค้นหาราย Trailer"
                rulse="fieldRequire"
                :items="trailerList"
                itemText="plate_no"
                itemValue="trailer_id"
                @update-data="mapDataTrailer($event)"
              />
            </v-flex>
          </v-layout>

          <v-layout pa-6 v-if="trailer">
            <v-flex>
              <v-layout mb-3>
                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    รหัสหาง <span style="color: #EB5757">*</span> :
                  </div>
                  <TextInput
                    :required="is_trailer"
                    :disabled="!is_trailer"
                    :model="trailer.tail_code"
                    rulse="fieldRequire"
                    @update-data="updateData('trailer', 'tail_code', $event)"
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    Plate No. <span style="color: #EB5757">*</span> :
                  </div>
                  <TextInput
                    :required="is_trailer"
                    :disabled="!is_trailer"
                    :model="trailer.plate_no"
                    rulse="fieldRequire"
                    @update-data="updateData('trailer', 'plate_no', $event)"
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    Province Code <span style="color: #EB5757">*</span> :
                  </div>
                  <AutocompleteInput
                    clearable
                    :required="is_trailer"
                    :disabled="!is_trailer"
                    :model="trailer.province_code"
                    rulse="fieldRequire"
                    :items="provinceList"
                    itemText="name_th"
                    itemValue="code"
                    @update-data="
                      updateProvinceSelect(
                        'trailer',
                        'province_code',
                        'province_name',
                        $event
                      )
                    "
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    ยี่ห้อหาง :
                  </div>
                  <TextInput
                    :disabled="!is_trailer"
                    :model="trailer.tail_brand"
                    rulse="fieldRequire"
                    @update-data="updateData('trailer', 'tail_brand', $event)"
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    ประเภทหาง :
                  </div>
                  <AutocompleteInput
                    clearable
                    :disabled="!is_trailer"
                    :model="trailer.tail_type_id"
                    rulse="fieldRequire"
                    :items="tailTypeList"
                    itemText="tail_type_name"
                    itemValue="tail_type_id"
                    @update-data="
                      updateSelect(
                        'trailer',
                        'tail_type_id',
                        'tail_type_name',
                        $event
                      )
                    "
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    ประเภทหางย่อย :
                  </div>
                  <AutocompleteInput
                    clearable
                    :disabled="!is_trailer"
                    :model="trailer.tail_sub_type_id"
                    rulse="fieldRequire"
                    :items="tailSubTypeList"
                    itemText="tail_sub_type_name"
                    itemValue="tail_sub_type_id"
                    @update-data="
                      updateSelect(
                        'trailer',
                        'tail_sub_type_id',
                        'tail_sub_type_name',
                        $event
                      )
                    "
                  />
                </v-flex>
              </v-layout>

              <v-layout mb-3>
                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    วันที่จดทะเบียนหาง <span style="color: #EB5757">*</span> :
                  </div>
                  <DatePicker
                    :required="is_trailer"
                    :disabled="!is_trailer"
                    placeholder="DD/MM/YY"
                    hideDetail
                    rulse="fieldRequire"
                    :model="date.trailer_tail_register_date"
                    @update-data="
                      date.trailer_tail_register_date = $event
                        ? customDate($event)
                        : '';
                      trailer.tail_register_date = $event ? $event : '';
                    "
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    วันที่ต่อ พรบ. <span style="color: #EB5757">*</span> :
                  </div>
                  <DatePicker
                    :required="is_trailer"
                    :disabled="!is_trailer"
                    placeholder="DD/MM/YY"
                    hideDetail
                    rulse="fieldRequire"
                    :model="date.trailer_date_per_act"
                    @update-data="
                      date.trailer_date_per_act = $event
                        ? customDate($event)
                        : '';
                      trailer.date_per_act = $event ? $event : '';
                    "
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    วันที่ต่อ ภาษี <span style="color: #EB5757">*</span> :
                  </div>
                  <DatePicker
                    :required="is_trailer"
                    :disabled="!is_trailer"
                    placeholder="DD/MM/YY"
                    hideDetail
                    rulse="fieldRequire"
                    :model="date.trailer_tax_date"
                    @update-data="
                      date.trailer_tax_date = $event ? customDate($event) : '';
                      trailer.tax_date = $event ? $event : '';
                    "
                  />
                </v-flex>
              </v-layout>

              <v-layout mb-3>
                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    บริษัทประกันรถ :
                  </div>
                  <TextInput
                    :disabled="!is_trailer"
                    :model="trailer.insurance_company"
                    rulse="fieldRequire"
                    @update-data="
                      updateData('trailer', 'insurance_company', $event)
                    "
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    ทุนประกัน :
                  </div>
                  <TextInput
                    :disabled="!is_trailer"
                    :model="trailer.insurance_funds"
                    rulse="fieldRequire"
                    @update-data="
                      updateData('trailer', 'insurance_funds', $event)
                    "
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    วันที่เริ่มประกัน <span style="color: #EB5757">*</span> :
                  </div>
                  <DatePicker
                    :required="is_trailer"
                    :disabled="!is_trailer"
                    placeholder="DD/MM/YY"
                    hideDetail
                    rulse="fieldRequire"
                    :model="date.insurance_start_date_trailer"
                    @update-data="
                      date.insurance_start_date_trailer = $event
                        ? customDate($event)
                        : '';
                      trailer.insurance_start_date = $event ? $event : '';
                    "
                  />
                </v-flex>

                <v-flex xs2 pr-3>
                  <div class="font-14 font-weight-black">
                    ประเภทประกันรถ :
                  </div>
                  <AutocompleteInput
                    clearable
                    :disabled="!is_trailer"
                    :model="trailer.car_insurance_type_id"
                    rulse="fieldRequire"
                    :items="carInsuranceTypeList"
                    itemText="car_insurance_type_name"
                    itemValue="car_insurance_type_id"
                    @update-data="
                      updateSelect(
                        'trailer',
                        'car_insurance_type_id',
                        'car_insurance_type_name',
                        $event
                      )
                    "
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </div>
    </v-form>

    <div class="text-end mt-6 mb-16">
      <v-btn
        @click="$router.push('/truck-supply')"
        class="mr-4 font-weight-bold text-capitalize"
        outlined
        width="150"
        color="#4B5768"
      >
        Cancel
      </v-btn>

      <v-btn
        class="mr-4 font-weight-bold text-capitalize"
        width="150"
        :disabled="!valid"
        :dark="valid"
        color="#338A68"
        @click="save"
      >
        Save
      </v-btn>
    </div>

    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="false"
    />
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import TextareaInput from "../../../../components/input/TextareaInput.vue";
import TextInput from "../../../../components/input/TextInput.vue";
import DatePicker from "../../../../components/picker/DatePicker.vue";
import AutocompleteInput from "../../../../components/select/AutocompleteInput.vue";
import { mapState } from "vuex";
export default {
  components: {
    TextInput,
    AutocompleteInput,
    DatePicker,
    TextareaInput,
    Loading,
  },
  data() {
    return {
      selectd_driver: "",
      selectd_truck: "",
      selectd_trailer: "",
      is_driver: false,
      is_truck: false,
      is_trailer: false,
      truck_supply_id: "",
      valid: true,
      isLoading: false,
      fullPage: true,
      titleList: [
        { title_id: 1, title_name: "นาย" },
        { title_id: 2, title_name: "นางสาว" },
        { title_id: 3, title_name: "นาง" },
      ],
      sexList: [
        { sex_id: 1, sex_name: "ชาย" },
        { sex_id: 2, sex_name: "หญิง" },
      ],
      educationList: [
        { education_id: 1, education_name: "ประถม" },
        { education_id: 2, education_name: "มัธยมต้น" },
        { education_id: 3, education_name: "มัธยมปลาย" },
        { education_id: 4, education_name: "ปวช" },
        { education_id: 5, education_name: "ปวส" },
        { education_id: 6, education_name: "ปริญญาตรี" },
        { education_id: 7, education_name: "ปริญญาโท" },
      ],
      statusList: [
        { status_id: 1, status_name: "Active" },
        { status_id: 2, status_name: "Past" },
        { status_id: 3, status_name: "Inactive" },
        { status_id: 4, status_name: "Block" },
      ],
      driverTypeList: [
        { driver_type_id: 1, driver_type_name: "ตัวจริง" },
        { driver_type_id: 2, driver_type_name: "ตัวสำรอง" },
      ],
      bankList: [
        { bank_id: 1, bank_name: "k bank" },
        { bank_id: 2, bank_name: "ktc" },
      ],
      carInsuranceTypeList: [
        { car_insurance_type_id: 1, car_insurance_type_name: "ชั้น1" },
        { car_insurance_type_id: 2, car_insurance_type_name: "ชั้น2" },
        { car_insurance_type_id: 1, car_insurance_type_name: "ประกันชั้น 1" },
        { car_insurance_type_id: 2, car_insurance_type_name: "ประกันชั้น 2" },
        { car_insurance_type_id: 3, car_insurance_type_name: "ประกันชั้น 2+" },
        { car_insurance_type_id: 4, car_insurance_type_name: "ประกันชั้น 3" },
        { car_insurance_type_id: 5, car_insurance_type_name: "ประกันชั้น 3+" },
      ],
      licenseTypeList: [
        { license_type_id: 1, license_type_name: "บ1" },
        { license_type_id: 2, license_type_name: "บ2" },
        { license_type_id: 3, license_type_name: "บ3" },
        { license_type_id: 4, license_type_name: "บ4" },
        { license_type_id: 5, license_type_name: "ท1" },
        { license_type_id: 6, license_type_name: "ท2" },
        { license_type_id: 7, license_type_name: "ท3" },
        { license_type_id: 8, license_type_name: "ท4" },
      ],
      lissuedByList: [
        { lissued_by_id: 1, lissued_by_name: "L Issue By 1" },
        { lissued_by_id: 2, lissued_by_name: "L Issue By 2" },
      ],
      carTypeList: [
        { car_type_id: 1, car_type_name: "รถประเภทย่อย" },
        { car_type_id: 2, car_type_name: "รถพ่วงหัว" },
        { car_type_id: 3, car_type_name: "หัวลาก" },
      ],
      sub_category_vehicles: [
        { car_sub_type_id: 1, car_sub_type_name: "รถ 4 ล้อ" },
        { car_sub_type_id: 2, car_sub_type_name: "รถ 4 ล้อห้องเย็น" },
        { car_sub_type_id: 3, car_sub_type_name: "รถ 4 ล้อตู้ทึบ" },
        { car_sub_type_id: 4, car_sub_type_name: "รถ 6 ล้อ" },
        { car_sub_type_id: 5, car_sub_type_name: "รถ 6 ล้อห้องเย็น" },
        { car_sub_type_id: 6, car_sub_type_name: "รถ 6 ล้อตู้ทึบ" },
        { car_sub_type_id: 7, car_sub_type_name: "รถ 6 ล้อคอกต่ำ" },
        { car_sub_type_id: 8, car_sub_type_name: "รถ 6 ล้อติดเครน" },
        { car_sub_type_id: 9, car_sub_type_name: "รถ 6 ล้อพื้นเรียบ" },
        { car_sub_type_id: 10, car_sub_type_name: "รถ 6 ล้อห้องเย็น" },
        { car_sub_type_id: 11, car_sub_type_name: "รถ 6 ล้อตู้ทึบ" },
        { car_sub_type_id: 12, car_sub_type_name: "รถ 6 ล้อคอกสูง" },
        { car_sub_type_id: 13, car_sub_type_name: "รถ 6 ล้อติดเครน" },
        { car_sub_type_id: 14, car_sub_type_name: "รถ 6 ล้อOpen Top" },
        { car_sub_type_id: 15, car_sub_type_name: "รถ 10 ล้อ" },
        { car_sub_type_id: 16, car_sub_type_name: "รถ 10 ล้อห้องเย็น" },
        { car_sub_type_id: 17, car_sub_type_name: "รถ 10 ล้อตู้ทึบ" },
        { car_sub_type_id: 18, car_sub_type_name: "รถ 10 ล้อคอก" },
        { car_sub_type_id: 19, car_sub_type_name: "รถ 10 ล้อดัมพ" },
        { car_sub_type_id: 20, car_sub_type_name: "รถ 10 ล้อบรรทุก Container" },
        { car_sub_type_id: 21, car_sub_type_name: "รถ 10 ล้อติดเครน" },
        { car_sub_type_id: 22, car_sub_type_name: "รถ 12 ล้อ" },
        { car_sub_type_id: 23, car_sub_type_name: "รถ 12 ล้อพื้นเรียบ" },
        { car_sub_type_id: 24, car_sub_type_name: "รถ 12 ล้อห้องเย็น" },
        { car_sub_type_id: 25, car_sub_type_name: "รถ 12 ล้อตู้ทึบ" },
        { car_sub_type_id: 26, car_sub_type_name: "รถ 12 ล้อคอก" },
        { car_sub_type_id: 27, car_sub_type_name: "รถ 12 ล้อดัมพ" },
        { car_sub_type_id: 28, car_sub_type_name: "รถ 12 ล้อบรรทุก Container" },
        { car_sub_type_id: 29, car_sub_type_name: "รถ 12 ล้อติดเครน" },
      ],
      trailer_head: [
        { car_sub_type_id: 2, car_sub_type_name: "หัวพ่วง 6 ล้อ" },
        { car_sub_type_id: 3, car_sub_type_name: "หัวพ่วง 6 ล้อตู้ทึบ" },
        {
          car_sub_type_id: 4,
          car_sub_type_name: "หัวพ่วง 6 ล้อบรรทุก Container ",
        },
        { car_sub_type_id: 5, car_sub_type_name: "หัวพ่วง 6 ล้อพื้นเรียบ" },
        { car_sub_type_id: 6, car_sub_type_name: "หัวพ่วง 10 ล้อ" },
        { car_sub_type_id: 7, car_sub_type_name: "หัวพ่วง 10 ล้อตู้ทึบ" },
        {
          car_sub_type_id: 8,
          car_sub_type_name: "หัวพ่วง 10 ล้อบรรทุก Container ",
        },
        { car_sub_type_id: 9, car_sub_type_name: "หัวพ่วง 10 ล้อพื้นเรียบ" },
        { car_sub_type_id: 10, car_sub_type_name: "หัวพ่วง 12 ล้อ" },
        { car_sub_type_id: 11, car_sub_type_name: "หัวพ่วง 12 ล้อตู้ทึบ" },
        {
          car_sub_type_id: 12,
          car_sub_type_name: "หัวพ่วง 12 ล้อบรรทุก Container ",
        },
        { car_sub_type_id: 13, car_sub_type_name: "หัวพ่วง 12 ล้อพื้นเรียบ" },
      ],
      towing_head: [
        { car_sub_type_id: 2, car_sub_type_name: "หัวลาก 6 ล้อ" },
        { car_sub_type_id: 3, car_sub_type_name: "หัวลาก 10 ล้อ" },
        { car_sub_type_id: 4, car_sub_type_name: "หัวลาก 12 ล้อ" },
      ],
      carSubTypeList: [],
      provinceList: [],
      tailTypeList: [
        { tail_type_id: 1, tail_type_name: "รถพ่วงหาง" },
        { tail_type_id: 2, tail_type_name: "รถเทรลเลอร์" },
      ],
      tailSubTypeList: [],

      trailer_tail: [
        { tail_sub_type_id: 1, tail_sub_type_name: "หางพ่วง 8 ล้อ" },
        { tail_sub_type_id: 2, tail_sub_type_name: "หางพ่วง 8 ล้อตู้ทึบ" },
        {
          tail_sub_type_id: 3,
          tail_sub_type_name: "หางพ่วง 8 ล้อบรรทุก Container ",
        },
        { tail_sub_type_id: 4, tail_sub_type_name: "หางพ่วง 8 ล้อพื้นเรียบ" },
        { tail_sub_type_id: 5, tail_sub_type_name: "หางพ่วง 12 ล้อ" },
        { tail_sub_type_id: 6, tail_sub_type_name: "หางพ่วง 12 ล้อตู้ทึบ" },
        {
          tail_sub_type_id: 7,
          tail_sub_type_name: "หางพ่วง 12 ล้อบรรทุก Container ",
        },
        { tail_sub_type_id: 8, tail_sub_type_name: "หางพ่วง 12 ล้อพื้นเรียบ" },
      ],
      sub_trailer: [
        {
          tail_sub_type_id: 1,
          tail_sub_type_name: "หางเทรลเลอร์ 2 เพลา(8ล้อ)",
        },
        {
          tail_sub_type_id: 2,
          tail_sub_type_name: "หางเทรลเลอร์ 2 เพลาพื้นเรียบ(8ล้อ)",
        },
        {
          tail_sub_type_id: 3,
          tail_sub_type_name: "หางเทรลเลอร์ 2 เพลาตู้ทึบ(8ล้อ)",
        },
        {
          tail_sub_type_id: 4,
          tail_sub_type_name: "หางเทรลเลอร์ 2 เพลาคอก(8ล้อ)",
        },
        {
          tail_sub_type_id: 5,
          tail_sub_type_name: "หางเทรลเลอร์ 2 เพลาดัมพ์(8ล้อ)",
        },
        {
          tail_sub_type_id: 6,
          tail_sub_type_name: "หางเทรลเลอร์ 2 เพลาบรรทุก Container(8ล้อ)",
        },
        {
          tail_sub_type_id: 7,
          tail_sub_type_name:
            "หางเทรลเลอร์ 2 เพลาบรรทุก Container Reefer(8ล้อ)",
        },
        {
          tail_sub_type_id: 8,
          tail_sub_type_name: "หางเทรลเลอร์ 2 เพลาติดเครน(8ล้อ)",
        },
        {
          tail_sub_type_id: 9,
          tail_sub_type_name: "หางเทรลเลอร์ 2 เพลาlow bed(8ล้อ)",
        },
        {
          tail_sub_type_id: 10,
          tail_sub_type_name: "หางเทรลเลอร์ 3 เพลา(12ล้อ)",
        },
        {
          tail_sub_type_id: 11,
          tail_sub_type_name: "หางเทรลเลอร์ 3 เพลาพื้นเรียบ(12ล้อ)",
        },
        {
          tail_sub_type_id: 12,
          tail_sub_type_name: "หางเทรลเลอร์ 3 เพลาตู้ทึบ(12ล้อ)",
        },
        {
          tail_sub_type_id: 13,
          tail_sub_type_name: "หางเทรลเลอร์ 3 เพลาดัมพ์(12ล้อ)",
        },
        {
          tail_sub_type_id: 14,
          tail_sub_type_name: "หางเทรลเลอร์ 3 เพลาบรรทุก Container(12ล้อ)",
        },
        {
          tail_sub_type_id: 15,
          tail_sub_type_name:
            "หางเทรลเลอร์ 3 เพลาบรรทุก Container Reefer(12ล้อ)",
        },
        {
          tail_sub_type_id: 16,
          tail_sub_type_name: "หางเทรลเลอร์ 3 เพลาติดเครน(12ล้อ)",
        },
        {
          tail_sub_type_id: 17,
          tail_sub_type_name: "หางเทรลเลอร์ 3 เพลาติดเครน(12ล้อ)",
        },
        {
          tail_sub_type_id: 18,
          tail_sub_type_name: "หางเทรลเลอร์ 3 เพลาlow bed(12ล้อ)",
        },
      ],
      driver: {
        driver_id: "",
        driver_code: "",
        title_id: "",
        title_name: "",
        f_name: "",
        l_name: "",
        tel: "",
        age: "",
        sex_id: "",
        sex_name: "",
        n_name: "",
        personal_id: "",
        birth_date: "",
        issue_by_code: "",
        issue_by_name: "",
        personal_id_expri_date: "",
        address1: "",
        address2: "",
        education_id: "",
        education_name: "",
        license_type_id: "",
        license_type_name: "",
        license_no: "",
        lissued_date: "",
        lissued_by_code: "",
        lissued_by_name: "",
        driver_license_expir: "",
        government_history: "",
        working_date: "",
        driving_exp: "",
        latest_work_place: "",
        reason_resignation: "",
        work_exp: "",
        account_number: "",
        bank_id: "",
        bank_name: "",
        carrier_id: "",
        carrier_code: "",
        contact_person: "",
        contact_rel: "",
        contact_tel: "",
        email: "",
        line_id: "",
        status_id: "",
        status_name: "",
        driver_type_id: "",
        driver_type_name: "",
        note: "",
      },
      carrier_name: "",
      carrier_id: "",
      truck: {
        truck_id: "",
        car_code: "",
        plate_no: "",
        province_code: "",
        province_name: "",
        car_brand: "",
        car_type_id: "",
        car_type_name: "",
        car_sub_type_id: "",
        car_sub_type_name: "",
        car_body_code: "",
        car_engine_code: "",
        car_year: "",
        car_weight: "",
        register_date: "",
        date_per_act: "",
        tax_date: "",
        finance_company: "",
        hire_purchase_no: "",
        vender_gps: "",
        gps_box: "",
        phone_gps: "",
        gps_contract: "",
        gps_install_date: "",
        gps_contract_expir_date: "",
        insurance_company: "",
        product_insurance_limit: "",
        protect_start_date: "",
        car_insurance_company: "",
        insurance_funds: "",
        insurance_start_date: "",
        car_insurance_type_id: "",
        car_insurance_type_name: "",
      },
      trailer: {
        trailer_id: "",
        tail_code: "",
        plate_no: "",
        province_code: "",
        province_name: "",
        tail_brand: "",
        tail_type_id: "",
        tail_type_name: "",
        tail_sub_type_id: "",
        tail_sub_type_name: "",
        tail_register_date: "",
        date_per_act: "",
        tax_date: "",
        insurance_company: "",
        insurance_funds: "",
        insurance_start_date: "",
        car_insurance_type_id: "",
        car_insurance_type_name: "",
      },
      date: {
        birth_date: "",
        personal_id_expri_date: "",
        lissued_date: "",
        driver_license_expir: "",
        working_date: "",
        car_year: "",
        register_date: "",
        truck_date_per_act: "",
        trailer_date_per_act: "",
        tax_date: "",
        trailer_tax_date: "",
        gps_install_date: "",
        gps_contract_expir_date: "",
        protect_start_date: "",
        insurance_start_date_truck: "",
        insurance_start_date_trailer: "",
        tail_register_date: "",
        trailer_tail_register_date: "",
      },
      driverList: [],
      truckList: [],
      trailerList: [],
      carrierList: [],
      driverSelected: null,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  watch: {
    is_driver: {
      handler: function(newValue) {
        if (!newValue) {
          this.selectd_driver = "";
          this.driver = {
            driver_id: null,
            driver_code: null,
            title_id: null,
            title_name: null,
            f_name: null,
            l_name: null,
            tel: null,
            age: null,
            sex_id: null,
            sex_name: null,
            n_name: null,
            personal_id: null,
            birth_date: null,
            issue_by_code: null,
            issue_by_name: null,
            personal_id_expri_date: null,
            address1: null,
            address2: null,
            education_id: null,
            education_name: null,
            license_type_id: null,
            license_type_name: null,
            license_no: null,
            lissued_date: null,
            lissued_by_code: null,
            lissued_by_name: null,
            driver_license_expir: null,
            government_history: null,
            working_date: null,
            driving_exp: null,
            latest_work_place: null,
            reason_resignation: null,
            work_exp: null,
            account_number: null,
            bank_id: null,
            bank_name: null,
            carrier_id: null,
            carrier_code: null,
            contact_person: null,
            contact_rel: null,
            contact_tel: null,
            email: null,
            line_id: null,
            status_id: null,
            status_name: null,
            driver_type_id: null,
            driver_type_name: null,
            note: null,
          };
          this.date.birth_date = null;
          this.date.personal_id_expri_date = "";
          this.date.lissued_date = "";
          this.date.driver_license_expir = "";
          this.date.working_date = "";
        } else {
          this.valid = true;
        }
      },
    },
    is_truck: {
      handler: function(newValue) {
        if (!newValue) {
          this.selectd_truck = "";
          this.truck = {
            truck_id: "",
            car_code: "",
            plate_no: "",
            province_code: "",
            province_name: "",
            car_brand: "",
            car_type_id: "",
            car_type_name: "",
            car_sub_type_id: "",
            car_sub_type_name: "",
            car_body_code: "",
            car_engine_code: "",
            car_year: "",
            car_weight: "",
            register_date: "",
            date_per_act: "",
            tax_date: "",
            finance_company: "",
            hire_purchase_no: "",
            vender_gps: "",
            gps_box: "",
            phone_gps: "",
            gps_contract: "",
            gps_install_date: "",
            gps_contract_expir_date: "",
            insurance_company: "",
            product_insurance_limit: "",
            protect_start_date: "",
            car_insurance_company: "",
            insurance_funds: "",
            insurance_start_date: "",
            car_insurance_type_id: "",
            car_insurance_type_name: "",
          };
          this.date.car_year = "";
          this.date.register_date = "";
          this.date.truck_date_per_act = "";
          this.date.tax_date = "";
          this.date.gps_install_date = "";
          this.date.gps_contract_expir_date = "";
          this.date.protect_start_date = "";
          this.date.insurance_start_date_truck = "";
        } else {
          this.valid = true;
        }
      },
    },
    is_trailer: {
      handler: function(newValue) {
        if (!newValue) {
          this.selectd_trailer = "";
          this.trailer = {
            trailer_id: "",
            tail_code: "",
            plate_no: "",
            province_code: "",
            province_name: "",
            tail_brand: "",
            tail_type_id: "",
            tail_type_name: "",
            tail_sub_type_id: "",
            tail_sub_type_name: "",
            tail_register_date: "",
            date_per_act: "",
            tax_date: "",
            insurance_company: "",
            insurance_funds: "",
            insurance_start_date: "",
            car_insurance_type_id: "",
            car_insurance_type_name: "",
          };
          this.date.trailer_tail_register_date = "";
          this.date.trailer_date_per_act = "";
          this.date.trailer_tax_date = "";
          this.date.insurance_start_date_trailer = "";
        } else {
          this.valid = true;
        }
      },
    },
    driver: {
      handler: function(newValue) {
        var d = new Date();
        var n = d.getFullYear();
        if (newValue.birth_date) {
          this.driver.age = n - parseInt(newValue.birth_date.substring(0, 4));
        }
        if (newValue.working_date) {
          let work_exp = n - parseInt(newValue.working_date.substring(0, 4));
          this.driver.work_exp = work_exp > 0 ? work_exp : 1;
        }
      },
      deep: true,
    },
    truck: {
      handler: function(val, oldVal) {
        if (val && val.car_type_id) {
          if (val.car_type_id == 1) {
            this.carSubTypeList = this.sub_category_vehicles;
          } else if (val.car_type_id == 2) {
            this.carSubTypeList = this.trailer_head;
          } else {
            this.carSubTypeList = this.towing_head;
          }
        }
      },
      deep: true,
    },
    trailer: {
      handler: function(val, oldVal) {
        if (val.tail_type_id) {
          if (val.tail_type_id == 1) {
            this.tailSubTypeList = this.trailer_tail;
          } else {
            this.tailSubTypeList = this.sub_trailer;
          }
        }
      },
      deep: true,
    },
  },
  created() {
    if (this.$route.name === "Edit Truck Supply") {
      this.isLoading = true;
      Promise.all([this.getProvince(), this.getDataById(this.$route.params.id)])
        .then((values) => {
          this.isLoading = false;
          this.getDataList();
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    } else {
      this.getDataList();
      this.isLoading = true;
      Promise.all([this.getProvince()])
        .then((values) => {
          this.isLoading = false;

          // Set Carrier From carrier role
          if (this.user.user_group_id == 3) {
            this.driver.carrier_id = this.user.carrier
              ? this.user.carrier.carrier_id
              : "";
            this.driver.carrier_code = this.user.carrier
              ? this.user.carrier.name
              : "";
            this.carrier_name = this.user.carrier ? this.user.carrier.name : "";
            this.carrier_id = this.user.carrier
              ? this.user.carrier.carrier_id
              : "";
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    }
  },
  methods: {
    async getDataById(id) {
      const res = await this.$store.dispatch("truck_supply/getDataById", id);
      this.truck_supply_id = res.data.truck_supply_id;
      // Map Company from carrier
      // this.driver.carrier_id = res.data.carrier.carrier_id;
      // this.driver.carrier_code = res.data.carrier.code;
      // this.carrier_name = res.data.carrier ? res.data.carrier.name : "";
      // this.carrier_id = res.data.carrier ? res.data.carrier.carrier_id : "";
      // Map Driver
      this.mapDataDriver(res.data.drivers_edit);
      // Map Truck
      this.mapDataTruck(res.data.truck_edit);
      // Map Trailer
      this.mapDataTrailer(res.data.trailer_edit);
      return res.data;
    },
    async getProvince() {
      const res = await this.$store.dispatch("master_data/getProvince");
      this.provinceList = res.data;
      return res.data;
    },
    getDataList() {
      this.$store.dispatch("master_data/getDataList").then((res) => {
        this.driverList = res.data.drivers.map((data) => ({
          ...data,
          fullname: data.f_name + " " + data.l_name,
        }));
        this.truckList = res.data.trucks;
        this.trailerList = res.data.trailers;
        this.carrierList = res.data.carriers;
      });
    },
    save() {
      if (this.is_driver || this.is_truck || this.is_trailer) {
        if (this.$refs.form.validate()) {
          this.driver.fullname = undefined;
          // Create
          if (this.$route.name === "Create Truck Supply") {
            this.driver.driver_id = "";
            this.truck.truck_id = "";
            this.trailer.trailer_id = "";
            this.isLoading = true;
            this.$store
              .dispatch("truck_supply/save", {
                user_id: this.user.id,
                driver: this.driver,
                truck: this.truck,
                trailer: this.trailer,
                carrier_name: this.carrier_name,
                carrier_id: this.carrier_id,
                is_driver: this.is_driver,
                is_truck: this.is_truck,
                is_trailer: this.is_trailer,
              })
              .then((res) => {
                this.isLoading = false;
                if (!res.data.success) {
                  for (const data in res.data.data.error) {
                    this.$swal.fire({
                      icon: "error",
                      title: "Save Failed",
                      text: res.data.data.error[data][0],
                    });
                  }
                } else {
                  this.$swal
                    .fire({
                      icon: "success",
                      title: "Save Truck Supply successfully!",
                      showConfirmButton: false,
                      timer: 1500,
                    })
                    .then((result) => {
                      this.$router.push("/truck-supply");
                    });
                }
              })
              .catch((error) => {
                this.isLoading = false;
                console.log(error.response.data.data);
                this.$swal.fire({
                  icon: "error",
                  title: "Save Failed",
                  text: "กรุณาตรวจสอบฟอร์ม Truck Supply",
                });
              });
          }
          //  Update
          else {
            this.isLoading = true;
            this.$store
              .dispatch("truck_supply/update", {
                id: this.truck_supply_id,
                user_id: this.user.id,
                driver: this.driver,
                truck: this.truck,
                trailer: this.trailer,
                is_driver: this.is_driver,
                is_truck: this.is_truck,
                is_trailer: this.is_trailer,
              })
              .then((res) => {
                this.isLoading = false;
                if (!res.data.success) {
                  for (const data in res.data.data.error) {
                    this.$swal.fire({
                      icon: "error",
                      title: "Save Failed",
                      text: res.data.data.error[data][0],
                    });
                  }
                } else {
                  this.$swal
                    .fire({
                      icon: "success",
                      title: "Save Truck Supply successfully!",
                      showConfirmButton: false,
                      timer: 1500,
                    })
                    .then((result) => {
                      this.$router.push("/truck-supply");
                    });
                }
              })
              .catch((error) => {
                this.isLoading = false;
                console.log(error.response.data.data);
                this.$swal.fire({
                  icon: "error",
                  title: "Save Failed",
                  text: "กรุณาตรวจสอบฟอร์ม Truck Supply",
                });
              });
          }
        }
      } else {
        this.$swal.fire({
          title: "Something went wrong!",
          text: "Please, Input Form",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      }
    },
    updateData(name, text, val) {
      this[name][text] = val;
    },
    updateProvinceSelect(name, keyId, keyName, val) {
      this[name][keyId] = val.code;
      this[name][keyName] = val.name_th;
    },
    updateSelect(name, keyId, keyName, val) {
      // Check Clear select
      if (keyId === "car_type_id") {
        if (this.truck.car_type_id != (val ? val[keyId] : "")) {
          this.truck.car_sub_type_id = "";
          this.truck.car_sub_type_name = "";
        }
      }
      if (keyId === "tail_type_id") {
        if (this.trailer.tail_type_id != (val ? val[keyId] : "")) {
          this.trailer.tail_sub_type_id = "";
          this.trailer.tail_sub_type_name = "";
        }
      }

      // Map value
      this[name][keyId] = val ? val[keyId] : "";
      this[name][keyName] = val ? val[keyName] : "";
    },
    customDate(val) {
      let sub_year = val.substring(2, 4);
      let sub_month = val.substring(5, 7);
      let sub_day = val.substring(8, 10);
      let changeDate = sub_day + "/" + sub_month + "/" + sub_year;
      return changeDate;
    },
    mapDataDriver(data) {
      this.is_driver = data && data.personal_id ? true : false;
      this.driver = data ? data : this.driver;
      this.date.birth_date =
        data && data.birth_date ? this.customDate(data.birth_date) : "";
      this.date.personal_id_expri_date =
        data && data.personal_id_expri_date
          ? this.customDate(data.personal_id_expri_date)
          : "";
      this.date.lissued_date =
        data && data.lissued_date ? this.customDate(data.lissued_date) : "";
      this.date.driver_license_expir =
        data && data.driver_license_expir
          ? this.customDate(data.driver_license_expir)
          : "";
      this.date.working_date =
        data && data.working_date ? this.customDate(data.working_date) : "";
    },
    mapDataTruck(data) {
      this.is_truck = data && data.plate_no ? true : false;
      if (data) {
        this.truck = data;
        this.date.car_year =
          data && data.car_year ? this.customDate(data.car_year) : "";
        this.date.register_date =
          data && data.register_date ? this.customDate(data.register_date) : "";
        this.date.truck_date_per_act =
          data && data.date_per_act ? this.customDate(data.date_per_act) : "";
        this.date.tax_date =
          data && data.tax_date ? this.customDate(data.tax_date) : "";
        this.date.gps_install_date =
          data && data.gps_install_date
            ? this.customDate(data.gps_install_date)
            : "";
        this.date.gps_contract_expir_date =
          data && data.gps_contract_expir_date
            ? this.customDate(data.gps_contract_expir_date)
            : "";
        this.date.protect_start_date =
          data && data.protect_start_date
            ? this.customDate(data.protect_start_date)
            : "";
        this.date.insurance_start_date_truck =
          data && data.insurance_start_date
            ? this.customDate(data.insurance_start_date)
            : "";
      }
    },
    mapDataTrailer(data) {
      this.is_trailer = data && data.plate_no ? true : false;
      if (data) {
        this.trailer = data;
        this.trailer.province_code =
          data && data.province_code ? data.province_code.toString() : "";
        this.date.trailer_tail_register_date =
          data && data.tail_register_date
            ? this.customDate(data.tail_register_date)
            : "";
        this.date.trailer_date_per_act =
          data && data.date_per_act ? this.customDate(data.date_per_act) : "";
        this.date.trailer_tax_date =
          data && data.tax_date ? this.customDate(data.tax_date) : "";
        this.date.insurance_start_date_trailer =
          data && data.insurance_start_date
            ? this.customDate(data.insurance_start_date)
            : "";
      }
    },
  },
};
</script>

<style></style>
