<template>
  <div class="mx-8">
    <div class="mb-4 mt-1 d-flex justify-start align-center">
      <img
        width="25"
        src="../../../assets/images/menu-icons/NewspaperColor.png"
        alt=""
      />
      <span class="ml-2 font-weight-bold">ค่าผ่านลาน และค่ายกตู้</span>
    </div>

    <v-layout>
      <v-flex style="width: 1px">
        <div>
          <!-- Main Table -->
          <v-data-table
            :headers="headers"
            :items="items"
            hide-default-footer
            item-key="lan_pass_lifting_fee_id"
            class="elevation-1"
            show-expand
          >
            <!-- Header Table -->
            <template v-slot:top>
              <v-card flat tile color="#C4C4C4">
                <v-form class="pa-6" ref="form" v-model="valid" lazy-validation>
                  <v-layout>
                    <v-flex>
                      <v-layout wrap>
                        <v-flex shrink mt-6 mr-4>
                          <v-btn color="#338A68" dark @click="save(form)">
                            <v-icon class="mr-2"
                              >mdi-plus-circle-outline</v-icon
                            >
                            <span class="text-capitalize font-weight-black"
                              >Add</span
                            >
                          </v-btn>
                        </v-flex>
                        <v-flex xs1 class="px-1">
                          <label for="" class="font-12 font-weight-black">
                            CY Place<span style="color: red">*</span> :
                          </label>
                          <AutocompleteInput
                            hideDetails
                            :model="form.cy_place_id"
                            rulse="fieldRequire"
                            :items="cyPlaceList"
                            itemText="cy_place_name"
                            itemValue="cy_place_id"
                            @update-data="onHandleCyPlace($event)"
                          />
                        </v-flex>

                        <v-flex xs1 class="px-1">
                          <label for="" class="font-12 font-weight-black">
                            Zone :
                          </label>
                          <TextInput
                            readonly
                            hideDetails
                            :model="form.zone_name"
                            rulse="fieldRequire"
                            @update-data="form.zone_name = $event"
                          />
                        </v-flex>

                        <v-spacer> </v-spacer>

                        <v-flex xs1 class="px-1">
                          <label for="" class="font-12 font-weight-black">
                            ค่าผ่านลาน :
                          </label>
                          <TextInput
                            type="number"
                            hideDetails
                            :model="form.lan_pass"
                            rulse="fieldRequire"
                            @update-data="form.lan_pass = $event"
                          />
                        </v-flex>

                        <v-flex xs1 class="px-1">
                          <label for="" class="font-12 font-weight-black">
                            หน่วย :
                          </label>
                          <AutocompleteInput
                            hideDetails
                            :model="form.unit_id"
                            rulse="fieldRequire"
                            :items="unitList"
                            itemText="unit_name"
                            itemValue="unit_id"
                            @update-data="
                              form.unit_id = $event ? $event.unit_id : '';
                              form.unit_name = $event ? $event.unit_name : '';
                            "
                          />
                        </v-flex>

                        <v-flex xs1 class="px-1">
                          <label for="" class="font-12 font-weight-black">
                            ค่ายกตู้ EX20’ :
                          </label>
                          <TextInput
                            type="number"
                            hideDetails
                            :model="form.lifting_fee_ex20"
                            rulse="fieldRequire"
                            @update-data="form.lifting_fee_ex20 = $event"
                          />
                        </v-flex>

                        <v-flex xs1 class="px-1">
                          <label for="" class="font-12 font-weight-black">
                            ค่ายกตู้ EX40’ :
                          </label>
                          <TextInput
                            type="number"
                            hideDetails
                            :model="form.lifting_fee_ex40"
                            rulse="fieldRequire"
                            @update-data="form.lifting_fee_ex40 = $event"
                          />
                        </v-flex>

                        <v-flex xs1 class="px-1">
                          <label for="" class="font-12 font-weight-black">
                            ค่ายกตู้ EX45’ :
                          </label>
                          <TextInput
                            type="number"
                            hideDetails
                            :model="form.lifting_fee_ex45"
                            rulse="fieldRequire"
                            @update-data="form.lifting_fee_ex45 = $event"
                          />
                        </v-flex>

                        <v-flex xs1 class="px-1">
                          <label for="" class="font-12 font-weight-black">
                            ค่ายกตู้ IM20’ :
                          </label>
                          <TextInput
                            type="number"
                            hideDetails
                            :model="form.lifting_fee_im20"
                            rulse="fieldRequire"
                            @update-data="form.lifting_fee_im20 = $event"
                          />
                        </v-flex>

                        <v-flex xs1 class="px-1">
                          <label for="" class="font-12 font-weight-black">
                            ค่ายกตู้ IM40’ :
                          </label>
                          <TextInput
                            type="number"
                            hideDetails
                            :model="form.lifting_fee_im40"
                            rulse="fieldRequire"
                            @update-data="form.lifting_fee_im40 = $event"
                          />
                        </v-flex>

                        <v-flex xs1 class="px-1">
                          <label for="" class="font-12 font-weight-black">
                            ค่ายกตู้ IM45’ :
                          </label>
                          <TextInput
                            type="number"
                            hideDetails
                            :model="form.lifting_fee_im45"
                            rulse="fieldRequire"
                            @update-data="form.lifting_fee_im45 = $event"
                          />
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-card>
            </template>

            <!-- Custom Main Table Item rows -->
            <template v-slot:item="{ item, index, expand, isExpanded }">
              <!-- Edit -->
              <tr v-if="isEdit && item.lan_pass_lifting_fee_id == itemId">
                <td class="text-center">
                  <v-icon v-if="!isExpanded" @click="expand(!isExpanded)">
                    mdi-chevron-down
                  </v-icon>
                  <v-icon v-else @click="expand(!isExpanded)"
                    >mdi-chevron-up</v-icon
                  >
                </td>
                <td class="text-center ">
                  <v-layout class="justify-center">
                    <v-flex shrink>
                      <v-btn
                        fab
                        depressed
                        small
                        text
                        @click="cancle(item, index)"
                      >
                        <v-icon mx-2 color="red">
                          mdi-close-circle-outline
                        </v-icon>
                      </v-btn>
                    </v-flex>
                    <v-flex shrink>
                      <v-btn fab depressed small text @click="update(item)">
                        <v-icon color="#338A68" class="mx-2">
                          mdi-content-save-outline
                        </v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </td>
                <td class=" border-left">{{ item.no }}</td>
                <td class="">
                  {{ item.cy_place_name ? item.cy_place_name : "--" }}
                </td>
                <td class="">{{ item.zone_name ? item.zone_name : "--" }}</td>
                <td class="">{{ item.agent_name ? item.agent_name : "--" }}</td>
                <td class="text-center">
                  <TextInput
                    type="number"
                    hideDetails
                    :model="item.lan_pass"
                    rulse="fieldRequire"
                    @update-data="item.lan_pass = $event"
                  />
                </td>
                <td class="text-center">
                  <AutocompleteInput
                    hideDetails
                    :model="item.unit_id"
                    rulse="fieldRequire"
                    :items="unitList"
                    itemText="unit_name"
                    itemValue="unit_id"
                    @update-data="
                      item.unit_id = $event ? $event.unit_id : '';
                      item.unit_name = $event ? $event.unit_name : '';
                    "
                  />
                </td>
                <td class="text-center">
                  <TextInput
                    hideDetails
                    :model="item.lifting_fee_ex20"
                    rulse="fieldRequire"
                    @update-data="item.lifting_fee_ex20 = $event"
                  />
                </td>
                <td class="text-center">
                  <TextInput
                    type="number"
                    hideDetails
                    :model="item.lifting_fee_ex40"
                    rulse="fieldRequire"
                    @update-data="item.lifting_fee_ex40 = $event"
                  />
                </td>
                <td class="text-center">
                  <TextInput
                    type="number"
                    hideDetails
                    :model="item.lifting_fee_ex45"
                    rulse="fieldRequire"
                    @update-data="item.lifting_fee_ex45 = $event"
                  />
                </td>
                <td class="text-center">
                  <TextInput
                    type="number"
                    hideDetails
                    :model="item.lifting_fee_im20"
                    rulse="fieldRequire"
                    @update-data="item.lifting_fee_im20 = $event"
                  />
                </td>
                <td class="text-center">
                  <TextInput
                    type="number"
                    hideDetails
                    :model="item.lifting_fee_im40"
                    rulse="fieldRequire"
                    @update-data="item.lifting_fee_im40 = $event"
                  />
                </td>
                <td class="text-center">
                  <TextInput
                    type="number"
                    hideDetails
                    :model="item.lifting_fee_im45"
                    rulse="fieldRequire"
                    @update-data="item.lifting_fee_im45 = $event"
                  />
                </td>
              </tr>

              <!-- Show -->
              <tr v-else>
                <td class="text-center">
                  <v-icon v-if="!isExpanded" @click="expand(!isExpanded)">
                    mdi-chevron-down
                  </v-icon>
                  <v-icon v-else @click="expand(!isExpanded)"
                    >mdi-chevron-up</v-icon
                  >
                </td>
                <td class="text-center">
                  <v-layout class="justify-lg-space-around">
                    <v-flex shrink>
                      <v-btn
                        fab
                        depressed
                        small
                        text
                        :disabled="isEdit"
                        @click="deleteItem(item, index)"
                      >
                        <v-icon>
                          mdi-delete-outline
                        </v-icon>
                      </v-btn>
                    </v-flex>
                    <v-flex shrink>
                      <v-btn
                        fab
                        depressed
                        small
                        :disabled="isEdit"
                        text
                        @click="edit(item, index)"
                      >
                        <v-icon color="#62C4E3">
                          mdi-pencil-outline
                        </v-icon>
                      </v-btn>
                    </v-flex>
                    <v-flex shrink>
                      <v-btn
                        fab
                        depressed
                        small
                        :disabled="isEdit"
                        text
                        @click="add(item, index)"
                      >
                        <v-icon color="#338A68">
                          mdi-plus-circle-outline
                        </v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </td>
                <td style="width: 150px" class="border-left">{{ item.no }}</td>
                <td class="">
                  {{ item.cy_place_name ? item.cy_place_name : "--" }}
                </td>
                <td class="">{{ item.zone_name ? item.zone_name : "--" }}</td>
                <td class="">{{ item.agent_name ? item.agent_name : "--" }}</td>
                <td class="text-center">
                  {{ item.lan_pass ? item.lan_pass : "--" }}
                </td>
                <td class="text-center">
                  {{ item.unit_name ? item.unit_name : "--" }}
                </td>
                <td class="text-center">
                  {{ item.lifting_fee_ex20 ? item.lifting_fee_ex20 : "--" }}
                </td>
                <td class="text-center">
                  {{ item.lifting_fee_ex40 ? item.lifting_fee_ex40 : "--" }}
                </td>
                <td class="text-center">
                  {{ item.lifting_fee_ex45 ? item.lifting_fee_ex45 : "--" }}
                </td>
                <td class="text-center">
                  {{ item.lifting_fee_im20 ? item.lifting_fee_im20 : "--" }}
                </td>
                <td class="text-center">
                  {{ item.lifting_fee_im40 ? item.lifting_fee_im40 : "--" }}
                </td>
                <td class="text-center">
                  {{ item.lifting_fee_im45 ? item.lifting_fee_im45 : "--" }}
                </td>
              </tr>
            </template>

            <!-- Table expanded -->
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <!-- Child Table -->
                <v-data-table
                  :headers="headers"
                  :items="item.child_data"
                  item-key="lan_pass_lifting_fee_id"
                  class="elevation-1 table-child"
                  hide-default-footer
                  items-per-page="999999"
                >
                  <!-- <template v-slot:header.zone="{ header }"> </template>
                  <template v-slot:header.no="{ header }"> </template>
                  <template v-slot:header.cy_place="{ header }"> </template> -->
                  <!-- Custom Main Table Item rows -->
                  <template v-slot:item="{ item, index }">
                    <!-- Add / Edit -->
                    <tr
                      v-if="
                        isEditChild &&
                          mainId === item.lan_pass_lifting_fee_id &&
                          index === childIndex
                      "
                    >
                      <td style="width: 56px"></td>
                      <td class="text-center">
                        <v-layout class=" justify-center">
                          <v-flex shrink>
                            <v-btn
                              fab
                              depressed
                              small
                              text
                              @click="cancle(item, index)"
                            >
                              <v-icon mx-2 color="red">
                                mdi-close-circle-outline
                              </v-icon>
                            </v-btn>
                          </v-flex>
                          <v-flex shrink>
                            <v-btn
                              v-if="isAdd"
                              fab
                              depressed
                              small
                              text
                              @click="save(item)"
                            >
                              <v-icon color="#338A68" class="mx-2">
                                mdi-content-save-outline
                              </v-icon>
                            </v-btn>
                            <v-btn
                              v-else
                              fab
                              depressed
                              small
                              text
                              @click="update(item)"
                            >
                              <v-icon color="#338A68" class="mx-2">
                                mdi-content-save-outline
                              </v-icon>
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </td>
                      <td class=" border-left"></td>
                      <td class=""></td>
                      <td class=""></td>
                      <td class="pa-0 ma-0">
                        <AutocompleteInput
                          hideDetails
                          :model="item.shipping_agent_id"
                          rulse="fieldRequire"
                          :items="agentList"
                          itemText="shipping_agent_name"
                          itemValue="shipping_agent_id"
                          @update-data="
                            item.shipping_agent_id = $event
                              ? $event.shipping_agent_id
                              : '';
                            item.shipping_agent_name = $event
                              ? $event.shipping_agent_name
                              : '';
                          "
                        />
                      </td>
                      <td class="text-center">
                        <TextInput
                          type="number"
                          hideDetails
                          :model="item.lan_pass"
                          rulse="fieldRequire"
                          @update-data="item.lan_pass = $event"
                        />
                      </td>
                      <td class="text-center">
                        <AutocompleteInput
                          hideDetails
                          :model="item.unit_id"
                          rulse="fieldRequire"
                          :items="unitList"
                          itemText="unit_name"
                          itemValue="unit_id"
                          @update-data="
                            item.unit_id = $event ? $event.unit_id : '';
                            item.unit_name = $event ? $event.unit_name : '';
                          "
                        />
                      </td>
                      <td class="text-center">
                        <TextInput
                          type="number"
                          hideDetails
                          :model="item.lifting_fee_ex20"
                          rulse="fieldRequire"
                          @update-data="item.lifting_fee_ex20 = $event"
                        />
                      </td>
                      <td class="text-center">
                        <TextInput
                          type="number"
                          hideDetails
                          :model="item.lifting_fee_ex40"
                          rulse="fieldRequire"
                          @update-data="item.lifting_fee_ex40 = $event"
                        />
                      </td>
                      <td class="text-center">
                        <TextInput
                          type="number"
                          hideDetails
                          :model="item.lifting_fee_ex45"
                          rulse="fieldRequire"
                          @update-data="item.lifting_fee_ex45 = $event"
                        />
                      </td>
                      <td class="text-center">
                        <TextInput
                          type="number"
                          hideDetails
                          :model="item.lifting_fee_im20"
                          rulse="fieldRequire"
                          @update-data="item.lifting_fee_im20 = $event"
                        />
                      </td>
                      <td class="text-center">
                        <TextInput
                          type="number"
                          hideDetails
                          :model="item.lifting_fee_im40"
                          rulse="fieldRequire"
                          @update-data="item.lifting_fee_im40 = $event"
                        />
                      </td>
                      <td class="text-center">
                        <TextInput
                          type="number"
                          hideDetails
                          :model="item.lifting_fee_im45"
                          rulse="fieldRequire"
                          @update-data="item.lifting_fee_im45 = $event"
                        />
                      </td>
                    </tr>

                    <!-- Show -->
                    <tr v-else>
                      <td style="width: 56px"></td>
                      <td class="text-center">
                        <v-layout class=" justify-center">
                          <v-flex shrink>
                            <v-btn
                              :disabled="isEditChild"
                              fab
                              depressed
                              small
                              text
                              @click="deleteItem(item, index)"
                            >
                              <v-icon>
                                mdi-delete-outline
                              </v-icon>
                            </v-btn>
                          </v-flex>
                          <v-flex shrink>
                            <v-btn
                              class="pa-0 ma-0"
                              fab
                              depressed
                              small
                              text
                              :disabled="isEditChild"
                              @click="editChild(item, index)"
                            >
                              <v-icon color="#62C4E3">
                                mdi-pencil-outline
                              </v-icon>
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </td>
                      <td class=" border-left"></td>
                      <td class=""></td>
                      <td class=""></td>
                      <td class="">
                        {{
                          item.shipping_agent_name
                            ? item.shipping_agent_name
                            : "--"
                        }}
                      </td>
                      <td class="text-center">
                        {{ item.lan_pass ? item.lan_pass : "--" }}
                      </td>
                      <td class="text-center">
                        {{ item.unit_name ? item.unit_name : "--" }}
                      </td>
                      <td class="text-center">
                        {{
                          item.lifting_fee_ex20 ? item.lifting_fee_ex20 : "--"
                        }}
                      </td>
                      <td class="text-center">
                        {{
                          item.lifting_fee_ex40 ? item.lifting_fee_ex40 : "--"
                        }}
                      </td>
                      <td class="text-center">
                        {{
                          item.lifting_fee_ex45 ? item.lifting_fee_ex45 : "--"
                        }}
                      </td>
                      <td class="text-center">
                        {{
                          item.lifting_fee_im20 ? item.lifting_fee_im20 : "--"
                        }}
                      </td>
                      <td class="text-center">
                        {{
                          item.lifting_fee_im40 ? item.lifting_fee_im40 : "--"
                        }}
                      </td>
                      <td class="text-center">
                        {{
                          item.lifting_fee_im45 ? item.lifting_fee_im45 : "--"
                        }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </td>
            </template>
          </v-data-table>

          <v-layout mt-4>
            <v-flex>
              <Paginate
                :current_page="paginate.current_page"
                :last_page="paginate.last_page"
                :nextPage="onHandlePaginate"
              />
            </v-flex>
          </v-layout>

          <Loading
            :active.sync="isLoading"
            :can-cancel="false"
            :is-full-page="fullPage"
          />
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import TextInput from "../../../components/input/TextInput.vue";
import Paginate from "../../../components/pagination/Paginate.vue";
import AutocompleteInput from "../../../components/select/AutocompleteInput.vue";
export default {
  data() {
    return {
      mainId: "",
      childIndex: "",
      valid: true,
      isEdit: false,
      isEditChild: false,
      itemId: null,
      headers: [
        {
          text: "Action",
          value: "action",
          align: "center",
          sortable: false,
          width: "160px",
          class: "font-weight-bold",
        },
        {
          text: "No.",
          value: "no",
          align: "start",
          sortable: false,
          width: "50px",
          class: "font-weight-bold border-left",
        },
        {
          text: "CY Place",
          value: "cy_place",
          align: "start",
          sortable: false,
          width: "250px",
          class: "font-weight-bold ",
        },
        {
          text: "Zone",
          value: "zone",
          align: "start",
          sortable: false,
          width: "100px",
          class: "font-weight-bold ",
        },
        {
          text: "Agent",
          value: "agent",
          align: "start",
          sortable: false,
          width: "250px",
          class: "font-weight-bold ",
        },
        {
          text: "ค่าผ่านลาน",
          align: "center",
          sortable: false,
          width: "100px",
          class: "font-weight-bold ",
        },
        {
          text: "หน่วย",
          align: "center",
          sortable: false,
          width: "150px",
          class: "font-weight-bold ",
        },
        {
          text: "ค่ายกตู้ EX20’",
          align: "center",
          sortable: false,
          width: "100px",
          class: "font-weight-bold ",
        },
        {
          text: "ค่ายกตู้ EX40’",
          align: "center",
          sortable: false,
          width: "100px",
          class: "font-weight-bold ",
        },
        {
          text: "ค่ายกตู้ EX45’",
          align: "center",
          sortable: false,
          width: "100px",
          class: "font-weight-bold ",
        },
        {
          text: "ค่ายกตู้ IM20’",
          align: "center",
          sortable: false,
          width: "100px",
          class: "font-weight-bold ",
        },
        {
          text: "ค่ายกตู้ IM40’",
          align: "center",
          sortable: false,
          width: "100px",
          class: "font-weight-bold ",
        },
        {
          text: "ค่ายกตู้ IM45’",
          align: "start",
          sortable: false,
          width: "100px",
          class: "font-weight-bold ",
        },
      ],
      items: [],
      cyPlaceList: [],
      agentList: [],
      form: {
        cy_place_id: "",
        cy_place_name: "",
        zone_id: "",
        zone_name: "",
        lan_pass: "",
        unit_id: "",
        unit_name: "",
        shipping_agent_id: "",
        shipping_agent_name: "",
        lifting_fee_ex20: "",
        lifting_fee_ex40: "",
        lifting_fee_ex45: "",
        lifting_fee_im20: "",
        lifting_fee_im40: "",
        lifting_fee_im45: "",
      },
      paginate: {
        current_page: 1,
        from: 1,
        last_page: 1,
        per_page: 1,
        total: 1,
        to: 1,
      },
      isLoading: false,
      fullPage: true,
      unitList: [
        { unit_id: 1, unit_name: "ตู้" },
        { unit_id: 2, unit_name: "เที่ยว" },
      ],
    };
  },
  components: { AutocompleteInput, TextInput, Paginate, Loading },
  created() {
    this.isLoading = true;
    Promise.all([this.getData(1), this.getMasterData()])
      .then((values) => {
        this.isLoading = false;
      })
      .catch((error) => {
        console.log(error.response.data.message);
        this.isLoading = false;
      });
  },
  methods: {
    async getData(page) {
      const res = await this.$store.dispatch(
        "lan_pass_lifting_fee/getData",
        page
      );
      //! เลข no table
      res.data.data = res.data.data.map((data, i) => ({
        ...data,
        no: (res.data.current_page - 1) * res.data.per_page + i + 1,
      }));
      this.items = res.data.data;
      this.paginate.current_page = res.data.current_page;
      this.paginate.last_page = res.data.last_page;
    },
    async getMasterData() {
      const table = ["cy_place", "shipping_agent"];
      const res = await this.$store.dispatch(
        "master_data/getMasterData",
        table
      );
      this.cyPlaceList = res.data[0].cy_place;
      this.agentList = res.data[1].shipping_agent;
      return res.data;
    },
    onHandlePaginate(page) {
      this.isLoading = true;
      this.$store
        .dispatch("lan_pass_lifting_fee/getData", page)
        .then((res) => {
          this.isLoading = false;
          //! เลข no table
          res.data.data = res.data.data.map((data, i) => ({
            ...data,
            no: (res.data.current_page - 1) * res.data.per_page + i + 1,
          }));
          this.items = res.data.data;
          this.paginate.current_page = res.data.current_page;
          this.paginate.last_page = res.data.last_page;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    add(item, index) {
      this.mainId = item.lan_pass_lifting_fee_id;
      this.childIndex = item.child_data.length;
      this.isEditChild = true;
      this.isEdit = true;
      this.isAdd = true;
      this.items.find((element) => {
        if (element.lan_pass_lifting_fee_id == item.lan_pass_lifting_fee_id) {
          element.child_data.push({
            lan_pass_lifting_fee_id: item.lan_pass_lifting_fee_id,
            cy_place_id: item.cy_place_id,
            cy_place_name: item.cy_place_name,
            zone_id: item.zone_id,
            zone_name: item.zone_name,
            lan_pass: "",
            unit_id: "",
            unit_name: "",
            shipping_agent_id: "",
            shipping_agent_name: "",
            lifting_fee_ex20: "",
            lifting_fee_ex40: "",
            lifting_fee_ex45: "",
            lifting_fee_im20: "",
            lifting_fee_im40: "",
            lifting_fee_im45: "",
          });
        }
      });
    },
    save(item) {
      if (item.cy_place_id) {
        this.isLoading = true;
        this.$store
          .dispatch("lan_pass_lifting_fee/save", item)
          .then((res) => {
            this.isLoading = false;
            this.$swal
              .fire({
                icon: "success",
                title: "Save successfully!",
                showConfirmButton: false,
                timer: 1500,
              })
              .then(() => {
                this.isLoading = true;
                this.$store
                  .dispatch(
                    "lan_pass_lifting_fee/getData",
                    this.paginate.current_page
                  )
                  .then((res) => {
                    this.isLoading = false;
                    this.isEdit = false;
                    this.isAdd = false;
                    this.isEditChild = false;
                    //! เลข no table
                    res.data.data = res.data.data.map((data, i) => ({
                      ...data,
                      no:
                        (res.data.current_page - 1) * res.data.per_page + i + 1,
                    }));
                    this.items = res.data.data;
                    this.paginate.current_page = res.data.current_page;
                    this.paginate.last_page = res.data.last_page;
                    this.form = {
                      cy_place_id: "",
                      cy_place_name: "",
                      zone_id: "",
                      zone_name: "",
                      lan_pass: "",
                      unit_id: "",
                      unit_name: "",
                      shipping_agent_id: "",
                      shipping_agent_name: "",
                      lifting_fee_ex20: "",
                      lifting_fee_ex40: "",
                      lifting_fee_ex45: "",
                      lifting_fee_im20: "",
                      lifting_fee_im40: "",
                      lifting_fee_im45: "",
                    };
                  })
                  .catch((error) => {
                    this.isEdit = false;
                    this.isEditChild = false;
                    this.isLoading = false;
                  });
              });
          })
          .catch((error) => {
            this.isEdit = false;
            this.isEditChild = false;
            this.isLoading = false;
            console.log(error);
          });
      } else {
        this.$swal.fire({
          icon: "warning",
          title: "Waring! ",
          text: "กรุณาเลือก CY Place",
        });
      }
    },
    edit(item, index) {
      this.isEditChild = true;
      this.isEdit = true;
      this.itemId = item.lan_pass_lifting_fee_id;
    },
    editChild(item, index) {
      this.isEditChild = true;
      this.childIndex = index;
      this.mainId = item.lan_pass_lifting_fee_id;
      this.isEdit = true;
      this.itemId = item.lan_pass_lifting_fee_id;
    },
    update(item) {
      this.isLoading = true;
      this.$store
        .dispatch("lan_pass_lifting_fee/update", item)
        .then((res) => {
          this.isLoading = false;
          this.$swal
            .fire({
              icon: "success",
              title: "Update successfully!",
              showConfirmButton: false,
              timer: 1500,
            })
            .then(() => {
              this.itemId = null;
              this.isEdit = false;
              this.isEditChild = false;
            });
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    deleteItem(item, id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            this.$store
              .dispatch(
                "lan_pass_lifting_fee/delete",
                item.lan_pass_lifting_fee_id
              )
              .then((res) => {
                this.isLoading = false;
                // this.items = this.items.filter((data, i) => i != id);
                this.$swal
                  .fire({
                    icon: "success",
                    title: "Delete successfully!",
                    showConfirmButton: false,
                    timer: 1500,
                  })
                  .then(() => {
                    this.isLoading = true;
                    this.$store
                      .dispatch(
                        "lan_pass_lifting_fee/getData",
                        this.paginate.current_page
                      )
                      .then((res) => {
                        this.isLoading = false;
                        this.isEdit = false;
                        this.isAdd = false;
                        this.isEditChild = false;
                        //! เลข no table
                        res.data.data = res.data.data.map((data, i) => ({
                          ...data,
                          no:
                            (res.data.current_page - 1) * res.data.per_page +
                            i +
                            1,
                        }));
                        this.items = res.data.data;
                        this.paginate.current_page = res.data.current_page;
                        this.paginate.last_page = res.data.last_page;
                        this.form = {
                          cy_place_id: "",
                          cy_place_name: "",
                          zone_id: "",
                          zone_name: "",
                          lan_pass: "",
                          unit_id: "",
                          unit_name: "",
                          shipping_agent_id: "",
                          shipping_agent_name: "",
                          lifting_fee_ex20: "",
                          lifting_fee_ex40: "",
                          lifting_fee_ex45: "",
                          lifting_fee_im20: "",
                          lifting_fee_im40: "",
                          lifting_fee_im45: "",
                        };
                      })
                      .catch((error) => {
                        this.isEdit = false;
                        this.isEditChild = false;
                        this.isLoading = false;
                      });
                  });
              })
              .catch((error) => {
                this.isLoading = false;
              });
          }
        });
    },
    cancle(item, index) {
      if (this.isAdd) {
        this.items.find((element) => {
          if (element.lan_pass_lifting_fee_id === this.mainId) {
            element.child_data = element.child_data.filter(
              (data, i) => i != index
            );
          }
        });
        this.isAdd = false;
      }
      this.isEditChild = false;
      this.isEdit = false;
      this.mainId = "";
      this.itemId = "";
      this.getData(1);
    },
    onHandleCyPlace(item) {
      this.form.cy_place_id = item.cy_place_id;
      this.form.cy_place_name = item.cy_place_name;
      this.form.zone_id = item.zone_id;
      this.form.zone_name = item.zone_name;
    },
  },
};
</script>

<style scope>
/* .test thead.v-data-table-header {
  background-color: #4b5768;
} */
.table-color {
  background-color: #99ca545e;
}
</style>
