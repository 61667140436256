<script>
import { Bar, mixins } from "vue-chartjs";
export default {
  extends: Bar,
  props: ["data", "options"],
  // mixins: [mixins.reactiveProp],
  mounted() {
    this.renderChart(
      this.data,
      {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              stacked: true,
              categoryPercentage: 0.5,
              barPercentage: 1,
            },
          ],
          yAxes: [
            {
              stacked: true,
            },
          ],
        },
      }
    );
  },
};
</script>

<style scoped></style>
