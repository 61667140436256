<template>
  <div class="">
    <div class="ml-8 mb-4 mt-1">
      <v-icon small class="mr-2">mdi-file</v-icon>
      <span class=" font-weight-bold text-uppercase">{{ $route.name }}</span>
    </div>

    <div class=" mx-8">
      <v-row>
        <v-col cols="7" pr-2>
          <!-- Form -->
          <v-card flat outlined min-height="338px">
            <v-form
              class="px-3 pb-6 pt-12"
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-layout>
                <v-flex xs4>
                  <v-layout class="align-center" mb-3>
                    <v-flex class="xs5 text-end  font-12 mr-3 font-weight-bold">
                      Job no. :
                    </v-flex>
                    <v-flex class="xs7 px-0">
                      <TextInput
                        hideDetails
                        placeholder="Job no."
                        :model="form.order_id"
                        rulse="fieldRequire"
                        @update-data="updateData('order_id', $event)"
                      />
                    </v-flex>
                  </v-layout>

                  <v-layout class="align-center" mb-3>
                    <v-flex class="xs5 text-end font-12 mr-3 font-weight-bold">
                      Importer <br />
                      /Exporter :
                    </v-flex>
                    <v-flex class="xs7 px-0">
                      <AutocompleteInput
                        clearable
                        hideDetails
                        placeholder="Importer/Exporter"
                        :model="form.importer_exporter_id"
                        rulse="fieldRequire"
                        :items="importerExporter"
                        itemText="customer_code"
                        itemValue="customer_id"
                        @update-data="
                          updateSelect(
                            'importer_exporter',
                            'customer_id',
                            $event
                          )
                        "
                      />
                    </v-flex>
                  </v-layout>

                  <v-layout class="align-center" mb-3>
                    <v-flex class="xs5 text-end font-12 mr-3 font-weight-bold">
                      Customer :
                    </v-flex>
                    <v-flex class="xs7 px-0">
                      <AutocompleteInput
                        clearable
                        hideDetails
                        placeholder="Customer"
                        :model="form.customer_id"
                        rulse="fieldRequire"
                        :items="customer"
                        itemText="customer_code"
                        itemValue="customer_id"
                        @update-data="
                          updateSelect('customer', 'customer_id', $event)
                        "
                      />
                    </v-flex>
                  </v-layout>

                  <v-layout class="align-center" mb-3>
                    <v-flex class="xs5 text-end  font-12 mr-3 font-weight-bold">
                      Booking :
                    </v-flex>
                    <v-flex class="xs7 px-0">
                      <TextInput
                        hideDetails
                        placeholder="Booking"
                        :model="form.booking_no"
                        rulse="fieldRequire"
                        @update-data="updateData('booking_no', $event)"
                      />
                    </v-flex>
                  </v-layout>

                  <v-layout class="align-center" mb-3>
                    <v-flex class="xs5 text-end font-12  font-weight-bold mr-3">
                      WH/Factory :
                    </v-flex>
                    <v-flex class="xs7">
                      <AutocompleteInput
                        clearable
                        hideDetail
                        placeholder="WH/Factory"
                        :model="form.wh_factory_id"
                        rulse="fieldRequire"
                        :items="factoryList"
                        itemText="wh_factory_name"
                        itemValue="wh_factory_id"
                        @update-data="
                          updateSelect('wh_factory', 'wh_factory_id', $event)
                        "
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>

                <v-flex xs8>
                  <v-layout mb-3>
                    <v-flex class="text-end font-12">
                      <v-layout class="align-center">
                        <v-flex
                          class="xs4 text-end font-12 mr-3 font-weight-bold"
                        >
                          CY Date :
                        </v-flex>
                        <v-flex class="xs4 px-0">
                          <DatePicker
                            hideDetails
                            hideDetail
                            rulse="fieldRequire"
                            :model="date.cy_date_from"
                            @update-data="
                              date.cy_date_from = $event
                                ? customDate($event)
                                : '';
                              form.cy_date_from = $event ? $event : '';
                            "
                          ></DatePicker>
                        </v-flex>
                        <v-flex
                          class="xs1 text-end font-12 mx-3 font-weight-bold"
                        >
                          To :
                        </v-flex>
                        <v-flex class="xs4 px-0">
                          <DatePicker
                            hideDetails
                            hideDetail
                            rulse="fieldRequire"
                            :model="date.cy_date_to"
                            @update-data="updateDate('cy_date_to', $event)"
                          ></DatePicker>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>

                  <v-layout class="align-center" mb-2>
                    <v-flex class="text-end font-12">
                      <v-layout class="align-center">
                        <v-flex
                          class="xs4 text-end font-12 mr-3 font-weight-bold"
                        >
                          Load <br />
                          /Unload Date :
                        </v-flex>
                        <v-flex class="xs4 px-0">
                          <DatePicker
                            hideDetail
                            rulse="fieldRequire"
                            :model="date.load_and_unload_date_from"
                            @update-data="
                              updateDate('load_and_unload_date_from', $event)
                            "
                          ></DatePicker>
                        </v-flex>
                        <v-flex
                          class="xs1 text-end font-12 mx-3 font-weight-bold"
                        >
                          To :
                        </v-flex>
                        <v-flex class="xs4 px-0">
                          <DatePicker
                            hideDetail
                            rulse="fieldRequire"
                            :model="date.load_and_unload_date_to"
                            @update-data="
                              updateDate('load_and_unload_date_to', $event)
                            "
                          ></DatePicker>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <v-layout class="align-center" mb-2>
                    <v-flex class="text-end font-12">
                      <v-layout class="align-center">
                        <v-flex
                          class="xs4 text-end font-12 mr-3 font-weight-bold"
                        >
                          Create <br />
                          /Update Tender :
                        </v-flex>
                        <v-flex class="xs4 px-0">
                          <DatePicker
                            hideDetail
                            rulse="fieldRequire"
                            :model="date.create_update_tender_from"
                            @update-data="
                              updateDate('create_update_tender_from', $event)
                            "
                          ></DatePicker>
                        </v-flex>
                        <v-flex
                          class="xs1 text-end font-12 mx-3 font-weight-bold"
                        >
                          To :
                        </v-flex>
                        <v-flex class="xs4 px-0">
                          <DatePicker
                            hideDetail
                            rulse="fieldRequire"
                            :model="date.create_update_tender_to"
                            @update-data="
                              updateDate('create_update_tender_to', $event)
                            "
                          ></DatePicker>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>

                  <v-layout class="align-center" mb-3>
                    <v-flex
                      class="xs4 text-end font-12 mr-3 ml-n4 font-weight-bold"
                    >
                      INV./PO* :
                    </v-flex>
                    <v-flex class="xs9 px-0">
                      <TextInput
                        hideDetails
                        placeholder="INV./PO"
                        :model="form.inv_po"
                        rulse="fieldRequire"
                        @update-data="updateData('inv_po', $event)"
                      />
                    </v-flex>
                  </v-layout>

                  <v-layout class="align-center ml-2">
                    <v-flex
                      class="xs4 text-end font-12 mr-3 ml-n6 font-weight-bold"
                    >
                      Vendor :
                    </v-flex>
                    <v-flex class="xs9">
                      <TextInput
                        hideDetails
                        placeholder="carrier"
                        :model="form.carrier"
                        rulse="fieldRequire"
                        @update-data="updateData('carrier', $event)"
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-form>
          </v-card>
        </v-col>
        <v-col cols="5" pl-2>
          <v-card
            outlined
            flat
            class="pa-3"
            min-height="338px"
            style="overflow-x: scroll;"
          >
            <Loading
              :active.sync="isLoadingChart"
              :can-cancel="false"
              :is-full-page="false"
            />
            <v-layout>
              <v-flex v-for="(data, i) in dataChart" :key="i">
                <TenderChart :data="data" />
              </v-flex>
            </v-layout>
          </v-card>
        </v-col>
      </v-row>

      <!-- Search -->
      <v-card outlined width="100%" flat class="mt-4">
        <v-layout>
          <v-flex xs8>
            <v-layout class="align-center text-end">
              <v-flex xs2 @click="onHandleClearSearch">Mode : </v-flex>
              <v-flex xs10 pl-6>
                <v-layout>
                  <v-flex shrink mr-4>
                    <v-checkbox
                      color="#338A68"
                      v-model="is_import"
                      label="Import"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex shrink mr-4>
                    <v-checkbox
                      color="#338A68"
                      v-model="is_export"
                      label="Export"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex shrink mr-4>
                    <v-checkbox
                      color="#338A68"
                      v-model="is_empty"
                      label="Empty"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex shrink mr-4>
                    <v-checkbox
                      color="#338A68"
                      v-model="is_laden"
                      label="Laden"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex shrink mr-4>
                    <v-checkbox
                      color="#338A68"
                      v-model="is_drop_load"
                      label="Drop Load"
                    ></v-checkbox>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex shrink class="text-end pr-6">
            <v-btn
              dark
              depressed
              color="#338A68"
              class="my-4 font-weight-bold text-capitalize"
              @click="onHandleNewJobToday"
            >
              New job today
            </v-btn>
          </v-flex>
          <v-flex shrink class="text-end pr-6">
            <v-btn
              dark
              depressed
              color="#EC8235"
              class="my-4 font-weight-bold text-capitalize"
              @click="onHandleNonTender"
            >
              Non Tender
            </v-btn>
          </v-flex>
          <v-flex shrink class="text-end pr-6">
            <v-btn
              depressed
              color="#F7D25D"
              class="my-4 font-weight-bold text-capitalize"
              @click="onHandleSearch"
            >
              <v-icon class="mr-3">mdi-magnify</v-icon>
              Search
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>

      <!-- button -->
      <v-btn
        depressed
        color="#64C8E7"
        dark
        class="my-4 font-weight-bold mr-3"
        @click="print()"
      >
        <v-icon class="mr-3">mdi-printer</v-icon>
        <span class="text-capitalize">Print</span>
      </v-btn>

      <v-btn
        outlined
        depressed
        color="#338A68"
        dark
        class="my-4 font-weight-bold"
        @click="exportExcel()"
      >
        <v-icon class="mr-3">mdi-file-excel</v-icon>
        <span class="text-capitalize">Export Excel</span>
      </v-btn>
    </div>

    <v-layout>
      <v-flex style="width: 1px">
        <v-card flat>
          <div class="mx-8 table-tender">
            <!-- Main Table -->
            <v-data-table
              :items-per-page="25"
              :headers="headers"
              show-select
              :items="items"
              v-model="selectedMain"
              :single-expand="singleExpand"
              :expanded.sync="expanded"
              item-key="order_detail_id"
              show-expand
              class="elevation-1"
              hide-default-footer
            >
              <!-- Custom Main Table Item rows -->
              <template
                v-slot:item="{
                  item,
                  index,
                  expand,
                  isExpanded,
                  isSelected,
                  select,
                }"
              >
                <tr :class="isExpanded && 'td-row-active'">
                  <td colspan="" class="text-start">
                    <v-icon v-if="!isExpanded" @click="expand(!isExpanded)">
                      mdi-chevron-down
                    </v-icon>

                    <v-icon v-else @click="expand(!isExpanded)"
                      >mdi-chevron-up</v-icon
                    >
                  </td>

                  <td class="border-right">
                    <v-simple-checkbox
                      color="#338A68"
                      :value="isSelected"
                      @input="select($event)"
                    ></v-simple-checkbox>
                  </td>

                  <td class="text-center border-right">
                    <v-icon
                      color="#338A68"
                      :disabled="isAdd"
                      @click="onHandleAddItem(item, index)"
                    >
                      mdi-plus-circle-outline
                    </v-icon>
                  </td>
                  <td class="text-center">{{ item.no }}</td>
                  <td class="text-left">{{ item.order_id }}</td>
                  <td class="text-center">
                    {{
                      item.order && item.order.inv_po ? item.order.inv_po : "--"
                    }}
                  </td>
                  <td class="text-left">
                    <span v-if="item.order && item.order.cy_date">
                      {{ item.order.cy_date | moment("DD/MM/YYYY") }}
                    </span>
                    <span v-else>--</span>
                  </td>
                  <td class="text-left">
                    {{
                      item.order && item.order.booking_no
                        ? item.order.booking_no
                        : "--"
                    }}
                  </td>
                  <td class="text-left">
                    {{ item.cy_place_name ? item.cy_place_name : "--" }}
                  </td>
                  <td class="text-left">
                    <span v-if="item.order && item.order.release_date">
                      {{ item.order.release_date | moment("DD/MM/YYYY") }}
                    </span>
                    <span v-else>--</span>
                  </td>
                  <td class="text-left">
                    {{ item.return_place_name ? item.return_place_name : "--" }}
                  </td>
                  <td
                    class="text-left"
                    :style="
                      item.total_qty == item.qty
                        ? { color: '#338A68' }
                        : { color: '#EB5757' }
                    "
                  >
                    <span
                      class="px-2"
                      :class="
                        item.container_size_name == 20 && +item.qty % 2 == 1
                          ? 'active-bg-qty'
                          : ''
                      "
                    >
                      {{ item.qty }}/{{ item.total_qty ? item.total_qty : 0 }}
                    </span>
                  </td>
                  <td class="text-left">
                    {{ item.container_size_name }}
                  </td>
                  <td class="text-left">
                    {{ item.container_isotype_name }}
                  </td>
                  <td class="text-left">
                    {{ item.wh_factory_1_name }}
                  </td>
                  <td class="text-left">
                    <span v-if="item.unload_load_date1">
                      {{ item.unload_load_date1 | moment("DD/MM/YYYY") }}
                    </span>
                    <span v-else>--</span>
                  </td>
                  <td class="text-left">
                    {{ subStringTime(item.unload_load_time1) }}
                  </td>
                  <td class="text-left">
                    {{ item.wh_factory_2_name }}
                  </td>
                  <td class="text-left">
                    <span v-if="item.unload_load_date2">
                      {{ item.unload_load_date2 | moment("DD/MM/YYYY") }}
                    </span>
                    <span v-else>--</span>
                  </td>
                  <td class="text-left">
                    {{ subStringTime(item.unload_load_time2) }}
                  </td>
                </tr>
              </template>

              <!-- Expanable Table in Main Table -->
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <!-- Child Table -->
                  <v-data-table
                    v-model="selectedSub"
                    :headers="headersChild"
                    :items="item.tender"
                    :items-per-page="-1"
                    item-key="tender_id"
                    class="elevation-1 table-child"
                    hide-default-footer
                  >
                    <!-- Custom Child Table Item rows -->
                    <template v-slot:item="{ item, index, isSelected, select }">
                      <!-- Add / Edit -->
                      <tr
                        v-if="
                          isAdd &&
                            indexChild === index &&
                            item.order_detail_id === indexMain
                        "
                      >
                        <td>
                          <v-simple-checkbox
                            color="#338A68"
                            :value="isSelected"
                            @input="select($event)"
                          ></v-simple-checkbox>
                        </td>
                        <td class="text-center border-right border-left pr-6">
                          <v-layout>
                            <v-flex>
                              <!-- btn ใช้ตอนกด add ข้อมูล tender  -->
                              <v-btn
                                v-if="isEdit"
                                fab
                                depressed
                                small
                                text
                                @click="onHandleCancel(item, index)"
                              >
                                <v-icon color="red">
                                  mdi-close-circle-outline
                                </v-icon>
                              </v-btn>

                              <!-- btn ใช้ตอนกด delete และตอนกดแก้ไข  -->
                              <v-btn
                                v-else
                                fab
                                depressed
                                small
                                text
                                @click="onHandleDeleteChild(item, index)"
                              >
                                <v-icon color="red">
                                  mdi-close-circle-outline
                                </v-icon>
                              </v-btn>
                            </v-flex>
                            <v-flex>
                              <v-btn
                                fab
                                depressed
                                small
                                text
                                @click="onHandleSaveChild(item)"
                              >
                                <v-icon color="#338A68">
                                  mdi-content-save-outline
                                </v-icon>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </td>
                        <td class="text-center">{{ index + 1 }}</td>
                        <td class="text-center">
                          {{ item.tender_code }}
                        </td>
                        <td>
                          {{ item.cy_place_name ? item.cy_place_name : "--" }}
                        </td>
                        <td>
                          {{
                            item.return_place_name
                              ? item.return_place_name
                              : "--"
                          }}
                        </td>
                        <td class="text-center">
                          <TextInput
                            height="30px"
                            inputRule="number"
                            hideDetails
                            :model="item.qty"
                            rulse="fieldRequire"
                            @update-data="updateDataChild('qty', item, $event)"
                          />
                        </td>
                        <td class="text-center">
                          {{ item.container_size_name }}
                        </td>
                        <td class="text-center">
                          {{ item.container_isotype_name }}
                        </td>
                        <td class="text-center">{{ item.agent_name }}</td>
                        <td class="text-start">
                          <AutocompleteInput
                            clearable
                            height="30px"
                            required
                            hideDetail
                            :model="item.carrier_id"
                            rulse="fieldRequire"
                            :items="carrierList"
                            itemText="name"
                            itemValue="carrier_id"
                            @update-data="
                              updateDataChildSelect(
                                'carrier',
                                item,
                                'name',
                                'carrier_id',
                                $event
                              )
                            "
                          />
                        </td>
                        <td class="text-start">
                          <AutocompleteInput
                            height="30px"
                            required
                            hideDetail
                            :model="item.model_id"
                            rulse="fieldRequire"
                            :items="modelList"
                            itemText="model_name"
                            itemValue="model_id"
                            @update-data="
                              item.model_id = $event.model_id;
                              item.model_name = $event.model_name;
                            "
                          />
                        </td>
                        <td>{{ item.wh_factory_1_name }}</td>
                        <td class="text-left">
                          <span v-if="item.unload_load_date1">
                            {{ item.unload_load_date1 | moment("DD/MM/YYYY") }}
                          </span>
                          <span v-else>--</span>
                        </td>
                        <td class="text-center">
                          {{ subStringTime(item.unload_load_time1) }}
                        </td>
                        <td>{{ item.wh_factory_2_name }}</td>
                        <td class="text-left">
                          <span v-if="item.unload_load_date2">
                            {{ item.unload_load_date2 | moment("DD/MM/YYYY") }}
                          </span>
                          <span v-else>--</span>
                        </td>
                        <td class="text-center">
                          {{ subStringTime(item.unload_load_time2) }}
                        </td>
                        <td class="text-center">
                          <!-- <span v-if="item.sending_mail">ส่งแล้ว</span> -->
                          <span>
                            <v-icon
                              color="#338A68"
                              small
                              class="mr-2"
                              @click="sendMail(item)"
                            >
                              mdi-send
                            </v-icon>
                          </span>
                        </td>
                        <td class="text-left">
                          <span v-if="item.last_date_sending">
                            {{ item.last_date_sending | moment("DD/MM/YYYY") }}
                          </span>
                          <span v-else>--</span>
                        </td>
                        <td class="text-center">
                          {{ subStringTime(item.time_sending) }}
                        </td>
                        <td class="text-center">{{ item.status }}</td>
                        <td class="text-center">{{ item.reason }}</td>
                      </tr>

                      <!-- Show -->
                      <tr v-else>
                        <td>
                          <v-simple-checkbox
                            color="#338A68"
                            :value="isSelected"
                            @input="select($event)"
                          ></v-simple-checkbox>
                        </td>
                        <!-- <td class="text-start "></td> -->
                        <td class="text-center border-right border-left pr-6">
                          <v-layout>
                            <v-flex>
                              <v-btn
                                fab
                                depressed
                                small
                                text
                                :disabled="isAdd || item.status === 'cancel'"
                                @click="onHandleDeleteChild(item, index)"
                              >
                                <v-icon color="#000">
                                  mdi-delete-outline
                                </v-icon>
                              </v-btn>
                            </v-flex>
                            <v-flex>
                              <v-btn
                                fab
                                depressed
                                small
                                text
                                :disabled="isAdd || item.status === 'cancel'"
                                @click="onHandleEditChild(item, index)"
                              >
                                <v-icon color="#62C4E3">
                                  mdi-pencil-outline
                                </v-icon>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </td>
                        <td class="text-center">{{ index + 1 }}</td>
                        <td class="text-center">
                          {{ item.tender_code }}
                        </td>
                        <td>
                          {{ item.cy_place_name ? item.cy_place_name : "--" }}
                        </td>
                        <td>
                          {{
                            item.return_place_name
                              ? item.return_place_name
                              : "--"
                          }}
                        </td>
                        <td class="text-center">{{ item.qty }}</td>
                        <td class="text-center">
                          {{ item.container_size_name }}
                        </td>
                        <td class="text-center">
                          {{ item.container_isotype_name }}
                        </td>
                        <td>{{ item.agent_name }}</td>
                        <td class="text-start">{{ item.carrier_name }}</td>
                        <td class="text-center">
                          {{ item.model_name }}
                        </td>
                        <td>{{ item.wh_factory_1_name }}</td>
                        <td class="text-left">
                          <span v-if="item.unload_load_date1">
                            {{ item.unload_load_date1 | moment("DD/MM/YYYY") }}
                          </span>
                          <span v-else>--</span>
                        </td>
                        <td class="text-center">
                          {{ subStringTime(item.unload_load_time1) }}
                        </td>
                        <td>{{ item.wh_factory_2_name }}</td>
                        <td class="text-left">
                          <span v-if="item.unload_load_date2">
                            {{ item.unload_load_date2 | moment("DD/MM/YYYY") }}
                          </span>
                          <span v-else>--</span>
                        </td>
                        <td class="text-center">
                          {{ item.unload_load_time2 }}
                        </td>
                        <td class="text-center">
                          <!-- <span v-if="item.sending_mail">ส่งแล้ว</span> -->
                          <span>
                            <v-icon
                              color="#338A68"
                              small
                              class="mr-2"
                              :disabled="item.status === 'cancel'"
                              @click="sendMail(item)"
                            >
                              mdi-send
                            </v-icon>
                          </span>
                        </td>
                        <td class="text-center">
                          <span v-if="item.last_date_sending">
                            {{ item.last_date_sending | moment("DD/MM/YYYY") }}
                          </span>
                          <span v-else>--</span>
                        </td>
                        <td class="text-center">
                          {{ subStringTime(item.time_sending) }}
                        </td>
                        <td class="text-center">{{ item.status }}</td>
                        <td class="text-center">{{ item.reason }}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </td>
              </template>
            </v-data-table>

            <Loading
              :active.sync="isLoading"
              :can-cancel="false"
              :is-full-page="false"
            />
          </div>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout mt-4>
      <v-flex>
        <Paginate
          :current_page="paginate.current_page"
          :last_page="paginate.last_page"
          :nextPage="search"
        />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import XLSX from "xlsx"; // import xlsx;
import moment from "moment";
import TextInput from "../../../components/input/TextInput.vue";
import AutocompleteInput from "../../../components/select/AutocompleteInput.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import DatePicker from "../../../components/picker/DatePicker.vue";
import TenderChart from "../../../components/chart/TenderChart.vue";
import Paginate from "../../../components/pagination/Paginate.vue";

export default {
  components: {
    TextInput,
    Loading,
    AutocompleteInput,
    DatePicker,
    TenderChart,
    Paginate,
  },

  data() {
    return {
      valid: true,
      isClearSelected: false,
      indexMain: "",
      indexChild: null,
      items: [],
      paginate: {
        current_page: 1,
        from: 1,
        last_page: 1,
        per_page: 1,
        total: 1,
        to: 1,
      },
      dataChart: [],
      dataExcel: [],

      isChangePaginateAndClearForm: false,
      isLoadingChart: false,
      form: {
        order_id: "",
        importer_exporter_id: "",
        load_and_unload_date_from: "",
        load_and_unload_date_to: "",
        customer_id: "",
        booking_no: "",
        create_update_tender_from: "",
        create_update_tender_to: "",
        cy_date_from: "",
        cy_date_to: "",
        inv_po: "",
        wh_factory_id: "",
        mode: [],
        carrier: "",
        new_job_today: "",
        non_tender: "",
      },
      is_import: false,
      is_export: false,
      is_empty: false,
      is_laden: false,
      is_drop_load: false,
      date: {
        load_and_unload_date_from: "",
        load_and_unload_date_to: "",
        create_update_tender_from: "",
        create_update_tender_to: "",
        cy_date_from: "",
        cy_date_to: "",
      },
      listMode: [
        { label: "Import" },
        { label: "Export" },
        { label: "Empty" },
        { label: "Laden" },
        { label: "Drop Load" },
      ],
      importerExporter: [],
      customer: [],
      factoryList: [],
      carrierList: [],
      modelList: [],

      headers: [
        // {
        //   text: "Select",
        //   value: "select",
        //   sortable: false,
        //   width: "50px",
        //   class: "border-right",
        // },
        {
          text: "Actions",
          value: "actions",
          align: "center",
          sortable: false,
          width: "51px",
          class: "border-right border-left",
        },
        {
          text: "No.",
          value: "no",
          align: "start",
          sortable: false,
          width: "5px",
          class: "",
        },
        {
          text: "Job No.",
          value: "job_no",
          align: "start",
          sortable: false,
          width: "130px",
          class: "",
        },
        {
          text: "Inv/.PO No.*",
          value: "inv_po",
          align: "start",
          sortable: false,
          width: "110px",
          class: "",
        },
        {
          text: "CY Date",
          value: "cy_date",
          align: "start",
          sortable: false,
          width: "100px",
          class: "",
        },
        {
          text: "Booking",
          value: "booking_no",
          align: "start",
          sortable: false,
          width: "100px",
          class: "",
        },
        {
          text: "CY Place",
          value: "cy_place",
          align: "start",
          sortable: false,
          width: "130px",
          class: "",
        },
        {
          text: "Release Date",
          value: "release_date",
          align: "start",
          sortable: false,
          width: "100px",
          class: "",
        },
        {
          text: "Return Place",
          align: "start",
          sortable: false,
          width: "180px",
          class: "",
        },
        {
          text: "Qty",
          value: "qty",
          align: "start",
          sortable: false,
          width: "20px",
          class: "",
        },
        {
          text: "Size",
          align: "start",
          sortable: false,
          width: "20px",
          class: "",
        },
        {
          text: "Isotype",
          align: "start",
          sortable: false,
          width: "130px",
          class: "",
        },
        {
          text: "WH/factory 1",
          value: "wh_factory1",
          align: "start",
          sortable: false,
          width: "180px",
          class: "",
        },
        {
          text: "Unload/Load Date 1",
          value: "unload_load_date_1",
          align: "start",
          sortable: false,
          width: "200px",
          class: "",
        },
        {
          text: "Unload/Load Time 1",
          value: "unload_load_time_1",
          align: "start",
          sortable: false,
          width: "200px",
          class: "",
        },
        {
          text: "WH/factory 2",
          value: "wh_factory2",
          align: "start",
          sortable: false,
          width: "180px",
          class: "",
        },
        {
          text: "Unload/Load Date 2",
          value: "unload_load_date_2",
          align: "start",
          sortable: false,
          width: "200px",
          class: "",
        },
        {
          text: "Unload/Load Time 2",
          value: "unload_load_time_2",
          align: "start",
          sortable: false,
          width: "200px",
          class: "",
        },
      ],
      headersChild: [
        {
          text: "Select",
          value: "select",
          sortable: false,
          width: "50px",
          class: "border-right",
        },
        {
          text: "Action",
          value: "actions",
          sortable: false,
          align: "center",
          type: "action",
          width: "50px",
          class: "border-right border-left",
        },
        { text: "No.", align: "center", sortable: false, width: "50px" },
        {
          text: "Tender ID",
          value: "tender_id",
          align: "center",
          width: "50px",
          sortable: false,
        },
        {
          text: "CY Place",
          value: "cy_place",
          sortable: false,
          width: "100px",
        },
        {
          text: "Return Place",
          value: "return_place",
          sortable: false,
          width: "50px",
        },
        {
          text: "Qty",
          value: "qty",
          align: "center",
          sortable: false,
          width: "100px",
        },
        {
          text: "Size Container",
          value: "size_container",
          align: "center",
          sortable: false,
          width: "50px",
        },
        {
          text: "Isotype",
          value: "iso_type",
          align: "center",
          sortable: false,
          width: "50px",
        },
        {
          text: "Agent",
          value: "agent",
          sortable: false,
          width: "50px",
        },
        {
          text: "Vendor",
          value: "carrier",
          sortable: false,
          width: "200px",
        },
        {
          text: "Model",
          value: "model",
          sortable: false,
          align: "center",
          width: "150px",
        },
        {
          text: "WH/Factory 1",
          value: "wh_factory_1",
          sortable: false,
          width: "50px",
        },
        {
          text: "Unload/Load Date 1",
          value: "unload_load_date_1",
          align: "center",
          sortable: false,
          width: "50px",
        },
        {
          text: "Unload/Load Time 1",
          value: "unload_load_time_1",
          align: "center",
          sortable: false,
          width: "50px",
        },
        {
          text: "WH/Factory 2",
          value: "wh_factory_2",
          sortable: false,
          width: "50px",
        },
        {
          text: "Unload/Load Date 2",
          value: "unload_load_date_2",
          align: "center",
          sortable: false,
          width: "50px",
        },
        {
          text: "Unload/Load Time 2",
          value: "unload_load_time_2",
          align: "center",
          sortable: false,
          width: "50px",
        },
        {
          text: "ส่งอีเมล",
          value: "sending_mail",
          align: "center",
          sortable: false,
          width: "50px",
        },
        {
          text: "วันที่ส่งล่าสุด",
          value: "last_sending",
          align: "center",
          sortable: false,
          width: "50px",
        },
        {
          text: "เวลาที่ส่ง",
          value: "time_sanding",
          align: "center",
          sortable: false,
          width: "50px",
        },
        {
          text: "Status",
          value: "status",
          align: "center",
          sortable: false,
          width: "50px",
        },
        {
          text: "Reason",
          value: "reason",
          align: "center",
          sortable: false,
          width: "50px",
        },
      ],

      // isItem: [],
      selectedMain: [],
      selectedSub: [],
      isEdit: false,
      //? isCancel คือ ตัวแปลที่เอาไว้ตรวจสอบว่า item ไหน ที่ isCancel = true หมายถึง item นั้นกำลังแก้ไขอยู่ และ ปุ่มกากบาทของ item นั้น จะมีสถานะเป็นแค่ cancel ไม่ใช่ลบ item
      isCancel: false,
      isLoading: false,
      fullPage: true,
      isAdd: false,
      statusAdd: false,
      expanded: [],
      singleExpand: false,
      containerSizeList: [
        { container_size_id: "1", size: "20'" },
        { container_size_id: "2", size: "20'OT" },
        { container_size_id: "3", size: "20'FR" },
        { container_size_id: "4", size: "20'RF" },
        { container_size_id: "5", size: "20'ISO TANK" },
        { container_size_id: "6", size: "40'" },
        { container_size_id: "7", size: "40'HC" },
        { container_size_id: "8", size: "40'OT" },
        { container_size_id: "9", size: "40'FR" },
        { container_size_id: "10", size: "40'RF" },
        { container_size_id: "11", size: "45'" },
      ],
      renderComponent: true,

      cyPlaceList: [{ id: 1, name: "MPT" }],
    };
  },

  watch: {
    //! clear form เวลากด add/edit tender แล้วไม่ได้ save เช่น กดไปหน้า paginate อื่นๆ
    isChangePaginateAndClearForm: {
      handler: function(newValue) {
        this.isAdd = false;
        this.isCancel = false;
        this.isEdit = false;
      },
    },

    //! clear item main selected
    isClearSelected: {
      handler: function(newValue) {
        if (newValue) {
          this.selectedMain = [];
        }
      },
    },

    selectedMain: {
      handler: function(newValue) {
        if (newValue.length > 0) {
          this.isClearSelected = false;
        }
      },
    },

    is_import: {
      handler: function(val) {
        if (val) {
          if (this.form.mode.indexOf("Import") === -1) {
            this.form.mode.push("Import");
          }
        } else {
          this.form.mode.splice(this.form.mode.indexOf("Import"), 1);
        }
      },
    },
    is_export: {
      handler: function(val) {
        if (val) {
          if (this.form.mode.indexOf("Export") === -1) {
            this.form.mode.push("Export");
          }
        } else {
          this.form.mode.splice(this.form.mode.indexOf("Export"), 1);
        }
      },
    },
    is_empty: {
      handler: function(val) {
        if (val) {
          if (this.form.mode.indexOf("Empty") === -1) {
            this.form.mode.push("Empty");
          }
        } else {
          this.form.mode.splice(this.form.mode.indexOf("Empty"), 1);
        }
      },
    },
    is_laden: {
      handler: function(val) {
        if (val) {
          if (this.form.mode.indexOf("Laden") === -1) {
            this.form.mode.push("Laden");
          }
        } else {
          this.form.mode.splice(this.form.mode.indexOf("Laden"), 1);
        }
      },
    },
    is_drop_load: {
      handler: function(val) {
        if (val) {
          if (this.form.mode.indexOf("Drop Load") === -1) {
            this.form.mode.push("Drop Load");
          }
        } else {
          this.form.mode.splice(this.form.mode.indexOf("Drop Load"), 1);
        }
      },
    },
  },

  created() {
    this.getOrderDetail(1);
    this.getMasterData();
    this.getMasterCarrier();
    this.getDataChart();
  },

  methods: {
    updateData(name, val) {
      this.form[name] = val;
    },
    updateDate(name, val) {
      this.form[name] = val ? val : "";
      this.date[name] = val ? this.customDate(val) : "";
    },
    updateSelect(name, key, val) {
      if (val) {
        this.form[name + "_id"] = val[key];
      } else {
        this.form[name + "_id"] = "";
      }
    },
    customDate(val) {
      let sub_year = val.substring(2, 4);
      let sub_month = val.substring(5, 7);
      let sub_day = val.substring(8, 10);
      // sub_year = parseInt(sub_year) + 543;
      let changeDate = sub_day + "/" + sub_month + "/" + sub_year;
      return changeDate;
    },
    getOrderDetail(page) {
      this.isLoading = true;
      this.$store
        .dispatch("order_detail/getData", page)
        .then((res) => {
          if (res.data) {
            //! เลข no table
            res.data.data = res.data.data.map((data, i) => ({
              ...data,
              no: (res.data.current_page - 1) * res.data.per_page + i + 1,
            }));
            this.items = res.data.data;
            this.paginate.current_page = res.data.current_page;
            this.paginate.last_page = res.data.last_page;
            this.items.forEach((data) => {
              if (data.tender.length > 0) {
                let mapData = [];
                data.tender.forEach((item) => {
                  mapData.push({
                    order_id: data.order_id,
                    order_detail_id: data.order_detail_id,
                    tender_id: item.tender_id,
                    tender_code: item.tender_code,
                    cy_place_id: data.cy_place_id,
                    cy_place_name: data.cy_place_name,
                    return_place_id: data.return_place_id,
                    return_place_name: data.return_place_name,
                    qty: item.qty,
                    container_isotype_id: data.container_isotype_id,
                    container_isotype_name: data.container_isotype_name,
                    container_size_id: data.container_size_id,
                    container_size_name: data.container_size_name,
                    agent_id: data.order.shipping_agent_id,
                    agent_name: data.order.shipping_agent_name,
                    carrier_id: item.carrier_id,
                    carrier_name: item.carrier_name,
                    wh_factory_1_id: data.wh_factory_1_id,
                    wh_factory_1_name: data.wh_factory_1_name,
                    wh_factory_2_id: data.wh_factory_2_id,
                    wh_factory_2_name: data.wh_factory_2_name,
                    unload_load_date1: data.unload_load_date1,
                    unload_load_date2: data.unload_load_date2,
                    unload_load_time1: this.subStringTime(
                      data.unload_load_time1
                    ),
                    unload_load_time2: this.subStringTime(
                      data.unload_load_time2
                    ),
                    sending_mail: item.sending_mail,
                    last_date_sending: item.last_date_sending,
                    time_sending: this.subStringTime(item.time_sending),
                    status: item.status,
                    reason: item.reason,
                    updated_at: item.updated_at,
                    model_id: item.model_id,
                    model_name: item.model_name,
                  });
                });

                data.tender = mapData;
              }
            });
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    updateDataChild(text, item, value) {
      item[text] = value;
    },
    updateDataChildSelect(text, item, itemValue, itemText, value) {
      console.log("text", text);
      console.log("item", item);
      console.log("itemValue", itemValue);
      console.log("itemText", itemText);
      console.log("value", value);

      item[text + "_id"] = value[itemText];
      item[text + "_name"] = value[itemValue];
    },
    onHandleAddItem(item, index) {
      if (item.qty == item.total_qty) {
        this.$swal.fire({
          icon: "warning",
          title: "warning QTY!",
          text: "จำนวน Qty ครบแล้ว",
        });
      } else {
        this.isAdd = true;
        this.isEdit = true;
        this.isCancel = true;
        this.addItem(item, index);
      }
    },
    onHandleSaveChild(item) {
      if (!item.carrier_id || item.qty == 0) {
        this.$swal.fire({
          icon: "warning",
          title: "Please, Input!",
          text: "กรุณากรอกข้อมูลให้ครบ",
        });
      } else {
        let total = 0;
        this.items.forEach(async (data) => {
          if (data.order_detail_id == item.order_detail_id) {
            data.tender.forEach((td) => {
              if (td.status != "cancel") {
                total += parseInt(td.qty);
              }
            });

            if (parseInt(data.qty) < total) {
              this.$swal.fire({
                icon: "warning",
                title: "warning QTY!",
                text: "จำนวน Qty ไม่ถูกต้อง",
              });
            } else {
              this.saveItem(item);
            }
          }
        });
      }
    },
    print() {
      if (this.selectedSub.length === 1) {
        this.$swal
          .fire({
            title: "Are you sure?",
            text: "You need to Print Tender of your select. !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Print it!",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.isLoading = true;
              this.$store
                .dispatch("tender/print", this.selectedSub)
                .then((res) => {
                  this.isLoading = false;
                  window.open(res.data);
                })
                .catch((error) => {
                  this.isLoading = false;
                });
            }
          });
      } else {
        this.$swal.fire({
          title: "Something went wrong!",
          text: "Please, Select 1 order before Print",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      }
    },
    sendMail(item) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "Do You want to send mail?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Send mail!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            this.$store
              .dispatch("tender/resendMail", item.tender_id)
              .then((res) => {
                if (!res.data.success) {
                  this.isLoading = false;
                  this.$swal.fire({
                    title: "Something went wrong!",
                    text: res.data.data.error,
                    icon: "warning",
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "OK",
                  });
                } else {
                  this.isLoading = false;
                  this.$swal
                    .fire({
                      icon: "success",
                      title: "Send Mail successfully!",
                      showConfirmButton: false,
                      timer: 1500,
                      confirmButtonColor: "#3085d6",
                      confirmButtonText: "OK",
                    })
                    .then(() => {
                      //! ดึงข้อมูลมาใหม่เพื่ออัพเดตข้อมูลในตาราง
                      this.getOrderDetail(1);
                    });
                }
              })
              .catch((error) => {
                this.isLoading = false;
              });
          }
        });
    },
    onHandleCancel(item, i) {
      this.isAdd = false;
      this.isCancel = false;
      if (this.isEdit) {
        this.items.find((element) => {
          if (element.order_detail_id === item.order_detail_id) {
            element.tender = element.tender.filter(function(val, key) {
              return key !== i;
            });
          }
        });
        this.isEdit = false;
      }
    },
    onHandleEditChild(item, index) {
      this.isAdd = true;
      this.isEdit = false;
      this.isCancel = true;
      this.indexMain = item.order_detail_id;
      this.items.find((element) => {
        if (element.order_detail_id === item.order_detail_id) {
          this.indexChild = index;
        }
      });
    },

    onHandleDeleteChild(item, index) {
      if (this.isEdit === this.isCancel) {
        this.$swal
          .fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.isLoading = true;
              this.$store
                .dispatch("tender/delete", item.tender_id)
                .then((res) => {
                  this.isLoading = false;
                  if (!res.data.success) {
                    this.$swal.fire({
                      title: "Something went wrong!",
                      text: res.data.data.error,
                      icon: "warning",
                      showCancelButton: false,
                      confirmButtonColor: "#3085d6",
                      confirmButtonText: "OK",
                    });
                  } else {
                    this.$swal.fire({
                      icon: "success",
                      title: "Delete successfully!",
                      showConfirmButton: false,
                      timer: 1500,
                    });
                    // remove tender
                    this.items.find((element) => {
                      if (element.order_detail_id === item.order_detail_id) {
                        element.tender = element.tender.filter(function(
                          val,
                          key
                        ) {
                          return key !== index;
                        });
                      }
                    });
                  }
                })
                .catch((error) => {
                  console.log(error.response);
                  this.$swal.fire({
                    title: "Something went wrong!",
                    text: error.response.data.data.error,
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "OK",
                  });
                  this.isLoading = false;
                });
            }
          });
      } else {
        this.getOrderDetail(1);
      }
      this.isAdd = false;
      this.isCancel = false;
    },

    checkQty(item) {
      var total = 0;
      if (item.tender.length == 0) {
        return total;
      } else {
        item.tender.forEach((item) => {
          total += parseInt(item.qty);
        });
        return total;
      }
    },

    addItem(item, index) {
      this.indexMain = item.order_detail_id;
      this.indexChild = item.tender.length;
      this.items[index].tender.push({
        add: true,
        order_id: item.order_id,
        order_detail_id: item.order_detail_id,
        tender_id: "",
        cy_place_id: item.cy_place_id,
        cy_place_name: item.cy_place_name,
        return_place_id: item.return_place_id,
        return_place_name: item.return_place_name,
        qty: 0,
        container_isotype_id: item.container_isotype_id,
        container_isotype_name: item.container_isotype_name,
        container_size_id: item.container_size_id,
        container_size_name: item.container_size_name,
        agent_id: item.agent_id,
        agent_name: item.agent_name,
        carrier_id: "",
        carrier_name: "",
        wh_factory_1_id: item.wh_factory_1_id,
        wh_factory_1_name: item.wh_factory_1_name,
        wh_factory_2_id: item.wh_factory_2_id,
        wh_factory_2_name: item.wh_factory_2_name,
        unload_load_date1: item.unload_load_date1,
        unload_load_date2: item.unload_load_date2,
        unload_load_time1: this.subStringTime(item.unload_load_time1),
        unload_load_time2: this.subStringTime(item.unload_load_time2),
        sending_mail: item.sending_mail,
        last_date_sending: item.last_date_sending,
        time_sending: this.subStringTime(item.time_sending),
        status: item.status,
        reason: item.reason,
      });
    },

    saveItem(item) {
      console.log("saveItem", item);
      this.isLoading = true;
      this.$store
        .dispatch("tender/save", {
          tender_id: item.tender_id,
          order_detail_id: item.order_detail_id,
          container_isotype_id: item.container_isotype_id,
          carrier_id: item.carrier_id,
          carrier_name: item.carrier_name,
          qty: item.qty,
          model_id: item.model_id,
          model_name: item.model_name,
        })
        .then((res) => {
          this.isLoading = false;
          if (!res.data.success) {
            this.$swal.fire({
              title: "Something went wrong!",
              text: res.data.data.error,
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            });
          } else {
            this.isAdd = false;
            // this.isItem = [];
            this.isEdit = false;
            this.isCancel = false;
            this.indexChild = null;
            this.isLoading = false;
            this.$swal
              .fire({
                icon: "success",
                title: "Save Tender successfully!",
                text: res.message,
              })
              .then((res) => {
                this.search(this.paginate.current_page);
              });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.isAdd = false;
          // this.isItem = [];
          this.isEdit = false;
          this.isCancel = false;
          this.indexChild = null;
          this.$swal
            .fire({
              icon: "error",
              title: "Save Failed",
              text: "เกิดข้อผิดพลาดบางอย่าง",
            })
            .then(() => {
              // todo ถ้า save tender ไม่ได้ต้องหาวิธี reset ค่าในช่องตอนกรอกให้เป็นค่าเดิม
              //* ตอนนี้ใช้วิธีเรียก api ใหม่ ซึ่งดูไม่น่าใช่ท่าที่ดี
              this.getOrderDetail(1);
            });
        });
    },

    // selectedItem(items) {
    //   let arr = items.reverse();
    //   this.selectedMain = arr;
    //   this.selectedMain = items;
    // },

    //* =====> Search
    search(page) {
      this.isChangePaginateAndClearForm = true;
      this.isLoading = true;
      this.form["page"] = page;
      this.$store
        .dispatch("tender/search", this.form)
        .then((res) => {
          this.isLoading = false;
          this.isChangePaginateAndClearForm = false;
          //! เลข no table
          res.data.data = res.data.data.map((data, i) => ({
            ...data,
            no: (res.data.current_page - 1) * res.data.per_page + i + 1,
          }));
          this.items = res.data.data;
          this.paginate.current_page = res.data.current_page;
          this.paginate.last_page = res.data.last_page;
          this.items.forEach((data) => {
            if (data.tender.length > 0) {
              let mapData = [];
              data.tender.forEach((item) => {
                mapData.push({
                  order_id: data.order_id,
                  order_detail_id: data.order_detail_id,
                  tender_id: item.tender_id,
                  tender_code: item.tender_code,
                  cy_place_id: data.cy_place_id,
                  cy_place_name: data.cy_place_name,
                  return_place_id: data.return_place_id,
                  return_place_name: data.return_place_name,
                  qty: item.qty,
                  container_isotype_id: data.container_isotype_id,
                  container_isotype_name: data.container_isotype_name,
                  container_size_id: data.container_size_id,
                  container_size_name: data.container_size_name,
                  agent_id: data.order.shipping_agent_id,
                  agent_name: data.order.shipping_agent_name,
                  carrier_id: item.carrier_id,
                  carrier_name: item.carrier_name,
                  wh_factory_1_id: data.wh_factory_1_id,
                  wh_factory_1_name: data.wh_factory_1_name,
                  wh_factory_2_id: data.wh_factory_2_id,
                  wh_factory_2_name: data.wh_factory_2_name,
                  unload_load_date1: data.unload_load_date1,
                  unload_load_date2: data.unload_load_date2,
                  unload_load_time1: this.subStringTime(data.unload_load_time1),
                  unload_load_time2: this.subStringTime(data.unload_load_time2),
                  sending_mail: item.sending_mail,
                  last_date_sending: item.last_date_sending,
                  time_sending: this.subStringTime(item.time_sending),
                  status: item.status,
                  reason: item.reason,
                  updated_at: item.updated_at,
                  model_id: item.model_id,
                  model_name: item.model_name,
                });
              });

              data.tender = mapData;
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onHandleNewJobToday() {
      this.form = {
        order_id: "",
        importer_exporter_id: "",
        load_and_unload_date_from: "",
        load_and_unload_date_to: "",
        customer_id: "",
        booking_no: "",
        create_update_tender_from: "",
        create_update_tender_to: "",
        cy_date_from: "",
        cy_date_to: "",
        inv_po: "",
        wh_factory_id: "",
        mode: [],
        carrier: "",
        new_job_today: moment(new Date()).format("YYYY-MM-DD"),
        non_tender: "",
      };
      this.search(1);
    },
    onHandleNonTender() {
      this.form = {
        order_id: "",
        importer_exporter_id: "",
        load_and_unload_date_from: "",
        load_and_unload_date_to: "",
        customer_id: "",
        booking_no: "",
        create_update_tender_from: "",
        create_update_tender_to: "",
        cy_date_from: "",
        cy_date_to: "",
        inv_po: "",
        wh_factory_id: "",
        mode: [],
        carrier: "",
        new_job_today: "",
        non_tender: "non tender",
      };
      this.search(1);
    },
    onHandleSearch() {
      this.form.new_job_today = "";
      this.form.non_tender = "";
      this.search(1);
    },
    onHandleClearSearch() {
      this.form = {
        order_id: "",
        importer_exporter_id: "",
        load_and_unload_date_from: "",
        load_and_unload_date_to: "",
        customer_id: "",
        booking_no: "",
        create_update_tender_from: "",
        create_update_tender_to: "",
        cy_date_from: "",
        cy_date_to: "",
        inv_po: "",
        wh_factory_id: "",
        mode: [],
        carrier: "",
        new_job_today: "",
        non_tender: "",
      };
      this.search(1);
    },

    //* =====> Export Excel
    exportExcel() {
      if (this.selectedMain.length === 0) {
        this.$swal.fire({
          title: "Something went wrong!",
          text: "Please, Select at least 1 item",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      } else {
        const arr = this.selectedMain.sort((a, b) => {
          return new Date(b.order.cy_date) - new Date(a.order.cy_date);
        });

        //! set data and check mode
        let mode = this.selectedMain[0].order.mode;
        let no = 1;

        for (const data of this.selectedMain) {
          //! if mode not match
          if (mode !== data.order.mode) {
            this.$swal.fire({
              title: "Something went wrong!",
              text: `Please select the same mode. (${mode})`,
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            });
            this.isClearSelected = true;
            return;
          } else {
            //! map data to dataExcel
            this.setDataExcel(data, no);
            no++;
            this.$swal
              .fire({
                title: "Are you sure?",
                text: `Do you want to export Order to Excel?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, Export!",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  const dataWS = XLSX.utils.json_to_sheet(this.dataExcel);
                  const wb = XLSX.utils.book_new();
                  XLSX.utils.book_append_sheet(wb, dataWS);
                  const fileName = `Tender_${
                    this.selectedMain[0].order.mode
                  }_${moment(new Date()).format("DD_MM_YYYY_h_mm_ss")}.xlsx`;
                  XLSX.writeFile(wb, fileName);
                  this.isClearSelected = true;
                  this.dataExcel = [];
                } else {
                  this.dataExcel = [];
                  this.isClearSelected = true;
                }
              });
          }
        }
      }
    },

    setDataExcel(data, no) {
      let mode = data.order.mode;
      // * กรณีข้อมมูลเป็นโหมด Import
      if (mode === "Import") {
        if (data.tender.length > 0) {
          data.tender.forEach((item) => {
            this.dataExcel.push({
              "No.": no,
              "Mode of Transport ": data.order.mode,
              "CS* ": data.order.user_name,
              "Remark ": data.order.remark,
              "CUSTOMER ": data.order.customer_name,
              "IMPORTER* ": data.order.importer_name,
              "Goods* ": data.order.goods_name,
              "Inv./PO NO.*": data.order.inv_po,
              "ATA ": data.order.ata
                ? moment(data.order.ata).format("DD/MM/YYYY")
                : "",
              "วันที่ตู้ครบ ": data.order.discharge_complete
                ? moment(data.order.discharge_complete).format("DD/MM/YYYY")
                : "",
              "AGENT* ": data.order.shipping_agent_name,
              "Discharge PORT* ": data.order.port_discharge_name,
              "Release Place* ": data.order.release_port_name,
              "Release Date* ": data.order.release_date
                ? moment(data.order.release_date).format("DD/MM/YYYY")
                : "",
              "Qty ": item.qty,
              "Size+ISO Type ":
                data.container_size_name + "'" + data.container_isotype_name,
              "Shipping ": data.order.shipping_name,
              "Status Container": data.order.container_status,
              "Model ": item.model_name,
              "WH/Factory1* ": data.wh_factory_1_name,
              "UnloadDate1* ": data.unload_load_date1
                ? moment(data.unload_load_date1).format("DD/MM/YYYY")
                : "",
              "UnloadTime1* ": this.subStringTime(data.unload_load_time1),
              "WH/Factory2* ": data.wh_factory_2_name,
              "UnloadDate2* ": data.unload_load_date2
                ? moment(data.unload_load_date2).format("DD/MM/YYYY")
                : "",
              "UnloadTime2* ": this.subStringTime(data.unload_load_time2),
              // "Haulage ": item.carrier_name,
              "Haulage ": item.carrier_name
                ? item.carrier_name
                : data.order.haulage_name,
              "Tender Date ": item.updated_at
                ? moment(item.updated_at).format("DD/MM/YYYY")
                : "",
              "Driver ": "",
              "Assign Driver Date ": "",
              "RETURN PLACE* ": data.return_place_name,
              "RENT ": data.order.rent
                ? moment(data.order.rent).format("DD/MM/YYYY")
                : "",
              "DEM ": data.order.demurage
                ? moment(data.order.demurage).format("DD/MM/YYYY")
                : "",
              "DET ": data.order.detention
                ? moment(data.order.detention).format("DD/MM/YYYY")
                : "",
              "DET(Days) ": data.order.detention_days,
              "Status (Pending,Complete) ": "",
              "Status (Complete) Date ": "",
            });
          });
        } else {
          this.dataExcel.push({
            "No.": no,
            "Mode of Transport ": data.order.mode,
            "CS* ": data.order.user_name,
            "Remark ": data.order.remark,
            "CUSTOMER ": data.order.customer_name,
            "IMPORTER* ": data.order.importer_name,
            "Goods* ": data.order.goods_name,
            "Inv./PO NO.*": data.order.inv_po,
            "ATA ": data.order.ata
              ? moment(data.order.ata).format("DD/MM/YYYY")
              : "",
            "วันที่ตู้ครบ ": data.order.discharge_complete
              ? moment(data.order.discharge_complete).format("DD/MM/YYYY")
              : "",
            "AGENT* ": data.order.shipping_agent_name,
            "Discharge PORT* ": data.order.port_discharge_name,
            "Release Place* ": data.order.release_port_name,
            "Release Date* ": data.order.release_date
              ? moment(data.order.release_date).format("DD/MM/YYYY")
              : "",
            "Qty ": data.qty,
            "Size+ISO Type ":
              data.container_size_name + "'" + data.container_isotype_name,
            "Shipping ": data.order.shipping_name,
            "Status Container": data.order.container_status,
            "Model ": "",
            "WH/Factory1* ": data.wh_factory_1_name,
            "UnloadDate1* ": data.unload_load_date1
              ? moment(data.unload_load_date1).format("DD/MM/YYYY")
              : "",
            "UnloadTime1* ": this.subStringTime(data.unload_load_time1),
            "WH/Factory2* ": data.wh_factory_2_name,
            "UnloadDate2* ": data.unload_load_date2
              ? moment(data.unload_load_date2).format("DD/MM/YYYY")
              : "",
            "UnloadTime2* ": this.subStringTime(data.unload_load_time2),
            // "Haulage ": data.order.haulage_name,
            "Haulage ": data.order.haulage_name,
            "Tender Date ": "",
            "Driver ": "",
            "Assign Driver Date ": "",
            "RETURN PLACE* ": data.return_place_name,
            "RENT ": data.order.rent
              ? moment(data.order.rent).format("DD/MM/YYYY")
              : "",
            "DEM ": data.order.demurage
              ? moment(data.order.demurage).format("DD/MM/YYYY")
              : "",
            "DET ": data.order.detention
              ? moment(data.order.detention).format("DD/MM/YYYY")
              : "",
            "DET(Days) ": data.order.detention_days,
            "Status (Pending,Complete) ": "",
            "Status (Complete) Date ": "",
          });
        }
      }
      // * กรณีข้อมมูลเป็นโหมด Export และอื่นๆ
      else {
        if (data.tender.length > 0) {
          data.tender.forEach((item) => {
            this.dataExcel.push({
              "No.": no,
              "Mode of Transport ": data.order.mode,
              "CS* ": data.order.user_name,
              "Remark ": data.order.remark,
              "CUSTOMER ": data.order.customer_name,
              "EXPORTER* ": data.order.exporter_name,
              "Goods* ": data.order.goods_name,
              "Package ": data.order.package_name,
              "Inv./PO NO.*": data.order.inv_po,
              "Booking no.*": data.order.booking_no,
              "CY PLACE*": data.cy_place_name,
              "CY DATE*": data.order.cy_date
                ? moment(data.order.cy_date).format("DD/MM/YYYY")
                : "",
              "Shipping Agent* ": data.order.shipping_agent_name,
              "Qty ": item.qty,
              // "Qty ": data.qty,
              "Size+ISO Type ":
                data.container_size_name + "'" + data.container_isotype_name,
              "Status Container": data.order.container_status,
              "Model ": item.model_name,
              "WH/Factory1* ": data.wh_factory_1_name,
              "UnloadDate1* ": data.unload_load_date1
                ? moment(data.unload_load_date1).format("DD/MM/YYYY")
                : "",
              "UnloadTime1* ": this.subStringTime(data.unload_load_time1),
              "WH/Factory2* ": data.wh_factory_2_name,
              "UnloadDate2* ": data.unload_load_date2
                ? moment(data.unload_load_date2).format("DD/MM/YYYY")
                : "",
              "UnloadTime2* ": this.subStringTime(data.unload_load_time2),
              "Ton ": data.order.ton,
              "Forwarder ": data.order.forwarder_name,
              "FEEDER VESSEL* ": data.order.feeder_vessel,
              "OCEAN VESSEL ": data.order.ocean_vessel,
              "PORT/Country* ": data.order.port_country_name,
              "RETURN PLACE* ": data.order.return_place_name,
              "CLOSING DATE* ": data.order.closing_date
                ? moment(data.order.closing_date).format("DD/MM/YYYY")
                : "",
              "CLOSING TIME* ": this.subStringTime(data.order.closing_time),
              "FRD* ": data.order.frd
                ? moment(data.order.frd).format("DD/MM/YYYY")
                : "",
              "SHIPPING ": data.order.shipping_name,
              "Haulage ": item.carrier_name
                ? item.carrier_name
                : data.order.haulage_name,
              "Tender Date ": item.updated_at
                ? moment(item.updated_at).format("DD/MM/YYYY")
                : "",
              "Driver ": "",
              "Assign Driver Date ": "",
              "SHORE ": data.order.shore_name,
              "Fumigate ": data.order.fumigate_name,
              "Survey ": data.order.survey_name,
              "Others ": data.order.others_name,
              "SI CUT OFF DATE ": data.order.si_cut_off_date
                ? moment(data.order.si_cut_off_date).format("DD/MM/YYYY")
                : "",
              "SI CUT OFF TIME ": this.subStringTime(
                data.order.si_cut_off_time
              ),
              "VGM CUT OFF DATE ": data.order.vgm_cut_off_date
                ? moment(data.order.vgm_cut_off_date).format("DD/MM/YYYY")
                : "",
              "VGM CUT OFF TIME ": this.subStringTime(
                data.order.vgm_cut_off_time
              ),
              "Status (Pending,Complete) ": "",
              "Status (Complete) Date ": "",
            });
          });
        } else {
          this.dataExcel.push({
            "No.": no,
            "Mode of Transport ": data.order.mode,
            "CS* ": data.order.user_name,
            "Remark ": data.order.remark,
            "CUSTOMER ": data.order.customer_name,
            "EXPORTER* ": data.order.exporter_name,
            "Goods* ": data.order.goods_name,
            "Package ": data.order.package_name,
            "Inv./PO NO.*": data.order.inv_po,
            "Booking no.*": data.order.booking_no,
            "CY PLACE*": data.cy_place_name,
            "CY DATE*": data.order.cy_date
              ? moment(data.order.cy_date).format("DD/MM/YYYY")
              : "",
            "Shipping Agent* ": data.order.shipping_agent_name,
            "Qty ": data.qty,
            "Size+ISO Type ":
              data.container_size_name + "'" + data.container_isotype_name,
            "Status Container": data.order.container_status,
            "Model ": "",
            "WH/Factory1* ": data.wh_factory_1_name,
            "UnloadDate1* ": data.unload_load_date1
              ? moment(data.unload_load_date1).format("DD/MM/YYYY")
              : "",
            "UnloadTime1* ": this.subStringTime(data.unload_load_time1),
            "WH/Factory2* ": data.wh_factory_2_name,
            "UnloadDate2* ": data.unload_load_date2
              ? moment(data.unload_load_date2).format("DD/MM/YYYY")
              : "",
            "UnloadTime2* ": this.subStringTime(data.unload_load_time2),
            "Ton ": data.order.ton,
            "Forwarder ": data.order.forwarder_name,
            "FEEDER VESSEL* ": data.order.feeder_vessel,
            "OCEAN VESSEL ": data.order.ocean_vessel,
            "PORT/Country* ": data.order.port_country_name,
            "RETURN PLACE* ": data.order.return_place_name,
            "CLOSING DATE* ": data.order.closing_date
              ? moment(data.order.closing_date).format("DD/MM/YYYY")
              : "",
            "CLOSING TIME* ": this.subStringTime(data.order.closing_time),
            "FRD* ": data.order.frd
              ? moment(data.order.frd).format("DD/MM/YYYY")
              : "",
            "SHIPPING ": data.order.shipping_name,
            "Haulage ": data.order.haulage_name,
            "Tender Date ": "",
            "Driver ": "",
            "Assign Driver Date ": "",
            "SHORE ": data.order.shore_name,
            "Fumigate ": data.order.fumigate_name,
            "Survey ": data.order.survey_name,
            "Others ": data.order.others_name,
            "SI CUT OFF DATE ": data.order.si_cut_off_date
              ? moment(data.order.si_cut_off_date).format("DD/MM/YYYY")
              : "",
            "SI CUT OFF TIME ": this.subStringTime(data.order.si_cut_off_time),
            "VGM CUT OFF DATE ": data.order.vgm_cut_off_date
              ? moment(data.order.vgm_cut_off_date).format("DD/MM/YYYY")
              : "",
            "VGM CUT OFF TIME ": this.subStringTime(
              data.order.vgm_cut_off_time
            ),
            "Status (Pending,Complete) ": "",
            "Status (Complete) Date ": "",
          });
        }
      }
    },

    //! Get Master data
    getMasterData() {
      const table = ["importer", "customers", "wh_factory"];
      this.$store
        .dispatch("master_data/getMasterData", table)
        .then((res) => {
          this.importerExporter = res.data[0].importer;
          this.customer = res.data[1].customers;
          this.factoryList = res.data[2].wh_factory;
          this.modelList = res.data.scrap_cabinet_model;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getDataChart() {
      this.isLoadingChart = true;
      this.$store
        .dispatch("tender/getDataChart")
        .then((res) => {
          if (res.data.length > 0) {
            for (const data of res.data) {
              this.dataChart.push({
                graph_no: data.graph_no,
                carrier_name: data.name,
                carrier_code: data.code,
                commit:
                  (data.drivers.status_group.Block
                    ? data.drivers.status_group.Block.length
                    : 0) +
                  (data.drivers.status_group.Inactive
                    ? data.drivers.status_group.Inactive.length
                    : 0) +
                  (data.drivers.status_group.Past
                    ? data.drivers.status_group.Past.length
                    : 0) +
                  (data.drivers.status_group.Pending
                    ? data.drivers.status_group.Pending.length
                    : 0) +
                  (data.drivers.status_group.Active
                    ? data.drivers.status_group.Active.length
                    : 0),
                status: [
                  //! Block + Inactive
                  (data.drivers.status_group.Block
                    ? data.drivers.status_group.Block.length
                    : 0) +
                    (data.drivers.status_group.Inactive
                      ? data.drivers.status_group.Inactive.length
                      : 0),
                  //! Past
                  data.drivers.status_group.Past
                    ? data.drivers.status_group.Past.length
                    : 0,
                  //! Pending
                  data.drivers.status_group.Pending
                    ? data.drivers.status_group.Pending.length
                    : 0,
                  //! Active
                  data.drivers.status_group.Active
                    ? data.drivers.status_group.Active.length
                    : 0,
                ],
                labels: [
                  `Not Avaliable ${(data.drivers.status_group.Block
                    ? data.drivers.status_group.Block.length
                    : 0) +
                    (data.drivers.status_group.Inactive
                      ? data.drivers.status_group.Inactive.length
                      : 0)}`,
                  `Past ${
                    data.drivers.status_group.Past
                      ? data.drivers.status_group.Past.length
                      : 0
                  }`,
                  `Not Free ${
                    data.drivers.status_group.Pending
                      ? data.drivers.status_group.Pending.length
                      : 0
                  }`,
                  `Avaliable ${
                    data.drivers.status_group.Active
                      ? data.drivers.status_group.Active.length
                      : 0
                  }`,
                ],
              });
            }
          }

          this.isLoadingChart = false;
        })
        .catch((e) => {
          this.isLoadingChart = false;
          console.log(e);
        });
    },
    getMasterCarrier() {
      const fields = ["is_haulage"];
      this.$store
        .dispatch("master_data/getMasterCarrier", fields)
        .then((res) => {
          this.carrierList = res.data[0].is_haulage;
        });
    },
  },
};
</script>

<style scope>
/* .test thead.v-data-table-header {
  background-color: #4b5768;
} */
.table-color {
  background-color: #99ca545e;
}

.container-tender {
  width: 100%;
  padding: 12px;
  margin-right: auto;
  margin-left: auto;
}
.active-bg-qty {
  background: #f7d25d;
}
</style>
