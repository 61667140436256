<template>
  <div>
    <v-text-field
      :type="type"
      :height="height"
      :placeholder="placeholder"
      color="gray"
      :background-color="disabled ? '#E5E7E9' : '#fff'"
      outlined
      dense
      :disabled="disabled"
      :min="type === 'number' ? 0 : ''"
      :class="borderColor"
      :value="model"
      :rules="required ? [(v) => !!v || 'field is required'] : []"
      :label="label"
      :oninput="returnInputRule"
      required
      :readonly="readonly"
      :hide-details="hideDetails"
      @input="onChange"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    label: {
      default: "",
    },
    placeholder: {
      default: "",
    },
    height: {
      default: "37px",
    },
    borderColor: {
      default: "gray",
    },
    type: {
      default: "text",
    },
    inputRule: String,
  },
  computed: {
    returnInputRule: function() {
      switch (this.inputRule) {
        case "number":
          return "value=value.replace(/[^\\d]/g,'')";

        case "decimal":
          return "value=value.replace(/[^0-9\\.]/g,'')";

        default:
          return null;
      }
    },
  },
  data() {
    return {
      fieldRequire: [
        (v) => !!v || "Name is required",
        // (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
      ],
      ruleNumber: [
        (v) => !!v || "Name is required",
        // (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
    };
  },
  methods: {
    onChange(val) {
      this.$emit("update-data", val);
    },
  },
};
</script>

<style></style>
