import AuthService from "../AuthService";
import Service from "../Service";

const tender = {
  getData(page) {
    return AuthService.get(`/api/tender?page=${page}`).then((res) => res);
  },
  getDataChart(page) {
    return AuthService.get(`/api/get-data-chart`).then((res) => res);
  },
  getDataById(id) {
    return AuthService.get(`/api/tender/${id}`).then((res) => res);
  },
  save(data) {
    return AuthService.post("/api/tender/save", data).then((res) => res);
  },
  update(data) {
    return AuthService.post(`/api/tender/update/${data.id}`, data).then(
      (res) => res
    );
  },
  delete(id) {
    return AuthService.post(`/api/tender/delete/${id}`).then((res) => res);
  },
  resendMail(id) {
    return AuthService.post(`/api/resend-mail/${id}`).then((res) => res);
  },
  duplicate(id) {
    return AuthService.post(`/api/tender/duplicate/${id}`).then((res) => res);
  },
  print(data) {
    return AuthService.post(`/api/tender-print/`, data).then((res) => res);
  },
  search(data) {
    return AuthService.get(
      `/api/tender-search?page=${data.page}&` +
        `order_id=${data.order_id}&` +
        `importer_exporter_id=${data.importer_exporter_id}&` +
        `load_and_unload_date_from=${data.load_and_unload_date_from}&` +
        `load_and_unload_date_to=${data.load_and_unload_date_to}&` +
        `customer_id=${data.customer_id}&` +
        `booking_no=${data.booking_no}&` +
        `create_update_tender_from=${data.create_update_tender_from}&` +
        `create_update_tender_to=${data.create_update_tender_to}&` +
        `inv_po=${data.inv_po}&` +
        `wh_factory_id=${data.wh_factory_id}&` +
        `carrier=${data.carrier}&` +
        `mode=${JSON.stringify(data.mode)}&` +
        `new_job_today=${data.new_job_today}&` +
        `non_tender=${data.non_tender}&` +
        `cy_date_from=${data.cy_date_from}&` +
        `cy_date_to=${data.cy_date_to}`
    ).then((res) => res);
  },
};

export default tender;
