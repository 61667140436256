<template>
  <div>
    <div class="ml-8 mb-4 mt-1">
      <v-icon small class="mr-2">mdi-file</v-icon>
      <span class=" font-weight-bold text-uppercase">Order</span>
    </div>

    <div class="mx-8">
      <v-card flat outlined class="py-6 mb-8">
        <div class="pl-6 font-20 font-weight-bold">Volume</div>
        <v-layout class=" justify-center">
          <v-flex xs11>
            <BarChartStack
              v-if="barLoaded"
              :data="chartData"
              :options="chartOptions"
            />
          </v-flex>
        </v-layout>

        <v-layout my-6 justify-center>
          <v-flex
            style="width: 79px"
            class="font-14 shrink pl-3 font-weight-bold"
          >
            งานทั้งหมด
          </v-flex>
          <v-flex
            style="width: 60px;"
            class="text-center"
            v-for="(all_job, i) in all_jobs"
            :key="i"
            :style="i < 13 ? 'border-right: 1px solid rgba(0,0,0,0.25)' : ''"
          >
            {{ all_job }}
          </v-flex>
          <v-flex style="width: 30px"> </v-flex>
        </v-layout>
      </v-card>

      <!-- Truck supply -->
      <v-card flat outlined class="py-6">
        <div class="pl-6 font-20 font-weight-bold">
          Truck Supply
        </div>
        <v-layout class=" justify-center">
          <v-flex xs11>
            <BarChart
              v-if="barLoaded"
              :data="chartDataTruckSupply"
              :options="chartOptions"
            />
          </v-flex>
        </v-layout>
      </v-card>
    </div>

    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="false"
    />
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import BarChart from "../../../components/chart/BarChart.vue";
import BarChartStack from "../../../components/chart/BarChartStack.vue";
export default {
  components: { Loading, BarChart, BarChartStack },
  data: () => ({
    barLoaded: false,
    isLoading: false,
    fullPage: true,
    data_label: [],
    data_detail: [],
    all_jobs: [],
    truck_supplys: [],
    chartOptions: {
      hoverBorderWidth: 20,
    },
    chartData: {
      hoverBackgroundColor: "red",
      hoverBorderWidth: 10,
      labels: [],
      datasets: [],
    },
    chartDataTruckSupply: {
      hoverBackgroundColor: "red",
      hoverBorderWidth: 10,
      labels: [],
      datasets: [],
    },
  }),
  created() {
    this.getDataHome();
  },
  methods: {
    getDataHome() {
      this.isLoading = true;
      this.$store
        .dispatch("master_data/getDataHome")
        .then((res) => {
          //* ===> หางานจำนวนทั้งหทด
          res.data.has_accept_tender.forEach((data, i) => {
            this.all_jobs.push(data + res.data.non_accept_tender[i]);
          });

          //* ===> ข้อมูลสำหรับกราฟ Volume (งานที่สร้างเสร็จแล้ว)
          this.chartData.labels = res.data.dates;
          this.chartData.datasets.push({
            label: "งานที่สร้างเสร็จแล้ว",
            backgroundColor: "#338A67",
            data: res.data.has_accept_tender,
          });

          //* ===> ข้อมูลสำหรับกราฟ Volume (งานคงค้าง)
          this.chartData.datasets.push({
            label: "งานคงค้าง",
            backgroundColor: "#4F4F4F",
            data: res.data.non_accept_tender,
          });

          this.chartDataTruckSupply.labels = res.data.dates;
          this.chartDataTruckSupply.datasets.push({
            label: "รถคงเหลือ",
            backgroundColor: "#4D72BE",
            data: res.data.truck_supply,
          });
          this.isLoading = false;
        })
        .then(() => {
          this.barLoaded = true;
        });
    },
  },
};
</script>

<style></style>
