<template>
  <div style="width: 100%" class="table-order">
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="items"
      item-key="order_id"
      class="elevation-1"
      show-expand
      :items-per-page="25"
      hide-default-footer
      show-select
    >
      <template
        v-slot:item="{
          item,
          isSelected,
          select,
          expand,
          isExpanded,
        }"
      >
        <tr :class="isExpanded && 'td-row-active'">
          <td class="text-center">
            <v-icon v-if="!isExpanded" @click="expand(!isExpanded)">
              mdi-chevron-down
            </v-icon>
            <v-icon v-else @click="expand(!isExpanded)">mdi-chevron-up</v-icon>
          </td>
          <td class="border-right">
            <v-simple-checkbox
              color="#338A68"
              :value="isSelected"
              @input="select($event)"
            ></v-simple-checkbox>
          </td>
          <td class="text-center border-right">
            <v-layout>
              <v-flex v-if="!user.carrier_id && user.user_group_id != 2">
                <v-btn fab depressed small text @click="deleteItem(item)">
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </v-flex>
              <v-flex>
                <v-btn
                  v-if="user.carrier_id || user.user_group_id == 2"
                  fab
                  depressed
                  small
                  text
                  @click="viewItem(item)"
                >
                  <v-icon color="#62C4E3">mdi-eye-outline</v-icon>
                </v-btn>
                <v-btn v-else fab depressed small text @click="editItem(item)">
                  <v-icon color="#62C4E3">mdi-pencil-outline</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </td>
          <td class="text-left">{{ item.no }}</td>
          <td class="text-left">{{ item.order_id }}</td>
          <td class="text-left">{{ item.inv_po }}</td>
          <td class="text-left">
            {{ item.cy_date | moment("DD/MM/YYYY") }}
          </td>
          <td class="text-left">
            {{ checkQty(item.order_detail_with_tender) }}
          </td>
          <td class="text-left">
            {{ groupContainerSize(item.order_detail_with_tender) }}
          </td>
          <td class="text-left">
            {{ groupContainerIsotype(item.order_detail_with_tender) }}
          </td>
          <td class="text-left">
            {{ item.customers ? item.customers.customer_code : "" }}
          </td>
          <td class="text-left">
            {{ item.booking_no ? item.booking_no : "--" }}
          </td>
          <td class="text-left">
            {{ item.haulage_name ? item.haulage_name : "--" }}
          </td>
          <td class="text-left">
            {{ item.return_place_name ? item.return_place_name : "--" }}
          </td>
          <td class="text-left">
            {{ item.closing_date | moment("DD/MM/YYYY") }}
          </td>
          <td class="text-left">
            {{ subStringTime(item.closing_time) }}
          </td>
          <td class="text-left">
            {{ item.frd | moment("L") }}
          </td>
          <td class="text-left">
            {{ item.shipping_agent_name ? item.shipping_agent_name : "--" }}
          </td>
          <td class="text-left">
            {{ item.updated_at | moment("DD/MM/YYYY") }}
          </td>
          <td class="text-left">{{ item.user_name }}</td>
          <td class="text-left">
            {{ item.importer_name ? item.importer_name : "--" }}
          </td>
          <td class="text-left">
            {{ item.exporter_name ? item.exporter_name : "--" }}
          </td>
        </tr>
      </template>

      <!-- expanded -->
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-data-table
            :headers="headers_order_detail"
            :items="item.order_detail_with_tender"
            item-key="order_detail"
            class="elevation-1 table-child"
            hide-default-footer
          >
            <template
              v-slot:item="{
                item,
                index,
              }"
            >
              <tr>
                <td colspan="2"></td>
                <td class="text-left">
                  {{ index + 1 }}
                </td>
                <td class="text-left">{{ item.order_id }}</td>
                <td class="text-left">
                  {{ item.cy_place_name ? item.cy_place_name : "--" }}
                </td>
                <td class="text-left">
                  {{ item.return_place_name ? item.return_place_name : "--" }}
                </td>
                <td class="text-left">
                  {{ item.qty }}
                </td>
                <td class="text-left">
                  {{ item.container_size_name }}
                </td>
                <td class="text-left">
                  {{ item.container_isotype_name }}
                </td>
                <td class="text-left">
                  {{ item.wh_factory_1_name }}
                </td>
                <td class="text-center">
                  {{ item.unload_load_date1 | moment("DD/MM/YYYY") }}
                </td>
                <td class="text-center">
                  <!-- {{ item.unload_load_time1 }} -->
                  {{ subStringTime(item.unload_load_time1) }}
                </td>
                <td class="text-left">
                  {{ item.wh_factory_2_name }}
                </td>
                <td class="text-left">
                  {{ item.unload_load_date2 | moment("DD/MM/YYYY") }}
                </td>
                <td class="text-left">
                  {{ subStringTime(item.unload_load_time2) }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { eventBus } from "../../services/Constants";
export default {
  props: {
    headers: Array,
    items: Array,
    nameTable: String,
    editItem: Function,
    viewItem: Function,
    deleteItem: Function,
    selectOrder: Function,
    isClearSelected: Boolean,
  },
  data: () => ({
    onRender: true,
    selected: [],
    headers_order_detail: [
      {
        text: "",
        value: "select",
        sortable: false,
        width: "89px",
        class: "bg-gray",
        align: "start",
      },
      {
        text: "",
        value: "actions",
        sortable: false,
        width: "65px",
        class: "bg-gray",
      },
      {
        text: "No.",
        value: "no",
        align: "start",
        sortable: false,
        width: "1px",
        class: "bg-gray",
      },
      {
        text: "Job No.",
        value: "job_no",
        align: "start",
        sortable: false,
        width: "100px",
        class: "bg-gray",
      },
      {
        text: "CY Place",
        value: "cy_place",
        align: "start",
        sortable: false,
        width: "100px",
        class: "bg-gray",
      },
      {
        text: "Return Place",
        align: "start",
        sortable: false,
        width: "120px",
        class: "bg-gray",
      },
      {
        text: "Qty",
        value: "qty",
        align: "start",
        sortable: false,
        width: "20px",
        class: "bg-gray",
      },
      {
        text: "Size Container",
        align: "start",
        sortable: false,
        width: "120px",
        class: "bg-gray",
      },
      {
        text: "Isotype",
        align: "start",
        sortable: false,
        width: "130px",
        class: "bg-gray",
      },
      {
        text: "WH/factory 1",
        value: "wh_factory1",
        align: "start",
        sortable: false,
        width: "120px",
        class: "bg-gray",
      },
      {
        text: "Unload/Load Date 1",
        value: "unload_load_date_1",
        align: "center",
        sortable: false,
        width: "120px",
        class: "bg-gray",
      },
      {
        text: "Unload/Load Time 1",
        value: "unload_load_time_1",
        align: "center",
        sortable: false,
        width: "120px",
        class: "bg-gray",
      },
      {
        text: "WH/factory 2",
        value: "wh_factory2",
        align: "left",
        sortable: false,
        width: "120px",
        class: "bg-gray",
      },
      {
        text: "Unload/Load Date 2",
        value: "unload_load_date_2",
        align: "center",
        sortable: false,
        width: "120px",
        class: "bg-gray",
      },
      {
        text: "Unload/Load Time 2",
        value: "unload_load_time_2",
        align: "center",
        sortable: false,
        width: "120px",
        class: "bg-gray",
      },
    ],
  }),
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  watch: {
    isClearSelected: {
      handler: function(newValue) {
        if (newValue) {
          this.selected = [];
        }
      },
    },
    selected: {
      handler: function(newValue) {
        this.selectOrder(newValue);
      },
    },
  },
  created() {
    eventBus.$on("onloadTable", () => {
      this.onRender = false;
      setTimeout(() => {
        this.onRender = true;
      }, 10);
    });
  },
  methods: {
    checkQty(containers) {
      var total = 0;
      containers.forEach((item) => {
        total += parseInt(item.qty);
      });
      return total;
    },
    groupContainerSize(containers) {
      var total = "";
      containers.forEach((item, i) => {
        total +=
          item.container_size_name + (containers.length == i + 1 ? "" : "/");
      });
      return total;
    },
    groupContainerIsotype(containers) {
      var total = "";
      containers.forEach((item, i) => {
        total += item.container_isotype_name
          ? item.container_isotype_name +
            (containers.length == i + 1 ? "" : "/")
          : "";
      });
      return total;
    },
    selectAll(event) {
      if (event.status) {
        alert("selected all");
      } else {
        alert("deselected all");
      }
    },
    changeTime(val) {
      if (val) {
        if (val.length > 5) {
          return val.substring(0, 5);
        }
      }
      return val;
    },
  },
};
</script>

<style lang="css">
.table-border {
  /* border: 1px solid #fff; */
  color: #fff;
}
</style>
