import AuthService from "../AuthService";
import Service from "../Service";

const tollScale = {
  getData(page) {
    return AuthService.get(`/api/tolls-scale?page=${page}`).then((res) => res);
    // .catch((err) => {
    //   AuthService.error(err);
    // });
  },
  getDataById(id) {
    return AuthService.get(`/api/tolls-scale/${id}`).then((res) => res);
    // .catch((err) => {
    //   AuthService.error(err);
    // });
  },
  save(data) {
    return AuthService.post("/api/tolls-scale/save", data).then((res) => res);
    // .catch((err) => {
    //   AuthService.error(err);
    // });
  },
  update(data) {
    return AuthService.post(
      `/api/tolls-scale/update/${data.tolls_scale_id}`,
      data
    ).then((res) => res);
    // .catch((err) => {
    //   AuthService.error(err);
    // });
  },
  delete(id) {
    return AuthService.post(`/api/tolls-scale/delete/${id}`).then((res) => res);
    // .catch((err) => {
    //   AuthService.error(err);
    // });
  },
};

export default tollScale;
