<template>
  <v-layout>
    <!-- 1 -->
    <v-flex>
      <v-btn
        class="pa-1 tab-table"
        width="100%"
        depressed
        :color="$route.query.tab === 'accept-tender' ? '#4B5768' : '#fff'"
        :dark="$route.query.tab === 'accept-tender' ? true : false"
        @click="onClickTab('accept-tender')"
      >
        <span class="text-capitalize font-10 font-weight-bold">
          Accept Tender ({{ count.accept_tender_count }})
        </span>
      </v-btn>
    </v-flex>

    <!-- 2 -->
    <v-flex>
      <v-btn
        class="pa-1 tab-table"
        width="100%"
        depressed
        :color="$route.query.tab === 'receive-container' ? '#4B5768' : '#fff'"
        :dark="$route.query.tab === 'receive-container' ? true : false"
        @click="onClickTab('receive-container')"
      >
        <span class="text-capitalize font-10 font-weight-bold">
          Receive Container ({{ count.receive_container_count }})
        </span>
      </v-btn>
    </v-flex>

    <!-- 3 -->
    <v-flex v-if="mode !== 'Empty' && mode !== 'Drop Load' && mode !== 'Laden'">
      <v-btn
        class="pa-1 tab-table"
        width="100%"
        depressed
        :color="$route.query.tab === 'arrived' ? '#4B5768' : '#fff'"
        :dark="$route.query.tab === 'arrived' ? true : false"
        @click="onClickTab('arrived')"
      >
        <span class="text-capitalize font-10 font-weight-bold">
          Arrived ({{ count.arrived_count }})
        </span>
      </v-btn>
    </v-flex>

    <!-- 4 -->
    <v-flex v-if="mode !== 'Empty' && mode !== 'Drop Load' && mode !== 'Laden'">
      <v-btn
        class="pa-1 tab-table"
        width="100%"
        depressed
        :color="$route.query.tab === 'start-load-unload' ? '#4B5768' : '#fff'"
        :dark="$route.query.tab === 'start-load-unload' ? true : false"
        @click="onClickTab('start-load-unload')"
      >
        <span class="text-capitalize font-10 font-weight-bold">
          Start Load/unload ({{ count.start_load_unload_count }})
        </span>
      </v-btn>
    </v-flex>

    <!-- 5 -->
    <v-flex v-if="mode !== 'Empty' && mode !== 'Laden'">
      <v-btn
        class="pa-1 tab-table"
        width="100%"
        depressed
        :color="$route.query.tab === 'end-load-unload' ? '#4B5768' : '#fff'"
        :dark="$route.query.tab === 'end-load-unload' ? true : false"
        @click="onClickTab('end-load-unload')"
      >
        <span class="text-capitalize font-10 font-weight-bold">
          End Load/unload ({{ count.end_load_unload_count }})
        </span>
      </v-btn>
    </v-flex>

    <!-- 6 -->
    <v-flex>
      <v-btn
        class="pa-1 tab-table"
        width="100%"
        depressed
        :color="$route.query.tab === 'return' ? '#4B5768' : '#fff'"
        :dark="$route.query.tab === 'return' ? true : false"
        @click="onClickTab('return')"
      >
        <span class="text-capitalize font-10 font-weight-bold">
          Return ({{ count.return_count }})
        </span>
      </v-btn>
    </v-flex>

    <v-flex>
      <v-btn
        class="pa-1 tab-table"
        width="100%"
        depressed
        :color="$route.query.tab === 'shipping' ? '#4B5768' : '#fff'"
        :dark="$route.query.tab === 'shipping' ? true : false"
        @click="onClickTab('shipping')"
      >
        <span class="text-capitalize font-10 font-weight-bold">
          Shipping ({{ count.shipping_count }})
        </span>
      </v-btn>
    </v-flex>

    <v-flex>
      <v-btn
        class="pa-1 tab-table"
        width="100%"
        depressed
        :color="$route.query.tab === 'shore' ? '#4B5768' : '#fff'"
        :dark="$route.query.tab === 'shore' ? true : false"
        @click="onClickTab('shore')"
      >
        <span class="text-capitalize font-10 font-weight-bold">
          Shore ({{ count.shore_count }})
        </span>
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: {
    count: null,
    mode: String,
    onClickTab: Function,
  },
};
</script>

<style>
.tab-table {
  border-radius: 5px 5px 0px 0px !important;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
}
</style>
