<template>
  <v-card outlined class="py-2 px-3">
    <v-layout class="my-1">
      <v-flex xs3>
        <v-layout align-center mb-3>
          <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
            Job no. :
          </v-flex>
          <v-flex xs9>
            <v-text-field
              class="readonly-text-field"
              v-model="order.order_id"
              outlined
              dense
              readonly
              hide-details
              background-color="#F5F5F5"
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-layout align-center mb-3>
          <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
            INV./PO :
          </v-flex>
          <v-flex xs9>
            <v-text-field
              class="readonly-text-field"
              v-model="order.inv_po"
              outlined
              dense
              readonly
              hide-details
              background-color="#F5F5F5"
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-layout align-center mb-3>
          <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
            CY Place/<br />Release Port :
          </v-flex>
          <v-flex xs9>
            <v-text-field
              class="readonly-text-field"
              v-model="order.customer_name"
              outlined
              dense
              readonly
              hide-details
              background-color="#F5F5F5"
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-layout align-center mb-3>
          <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
            WH/Factory1 :
          </v-flex>
          <v-flex xs9>
            <v-text-field
              class="readonly-text-field"
              v-model="wh_Factory1"
              outlined
              dense
              readonly
              hide-details
              background-color="#F5F5F5"
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-layout align-center mb-3>
          <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
            WH/Factory2 :
          </v-flex>
          <v-flex xs9>
            <v-text-field
              class="readonly-text-field"
              v-model="wh_Factory2"
              outlined
              dense
              readonly
              hide-details
              background-color="#F5F5F5"
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-layout align-center mb-3>
          <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
            Rent :
          </v-flex>
          <v-flex xs9>
            <v-text-field
              class="readonly-text-field"
              v-model="rent"
              outlined
              dense
              readonly
              hide-details
              background-color="#F5F5F5"
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex xs3>
        <v-layout align-center mb-3>
          <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
            Customer :
          </v-flex>
          <v-flex xs9>
            <v-text-field
              class="readonly-text-field"
              v-model="order.customer_name"
              outlined
              dense
              readonly
              hide-details
              background-color="#F5F5F5"
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-layout align-center mb-3>
          <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
            Booking :
          </v-flex>
          <v-flex xs9>
            <v-text-field
              class="readonly-text-field"
              v-model="order.booking_no"
              outlined
              dense
              readonly
              hide-details
              background-color="#F5F5F5"
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-layout align-center mb-3>
          <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
            CY Date/<br />Release Date :
          </v-flex>
          <v-flex xs9>
            <v-text-field
              class="readonly-text-field"
              v-model="cy_and_release_date"
              outlined
              dense
              readonly
              hide-details
              background-color="#F5F5F5"
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-layout align-center mb-3>
          <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
            Load/Unload <br />Date Time 1 :
          </v-flex>
          <v-flex xs9>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  class="readonly-text-field"
                  v-model="load_unload1"
                  outlined
                  dense
                  readonly
                  hide-details
                  background-color="#F5F5F5"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  class="readonly-text-field"
                  v-model="unload_load_time1"
                  outlined
                  dense
                  readonly
                  hide-details
                  background-color="#F5F5F5"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-flex>
        </v-layout>

        <v-layout align-center mb-3>
          <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
            Load/Unload <br />Date Time 2 :
          </v-flex>
          <v-flex xs9>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  class="readonly-text-field"
                  v-model="load_unload2"
                  outlined
                  dense
                  readonly
                  hide-details
                  background-color="#F5F5F5"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  class="readonly-text-field"
                  v-model="unload_load_time2"
                  outlined
                  dense
                  readonly
                  hide-details
                  background-color="#F5F5F5"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-flex>
        </v-layout>
        <v-layout align-center mb-3>
          <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
            Demurage :
          </v-flex>
          <v-flex xs9>
            <v-text-field
              class="readonly-text-field"
              v-model="demurage"
              outlined
              dense
              readonly
              hide-details
              background-color="#F5F5F5"
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex xs3>
        <v-layout align-center mb-3>
          <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
            Importer / <br />
            Exporter :
          </v-flex>
          <v-flex xs9>
            <v-text-field
              class="readonly-text-field"
              v-model="importer_exporter_name"
              outlined
              dense
              readonly
              hide-details
              background-color="#F5F5F5"
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-layout align-center mb-3>
          <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
            Qty :
          </v-flex>
          <v-flex xs9>
            <v-text-field
              class="readonly-text-field"
              v-model="qty"
              outlined
              dense
              readonly
              hide-details
              background-color="#F5F5F5"
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-layout align-center mb-3>
          <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
            Shipping Agent :
          </v-flex>
          <v-flex xs9>
            <v-text-field
              class="readonly-text-field"
              v-model="order.shipping_agent_name"
              outlined
              dense
              readonly
              hide-details
              background-color="#F5F5F5"
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-layout align-center mb-3>
          <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
            Return <br />place 1 :
          </v-flex>
          <v-flex xs9>
            <v-text-field
              class="readonly-text-field"
              v-model="order.return_place_name"
              outlined
              dense
              readonly
              hide-details
              background-color="#F5F5F5"
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-layout align-center mb-3>
          <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
            Return <br />place 2 :
          </v-flex>
          <v-flex xs9>
            <v-text-field
              class="readonly-text-field"
              v-model="order.return_place_name"
              outlined
              dense
              readonly
              hide-details
              background-color="#F5F5F5"
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-layout align-center mb-3>
          <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
            Closing<br />Date Time* :
          </v-flex>
          <v-flex xs9>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  class="readonly-text-field"
                  v-model="closing_date"
                  outlined
                  dense
                  readonly
                  hide-details
                  background-color="#F5F5F5"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  class="readonly-text-field"
                  v-model="closing_time"
                  outlined
                  dense
                  readonly
                  hide-details
                  background-color="#F5F5F5"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex xs3>
        <v-layout align-center mb-3>
          <v-flex xs3 class=" text-end font-12 pr-2 font-weight-bold">
            Create / <br />
            Update :
          </v-flex>
          <v-flex xs4>
            <v-text-field
              class="readonly-text-field"
              v-model="created_at_order"
              outlined
              dense
              readonly
              hide-details
              background-color="#F5F5F5"
            ></v-text-field>
          </v-flex>
          <v-flex xs1 class=" text-end font-12 pr-2 font-weight-bold">
            To :
          </v-flex>
          <v-flex xs4>
            <v-text-field
              class="readonly-text-field"
              v-model="created_at_order"
              outlined
              dense
              readonly
              hide-details
              background-color="#F5F5F5"
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-layout align-center mb-3>
          <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
            Size Type :
          </v-flex>
          <v-flex xs9>
            <v-text-field
              class="readonly-text-field"
              v-model="size_type"
              outlined
              dense
              readonly
              hide-details
              background-color="#F5F5F5"
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-layout align-center mb-3>
          <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
            Container <br />Status :
          </v-flex>
          <v-flex xs9>
            <v-text-field
              class="readonly-text-field"
              v-model="order.container_status"
              outlined
              dense
              readonly
              hide-details
              background-color="#F5F5F5"
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-layout align-center mb-3>
          <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
            Remark :
          </v-flex>
          <v-flex xs9>
            <v-text-field
              class="readonly-text-field"
              v-model="order.remark"
              outlined
              dense
              readonly
              hide-details
              background-color="#F5F5F5"
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import moment from "moment";

export default {
  props: {
    order: null,
  },
  computed: {
    created_at_order() {
      return this.order && this.order.created_at
        ? moment(this.order.created_at).format("DD/MM/YYYY")
        : "-";
    },
    importer_exporter_name() {
      return this.order.importer_name ?? this.order.exporter_name;
    },
    wh_Factory1() {
      return this.order && this.order.order_detail
        ? this.groupWHFactory("1", this.order.order_detail)
        : "-";
    },
    wh_Factory2() {
      return this.order && this.order.order_detail
        ? this.groupWHFactory("2", this.order.order_detail)
        : "-";
    },
    rent() {
      return this.order && this.order.rent
        ? moment(this.order.rent).format("DD/MM/YYYY")
        : "-";
    },
    cy_and_release_date() {
      return this.order && this.order.cy_date
        ? moment(this.order.cy_date).format("DD/MM/YYYY")
        : moment(this.order.release_date).format("DD/MM/YYYY");
    },
    load_unload1() {
      return this.order && this.order.order_detail
        ? this.groupUnloadDate("1", this.order.order_detail)
        : "-";
    },
    unload_load_time1() {
      return this.order && this.order.order_detail
        ? this.groupUnloadTime("1", this.order.order_detail)
        : "-";
    },
    load_unload2() {
      return this.order && this.order.order_detail
        ? this.groupUnloadDate("2", this.order.order_detail)
        : "-";
    },
    unload_load_time2() {
      return this.order && this.order.order_detail
        ? this.groupUnloadTime("2", this.order.order_detail)
        : "-";
    },
    demurage() {
      return this.order && this.order.demurage
        ? moment(this.order.demurage).format("DD/MM/YYYY")
        : "-";
    },
    qty() {
      var total = 0;
      if (this.order && this.order.order_detail) {
        this.order.order_detail.forEach((item) => {
          if (item.qty) {
            total += parseInt(item.qty);
          }
        });
      }
      return total;
    },
    closing_date() {
      return this.order && this.order.closing_date
        ? moment(this.order.closing_date).format("DD/MM/YYYY")
        : "-";
    },
    closing_time() {
      return this.order && this.order.closing_time
        ? this.order.closing_time.slice(0, 5)
        : "-";
    },
    size_type() {
      var text = "";
      if (this.order && this.order.order_detail) {
        this.order.order_detail.forEach((item, i) => {
          text +=
            item.container_size_name +
            // item.container_isotype_name +
            (this.order.order_detail.length == i + 1 ? "" : " / ");
        });
      }
      return text;
    },
  },
  methods: {
    groupWHFactory(type, containers) {
      var text = "";
      containers.forEach((item, i) => {
        if (item["wh_factory_" + type + "_name"]) {
          text +=
            item["wh_factory_" + type + "_name"] +
            (containers.length == i + 1 ? "" : " / ");
        }
      });
      return text ? text : "-";
    },
    groupUnloadDate(type, containers) {
      var text = "";
      containers.forEach((item, i) => {
        if (item["unload_load_date" + type]) {
          text +=
            moment(item["unload_load_date" + type]).format("DD/MM/YYYY") +
            (containers.length == i + 1 ? "" : ", ");
        }
      });
      return text ? text : "-";
    },
    groupUnloadTime(type, containers) {
      var text = "";
      containers.forEach((item, i) => {
        if (item["unload_load_time" + type]) {
          text +=
            item["unload_load_time" + type] +
            (containers.length == i + 1 ? "" : " / ");
        }
      });
      return text ? text : "-";
    },
  },
};
</script>

<style></style>
