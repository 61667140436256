import AuthService from "../AuthService";

const masterData = {
  getMasterData(table) {
    const arr = JSON.stringify(table);
    return AuthService.get(`/api/master-data?table=${arr}`).then((res) => {
      return res;
    });
  },
  getMasterCarrier(fields) {
    const arr = JSON.stringify(fields);
    return AuthService.get(`/api/master-data-carrier?fields=${arr}`).then(
      (res) => {
        return res;
      }
    );
  },
  getProvince() {
    return AuthService.get(`/api/get-province`).then((res) => {
      return res;
    });
  },
  getTruckForSearch() {
    return AuthService.get(`/api/get-truckforsearch`).then((res) => {
      return res;
    });
  },
  getTrailerForSearch() {
    return AuthService.get(`/api/get-trailerforsearch`).then((res) => {
      return res;
    });
  },
  getDataHome() {
    return AuthService.get(`/api/home`).then((res) => {
      return res;
    });
  },
  getDriver() {
    return AuthService.get(`/api/get-drivers`).then((res) => {
      return res;
    });
  },
  getDataList() {
    return AuthService.get(`/api/get-data-list`).then((res) => {
      return res;
    });
  },
};

export default masterData;
