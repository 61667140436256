<template>
  <div style="width: 100%" class="table-order">
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="items"
      class="elevation-1"
      item-key="id"
      show-expand
      show-select
      hide-default-footer
    >
      <template
        v-slot:item="{
          item,
          isSelected,
          select,
          expand,
          isExpanded,
        }"
      >
        <tr :class="isExpanded && 'td-row-active'">
          <td class="text-center">
            <v-icon v-if="!isExpanded" @click="expand(!isExpanded)">
              mdi-chevron-down
            </v-icon>
            <v-icon v-else @click="expand(!isExpanded)">mdi-chevron-up</v-icon>
          </td>
          <td>
            <v-simple-checkbox
              color="#338A68"
              :value="isSelected"
              @input="select($event)"
            ></v-simple-checkbox>
          </td>
          <td class="text-left border-left">{{ item.no }}</td>
          <td class="text-left">
            {{ item.id ? item.id : "--" }}
          </td>
          <td class="text-left">
            {{ item.status ? item.status : "--" }}
          </td>
          <td class="text-left">
            <span v-if="item.job_date">
              {{ item.job_date | moment("DD/MM/YYYY") }}
            </span>
            <span v-else>--</span>
          </td>
          <td class="text-left">
            {{ item.plate_no ? item.plate_no : "--" }}
          </td>
          <td class="text-left">
            {{
              item.drivers && item.drivers.f_name
                ? item.drivers.f_name + " " + item.drivers.l_name
                : "--"
            }}
          </td>
          <td class="text-right">
            <!-- {{ item.total_lan_pass_fee ? calTotal(item, "lan_pass_fee") : "0.00" }} -->
            {{ item.total_lan_pass_fee ? item.total_lan_pass_fee : "0.00" }}
          </td>
          <td class="text-right">
            {{ item.total_lifting_fee ? item.total_lifting_fee : "0.00" }}
          </td>
          <td class="text-right">
            {{ item.total_entrance_fee ? item.total_entrance_fee : "0.00" }}
          </td>
          <td class="text-right">
            {{ item.total_scale_fee ? item.total_scale_fee : "0.00" }}
          </td>
          <td class="text-right">
            {{ item.spending_amount ? item.spending_amount : "0.00" }}
          </td>
          <td class="text-right">
            {{ item.spending_to }}
          </td>
          <td class="text-right">
            {{ item.total }}
          </td>
          <td class="text-left"></td>
        </tr>
      </template>

      <!-- expanded -->
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-data-table
            v-model="selectedSub"
            :headers="headersChild"
            :items="item['cost_per_truck']"
            item-key="cost_per_truck_id"
            class="elevation-1 table-child"
            hide-default-footer
          >
            <template
              v-slot:item="{
                item,
                isSelected,
                select,
                index,
              }"
            >
              <tr>
                <td></td>
                <td>
                  <v-simple-checkbox
                    color="#338A68"
                    :value="isSelected"
                    @input="select($event)"
                  ></v-simple-checkbox>
                </td>
                <td class="text-right pr-6 border-left">
                  {{ index + 1 }}
                </td>
                <td class="text-center">
                  {{ item.mod ? item.mod : "--" }}
                </td>
                <td class="text-left">
                  {{ item.inv_po ? item.inv_po : "--" }}
                </td>
                <td class="text-left">
                  {{ item.booking_no ? item.booking_no : "--" }}
                </td>
                <td class="text-left">
                  {{ item.customer_name ? item.customer_name : "--" }}
                </td>
                <td class="text-left">
                  {{ item.goods_name ? item.goods_name : "--" }}
                </td>
                <td class="text-left">
                  {{ item.cy_place_name ? item.cy_place_name : "--" }}
                </td>
                <td class="text-left">
                  {{
                    item.shipping_agent_name ? item.shipping_agent_name : "--"
                  }}
                </td>
                <td class="text-left">
                  <span v-if="item.cy_date">
                    {{ item.cy_date | moment("DD/MM/YYYY") }}
                  </span>
                  <span v-else>--</span>
                </td>
                <td class="text-left">
                  {{ item.container_status ? item.container_status : "--" }}
                </td>
                <td class="text-left">
                  {{ item.qty ? item.qty : "--" }}
                </td>
                <td class="text-left">
                  {{
                    item.container_size_name ? item.container_size_name : "--"
                  }}
                </td>
                <td class="text-left">
                  {{
                    item.container_isotype_name
                      ? item.container_isotype_name
                      : "--"
                  }}
                </td>
                <td class="text-left">
                  {{ item.lan_pass_fee }}
                </td>
                <td class="text-left">
                  {{ item.lifting_fee ? item.lifting_fee : "--" }}
                </td>
                <td class="text-left">
                  {{ item.entrance_fee ? item.entrance_fee : "--" }}
                </td>
                <td class="text-left">
                  {{ item.scale_fee ? item.scale_fee : "--" }}
                </td>
                <td class="text-left">
                  {{ item.address_lanpass ? item.address_lanpass : "--" }}
                </td>
                <td class="text-left">
                  {{ item.address_liftingfee ? item.address_liftingfee : "--" }}
                </td>
                <td class="text-left">
                  {{ item.address_port ? item.address_port : "--" }}
                </td>
                <td class="text-left">
                  {{ item.address_scale ? item.address_scale : "--" }}
                </td>
                <td class="text-left">
                  {{ item.wh_factory_1_name ? item.wh_factory_1_name : "--" }}
                </td>
                <td class="text-left">
                  <span v-if="item.unload_load_date1">
                    {{ item.unload_load_date1 | moment("DD/MM/YYYY") }}
                  </span>
                  <span v-else>--</span>
                </td>
                <td class="text-left">
                  {{
                    item.unload_load_time1
                      ? subStringTime(item.unload_load_time1)
                      : "--"
                  }}
                </td>
                <td class="text-left">
                  {{ item.wh_factory_2_name ? item.wh_factory_2_name : "--" }}
                </td>
                <td class="text-left">
                  <span v-if="item.unload_load_date2">
                    {{ item.unload_load_date2 | moment("DD/MM/YYYY") }}
                  </span>
                  <span v-else>--</span>
                </td>
                <td class="text-left">
                  {{
                    item.unload_load_time2
                      ? subStringTime(item.unload_load_time2)
                      : "--"
                  }}
                </td>
                <td class="text-left">
                  {{ item.return_place_name ? item.return_place_name : "--" }}
                </td>
                <td class="text-left">
                  <span v-if="item.closing_date">
                    {{ item.closing_date | moment("DD/MM/YYYY") }}
                  </span>
                  <span v-else>--</span>
                </td>
                <td class="text-left">
                  {{
                    item.closing_time ? subStringTime(item.closing_time) : "--"
                  }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    items: Array,
    nameTable: String,
    deleteItem: Function,
    selectedItem: Function,
    onSelectSubItem: Function,
    clearSubSelect: Boolean,
    clearSelected: Boolean,
    onClearSubSelect: Function,
    onClearSelect: Function,
  },
  data: () => ({
    selected: [],
    selectedSub: [],
    itemSub: [],

    headers: [
      {
        text: "No.",
        value: "no",
        align: "start",
        sortable: false,
        width: "50px",
        class: "font-weight-bold border-left",
      },
      {
        text: "ID",
        value: "id",
        align: "start",
        sortable: false,
        width: "50px",
        class: "font-weight-bold",
      },
      {
        text: "Status",
        value: "status",
        align: "start",
        sortable: false,
        width: "100px",
        class: "font-weight-bold",
      },
      {
        text: "งานวันที่",
        value: "date_job",
        align: "start",
        sortable: false,
        width: "120px",
        class: "font-weight-bold",
      },
      {
        text: "ทะเบียนรถ",
        value: "plate_no",
        align: "start",
        sortable: false,
        width: "150px",
        class: "font-weight-bold",
      },
      {
        text: "ชื่อ พขร.",
        align: "start",
        sortable: false,
        width: "200px",
        class: "font-weight-bold",
      },
      {
        text: "ค่าผ่านลาน",
        align: "right",
        sortable: false,
        width: "120px",
        class: "font-weight-bold",
      },
      {
        text: "ค่ายกตู้",
        align: "right",
        sortable: false,
        width: "130px",
        class: "font-weight-bold",
      },
      {
        text: "ค่าผ่านท่า",
        align: "right",
        sortable: false,
        width: "100px",
        class: "font-weight-bold",
      },
      {
        text: "ค่าชั่ง",
        align: "right",
        sortable: false,
        width: "100px",
        class: "font-weight-bold",
      },
      {
        text: "ยอดยกมา",
        align: "right",
        sortable: false,
        width: "100px",
        class: "font-weight-bold",
      },
      {
        text: "ยอดยกไปยัง",
        align: "right",
        sortable: false,
        width: "100px",
        class: "font-weight-bold",
      },
      {
        text: "รวม",
        align: "right",
        sortable: false,
        width: "100px",
        class: "font-weight-bold",
      },
      {
        text: "",
        width: "3500px",
      },
    ],
    headersChild: [
      {
        text: "",
        value: "select",
        sortable: false,
        width: "50px",
        class: "bg-gray",
      },
      {
        text: "",
        value: "actions",
        sortable: false,
        width: "70px",
        class: "bg-gray",
      },
      {
        text: "No.",
        value: "no",
        align: "start",
        sortable: false,
        width: "50px",
        class: "border-left",
      },
      {
        text: "Mode",
        align: "center",
        sortable: false,
        width: "100px",
        class: "bg-gray",
      },
      {
        text: "INV/PO",
        align: "start",
        sortable: false,
        width: "150px",
      },
      {
        text: "Booking",
        align: "start",
        sortable: false,
        width: "150px",
      },
      {
        text: "Customer",
        align: "start",
        sortable: false,
        width: "150px",
      },
      {
        text: "Goods",
        align: "start",
        sortable: false,
        width: "150px",
      },
      {
        text: "CY Place",
        align: "start",
        sortable: false,
        width: "150px",
      },
      {
        text: "Agent",
        align: "start",
        sortable: false,
        width: "150px",
      },
      {
        text: "CY Date",
        align: "start",
        sortable: false,
        width: "150px",
      },
      {
        text: "สถานะตู้",
        align: "start",
        sortable: false,
        width: "150px",
      },
      {
        text: "Qty",
        align: "start",
        sortable: false,
        width: "150px",
      },
      {
        text: "Size",
        align: "start",
        sortable: false,
        width: "150px",
      },
      {
        text: "Isotype",
        align: "start",
        sortable: false,
        width: "150px",
      },
      {
        text: "ค่าผ่านลาน",
        align: "start",
        sortable: false,
        width: "150px",
      },
      {
        text: "ค่ายกตู้",
        align: "start",
        sortable: false,
        width: "150px",
      },
      {
        text: "ค่าผ่านท่า",
        align: "start",
        sortable: false,
        width: "150px",
      },
      {
        text: "ค่าชั่ง",
        align: "start",
        sortable: false,
        width: "150px",
      },
      {
        text: "ที่อยู่ออกใบเสร็จค่าผ่านลาน",
        align: "start",
        sortable: false,
        width: "250px",
      },
      {
        text: "ที่อยู่ออกใบเสร็จค่ายกตู้",
        align: "start",
        sortable: false,
        width: "250px",
      },
      {
        text: "ที่อยู่ออกใบเสร็จ ค่าผ่านท่า",
        align: "start",
        sortable: false,
        width: "250px",
      },
      {
        text: "ที่อยู่ออกใบเสร็จ ค่าชั่ง",
        align: "start",
        sortable: false,
        width: "250px",
      },
      {
        text: "WH/factory1",
        align: "start",
        sortable: false,
        width: "150px",
      },
      {
        text: "Load/Unload Date1",
        align: "start",
        sortable: false,
        width: "150px",
      },
      {
        text: "Load/Unload Time1",
        align: "start",
        sortable: false,
        width: "150px",
      },
      {
        text: "WH/factory2",
        align: "start",
        sortable: false,
        width: "150px",
      },
      {
        text: "Load/Unload Date2",
        align: "start",
        sortable: false,
        width: "150px",
      },
      {
        text: "Load/Unload Time2",
        align: "start",
        sortable: false,
        width: "150px",
      },
      {
        text: "Return Place",
        align: "start",
        sortable: false,
        width: "200px",
      },
      {
        text: "Closing Date",
        align: "start",
        sortable: false,
        width: "150px",
      },
      {
        text: "Closing Time",
        align: "start",
        sortable: false,
        width: "150px",
      },
    ],
  }),
  computed: {
    // calTotal() {
    //   let total = 0;
    //   this.items[0].forEach((data,i) => {
    //     total += data[i];
    //   });
    //   return total;
    // },
  },
  watch: {
    clearSubSelect: {
      handler: function(newValue) {
        if (!newValue) {
          this.selectedSub = [];
        }
      },
    },
    clearSelected: {
      handler: function(newValue) {
        if (!newValue) {
          this.selected = [];
        }
      },
    },
    selected: {
      handler: function(newValue) {
        this.selectedItem(newValue);
        if (newValue.length > 0) {
          this.onClearSelect(true);
        } else {
          this.onClearSelect(false);
        }
      },
    },
    selectedSub: {
      handler: function(newValue) {
        this.onSelectSubItem(newValue);
        if (newValue.length > 0) {
          this.onClearSubSelect(true);
        } else {
          this.onClearSubSelect(false);
        }
      },
    },
  },

  created() {},

  methods: {
    calTotal(data, key) {
      let total = 0;
      data.forEach((data) => {
        total += data[key];
      });
      return total;
    },
    totalAmount(data) {
      let total = 0;
      data.forEach((item) => {
        total +=
          item.lan_pass_fee +
          item.lifting_fee +
          item.entrance_fee +
          item.scale_fee +
          (item.spending_amount && item.spending_amount);
      });
      return total;
    },
    checkQty(containers) {
      var total = 0;
      containers.forEach((item) => {
        total += parseInt(item.qty);
      });
      return total;
    },
    groupContainerSize(containers) {
      var total = "";
      containers.forEach((item, i) => {
        total +=
          item.container_size_name + (containers.length == i + 1 ? "" : "/");
      });
      return total;
    },
    groupContainerIsotype(containers) {
      var total = "";
      containers.forEach((item, i) => {
        total += item.container_isotype_name
          ? item.container_isotype_name +
            (containers.length == i + 1 ? "" : "/")
          : "";
      });
      return total;
    },
    selectAll(event) {
      if (event.status) {
        alert("selected all");
      } else {
        alert("deselected all");
      }
    },
  },
};
</script>

<style lang="css">
.table-border {
  /* border: 1px solid #fff; */
  color: #fff;
}
</style>
