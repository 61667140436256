<template>
  <div>
    <v-layout>
      <v-flex shrink pt-2 v-if="label" style="font-weight: bold">
        <span class="mr-3">{{ label }}</span>
      </v-flex>
      <v-flex>
        <v-menu
          v-model="menu_date"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
          :disabled="disabled"
          :ref="menu_date"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              @click:clear="onClearable"
              clearable
              :height="height"
              :id="id"
              :placeholder="placeholder"
              :hide-details="hideDetail"
              :value="model"
              :class="borderColor"
              append-icon="date_range"
              readonly
              :background-color="disabled ? '#E5E7E9' : '#fff'"
              item-color="#4B5768"
              color="#4B5768"
              filled
              outlined
              dense
              v-on="on"
              :disabled="disabled"
              :rules="required ? [(v) => !!v || 'field is required'] : []"
            ></v-text-field>
          </template>
          <v-date-picker
            @input="selectDate"
            v-model="picker"
            locale="th"
            type="date"
            color="#4B5768"
            header-color="#4B5768"
            :disabled="disabled"
            :min="min"
            :max="max"
          ></v-date-picker>
        </v-menu>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "DateInputForm",
  props: {
    model: null,
    label: {
      default: "",
    },
    id: {
      default: "",
    },
    hideDetail: {
      type: Boolean,
    },
    min: {
      default: "",
    },
    max: {
      default: "",
    },
    disabled: Boolean,
    required: {
      type: Boolean,
      default: false,
    },
    height: {
      default: "37px",
    },
    borderColor: {
      default: "gray",
    },
    placeholder: {
      default: "",
    },
    onClearData: {
      type: Function,
    },
  },
  data: () => ({
    picker: "",
    menu_date: "",
  }),

  methods: {
    selectDate(val) {
      this.menu_date = false;
      this.$emit("update-data", val);
    },
    onClearable() {
      this.picker = "";
      this.selectDate("");
    },
  },
};
</script>

<style scoped></style>
