import Service from "../../services";

const state = {};

const getters = {};

const actions = {
  getMasterData({ commit }, table) {
    return Service.master_data.getMasterData(table).then((res) => {
      return res;
    });
  },
  getMasterCarrier({ commit }, fields) {
    return Service.master_data.getMasterCarrier(fields).then((res) => {
      return res;
    });
  },
  getProvince({ commit }) {
    return Service.master_data.getProvince().then((res) => {
      return res;
    });
  },
  getTruckForSearch({ commit }) {
    return Service.master_data.getTruckForSearch().then((res) => {
      return res;
    });
  },
  getTrailerForSearch({ commit }) {
    return Service.master_data.getTrailerForSearch().then((res) => {
      return res;
    });
  },
  getDataHome({ commit }) {
    return Service.master_data.getDataHome().then((res) => {
      return res;
    });
  },
  getDriver({ commit }) {
    return Service.master_data.getDriver().then((res) => {
      return res;
    });
  },
  getDataList({ commit }) {
    return Service.master_data.getDataList().then((res) => {
      return res;
    });
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
