import { render, staticRenderFns } from "./DataTableTruckSupply.vue?vue&type=template&id=f5e51392"
import script from "./DataTableTruckSupply.vue?vue&type=script&lang=js"
export * from "./DataTableTruckSupply.vue?vue&type=script&lang=js"
import style0 from "./DataTableTruckSupply.vue?vue&type=style&index=0&id=f5e51392&prod&scope=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports