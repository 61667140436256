<template>
  <div class="pa-6">
    <div class="d-flex align-center">
      <v-img
        max-width="25"
        max-height="25"
        aspect-ratio="1"
        :src="require('../../../assets/images/menu-icons/Handshake.png')"
      ></v-img>
      <span class="ml-1 font-18 font-weight-bold text-uppercase">
        CUSTOMER
      </span>
    </div>

    <v-card
      class=" pa-6"
      flat
      style="border: 1px solid #C4C4C4; border-radius: 0px;"
    >
      <v-row>
        <!-- Importer/Exporter -->
        <v-col cols="3" class="py-0">
          <v-layout class="align-center mb-3">
            <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
              Importer <br />
              /Exporter :
            </v-flex>
            <v-flex class="xs9">
              <v-autocomplete
                height="37px"
                append-icon="mdi-chevron-down"
                menu-props="offsetY"
                color="#98CA54"
                outlined
                dense
                class="gray"
                :items="importerList"
                v-model="filter.importer_exporter_id"
                hide-details
                placeholder=" Importer/Exporter"
                item-text="customer_code"
                item-value="customer_id"
                clearable
              >
              </v-autocomplete>
            </v-flex>
          </v-layout>
        </v-col>

        <!-- CY Place  : -->
        <v-col cols="3" class="py-0">
          <v-layout class="align-center mb-3">
            <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
              CY Place :
            </v-flex>
            <v-flex class="xs9">
              <v-autocomplete
                height="37px"
                append-icon="mdi-chevron-down"
                menu-props="offsetY"
                color="#98CA54"
                outlined
                dense
                class="gray"
                :items="cyPlaceList"
                v-model="filter.cy_place_id"
                hide-details
                placeholder="CY Place"
                itemText="cy_place_name"
                itemValue="cy_place_id"
                clearable
              >
              </v-autocomplete>
            </v-flex>
          </v-layout>
        </v-col>

        <!--  CY Date : -->
        <v-col cols="3" class="py-0">
          <v-layout class="align-center mb-3">
            <v-flex class="xs3 text-end font-12 pr-3 font-weight-bold">
              CY Date/<br />Relase Date :
            </v-flex>
            <v-flex class="xs4 px-0">
              <DatePicker
                placeholder="DD/MM/YY"
                hideDetails
                hideDetail
                rulse="fieldRequire"
                :model="date.cy_date_start"
                :max="filter.cy_date_end"
                @update-data="updateDate('cy_date_start', $event)"
              ></DatePicker>
            </v-flex>
            <v-flex class="xs1 text-end font-12 pr-1 font-weight-bold">
              To :
            </v-flex>
            <v-flex class=" xs4">
              <DatePicker
                placeholder="DD/MM/YY"
                hideDetails
                hideDetail
                rulse="fieldRequire"
                :model="date.cy_date_end"
                :min="filter.cy_date_start"
                @update-data="updateDate('cy_date_end', $event)"
              ></DatePicker>
            </v-flex>
          </v-layout>
        </v-col>

        <!--Closing Date -->
        <v-col cols="3" class="py-0">
          <v-layout class="align-center mb-3">
            <v-flex class="xs3 text-end font-12 pr-3 font-weight-bold">
              Closing Date :
            </v-flex>
            <v-flex class="xs4 px-0">
              <DatePicker
                placeholder="DD/MM/YY"
                hideDetails
                hideDetail
                rulse="fieldRequire"
                :model="date.closing_date_start"
                :max="filter.closing_date_end"
                @update-data="updateDate('closing_date_start', $event)"
              ></DatePicker>
            </v-flex>
            <v-flex class="xs1 text-end font-12 pr-1 font-weight-bold">
              To :
            </v-flex>
            <v-flex class=" xs4">
              <DatePicker
                placeholder="DD/MM/YY"
                hideDetails
                hideDetail
                rulse="fieldRequire"
                :model="date.closing_date_end"
                :min="filter.closing_date_start"
                @update-data="updateDate('closing_date_end', $event)"
              ></DatePicker>
            </v-flex>
          </v-layout>
        </v-col>
      </v-row>

      <v-row>
        <!-- WH Factory 1-->
        <v-col cols="3" class="py-0">
          <v-layout class="align-center mb-3">
            <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
              WH/<br />Factory 1 :
            </v-flex>
            <v-flex xs9>
              <v-autocomplete
                height="37px"
                append-icon="mdi-chevron-down"
                menu-props="offsetY"
                color="#98CA54"
                outlined
                dense
                class="gray"
                :items="factoryList"
                v-model="filter.wh_factory1_id"
                hide-details
                placeholder="WH/Factory"
                item-text="wh_factory_name"
                item-value="wh_factory_id"
                clearable
              >
              </v-autocomplete>
            </v-flex>
          </v-layout>
        </v-col>

        <!-- Load  Unload Date 1 -->
        <v-col cols="3" class="py-0">
          <v-layout class="align-center mb-3">
            <v-flex class="xs3 text-end font-12 pr-3 font-weight-bold">
              Load/ <br />
              Unload Date 1:
            </v-flex>
            <v-flex class="xs4 px-0">
              <DatePicker
                placeholder="DD/MM/YY"
                hideDetails
                hideDetail
                rulse="fieldRequire"
                :model="date.unload_date1_start"
                :max="filter.unload_date1_end"
                @update-data="updateDate('unload_date1_start', $event)"
              ></DatePicker>
            </v-flex>
            <v-flex class="xs1 text-end font-12 pr-1 font-weight-bold">
              To :
            </v-flex>
            <v-flex class=" xs4">
              <DatePicker
                placeholder="DD/MM/YY"
                hideDetails
                hideDetail
                rulse="fieldRequire"
                :model="date.unload_date1_end"
                :min="filter.unload_date1_start"
                @update-data="updateDate('unload_date1_end', $event)"
              ></DatePicker>
            </v-flex>
          </v-layout>
        </v-col>

        <!-- Return Place  -->
        <v-col cols="3" class="py-0">
          <v-layout class="align-center mb-3">
            <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
              Return Place :
            </v-flex>
            <v-flex class="xs9">
              <v-autocomplete
                height="37px"
                append-icon="mdi-chevron-down"
                menu-props="offsetY"
                color="#98CA54"
                outlined
                dense
                class="gray"
                v-model="filter.return_place_id"
                hide-details
                placeholder="Return Place 1"
                :items="cyPlaceList"
                itemText="cy_place_name"
                itemValue="cy_place_id"
                clearable
              >
              </v-autocomplete>
            </v-flex>
          </v-layout>
        </v-col>

        <!-- Driver : -->
        <v-col cols="3" class="py-0">
          <v-layout class="align-center mb-3">
            <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
              Driver :
            </v-flex>
            <v-flex class="xs9">
              <v-autocomplete
                height="37px"
                append-icon="mdi-chevron-down"
                menu-props="offsetY"
                color="#98CA54"
                outlined
                dense
                class="gray"
                :items="driverList"
                v-model="filter.driver_id"
                hide-details
                placeholder="Driver"
                item-text="full_name"
                item-value="driver_id"
                clearable
              >
              </v-autocomplete>
            </v-flex>
          </v-layout>
        </v-col>
      </v-row>

      <v-row>
        <!-- WH Factory 2 -->
        <v-col cols="3" class="py-0">
          <v-layout class="align-center mb-3">
            <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
              WH/<br />Factory 2 :
            </v-flex>
            <v-flex xs9>
              <v-autocomplete
                height="37px"
                append-icon="mdi-chevron-down"
                menu-props="offsetY"
                color="#98CA54"
                outlined
                dense
                class="gray"
                :items="factoryList"
                v-model="filter.wh_factory2_id"
                hide-details
                placeholder="WH/Factory"
                item-text="wh_factory_name"
                item-value="wh_factory_id"
                clearable
              >
              </v-autocomplete>
            </v-flex>
          </v-layout>
        </v-col>

        <!-- Load  Unload Date 2 -->
        <v-col cols="3" class="py-0">
          <v-layout class="align-center mb-3">
            <v-flex class="xs3 text-end font-12 pr-3 font-weight-bold">
              Load/ <br />
              Unload Date 2:
            </v-flex>
            <v-flex class="xs4 px-0">
              <DatePicker
                placeholder="DD/MM/YY"
                hideDetails
                hideDetail
                rulse="fieldRequire"
                :model="date.unload_date2_start"
                :max="filter.unload_date2_end"
                @update-data="updateDate('unload_date2_start', $event)"
              ></DatePicker>
            </v-flex>
            <v-flex class="xs1 text-end font-12 pr-1 font-weight-bold">
              To :
            </v-flex>
            <v-flex class=" xs4">
              <DatePicker
                placeholder="DD/MM/YY"
                hideDetails
                hideDetail
                rulse="fieldRequire"
                :model="date.unload_date2_end"
                :min="filter.unload_date2_start"
                @update-data="updateDate('unload_date2_end', $event)"
              ></DatePicker>
            </v-flex>
          </v-layout>
        </v-col>

        <!-- Release Port -->
        <v-col cols="3" class="py-0">
          <v-layout class="align-center mb-3">
            <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
              Release Port :
            </v-flex>
            <v-flex class="xs9">
              <v-autocomplete
                height="37px"
                append-icon="mdi-chevron-down"
                menu-props="offsetY"
                color="#98CA54"
                outlined
                dense
                class="gray"
                :items="releasePortList"
                v-model="filter.release_port_id"
                hide-details
                placeholder="Release Port"
                itemText="port_discharge_name"
                itemValue="port_discharge_id"
                clearable
              >
              </v-autocomplete>
            </v-flex>
          </v-layout>
        </v-col>

        <!-- ทะเบียนรถ : -->
        <v-col cols="3" class="py-0">
          <v-layout class="align-center mb-3">
            <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
              ทะเบียนรถ :
            </v-flex>
            <v-flex class="xs9">
              <v-autocomplete
                height="37px"
                append-icon="mdi-chevron-down"
                menu-props="offsetY"
                color="#98CA54"
                outlined
                dense
                class="gray"
                :items="truckLicenseList"
                v-model="filter.truck_license_id"
                hide-details
                placeholder="ทะเบียนรถ"
                item-text="plate_no"
                item-value="plate_no"
                clearable
              >
              </v-autocomplete>
            </v-flex>
          </v-layout>
        </v-col>
      </v-row>

      <!-- <v-row>
        <v-col cols="3" class="py-0">
          <v-layout class="align-center mb-3">
            <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
              Order Complete :
            </v-flex>
            <v-flex xs9>
              <v-autocomplete
                height="37px"
                append-icon="mdi-chevron-down"
                menu-props="offsetY"
                color="#98CA54"
                outlined
                dense
                class="gray"
                :items="OrderComplete"
                v-model="filter.order_id"
                hide-details
                placeholder="WH/Factory"
                item-text="order_id"
                item-value="order_id"
                clearable
              >
              </v-autocomplete>
            </v-flex>
          </v-layout>
        </v-col>
      </v-row> -->
    </v-card>

    <v-card
      width="100%"
      outlined
      class="mt-4"
      flat
      style="border: 1px solid #C4C4C4; border-radius: 0px;"
    >
      <v-layout align-center>
        <v-flex shrink>
          <v-layout class="align-center text-end pl-6">
            <v-flex shrink class=" font-weight-bold">Mode : </v-flex>
            <v-flex shrink pl-6>
              <v-layout>
                <v-flex shrink mr-4>
                  <v-checkbox
                    color="#338A68"
                    v-model="is_import"
                    label="Import"
                  ></v-checkbox>
                </v-flex>
                <v-flex shrink mr-4>
                  <v-checkbox
                    color="#338A68"
                    v-model="is_export"
                    label="Export"
                  ></v-checkbox>
                </v-flex>
                <v-flex shrink mr-4>
                  <v-checkbox
                    color="#338A68"
                    v-model="is_empty"
                    label="Empty"
                  ></v-checkbox>
                </v-flex>
                <v-flex shrink mr-4>
                  <v-checkbox
                    color="#338A68"
                    v-model="is_laden"
                    label="Laden"
                  ></v-checkbox>
                </v-flex>
                <v-flex shrink mr-4>
                  <v-checkbox
                    color="#338A68"
                    v-model="is_drop_load"
                    label="Drop Load"
                  ></v-checkbox>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex shrink class="text-end pr-6">
          <v-btn
            depressed
            color="#338A68"
            dark
            class="my-4 font-weight-bold"
            @click="onSearchOrderComplete(1)"
          >
            <span class="text-capitalize">Order Complete</span>
          </v-btn>
        </v-flex>
        <v-flex shrink class="text-end pr-6">
          <v-btn
            depressed
            color="#EC8235"
            dark
            class="my-4 font-weight-bold"
            @click="onSearchOrderPending(1)"
          >
            <span class="text-capitalize">Order Pending</span>
          </v-btn>
        </v-flex>
        <v-flex shrink class="text-end pr-6">
          <v-btn
            depressed
            color="#F7D25D"
            class="my-4 font-weight-bold"
            @click="onSearch(1)"
          >
            <v-icon class="mr-3">mdi-magnify</v-icon>
            <span class="text-capitalize">Search</span>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card>

    <div>
      <v-btn
        outlined
        depressed
        color="#338A68"
        dark
        class="shrink my-4 font-weight-bold mr-3"
        @click="exportExcel()"
      >
        <img
          width="20"
          src="../../../assets/images/menu-icons/excel.png"
          alt=""
        />
        <span class="ml-2 shrink text-capitalize" style="color: #000"
          >Export Excel
        </span>
      </v-btn>
    </div>

    <div style="width: 100%" class="table-order">
      <v-data-table
        v-model="selectedMain"
        :headers="headers"
        :items="customerList"
        item-key="order_id"
        class="elevation-1"
        show-expand
        :items-per-page="10"
        hide-default-footer
        show-select
      >
        <template v-slot:item.progress="{ item }">
          <div
            :class="
              checkTotalProgress(item.driver_jobs) === 'Complete'
                ? 'progress-complete'
                : 'progress-pending'
            "
          >
            {{ checkProgress(item.driver_jobs) }}
          </div>
        </template>

        <template v-slot:item.status="{ item }">
          <div class="font-w-500">
            {{ checkTotalProgress(item.driver_jobs) }}
          </div>
        </template>

        <template v-slot:item.exporter_name="{ item }">
          <div>
            {{ item.exporter_name ? item.exporter_name : item.importer_name }}
          </div>
        </template>

        <template v-slot:item.closing_date="{ item }">
          <div>
            {{ item.closing_date | moment("DD/MM/YYYY") }}
            {{ item.closing_time }}
          </div>
        </template>

        <template v-slot:item.cy_place_name="{ item }">
          <div>
            {{
              groupCyPlace(item.order_detail)
                ? groupCyPlace(item.order_detail)
                : item.release_port_name
            }}
          </div>
        </template>

        <template v-slot:item.shipping_agent_name="{ item }">
          <div>
            {{
              item.shipping_agent_name
                ? item.shipping_agent_name
                : item.shipping_name
            }}
          </div>
        </template>

        <template v-slot:item.cy_date="{ item }">
          <div v-if="item.cy_date">
            {{ item.cy_date | moment("DD/MM/YYYY") }}
          </div>
          <div v-else>
            {{ item.release_date | moment("DD/MM/YYYY") }}
          </div>
        </template>

        <template v-slot:item.frd="{ item }">
          <div>
            {{ item.frd | moment("DD/MM/YYYY") }}
          </div>
        </template>

        <template v-slot:item.qty="{ item }">
          <div>{{ groupQty(item.order_detail) }}</div>
        </template>

        <template v-slot:item.container_size_name="{ item }">
          <div>{{ groupContainerSize(item.order_detail) }}</div>
        </template>

        <template v-slot:item.container_isotype_name="{ item }">
          <div>{{ groupIsoType(item.order_detail) }}</div>
        </template>

        <template v-slot:item.wh_factory1="{ item }">
          <div>{{ groupWHFactory1(item.order_detail) }}</div>
        </template>

        <template v-slot:item.unload_date1="{ item }">
          <div>{{ groupUnloadDate1(item.order_detail) }}</div>
        </template>

        <template v-slot:item.unload_time1="{ item }">
          <div>{{ groupUnloadTime1(item.order_detail) }}</div>
        </template>

        <template v-slot:item.wh_factory2="{ item }">
          <div>{{ groupWHFactory2(item.order_detail) }}</div>
        </template>

        <template v-slot:item.unload_date2="{ item }">
          <div>{{ groupUnloadDate2(item.order_detail) }}</div>
        </template>

        <template v-slot:item.unload_time2="{ item }">
          <div>{{ groupUnloadTime2(item.order_detail) }}</div>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pa-0 ma-0">
            <v-data-table
              :headers="headers_driver_job"
              :items="item.driver_jobs"
              item-key="order_detail"
              class="elevation-1 table-child"
              hide-default-footer
              :items-per-page="9999"
            >
              <template v-slot:header.accept_tender>
                <div>
                  Accept Tender ({{ item.driver_jobs.length }}/{{
                    item.driver_jobs_accept_tender.length
                  }})
                </div>
              </template>

              <template v-slot:header.receive_container>
                <div>
                  Receive Container ({{
                    item.driver_jobs_recieve_container.length
                  }}/{{ item.driver_jobs_accept_tender.length }})
                </div>
              </template>

              <template v-slot:header.arrived>
                <div>
                  Arrived ({{ item.driver_jobs_recieve_container.length }}/{{
                    item.driver_jobs_arrived.length
                  }})
                </div>
              </template>

              <template v-slot:header.start_load_unload>
                <div>
                  Start Load/unload ({{ item.driver_jobs_arrived.length }}/{{
                    item.driver_jobs_start_load.length
                  }})
                </div>
              </template>

              <template v-slot:header.end_load_unload>
                <div>
                  Load/unload End ({{ item.driver_jobs_start_load.length }}/{{
                    item.driver_jobs_end_load.length
                  }})
                </div>
              </template>

              <template v-slot:header.return>
                <div>
                  Return ({{ item.driver_jobs_end_load.length }}/{{
                    item.driver_jobs_return.length
                  }})
                </div>
              </template>

              <template v-slot:item.no="{ index }">
                {{ index + 1 }}
              </template>

              <template v-slot:item.cy_place="{ item }">
                <div>
                  {{
                    item.accept_tender &&
                    item.accept_tender.tender &&
                    item.accept_tender.tender.order_detail &&
                    item.accept_tender.tender.order_detail.cy_place_name
                      ? item.accept_tender.tender.order_detail.cy_place_name
                      : item.accept_tender.tender.order_detail.return_place_name
                  }}
                </div>
              </template>

              <template v-slot:item.qty="{ item }">
                {{
                  item.accept_tender &&
                  item.accept_tender.tender &&
                  item.accept_tender.tender.order_detail &&
                  item.accept_tender.tender.order_detail.qty
                    ? 1
                    : 0
                }}
              </template>

              <template v-slot:item.size="{ item }">
                {{
                  item.accept_tender &&
                  item.accept_tender.tender &&
                  item.accept_tender.tender.order_detail &&
                  item.accept_tender.tender.order_detail.container_size_name
                    ? item.accept_tender.tender.order_detail.container_size_name
                    : ""
                }}
              </template>

              <template v-slot:item.iso_type="{ item }">
                {{
                  item.accept_tender &&
                  item.accept_tender.tender &&
                  item.accept_tender.tender.order_detail &&
                  item.accept_tender.tender.order_detail.container_isotype_name
                    ? item.accept_tender.tender.order_detail
                        .container_isotype_name
                    : ""
                }}
              </template>

              <template v-slot:item.wh_factory1="{ item }">
                {{
                  item.accept_tender &&
                  item.accept_tender.tender &&
                  item.accept_tender.tender.order_detail &&
                  item.accept_tender.tender.order_detail.wh_factory_1_name
                    ? item.accept_tender.tender.order_detail.wh_factory_1_name
                    : ""
                }}
              </template>

              <template v-slot:item.accept_tender="{ item }">
                <div v-if="item.accept_tender_date" class="d-flex align-center">
                  <div class="px-2 font-12 font-w-500 text-center green-tab">
                    {{ item.accept_tender_date | moment("DD/MM H:mm") }}
                  </div>
                </div>
                <div v-else>
                  <div class="px-2 font-12 font-w-500 text-center gray-tab">
                    {{ item.accept_tender_date | moment("DD/MM H:mm") }}
                  </div>
                </div>
              </template>

              <template v-slot:item.receive_container="{ item }">
                <div v-if="item.receive_container" class="d-flex align-center">
                  <div class="px-2 font-12 font-w-500 text-center green-tab">
                    {{ item.receive_container | moment("DD/MM H:mm") }}
                  </div>
                </div>
                <div v-else>
                  <div
                    class="px-2 font-12 font-w-500 text-center gray-tab"
                  ></div>
                </div>
              </template>

              <template v-slot:item.arrived="{ item }">
                <div v-if="item.arrived" class="d-flex align-center">
                  <div class="px-2 font-12 font-w-500 text-center green-tab">
                    {{ item.arrived | moment("DD/MM H:mm") }}
                  </div>
                </div>
                <div v-else>
                  <div
                    class="px-2 font-12 font-w-500 text-center gray-tab"
                  ></div>
                </div>
              </template>

              <template v-slot:item.start_load_unload="{ item }">
                <div v-if="item.start_load_unload" class="d-flex align-center">
                  <div class="px-2 font-12 font-w-500 text-center green-tab">
                    {{ item.start_load_unload | moment("DD/MM H:mm") }}
                  </div>
                </div>
                <div v-else>
                  <div
                    class="px-2 font-12 font-w-500 text-center gray-tab"
                  ></div>
                </div>
              </template>

              <template v-slot:item.end_load_unload="{ item }">
                <div v-if="item.end_load_unload" class="d-flex align-center">
                  <div class="px-2 font-12 font-w-500 text-center green-tab">
                    {{ item.end_load_unload | moment("DD/MM H:mm") }}
                  </div>
                </div>
                <div v-else>
                  <div
                    class="px-2 font-12 font-w-500 text-center gray-tab"
                  ></div>
                </div>
              </template>

              <template v-slot:item.return="{ item }">
                <div v-if="item.return" class="d-flex align-center">
                  <div class="px-2 font-12 font-w-500 text-center green-tab">
                    {{ item.return | moment("DD/MM H:mm") }}
                  </div>
                </div>
                <div v-else>
                  <div
                    class="px-2 font-12 font-w-500 text-center  gray-tab"
                  ></div>
                </div>
              </template>

              <template v-slot:item.shipping="{ item }">
                <div v-if="item.shipping" class="d-flex align-center">
                  <div class="px-2 font-12 font-w-500 text-cente green-tabr">
                    {{ item.shipping | moment("DD/MM H:mm") }}
                  </div>
                </div>
                <div v-else>
                  <div
                    class="px-2 font-12 font-w-500 text-center gray-tab"
                  ></div>
                </div>
              </template>

              <template v-slot:item.invoice_file="{ item }">
                <div v-if="item.invoice_file" class="d-flex align-center">
                  <div class="px-2 font-12 font-w-500 text-center green-tab">
                    {{ item.invoice_file | moment("DD/MM H:mm") }}
                  </div>
                </div>
                <div v-else>
                  <div
                    class="px-2 font-12 font-w-500 text-center gray-tab"
                  ></div>
                </div>
              </template>

              <template v-slot:item.shore="{ item }">
                <div v-if="item.shore" class="d-flex align-center">
                  <div class="px-2 font-12 font-w-500 text-center green-tab">
                    {{ item.shore | moment("DD/MM H:mm") }}
                  </div>
                </div>
                <div v-else>
                  <div
                    class="px-2 font-12 font-w-500 text-center gray-tab"
                  ></div>
                </div>
              </template>

              <template v-slot:item.receive_file="{ item }">
                <div v-if="item.receive_file" class="d-flex align-center">
                  <div class="px-2 font-12 font-w-500 text-center green-tab">
                    {{ item.receive_file | moment("DD/MM H:mm") }}
                  </div>
                </div>
                <div v-else>
                  <div
                    class="px-2 font-12 font-w-500 text-center gray-tab"
                  ></div>
                </div>
              </template>

              <template v-slot:item.driver_finish="{ item, index }">
                <v-layout align-center v-if="!item.is_job_success_datetime">
                  <v-flex xs8>
                    <v-menu
                      v-model="date_driver_finish[index]"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="driverForm[index]"
                          readonly
                          outlined
                          clearable
                          height="37px"
                          placeholder="--"
                          hide-details
                          class="gray"
                          append-icon="mdi-calendar"
                          background-color="#fff"
                          item-color="#4B5768"
                          color="#4B5768"
                          filled
                          dense
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="driverForm[index]"
                        @input="date_driver_finish[index] = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs4 class="pl-2">
                    <v-btn
                      color="#59CB33"
                      :dark="driverForm[index] ? true : false"
                      depressed
                      :disabled="!driverForm[index]"
                      @click="saveJobFinish(item, driverForm[index])"
                    >
                      Save
                    </v-btn>
                  </v-flex>
                </v-layout>

                <div v-else>
                  {{ item.is_job_success_datetime | moment("DD/MM/YYYY") }}
                </div>
              </template>
            </v-data-table>
          </td>
        </template>
      </v-data-table>
    </div>

    <v-layout mt-4>
      <v-flex>
        <Paginate
          :current_page="paginate.current_page"
          :last_page="paginate.last_page"
          :nextPage="onClickPagination"
        />
      </v-flex>
    </v-layout>

    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="false"
    />
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import XLSX from "xlsx"; // import xlsx;
import moment from "moment";
import DatePicker from "../../../components/picker/DatePicker.vue";
import Paginate from "../../../components/pagination/Paginate.vue";
import Services from "../../../services";
import { url_path } from "../../../services/Constants";

export default {
  components: {
    Loading,
    DatePicker,
    Paginate,
  },
  data() {
    return {
      isLoading: false,
      is_import: false,
      is_export: false,
      is_empty: false,
      is_laden: false,
      is_drop_load: false,

      selected: [],
      headers: [
        {
          text: "No.",
          value: "no",
          align: "start",
          sortable: false,
          width: "50px",
          class: "bg-gray",
        },
        {
          text: "Progress",
          value: "progress",
          align: "start",
          sortable: false,
          width: "100px",
          class: "bg-gray",
        },
        {
          text: "Status",
          value: "status",
          align: "start",
          sortable: false,
          width: "100px",
          class: "bg-gray",
        },
        {
          text: "Job No.",
          value: "order_id",
          align: "start",
          sortable: false,
          width: "150px",
          class: "bg-gray",
        },
        {
          text: "Customer",
          value: "customer_name",
          align: "start",
          sortable: false,
          width: "150px",
          class: "bg-gray",
        },
        {
          text: "Importer/Exporter",
          value: "exporter_name",
          align: "start",
          sortable: false,
          width: "150px",
          class: "bg-gray",
        },
        {
          text: "Inv./PO",
          value: "inv_po",
          align: "start",
          sortable: false,
          width: "150px",
          class: "bg-gray",
        },
        {
          text: "Booking",
          value: "booking_no",
          align: "start",
          sortable: false,
          width: "150px",
          class: "bg-gray",
        },
        {
          text: "CY Place/Release Port",
          value: "cy_place_name",
          align: "start",
          sortable: false,
          width: "150px",
          class: "bg-gray",
        },
        {
          text: "CY Date/Release Date",
          value: "cy_date",
          align: "start",
          sortable: false,
          width: "150px",
          class: "bg-gray",
        },
        {
          text: "Shipping Agent/Agent",
          value: "shipping_agent_name",
          align: "start",
          sortable: false,
          width: "150px",
          class: "bg-gray",
        },
        {
          text: "QTY",
          value: "qty",
          align: "start",
          sortable: false,
          width: "150px",
          class: "bg-gray",
        },
        {
          text: "Size",
          value: "container_size_name",
          align: "start",
          sortable: false,
          width: "150px",
          class: "bg-gray",
        },
        {
          text: "ISO type",
          value: "container_isotype_name",
          align: "start",
          sortable: false,
          width: "150px",
          class: "bg-gray",
        },
        {
          text: "WH/Factory*1",
          value: "wh_factory1",
          align: "start",
          sortable: false,
          width: "250px",
          class: "bg-gray",
        },
        {
          text: "Unload Date*1",
          value: "unload_date1",
          align: "start",
          sortable: false,
          width: "150px",
          class: "bg-gray",
        },
        {
          text: "Unload Time*1",
          value: "unload_time1",
          align: "start",
          sortable: false,
          width: "150px",
          class: "bg-gray",
        },
        {
          text: "WH/Factory*2",
          value: "wh_factory2",
          align: "start",
          sortable: false,
          width: "250px",
          class: "bg-gray",
        },
        {
          text: "Unload Date*2",
          value: "unload_date2",
          align: "start",
          sortable: false,
          width: "150px",
          class: "bg-gray",
        },
        {
          text: "Unload Time*2",
          value: "unload_time2",
          align: "start",
          sortable: false,
          width: "150px",
          class: "bg-gray",
        },
        {
          text: "Return Place",
          value: "return_place_name",
          align: "start",
          sortable: false,
          width: "150px",
          class: "bg-gray",
        },
        {
          text: "FRD",
          value: "frd",
          align: "start",
          sortable: false,
          width: "150px",
          class: "bg-gray",
        },
        {
          text: "Closing Date/Time",
          value: "closing_date",
          align: "start",
          sortable: false,
          width: "150px",
          class: "bg-gray",
        },
      ],
      headers_driver_job: [
        // {
        //   text: "",
        //   value: "action",
        //   align: "center",
        //   sortable: false,
        //   width: "20px",
        //   class: "bg-gray pa-0",
        // },
        {
          text: "No.",
          value: "no",
          align: "start",
          sortable: false,
          width: "30px",
          class: "bg-gray",
        },
        {
          text: "Driver",
          value: "accept_tender.driver_name",
          align: "start",
          sortable: false,
          width: "150px",
          class: "bg-gray",
        },
        {
          text: "ทะเบียนรถ",
          value: "accept_tender.truck_license_name",
          align: "start",
          sortable: false,
          width: "100px",
          class: "bg-gray",
        },
        {
          text: "CY Place/Release Port",
          value: "cy_place",
          align: "center",
          sortable: false,
          width: "150px",
          class: "bg-gray",
        },
        {
          text: "Qty",
          value: "qty",
          align: "center",
          sortable: false,
          width: "100px",
          class: "bg-gray",
        },
        {
          text: "Size",
          value: "size",
          align: "center",
          sortable: false,
          width: "100px",
          class: "bg-gray",
        },
        {
          text: "ISO Type",
          value: "iso_type",
          align: "center",
          sortable: false,
          width: "100px",
          class: "bg-gray",
        },
        {
          text: "WH/Factory 1*",
          value: "wh_factory1",
          align: "center",
          sortable: false,
          width: "250px",
          class: "bg-gray",
        },
        {
          text: "Accept Tender",
          value: "accept_tender",
          align: "start",
          sortable: false,
          width: "250px",
          class: "bg-gray",
        },
        {
          text: "Receive Container",
          value: "receive_container",
          align: "start",
          sortable: false,
          width: "250px",
          class: "bg-gray",
        },
        {
          text: "Arrived",
          value: "arrived",
          align: "start",
          sortable: false,
          width: "250px",
          class: "bg-gray",
        },
        {
          text: "Start Load/unload",
          value: "start_load_unload",
          align: "start",
          sortable: false,
          width: "250px",
          class: "bg-gray",
        },
        {
          text: "Load/unload End",
          value: "end_load_unload",
          align: "start",
          sortable: false,
          width: "250px",
          class: "bg-gray",
        },
        {
          text: "Return",
          value: "return",
          align: "start",
          sortable: false,
          width: "250px",
          class: "bg-gray",
        },
      ],
      customerList: [],

      importerList: [],
      cyPlaceList: [],
      factoryList: [],
      releasePortList: [],
      driverList: [],
      truckLicenseList: [],
      OrderComplete: [],

      filter: {
        page: "",
        mode: [],
        importer_exporter_id: "",
        cy_place_id: "",
        cy_date_start: "",
        cy_date_end: "",
        closing_date_start: "",
        closing_date_end: "",

        wh_factory1_id: "",
        unload_date1_start: "",
        unload_date1_end: "",
        return_place_id: "",
        driver_id: "",

        wh_factory2_id: "",
        unload_date2_start: "",
        unload_date2_end: "",
        release_port_id: "",
        truck_license_id: "",

        order_id: "",
        is_order_pending: false,
        is_order_complete: false,
      },
      date: {
        unload_date1_start: "",
        unload_date1_end: "",
        unload_date2_end: "",
        cy_date_start: "",
        cy_date_end: "",
        closing_date_start: "",
        closing_date_end: "",
      },
      paginate: {
        current_page: 1,
        from: 1,
        last_page: 1,
        per_page: 1,
        total: 1,
        to: 1,
      },
      dataExcel: [],
      selectedMain: [],
    };
  },
  watch: {
    "date.cy_date_start": function(val) {
      if (!val) {
        this.filter.cy_date_end = "";
        this.date.cy_date_end = "";
      }
    },
    "date.cy_date_end": function(val) {
      if (!val) {
        this.filter.cy_date_start = "";
        this.date.cy_date_start = "";
      }
    },
    "date.unload_date_start": function(val) {
      if (!val) {
        this.filter.unload_date_end = "";
        this.date.unload_date_end = "";
      }
    },
    "date.unload_date_end": function(val) {
      if (!val) {
        this.filter.unload_date_start = "";
        this.date.unload_date_start = "";
      }
    },
    selectedMain: function(val) {
      // this.dataExcel = val;
      this.setDataExcel(val);
    },
    is_import: {
      handler: function(val) {
        if (val) {
          if (this.filter.mode.indexOf("Import") === -1) {
            this.filter.mode.push("Import");
          }
        } else {
          this.filter.mode.splice(this.filter.mode.indexOf("Import"), 1);
        }
      },
    },
    is_export: {
      handler: function(val) {
        if (val) {
          if (this.filter.mode.indexOf("Export") === -1) {
            this.filter.mode.push("Export");
          }
        } else {
          this.filter.mode.splice(this.filter.mode.indexOf("Export"), 1);
        }
      },
    },
    is_empty: {
      handler: function(val) {
        if (val) {
          if (this.filter.mode.indexOf("Empty") === -1) {
            this.filter.mode.push("Empty");
          }
        } else {
          this.filter.mode.splice(this.filter.mode.indexOf("Empty"), 1);
        }
      },
    },
    is_laden: {
      handler: function(val) {
        if (val) {
          if (this.filter.mode.indexOf("Laden") === -1) {
            this.filter.mode.push("Laden");
          }
        } else {
          this.filter.mode.splice(this.filter.mode.indexOf("Laden"), 1);
        }
      },
    },
    is_drop_load: {
      handler: function(val) {
        if (val) {
          if (this.filter.mode.indexOf("Drop Load") === -1) {
            this.filter.mode.push("Drop Load");
          }
        } else {
          this.filter.mode.splice(this.filter.mode.indexOf("Drop Load"), 1);
        }
      },
    },
  },
  created() {
    this.isLoading = true;
    Promise.all([this.getMasterData(), this.getCustomerList()])
      .then((values) => {
        this.isLoading = false;
      })
      .catch((error) => {
        console.log(error);
        this.isLoading = false;
      });
  },
  methods: {
    onSearch() {
      this.isLoading = true;
      this.filter.page = 1;
      this.filter.is_order_complete = false;
      this.filter.is_order_pending = false;
      this.getCustomerList().then((res) => {
        this.isLoading = false;
      });
    },

    onSearchOrderPending(page) {
      this.isLoading = true;
      this.filter.page = page;
      this.filter.is_order_pending = true;
      this.filter.is_order_complete = false;
      this.getCustomerList().then((res) => {
        this.isLoading = false;
      });
    },
    onSearchOrderComplete(page) {
      this.isLoading = true;
      this.filter.page = page;
      this.filter.is_order_complete = true;
      this.filter.is_order_pending = false;
      this.getCustomerList().then((res) => {
        this.isLoading = false;
      });
    },

    async getMasterData() {
      const table = ["importer", "cy_place", "wh_factory", "port_discharge"];
      const res = await this.$store.dispatch(
        "master_data/getMasterData",
        table
      );
      this.importerList = res.data[0].importer;
      this.cyPlaceList = res.data[1].cy_place;
      this.factoryList = res.data[2].wh_factory;
      this.releasePortList = res.data[3].port_discharge;
      this.driverList = res.data["drivers"];
      this.truckLicenseList = res.data["trucks"];
      return res.data;
    },

    async getCustomerList() {
      const res = await Services.Monitoring.getCustomerList(this.filter);
      res.data.data = res.data.data.map((data, i) => ({
        ...data,
        no: (res.data.current_page - 1) * res.data.per_page + i + 1,
      }));

      this.customerList = res.data.data;
      this.paginate.current_page = res.data.current_page;
      this.paginate.last_page = res.data.last_page;

      return res.data;
    },

    onClickPagination(page) {
      this.isLoading = true;
      this.filter.page = page;
      this.getCustomerList().then((res) => {
        this.isLoading = false;
      });
    },

    updateDate(name, val) {
      this.filter[name] = val ? val : "";
      this.date[name] = val ? this.customDate(val) : "";
    },

    customDate(val) {
      let sub_year = val.substring(0, 4);
      let sub_month = val.substring(5, 7);
      let sub_day = val.substring(8, 10);
      let changeDate = sub_day + "/" + sub_month + "/" + sub_year;
      return changeDate;
    },

    convertTime(time) {
      return time.slice(0, -3);
    },

    checkProgress(item) {
      return (
        item.length +
        " / " +
        item.filter((item) => item.is_job_success_datetime).length
      );
    },
    checkTotalProgress(item) {
      if (
        item.length ==
        item.filter((item) => item.is_job_success_datetime).length
      ) {
        return "Complete";
      } else {
        return "Pending";
      }
    },

    groupQty(containers) {
      var total = 0;
      containers.forEach((item) => {
        if (item.qty) {
          total += parseInt(item.qty);
        }
      });
      return total;
    },
    groupSize(containers) {
      var total = 0;
      containers.forEach((item) => {
        if (item.container_size_name) {
          total += parseInt(item.container_size_name);
        }
      });
      return total;
    },
    groupIsoType(containers) {
      var total = "";
      containers.forEach((item, i) => {
        if (item.container_isotype_name) {
          total +=
            item.container_isotype_name +
            (containers.length == i + 1 ? "" : " / ");
        }
      });
      return total;
    },
    groupContainerSize(containers) {
      var text = "";
      containers.forEach((item, i) => {
        text +=
          item.container_size_name + (containers.length == i + 1 ? "" : " / ");
      });
      return text;
    },
    groupWHFactory1(containers) {
      var text = "";
      containers.forEach((item, i) => {
        if (item.wh_factory_1_name) {
          text +=
            item.wh_factory_1_name + (containers.length == i + 1 ? "" : " / ");
        }
      });
      return text;
    },
    groupUnloadDate1(containers) {
      var text = "";
      containers.forEach((item, i) => {
        if (item.unload_load_date1) {
          text +=
            moment(item.unload_load_date1).format("DD/MM/YYYY") +
            (containers.length == i + 1 ? "" : " / ");
        }
      });
      return text;
    },
    groupUnloadTime1(containers) {
      var text = "";
      containers.forEach((item, i) => {
        if (item.unload_load_time1) {
          text +=
            item.unload_load_time1 + (containers.length == i + 1 ? "" : " / ");
        }
      });
      return text;
    },
    groupWHFactory2(containers) {
      var text = "";
      containers.forEach((item, i) => {
        if (item.wh_factory_2_name) {
          text +=
            item.wh_factory_2_name + (containers.length == i + 1 ? "" : " / ");
        }
      });
      return text;
    },
    groupUnloadDate2(containers) {
      var text = "";
      containers.forEach((item, i) => {
        if (item.unload_load_date2) {
          text +=
            moment(item.unload_load_date2).format("DD/MM/YYYY") +
            (containers.length == i + 1 ? "" : " / ");
        }
      });
      return text;
    },
    groupUnloadTime2(containers) {
      var text = "";
      containers.forEach((item, i) => {
        if (item.unload_load_time2) {
          text +=
            item.unload_load_time2 + (containers.length == i + 1 ? "" : " / ");
        }
      });
      return text;
    },

    groupCyPlace(containers) {
      var text = "";
      containers.forEach((item, i) => {
        if (item.cy_place_name) {
          text +=
            item.cy_place_name + (containers.length == i + 1 ? "" : " / ");
        }
      });
      return text;
    },

    //* =====> Export Excel
    exportExcel() {
      if (this.selectedMain.length === 0) {
        this.$swal.fire({
          title: "Something went wrong!",
          text: "Please, Select at least 1 item",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      } else {
        this.$swal
          .fire({
            title: "Are you sure?",
            text: `Do you want to export Order to Excel?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Export!",
          })
          .then((result) => {
            if (result.isConfirmed) {
              const dataWS = XLSX.utils.json_to_sheet(this.dataExcel);
              const wb = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(wb, dataWS);
              const fileName = `monitoring_${moment(new Date()).format(
                "DD_MM_YYYY_h_mm_ss"
              )}.xlsx`;
              XLSX.writeFile(wb, fileName);
              this.selectedMain = [];
              this.dataExcel = [];
            } else {
              this.selectedMain = [];
              this.dataExcel = [];
            }
          });
      }
    },

    setDataExcel(items) {
      const mode = items.length > 0 ? items[0].mode : "";
      let no = 1;
      if (mode === "Import") {
        items.forEach((data) => {
          if (mode !== data.mode) {
            this.$swal
              .fire({
                title: "Something went wrong!",
                text: `Please select the same mode. (${mode})`,
                icon: "warning",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK",
              })
              .then(() => {
                this.selectedMain = [];
                this.dataExcel = [];
              });
          } else {
            data.order_detail.forEach((item) => {
              this.dataExcel.push({
                "No.": no,
                "Mode of Transport ": data.mode,
                "User ": data.user_name,
                "Remark ": data.remark,
                "CUSTOMER ": data.customer_name,
                "IMPORTER* ": data.importer_name,
                "Goods* ": data.goods_name,
                "Package ": data.package_name,
                "Inv./PO NO.*": data.inv_po,
                "ATA ": data.ata ? moment(data.ata).format("DD/MM/YYYY") : "",
                "วันที่ตู้ครบ ": data.discharge_complete
                  ? moment(data.discharge_complete).format("DD/MM/YYYY")
                  : "",
                "AGENT* ": data.shipping_agent_name,
                "Discharge PORT* ": data.port_discharge_name,
                "Release Place* ": data.release_port_name,
                "Release Date* ": data.release_date
                  ? moment(data.release_date).format("DD/MM/YYYY")
                  : "",
                "Qty ": item.qty,
                "Size+ISO Type ":
                  item.container_size_name + "'" + item.container_isotype_name,
                "Shipping ": data.shipping_name,
                "Status Container": data.container_status,
                "WH/Factory1* ": item.wh_factory_1_name,
                "UnloadDate1* ": item.unload_load_date1
                  ? moment(item.unload_load_date1).format("DD/MM/YYYY")
                  : "",
                "UnloadTime1* ": this.subStringTime(item.unload_load_time1),
                "WH/Factory2* ": item.wh_factory_2_name,
                "UnloadDate2* ": item.unload_load_date2
                  ? moment(item.unload_load_date2).format("DD/MM/YYYY")
                  : "",
                "UnloadTime2* ": this.subStringTime(item.unload_load_time2),
                "overtime card ": url_path + data.overtime_card_path,
                "return card ": url_path + data.return_card_path,
              });
            });
            no++;
          }
        });
      } else {
        items.forEach((data) => {
          if (mode !== data.mode) {
            this.$swal
              .fire({
                title: "Something went wrong!",
                text: `Please select the same mode. (${mode})`,
                icon: "warning",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK",
              })
              .then(() => {
                this.selectedMain = [];
                this.dataExcel = [];
              });
          } else {
            data.order_detail.forEach((item) => {
              this.dataExcel.push({
                "No.": no,
                "Mode of Transport ": data.mode,
                "User ": data.user_name,
                "Remark ": data.remark,
                "CUSTOMER ": data.customer_name,
                "IMPORTER* ": data.importer_name,
                "Goods* ": data.goods_name,
                "Package ": data.package_name,
                "Inv./PO NO.*": data.inv_po,
                "Booking no.* ": data.booking_no,
                "CY PLACE* ": item.cy_place_name,
                "CY DATE* ": data.cy_date
                  ? moment(data.cy_date).format("DD/MM/YYYY")
                  : "",
                "Shipping Agent* ": data.shipping_agent_name,
                "Qty ": item.qty,
                "Size+ISO Type ":
                  item.container_size_name + "'" + item.container_isotype_name,
                "Status Container": data.container_status,
                "WH/Factory1* ": item.wh_factory_1_name,
                "load Date1* ": item.unload_load_date1
                  ? moment(item.unload_load_date1).format("DD/MM/YYYY")
                  : "",
                "load Time1* ": this.subStringTime(item.unload_load_time1),
                "WH/Factory2* ": item.wh_factory_2_name,
                "load Date2* ": item.unload_load_date2
                  ? moment(item.unload_load_date2).format("DD/MM/YYYY")
                  : "",
                "load Time2* ": this.subStringTime(item.unload_load_time2),
                "Ton ": item.ton,
                "Forwarder ": data.forwarder_name,
                "FEEDER VESSEL* ": data.feeder_vessel,
                "OCEAN VESSEL ": data.ocean_vessel,
                "PORT/Country* ": data.port_discharge_name
                  ? data.port_discharge_name
                  : data.port_country_name,
                "RETURN PLACE* ": item.return_place_name,
                "CLOSING DATE* ": item.closing_date
                  ? moment(item.closing_date).format("DD/MM/YYYY")
                  : "",
                "CLOSING TIME* ": this.subStringTime(item.closing_time),
                "FRD* ": item.frd ? moment(item.frd).format("DD/MM/YYYY") : "",
                "SHIPPING ": data.shipping_name,
              });
            });
            no++;
          }
        });
      }
    },
  },
};
</script>

<style>
.progress-complete {
  color: #338a68;
}
.progress-pending {
  color: #ec8235;
}
.green-tab {
  background-color: #338a68 !important;
  color: #fff;
  border-radius: 5px;
  width: 100px;
  height: 20px;
}
.orange-tab {
  background-color: #ec8235 !important;
  color: #fff;
  border-radius: 5px;
  width: 100px;
  height: 20px;
}
.gray-tab {
  background-color: #d8d8d8 !important;
  color: #fff;
  border-radius: 5px;
  width: 100px;
  height: 20px;
}
</style>
