<template>
  <div :class="borderColor">
    <v-textarea
      :placeholder="placeholder"
      color="gray"
      :background-color="disabled ? '#E5E7E9' : '#fff'"
      outlined
      dense
      class="text-field"
      :rules="required ? [(v) => !!v || 'field is required'] : []"
      :rows="row"
      :value="model"
      :label="label"
      required
      :hide-details="hideDetails"
      @input="onChange"
      :disabled="disabled"
    ></v-textarea>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    label: {
      default: "",
    },
    placeholder: {
      default: "",
    },
    row: {
      default: "3",
    },
    height: {
      default: "37px",
    },
    borderColor: {
      default: "gray",
    },
  },
  data() {
    return {
      fieldRequire: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
    };
  },
  methods: {
    onChange(val) {
      this.$emit("update-data", val);
    },
  },
};
</script>

<style></style>
