<template>
  <div class="mx-8 table-tender">
    <!-- Main Table -->
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="truck_supply_id"
      class="elevation-1"
      show-select
      hide-default-footer
      v-model="selected"
    >
      <!-- Custom Main Table Item rows -->
      <template v-slot:item="{ item, isSelected, select }">
        <tr>
          <td class="border-right ">
            <v-simple-checkbox
              color="#338A68"
              :value="isSelected"
              @input="select($event)"
            ></v-simple-checkbox>
          </td>
          <td class="text-center border-right">
            <v-icon color="#000" class="mr-3" @click="deleteItem(item)">
              mdi-delete-outline
            </v-icon>
            <v-icon color="#62C4E3" @click="editItem(item)">
              mdi-pencil-outline
            </v-icon>
          </td>
          <td class="text-center">{{ item.no }}</td>
          <td class="text-center">
            {{ item.status_name ? item.status_name : "--" }}
          </td>
          <td class="text-center">
            {{ item.carrier_code ? item.carrier_code : "--" }}
          </td>
          <td class="text-left">
            {{ item.driver_code ? item.driver_code : "--" }}
          </td>
          <td class="text-left">
            {{ item.title_name ? item.title_name : "--" }}
          </td>
          <td class="text-left">{{ item.f_name ? item.f_name : "--" }}</td>
          <td class="text-left">{{ item.l_name ? item.l_name : "--" }}</td>
          <td class="text-left">{{ item.tel ? item.tel : "--" }}</td>
          <td class="text-left">{{ item.line_id ? item.line_id : "--" }}</td>
          <td class="text-left">{{ item.car_code ? item.car_code : "--" }}</td>
          <td class="text-left">
            {{ item.car_sub_type_name ? item.car_sub_type_name : "--" }}
          </td>
          <td class="text-left">
            {{ item.truck_plate_no ? item.truck_plate_no : "--" }}
          </td>
          <td class="text-left">
            {{ item.tail_code ? item.tail_code : "--" }}
          </td>
          <td class="text-left">
            {{ item.tail_sub_type_name ? item.tail_sub_type_name : "--" }}
          </td>
          <td class="text-left">
            {{ item.tail_plate_no ? item.tail_plate_no : "--" }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  props: {
    headers: Array,
    items: Array,
    selectItem: Function,
    nameTable: String,
    deleteItem: Function,
    editItem: Function,
    isClearSelected: Boolean,
    clearSelected: Function,
  },
  data() {
    return {
      selected: [],
    };
  },
  watch: {
    isClearSelected: {
      handler: function(newValue) {
        if (!newValue) {
          this.selected = [];
        }
      },
    },
    selected: {
      handler: function(newValue) {
        this.selectItem(newValue);
        if (newValue.length > 0) {
          this.clearSelected(true);
        } else {
          this.clearSelected(false);
        }
      },
    },
  },
  components: {},
};
</script>

<style scope>
/* .test thead.v-data-table-header {
  background-color: #4b5768;
} */
.table-color {
  background-color: #99ca545e;
}
</style>
