<template>
  <div class="text-center">
    <v-pagination
      color="#338A68"
      :value="current_page"
      :length="last_page"
      :total-visible="15"
      prev-icon="mdi-menu-left"
      next-icon="mdi-menu-right"
      @input="nextPage"
    ></v-pagination>
  </div>
</template>

<script>
export default {
  props: {
    current_page: {
      default: 1,
    },
    last_page: {
      default: 4,
    },
    nextPage: Function,
  },
};
</script>

<style></style>
