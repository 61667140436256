import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import menu from "./modules/Menu.Store";
import auth from "./modules/Auth.Store";
import order from "./modules/Order.Store";
import order_detail from "./modules/OrderDetail.Store";
import tender from "./modules/Tender.Store";
import truck_supply from "./modules/TruckSupply.Store";
import accept_tender from "./modules/AcceptTender.Store";
import master_data from "./modules/MasterData.Store";
import tolls_scale from "./modules/TollsScale.Store";
import lan_pass_lifting_fee from "./modules/LanPassLiftingFee.Store";
import cost_per_truck from "./modules/CostPerTruck.Store";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    menu,
    order,
    order_detail,
    tender,
    master_data,
    truck_supply,
    accept_tender,
    tolls_scale,
    lan_pass_lifting_fee,
    cost_per_truck,
  },
  plugins: [createPersistedState()],
});
