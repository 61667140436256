<template>
  <div>
    <v-form
      style="background-color: #F5F5F5"
      ref="form"
      v-model="valid"
      lazy-validation
      class=" py-6"
    >
      <v-layout mb-3>
        <v-flex xs4>
          <v-layout class="align-center">
            <v-flex class="xs5 text-end font-12  font-weight-bold mr-3">
              CS<span style="color: red">*</span> :
            </v-flex>
            <v-flex class="xs7">
              {{ user.firstname }}
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-layout wrap px-6 mb-3>
        <v-flex xs3>
          <v-layout class="align-center">
            <v-flex class="xs6 text-end font-12  font-weight-bold mr-3">
              Customer :
            </v-flex>
            <v-flex class="xs7">
              <AutocompleteInput
                clearable
                borderColor="green"
                placeholder="customer"
                :model="form.customer_id"
                rulse="fieldRequire"
                :items="customerList"
                itemText="customer_code"
                itemValue="customer_id"
                @update-data="
                  form.customer_id = $event ? $event.customer_id : '';
                  form.customer_name = $event ? $event.customer_code : '';
                  form.receipt_type_id = '';
                  form.receipt_type_name = '';
                  form.address_id = '';
                  form.address_name = '';
                "
              />
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs3> </v-flex>
        <v-flex xs3>
          <v-layout class="align-center">
            <v-flex class="xs4 text-end font-12  font-weight-bold mr-3">
              Receipt Type <span style="color: red">*</span> :
            </v-flex>
            <v-flex class="xs7">
              <AutocompleteInput
                clearable
                borderColor="green"
                :model="form.receipt_type_id"
                rulse="fieldRequire"
                :items="receiptTypeList"
                itemText="receipt_type_name"
                itemValue="receipt_type_id"
                @update-data="
                  form.receipt_type_id = $event ? $event.receipt_type_id : '';
                  form.receipt_type_name = $event
                    ? $event.receipt_type_name
                    : '';
                  form.address_id = '';
                  form.address_name = '';
                "
              />
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs3>
          <v-layout class="align-center">
            <v-flex class="xs6 text-end font-12  font-weight-bold mr-3">
              Address :
            </v-flex>
            <v-flex class="xs7">
              <AutocompleteInput
                clearable
                borderColor="green"
                :model="form.address_id"
                rulse="fieldRequire"
                :items="addressList"
                itemText="address_name"
                itemValue="address_id"
                @update-data="onHandleAddress($event)"
              />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-layout wrap px-6>
        <v-flex xs3>
          <v-layout class="align-center">
            <v-flex class="xs6 text-end  font-12  font-weight-bold mr-3">
              IMPORTER <span style="color: red">*</span> :
            </v-flex>
            <v-flex class="xs7">
              <AutocompleteInput
                clearable
                required
                placeholder="IMPORTER"
                :model="form.importer_id"
                rulse="fieldRequire"
                :items="importerList"
                itemText="customer_code"
                itemValue="customer_id"
                @update-data="
                  form.importer_id = $event ? $event.customer_id : '';
                  form.importer_name = $event ? $event.customer_code : '';
                  form.receipt_type_id = '';
                  form.receipt_type_name = '';
                  form.address_id = '';
                  form.address_name = '';
                "
              />
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs3>
          <v-layout class="align-center">
            <v-flex class="xs4 text-end font-12  font-weight-bold mr-3">
              INV/.PO <span style="color: red">*</span> :
            </v-flex>
            <v-flex class="xs7">
              <TextInput
                required
                placeholder="INV/.PO"
                :model="form.inv_po"
                rulse="fieldRequire"
                @update-data="updateData('inv_po', $event)"
              />
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs3>
          <v-layout class="">
            <v-flex class="xs4 pt-2 text-end  font-12  font-weight-bold mr-3">
              Cost For :
            </v-flex>
            <v-flex class="xs7">
              <v-layout>
                <v-flex>
                  <v-checkbox
                    class="ma-0"
                    hide-details
                    label="ค่ายกตู้"
                    color="#338A68"
                    v-model="form.is_cost_for_lifting"
                  >
                    <template v-slot:label>
                      <div class="font-12 font-weight-bold">
                        ค่ายกตู้
                      </div>
                    </template>
                  </v-checkbox>
                </v-flex>
                <v-flex>
                  <v-checkbox
                    class="ma-0"
                    hide-details
                    label="ค่าชั่ง"
                    color="#338A68"
                    v-model="form.is_cost_for_scale"
                  >
                    <template v-slot:label>
                      <div class="font-12 font-weight-bold">
                        ค่าชั่ง
                      </div>
                    </template>
                  </v-checkbox>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex>
                  <v-checkbox
                    class="ma-0"
                    hide-details
                    label="ค่าผ่านลาน"
                    color="#338A68"
                    v-model="form.is_cost_for_lan"
                  >
                    <template v-slot:label>
                      <div class="font-12 font-weight-bold">
                        ค่าผ่านลาน
                      </div>
                    </template>
                  </v-checkbox>
                </v-flex>
                <v-flex>
                  <v-checkbox
                    class="ma-0"
                    hide-details
                    label="ค่าผ่านท่า"
                    color="#338A68"
                    v-model="form.is_cost_for_port"
                  >
                    <template v-slot:label>
                      <div class="font-12 font-weight-bold">
                        ค่าผ่านท่า
                      </div>
                    </template>
                  </v-checkbox>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex class="xs3">
          <v-layout class="align-center">
            <v-flex class="xs6 text-end font-12  font-weight-bold mr-3">
            </v-flex>
            <v-flex class="xs7 font-12">
              {{ form.address_name ? form.address_name : "-" }}
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-divider class="my-6"></v-divider>

      <v-layout px-6>
        <v-flex xs4>
          <v-layout class="align-center mb-3">
            <v-flex class="xs4 text-end font-12  font-weight-bold mr-3">
              AGENT<span style="color: red">*</span> :
            </v-flex>
            <v-flex class="xs7">
              <AutocompleteInput
                clearable
                required
                placeholder="AGENT"
                :model="form.shipping_agent_id"
                rulse="fieldRequire"
                :items="agentList"
                itemText="shipping_agent_name"
                itemValue="shipping_agent_id"
                @update-data="
                  updateSelect(
                    'shipping_agent',
                    'shipping_agent_id',
                    'shipping_agent_name',
                    $event
                  )
                "
              />
            </v-flex>
          </v-layout>

          <v-layout class="align-center mb-3">
            <v-flex class="xs4 text-end font-12  font-weight-bold mr-3">
              Goods <span style="color: red">*</span> :
            </v-flex>
            <v-flex class="xs7">
              <AutocompleteInput
                clearable
                placeholder="สินค้า"
                :model="form.goods_id"
                required
                :items="goodsList"
                itemText="goods_name"
                itemValue="goods_id"
                @update-data="
                  updateSelect('goods', 'goods_id', 'goods_name', $event)
                "
              />
            </v-flex>
          </v-layout>

          <v-layout class="align-center mb-3">
            <v-flex class="xs4 text-end font-12  font-weight-bold mr-3">
              Port Discharge <span style="color: red">*</span> :
            </v-flex>
            <v-flex class="xs7">
              <AutocompleteInput
                clearable
                required
                placeholder="Port Discharge"
                :model="form.port_discharge_id"
                rulse="fieldRequire"
                :items="portDischargeList"
                itemText="port_country_name"
                itemValue="port_country_id"
                @update-data="
                  updateSelect(
                    'port_discharge',
                    'port_country_id',
                    'port_country_name',
                    $event
                  )
                "
              />
            </v-flex>
          </v-layout>

          <v-layout class="align-center mb-3">
            <v-flex class="xs4 text-end  font-12  font-weight-bold mr-3">
              ATA :
            </v-flex>
            <v-flex class="xs4">
              <DatePicker
                borderColor="green"
                placeholder="DD/MM/YY"
                hideDetail
                rulse="fieldRequire"
                :model="date.ata"
                @update-data="updateDate('ata', $event)"
              ></DatePicker>
            </v-flex>
          </v-layout>

          <v-layout class="align-center mb-3">
            <v-flex class="xs4 text-end  font-12  font-weight-bold mr-3">
              Discharge Complete :
            </v-flex>
            <v-flex class="xs4">
              <DatePicker
                placeholder="DD/MM/YY"
                hideDetail
                rulse="fieldRequire"
                :model="date.discharge_complete"
                @update-data="updateDate('discharge_complete', $event)"
              ></DatePicker>
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex xs4>
          <v-layout class="align-center mb-3">
            <v-flex class="xs4 text-end font-12  font-weight-bold mr-3">
              Shipping <span style="color: red">*</span> :
            </v-flex>
            <v-flex class="xs7">
              <AutocompleteInput
                clearable
                required
                borderColor="green"
                placeholder="Shipping"
                :model="form.shipping_id"
                rulse="fieldRequire"
                :items="shippingList"
                itemText="name"
                itemValue="carrier_id"
                @update-data="
                  updateSelect('shipping', 'carrier_id', 'name', $event)
                "
              />
            </v-flex>
          </v-layout>

          <v-layout class="align-center mb-3">
            <v-flex class="xs4 text-end font-12  font-weight-bold mr-3">
              Haulage (est.) :
            </v-flex>
            <v-flex class="xs7">
              <AutocompleteInput
                clearable
                placeholder="Haulage"
                :model="form.haulage_id"
                :items="haulageList"
                itemText="name"
                itemValue="carrier_id"
                @update-data="
                  updateSelect('haulage', 'carrier_id', 'name', $event)
                "
              />
            </v-flex>
          </v-layout>

          <v-layout class="align-center mb-3">
            <v-flex class="xs4 text-end font-12  font-weight-bold mr-3">
              Release Port <span style="color: red">*</span> :
            </v-flex>
            <v-flex class="xs7">
              <AutocompleteInput
                clearable
                required
                borderColor="green"
                placeholder="Release Port"
                :model="form.release_port_id"
                rulse="fieldRequire"
                :items="releasePortList"
                itemText="port_discharge_name"
                itemValue="port_discharge_id"
                @update-data="
                  updateSelect(
                    'release_port',
                    'port_discharge_id',
                    'port_discharge_name',
                    $event
                  )
                "
              />
            </v-flex>
          </v-layout>

          <v-layout class="align-center mb-3">
            <v-flex class="xs4 text-end  font-12  font-weight-bold mr-3">
              Release Date <span style="color: red">*</span> :
            </v-flex>
            <v-flex class="xs4">
              <DatePicker
                placeholder="DD/MM/YY"
                required
                hideDetail
                rulse="fieldRequire"
                :model="date.release_date"
                @update-data="updateDate('release_date', $event)"
              ></DatePicker>
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex xs4>
          <v-layout class="mb-3">
            <v-flex xs6>
              <v-layout class="align-center">
                <v-flex class="xs5 text-end font-12  font-weight-bold mr-3">
                  RENT :
                </v-flex>
                <v-flex class="xs7">
                  <DatePicker
                    placeholder="DD/MM/YY"
                    :model="date.rent"
                    hideDetail
                    @update-data="updateDate('rent', $event)"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs6>
              <v-layout class="align-center">
                <v-flex class="xs5 text-end font-12  font-weight-bold mr-3">
                  Demurage :
                </v-flex>
                <v-flex class="xs7">
                  <DatePicker
                    placeholder="DD/MM/YY"
                    :model="date.demurage"
                    hideDetail
                    @update-data="updateDate('demurage', $event)"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

          <v-layout class="mb-3">
            <v-flex xs6>
              <v-layout class="align-center">
                <v-flex class="xs5 text-end font-12  font-weight-bold mr-3">
                  Detention :
                </v-flex>
                <v-flex class="xs7">
                  <DatePicker
                    placeholder="DD/MM/YY"
                    borderColor="green"
                    :model="date.detention"
                    hideDetail
                    @update-data="updateDate('detention', $event)"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs6>
              <v-layout class="align-center">
                <v-flex class="xs5 text-end font-12  font-weight-bold mr-3">
                  Detention <br />
                  (Days) :
                </v-flex>
                <v-flex class="xs7">
                  <TextInput
                    borderColor="green"
                    placeholder="Detention(Days)"
                    inputRule="number"
                    hideDetail
                    :model="form.detention_days"
                    @update-data="updateDate('detention_days', $event)"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

          <v-layout class="align-center mb-3">
            <v-flex class="xs2 text-end font-12  font-weight-bold mr-3">
              Container Status :
            </v-flex>
            <v-flex class="xs7 pl-2">
              <TextInput
                :model="form.container_status"
                rulse="fieldRequire"
                @update-data="form.container_status = $event"
              />
            </v-flex>
          </v-layout>

          <v-layout class="align-center mb-3">
            <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
              Overtime Card :
            </v-flex>
            <v-flex>
              <v-text-field
                readonly
                hide-details
                height="38"
                background-color="#fff"
                outlined
                v-model="overtime_card"
              />
            </v-flex>
            <v-flex shrink class="pl-1">
              <v-btn
                dark
                block
                height="38"
                color="#338A68"
                depressed
                @click="$refs.overtime_card.click()"
              >
                <v-icon>mdi-paperclip</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>

          <input
            type="file"
            hidden
            accept="application/pdf"
            ref="overtime_card"
            @change="onFileChange($event, 'overtime_card')"
          />

          <v-layout class="align-center mb-3">
            <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
              Return Card :
            </v-flex>
            <v-flex>
              <v-text-field
                readonly
                hide-details
                height="38"
                background-color="#fff"
                outlined
                v-model="return_card"
              />
            </v-flex>
            <v-flex shrink class="pl-1">
              <v-btn
                dark
                block
                height="38"
                color="#338A68"
                depressed
                @click="$refs.return_card.click()"
              >
                <v-icon>mdi-paperclip</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>

          <input
            type="file"
            hidden
            accept="application/pdf"
            ref="return_card"
            @change="onFileChange($event, 'return_card')"
          />
        </v-flex>
      </v-layout>

      <v-divider class="my-6"></v-divider>

      <v-layout wrap px-6>
        <v-flex>
          <v-layout mb-3 v-for="(item, i) in containers_form" :key="i">
            <!-- Group -->
            <v-flex mr-4 shrink>
              <v-layout>
                <v-flex xs4 class="font-12 mr-2">
                  <!-- QTY -->
                  <label v-if="i == 0" class=" font-weight-bold">
                    Qty :
                  </label>
                  <TextInput
                    required
                    inputRule="number"
                    hideDetail
                    borderColor="green"
                    rulse="fieldRequire"
                    :model="item.qty"
                    @update-data="updateDataContainer('qty', i, $event)"
                  />
                </v-flex>
                <v-flex xs4 class="font-12 mr-2">
                  <!-- Size -->
                  <label v-if="i == 0" class=" font-weight-bold">
                    Size :
                  </label>
                  <AutocompleteInput
                    clearable
                    required
                    :disabled="item.tender > 0"
                    borderColor="green"
                    hideDetail
                    placeholder="size"
                    :model="item.container_size_id"
                    rulse="fieldRequire"
                    :items="containerSizeList"
                    itemText="size"
                    itemValue="container_size_id"
                    @update-data="
                      updateDataContainerSelect('container_size', i, $event)
                    "
                  />
                </v-flex>
                <v-flex xs4 class="font-12">
                  <!-- Isotype -->
                  <label v-if="i == 0" class=" font-weight-bold">
                    Isotype :
                  </label>
                  <AutocompleteInput
                    clearable
                    borderColor="green"
                    hideDetail
                    :disabled="item.tender > 0"
                    placeholder="size"
                    :model="item.container_isotype_id"
                    :items="containerIsoTypeList"
                    itemText="isotype"
                    itemValue="container_isotype_id"
                    @update-data="
                      updateDataContainerSelect('container_isotype', i, $event)
                    "
                  />
                </v-flex>
              </v-layout>
            </v-flex>

            <!-- Group -->
            <v-flex mr-4 shrink>
              <v-layout>
                <v-flex xs4>
                  <v-layout>
                    <v-flex class="font-12 pr-2">
                      <label v-if="i == 0" class=" font-weight-bold">
                        WH/Factory1<span style="color: red">*</span> :
                      </label>
                      <AutocompleteInput
                        clearable
                        hideDetail
                        :disabled="item.tender > 0"
                        required
                        placeholder="WH/Factory1"
                        :model="item.wh_factory_1_id"
                        rulse="fieldRequire"
                        :items="factoryList"
                        itemText="wh_factory_name"
                        itemValue="wh_factory_id"
                        @update-data="
                          updateDataContainerSelect('wh_factory_1', i, $event)
                        "
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs8 class="font-12">
                  <label v-if="i == 0" class=" font-weight-bold">
                    Unload / Load Date Time1<span style="color: red">* </span>
                    :
                  </label>
                  <v-layout class="align-center">
                    <v-flex class="mr-2">
                      <DatePicker
                        placeholder="DD/MM/YY"
                        required
                        :disabled="item.tender > 0"
                        borderColor="green"
                        hideDetail
                        rulse="fieldRequire"
                        :model="date_con[i].unload_load_date1"
                        @update-data="
                          updateDateContainer('unload_load_date1', i, $event)
                        "
                      ></DatePicker>
                    </v-flex>
                    <v-flex>
                      <TimePicker
                        required
                        :disabled="item.tender > 0"
                        placeholder="00:00"
                        borderColor="green"
                        :model="item.unload_load_time1"
                        hideDetail
                        @update-data="
                          updateDataContainer('unload_load_time1', i, $event)
                        "
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>

            <!-- Group -->
            <v-flex mr-4 shrink>
              <v-layout>
                <v-flex xs4>
                  <v-layout>
                    <v-flex class="font-12 mr-2">
                      <label v-if="i == 0" class=" font-weight-bold">
                        WH/Factory2 :
                      </label>
                      <AutocompleteInput
                        clearable
                        hideDetail
                        :disabled="item.tender > 0"
                        placeholder="WH/Factory2"
                        :model="item.wh_factory_2_id"
                        rulse="fieldRequire"
                        :items="factoryList"
                        itemText="wh_factory_name"
                        itemValue="wh_factory_id"
                        @update-data="
                          updateDataContainerSelect('wh_factory_2', i, $event)
                        "
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs8 class="font-12">
                  <label v-if="i == 0" class=" font-weight-bold">
                    Unload / Load Date Time2 :
                  </label>
                  <v-layout class="align-center">
                    <v-flex class="mr-2">
                      <DatePicker
                        placeholder="DD/MM/YY"
                        :disabled="item.tender > 0"
                        borderColor="green"
                        hideDetail
                        rulse="fieldRequire"
                        :model="date_con[i].unload_load_date2"
                        @update-data="
                          updateDateContainer('unload_load_date2', i, $event)
                        "
                      ></DatePicker>
                    </v-flex>
                    <v-flex>
                      <TimePicker
                        :disabled="item.tender > 0"
                        placeholder="00:00"
                        borderColor="green"
                        :model="item.unload_load_time2"
                        hideDetail
                        @update-data="
                          updateDataContainer('unload_load_time2', i, $event)
                        "
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>

            <!-- Group -->
            <v-flex shrink class="font-12 mr-4">
              <!-- Return Place -->
              <v-layout>
                <v-flex x12 class="font-12">
                  <label v-if="i == 0" class=" font-weight-bold">
                    Return Place<span style="color: red">*</span> :
                  </label>
                  <AutocompleteInput
                    clearable
                    required
                    borderColor="green"
                    :disabled="item.tender > 0"
                    placeholder="Return Place"
                    :model="item.return_place_id"
                    rulse="fieldRequire"
                    :items="cyPlaceList"
                    itemText="cy_place_name"
                    itemValue="cy_place_id"
                    @update-data="
                      updateDataContainerSelect('return_place', i, $event)
                    "
                  />
                </v-flex>
              </v-layout>
            </v-flex>

            <!-- btn add/remove -->
            <v-flex
              xs2
              class="d-flex align-center"
              :class="i == 0 ? 'mt-4' : ''"
            >
              <v-btn
                class="ml-3"
                width="20"
                height="20"
                depressed
                fab
                small
                outlined
                color="gray"
                :disabled="containers_form.length <= 1 || item.tender > 0"
                @click="removeContainer(i)"
              >
                <v-icon dark>
                  mdi-minus
                </v-icon>
              </v-btn>
              <v-btn
                class="ml-3"
                width="20"
                height="20"
                depressed
                fab
                small
                outlined
                v-show="containers_form.length === i + 1"
                @click="addContainer"
              >
                <v-icon dark>
                  mdi-plus
                </v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-divider class="mt-6"></v-divider>

      <v-layout class="mb-n6">
        <v-flex xs6 class="d-flex justify-center align-center">
          <v-layout>
            <v-flex class="font-12 text-end xs3 font-weight-bold">
              Size Container :
            </v-flex>
            <v-flex xs9 ml-3>
              <v-layout mb-2>
                <v-flex
                  shrink
                  class="font-11 mr-4"
                  :style="
                    !containers.cn_20.isActive ? { color: '#C4C4C4' } : ''
                  "
                >
                  20' = {{ containers.cn_20.total }}
                </v-flex>
                <v-flex
                  shrink
                  class="font-11 mr-4"
                  :style="
                    !containers.cn_20_ot.isActive ? { color: '#C4C4C4' } : ''
                  "
                >
                  20'OT = {{ containers.cn_20_ot.total }}
                </v-flex>
                <v-flex
                  shrink
                  class="font-11 mr-4"
                  :style="
                    !containers.cn_20_fr.isActive ? { color: '#C4C4C4' } : ''
                  "
                >
                  20'FR = {{ containers.cn_20_fr.total }}
                </v-flex>
                <v-flex
                  shrink
                  class="font-11 mr-4"
                  :style="
                    !containers.cn_20_rf.isActive ? { color: '#C4C4C4' } : ''
                  "
                >
                  20'RF = {{ containers.cn_20_rf.total }}
                </v-flex>
                <v-flex
                  shrink
                  class="font-11 mr-4"
                  :style="
                    !containers.cn_20_ios_tank.isActive
                      ? { color: '#C4C4C4' }
                      : ''
                  "
                >
                  20'ISO TANK = {{ containers.cn_20_ios_tank.total }}
                </v-flex>
              </v-layout>
              <v-layout mb-2>
                <v-flex
                  shrink
                  class="font-11 mr-4"
                  :style="
                    !containers.cn_40.isActive ? { color: '#C4C4C4' } : ''
                  "
                >
                  40' = {{ containers.cn_40.total }}
                </v-flex>
                <v-flex
                  shrink
                  class="font-11 mr-4"
                  :style="
                    !containers.cn_40_hc.isActive ? { color: '#C4C4C4' } : ''
                  "
                >
                  40'HC = {{ containers.cn_40_hc.total }}
                </v-flex>
                <v-flex
                  shrink
                  class="font-11 mr-4"
                  :style="
                    !containers.cn_40_ot.isActive ? { color: '#C4C4C4' } : ''
                  "
                >
                  40'OT = {{ containers.cn_40_ot.total }}
                </v-flex>
                <v-flex
                  shrink
                  class="font-11 mr-4"
                  :style="
                    !containers.cn_40_fr.isActive ? { color: '#C4C4C4' } : ''
                  "
                >
                  40'FR = {{ containers.cn_40_fr.total }}
                </v-flex>
                <v-flex
                  shrink
                  class="font-11 mr-4"
                  :style="
                    !containers.cn_40_rf.isActive ? { color: '#C4C4C4' } : ''
                  "
                >
                  40'RF = {{ containers.cn_40_rf.total }}
                </v-flex>
                <v-flex
                  shrink
                  class="font-11 mr-4"
                  :style="
                    !containers.cn_45.isActive ? { color: '#C4C4C4' } : ''
                  "
                >
                  45' = {{ containers.cn_45.total }}
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex
                  shrink
                  class="font-11 mr-4"
                  :style="
                    !containers.cn_20_other.isActive ? { color: '#C4C4C4' } : ''
                  "
                >
                  20' Other= {{ containers.cn_20_other.total }}
                </v-flex>
                <v-flex
                  shrink
                  class="font-11 mr-4"
                  :style="
                    !containers.cn_40_other.isActive ? { color: '#C4C4C4' } : ''
                  "
                >
                  40' Other = {{ containers.cn_40_other.total }}
                </v-flex>
                <v-flex
                  shrink
                  class="font-11 mr-4"
                  :style="
                    !containers.cn_45_other.isActive ? { color: '#C4C4C4' } : ''
                  "
                >
                  45' Other = {{ containers.cn_45_other.total }}
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-divider vertical></v-divider>
        <v-flex
          shrink
          class="text-center d-flex justify-center align-center font-12 font-weight-bold px-3"
        >
          Total : {{ form.ct_total ? form.ct_total : 0 }}
        </v-flex>
        <v-divider vertical></v-divider>
        <v-flex xs5 mt-4 mb-6>
          <v-layout>
            <v-flex class="shrink2">
              <v-layout>
                <v-flex
                  class="xs3 text-end font-12  font-weight-bold mr-3 pt-2"
                >
                  Remark :
                </v-flex>
                <v-flex class="shrink0 text-start font-14 mr-3 pt-2">
                  <TextareaInput
                    row="2"
                    placeholder="หมายเหตุ"
                    hideDetail
                    rulse="fieldRequire"
                    :model="form.remark"
                    @update-data="updateData('remark', $event)"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-form>

    <div class="text-end mt-6" v-if="!user.carrier_id">
      <v-btn
        @click="onClickCancel"
        class="mr-4 font-weight-bold text-capitalize"
        outlined
        width="150"
        color="#4B5768"
      >
        Cancel
      </v-btn>

      <v-btn
        class="mr-4 font-weight-bold text-capitalize"
        width="150"
        :disabled="!valid"
        :dark="valid"
        color="#338A68"
        @click="save"
      >
        Save
      </v-btn>
    </div>

    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="false"
    />
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mapState } from "vuex";
import TextInput from "../../../../components/input/TextInput.vue";
import AutocompleteInput from "../../../../components/select/AutocompleteInput";
import DatePicker from "../../../../components/picker/DatePicker";
import TimePicker from "../../../../components/picker/TimePicker.vue";
import TextareaInput from "../../../../components/input/TextareaInput.vue";
export default {
  props: {
    statusForm: String,
  },
  components: {
    Loading,
    TextInput,
    AutocompleteInput,
    DatePicker,
    TimePicker,
    TextareaInput,
  },
  data: () => ({
    valid: true,
    isLoading: false,
    fullPage: true,
    order_id: null,
    form: {
      user_id: "",
      user_name: "",
      customer_id: "",
      customer_name: "",
      inv_po: "",
      importer_id: "",
      importer_name: "",
      shipping_agent_id: "",
      shipping_agent_name: "",
      shipping_id: "",
      shipping_name: "",
      goods_id: "",
      goods_name: "",
      haulage_id: "",
      haulage_name: "",
      port_discharge_id: "",
      port_discharge_name: "",
      release_port_id: "",
      release_port_name: "",
      ata: "",
      release_date: "",
      discharge_complete: "",
      rent: "",
      demurage: "",
      detention: "",
      detention_days: "",
      ct_total: "",
      remark: "",
      status: "",
      container_status: "",
      receipt_type_id: "",
      receipt_type_name: "",
      address_id: "",
      address_name: "",
      is_cost_for_lan: 0,
      is_cost_for_port: 0,
      is_cost_for_lifting: 0,
      is_cost_for_scale: 0,
      name_cost_for_lan: "",
      name_cost_for_port: "",
      name_cost_for_lifting: "",
      name_cost_for_scale: "",
      overtime_card: "",
      return_card: "",
    },
    overtime_card: null,
    return_card: null,
    receiptTypeList: [
      { receipt_type_id: 1, receipt_type_name: "Customer" },
      { receipt_type_id: 2, receipt_type_name: "Importer" },
    ],
    addressList: [
      { address_id: 1, address_name: "Address1" },
      { address_id: 2, address_name: "Address2" },
    ],
    containers_form: [
      {
        order_detail_id: null,
        return_place_id: "",
        return_place_name: "",
        container_size_id: "",
        container_size_name: "",
        container_isotype_id: "",
        container_isotype_name: "",
        qty: "0",
        wh_factory_1_id: "",
        wh_factory_1_name: "",
        wh_factory_2_id: "",
        wh_factory_2_name: "",
        unload_load_date1: "",
        unload_load_time1: "",
        unload_load_date2: "",
        unload_load_time2: "",
      },
    ],

    customerList: [],
    cyPlaceList: [],
    importerList: [],
    factoryList: [],
    agentList: [],
    goodsList: [],
    portDischargeList: [],
    placeDeliveryList: [],
    releasePortList: [],
    shippingList: [],
    haulageList: [],
    containerSizeList: [],
    containerIsoTypeList: [],
    listMode: [
      { label: "Import" },
      { label: "Export" },
      { label: "Emtyp" },
      { label: "Laden" },
      { label: "Drop Load" },
    ],
    date: {
      unload_date: "",
      ata: "",
      rent: "",
      demurage: "",
      detention: "",
      discharge_complete: "",
      release_date: "",
    },
    date_con: [
      {
        unload_load_date1: "",
        unload_load_date2: "",
      },
    ],
    containers: {
      cn_20: { total: 0, isActive: false },
      cn_20_ot: { total: 0, isActive: false },
      cn_20_fr: { total: 0, isActive: false },
      cn_20_rf: { total: 0, isActive: false },
      cn_20_ios_tank: { total: 0, isActive: false },
      cn_20_other: { total: 0, isActive: false },
      cn_40: { total: 0, isActive: false },
      cn_40_hc: { total: 0, isActive: false },
      cn_40_ot: { total: 0, isActive: false },
      cn_40_fr: { total: 0, isActive: false },
      cn_40_rf: { total: 0, isActive: false },
      cn_40_other: { total: 0, isActive: false },
      cn_45: { total: 0, isActive: false },
      cn_45_other: { total: 0, isActive: false },
    },
  }),
  watch: {
    containers_form: {
      handler: function(newValue) {
        var total = 0;
        var cn_20 = 0;
        var cn_20_ot = 0;
        var cn_20_fr = 0;
        var cn_20_rf = 0;
        var cn_20_ios_tank = 0;
        var cn_20_other = 0;
        var cn_40 = 0;
        var cn_40_hc = 0;
        var cn_40_ot = 0;
        var cn_40_fr = 0;
        var cn_40_rf = 0;
        var cn_40_other = 0;
        var cn_45 = 0;
        var cn_45_other = 0;
        if (newValue) {
          newValue.forEach((data, i) => {
            total += parseInt(data.qty);
            switch (data.container_size_id) {
              case 1:
                if (data.container_isotype_id === 1) {
                  cn_20_ot += parseInt(data.qty);
                } else if (data.container_isotype_id === 2) {
                  cn_20_fr += parseInt(data.qty);
                } else if (data.container_isotype_id === 3) {
                  cn_20_rf += parseInt(data.qty);
                } else if (data.container_isotype_id === 4) {
                  cn_20_ios_tank += parseInt(data.qty);
                } else if (data.container_isotype_id === 5) {
                  cn_20_other += parseInt(data.qty);
                } else {
                  cn_20 += parseInt(data.qty);
                }
                break;

              case 2:
                if (data.container_isotype_id === 1) {
                  cn_40_ot += parseInt(data.qty);
                } else if (data.container_isotype_id === 2) {
                  cn_40_fr += parseInt(data.qty);
                } else if (data.container_isotype_id === 3) {
                  cn_40_rf += parseInt(data.qty);
                } else if (data.container_isotype_id === 4) {
                  cn_40_other += parseInt(data.qty);
                } else if (data.container_isotype_id === 5) {
                  cn_40_hc += parseInt(data.qty);
                } else {
                  cn_40 += parseInt(data.qty);
                }
                break;

              case 3:
                if (data.container_isotype_id === 1) {
                  cn_45_other += parseInt(data.qty);
                } else if (data.container_isotype_id === 2) {
                  cn_45_other += parseInt(data.qty);
                } else if (data.container_isotype_id === 3) {
                  cn_45_other += parseInt(data.qty);
                } else if (data.container_isotype_id === 4) {
                  cn_45_other += parseInt(data.qty);
                } else if (data.container_isotype_id === 5) {
                  cn_45_other += parseInt(data.qty);
                } else {
                  cn_45 += parseInt(data.qty);
                }
                break;
              default:
                return 0;
            }
          });

          this.form.ct_total = total;

          this.containers.cn_20.total = cn_20;
          this.containers.cn_20.isActive = cn_20 != 0 ? true : false;

          this.containers.cn_20_ot.total = cn_20_ot;
          this.containers.cn_20_ot.isActive = cn_20_ot != 0 ? true : false;

          this.containers.cn_20_fr.total = cn_20_fr;
          this.containers.cn_20_fr.isActive = cn_20_fr != 0 ? true : false;

          this.containers.cn_20_rf.total = cn_20_rf;
          this.containers.cn_20_rf.isActive = cn_20_rf != 0 ? true : false;

          this.containers.cn_20_ios_tank.total = cn_20_ios_tank;
          this.containers.cn_20_ios_tank.isActive =
            cn_20_ios_tank != 0 ? true : false;

          this.containers.cn_20_other.total = cn_20_other;
          this.containers.cn_20_other.isActive =
            cn_20_other != 0 ? true : false;

          this.containers.cn_40.total = cn_40;
          this.containers.cn_40.isActive = cn_40 != 0 ? true : false;

          this.containers.cn_40_hc.total = cn_40_hc;
          this.containers.cn_40_hc.isActive = cn_40_hc != 0 ? true : false;

          this.containers.cn_40_ot.total = cn_40_ot;
          this.containers.cn_40_ot.isActive = cn_40_ot != 0 ? true : false;

          this.containers.cn_40_fr.total = cn_40_fr;
          this.containers.cn_40_fr.isActive = cn_40_fr != 0 ? true : false;

          this.containers.cn_40_rf.total = cn_40_rf;
          this.containers.cn_40_rf.isActive = cn_40_rf != 0 ? true : false;

          this.containers.cn_40_other.total = cn_40_other;
          this.containers.cn_40_other.isActive =
            cn_40_other != 0 ? true : false;

          this.containers.cn_45.total = cn_45;
          this.containers.cn_45.isActive = cn_45 != 0 ? true : false;

          this.containers.cn_45_other.total = cn_45_other;
          this.containers.cn_45_other.isActive =
            cn_45_other != 0 ? true : false;
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      mode: (state) => state.order.mode,
    }),
  },
  created() {
    this.isLoading = true;
    Promise.all([this.getMasterData(), this.getMasterCarrier()])
      .then((values) => {
        this.isLoading = false;
        if (this.statusForm === "editOrder") {
          this.getOrderById(this.$route.params.id);
        }
      })
      .catch((error) => {
        console.log(error.response.data.message);
        this.isLoading = false;
      });
  },
  methods: {
    getOrderById(id) {
      this.isLoading = true;
      this.$store
        .dispatch("order/getOrderById", id)
        .then((res) => {
          this.order_id = res.data.id;
          this.form.user_id = res.data.user_id;
          this.form.user_name = res.data.user_name;
          this.form.customer_id = res.data.customer_id;
          this.form.customer_name = res.data.customer_name;
          this.form.inv_po = res.data.inv_po;
          this.form.importer_id = res.data.importer_id;
          this.form.importer_name = res.data.importer_name;
          this.form.shipping_agent_id = res.data.shipping_agent_id;
          this.form.shipping_agent_name = res.data.shipping_agent_name;
          this.form.shipping_id = res.data.shipping_id;
          this.form.shipping_name = res.data.shipping_name;
          this.form.goods_id = res.data.goods_id;
          this.form.goods_name = res.data.goods_name;
          this.form.haulage_id = res.data.haulage_id;
          this.form.haulage_name = res.data.haulage_name;
          this.form.port_discharge_id = res.data.port_discharge_id;
          this.form.port_discharge_name = res.data.port_discharge_name;
          this.form.release_port_id = res.data.release_port_id;
          this.form.release_port_name = res.data.release_port_name;
          this.form.ata = res.data.ata;
          this.date.ata = res.data.ata ? this.customDate(res.data.ata) : "";
          this.form.release_date = res.data.release_date;
          this.date.release_date = res.data.release_date
            ? this.customDate(res.data.release_date)
            : "";
          this.form.discharge_complete = res.data.discharge_complete;
          this.date.discharge_complete = res.data.discharge_complete
            ? this.customDate(res.data.discharge_complete)
            : "";
          this.form.rent = res.data.rent;
          this.date.rent = res.data.rent ? this.customDate(res.data.rent) : "";
          this.form.demurage = res.data.demurage;
          this.date.demurage = res.data.demurage
            ? this.customDate(res.data.demurage)
            : "";
          this.form.detention = res.data.detention;
          this.date.detention = res.data.detention
            ? this.customDate(res.data.detention)
            : "";
          this.form.detention_days = res.data.detention_days;
          this.form.ct_total = res.data.ct_total;
          this.form.remark = res.data.remark;
          this.form.status = res.data.status;
          this.form.container_status = res.data.container_status;
          this.form.receipt_type_id = res.data.receipt_type_id;
          this.form.receipt_type_name = res.data.receipt_type_name;
          this.form.address_id = res.data.address_id;
          this.form.address_name = res.data.address_name;
          this.form.is_cost_for_lan = res.data.is_cost_for_lan;
          this.form.is_cost_for_port = res.data.is_cost_for_port;
          this.form.is_cost_for_lifting = res.data.is_cost_for_lifting;
          this.form.is_cost_for_scale = res.data.is_cost_for_scale;
          this.form.name_cost_for_lan = res.data.name_cost_for_lan;
          this.form.name_cost_for_port = res.data.name_cost_for_port;
          this.form.name_cost_for_lifting = res.data.name_cost_for_lifting;
          this.form.name_cost_for_scale = res.data.name_cost_for_scale;
          if (res.data.order_detail_with_tender.length > 0) {
            this.containers_form = [];
            this.date_con = [];
            res.data.order_detail_with_tender.forEach((data) => {
              this.date_con.push({
                unload_load_date1: data.unload_load_date1
                  ? this.customDate(data.unload_load_date1)
                  : "",
                unload_load_date2: data.unload_load_date2
                  ? this.customDate(data.unload_load_date2)
                  : "",
              });
              this.containers_form.push({
                tender: data.tender_carrier.length,
                order_detail_id: data.order_detail_id,
                return_place_id: data.return_place_id,
                return_place_name: data.return_place_name,
                container_size_id: data.container_size_id,
                container_size_name: data.container_size_name,
                container_isotype_id: data.container_isotype_id,
                container_isotype_name: data.container_isotype_name,
                qty: data.qty,
                wh_factory_1_id: data.wh_factory_1_id,
                wh_factory_1_name: data.wh_factory_1_name,
                wh_factory_2_id: data.wh_factory_2_id,
                wh_factory_2_name: data.wh_factory_2_name,
                unload_load_date1: data.unload_load_date1,
                unload_load_time1: data.unload_load_time1,
                unload_load_date2: data.unload_load_date2,
                unload_load_time2: data.unload_load_time2,
              });
            });
          }

          this.form.overtime_card = res.data.overtime_card_name;
          this.overtime_card = res.data.overtime_card_name;
          this.form.return_card = res.data.return_card_name;
          this.return_card = res.data.return_card_name;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    // Get Master data
    async getMasterData() {
      const table = [
        "customers",
        "shipping_agent",
        "goods",
        "port_discharge",
        "cy_place",
        "container_size",
        "container_isotype",
        "wh_factory",
        "importer",
        "port_country",
      ];
      const res = await this.$store.dispatch(
        "master_data/getMasterData",
        table
      );
      this.customerList = res.data[0].customers;
      this.agentList = res.data[1].shipping_agent;
      this.goodsList = res.data[2].goods;
      this.portDischargeList = res.data[9].port_country;
      this.releasePortList = res.data[3].port_discharge;
      this.cyPlaceList = res.data[4].cy_place;
      this.containerSizeList = res.data[5].container_size;
      this.containerIsoTypeList = res.data[6].container_isotype;
      this.factoryList = res.data[7].wh_factory;
      this.importerList = res.data[8].importer;

      return res.data;
    },
    async getMasterCarrier() {
      const fields = ["is_shipping", "is_haulage"];
      const res = await this.$store.dispatch(
        "master_data/getMasterCarrier",
        fields
      );
      this.shippingList = res.data[0].is_shipping;
      this.haulageList = res.data[1].is_haulage;
      return res.data;
    },
    save() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        this.form["mode"] = this.mode;
        this.form["user_id"] = this.user.id;
        this.form["user_name"] = this.user.firstname;
        this.form["return_card"] = this.form["return_card"]
          ? this.form["return_card"]
          : "";
        this.form["overtime_card"] = this.form["overtime_card"]
          ? this.form["overtime_card"]
          : "";
        console.log('this.form["return_card"]', this.form["return_card"]);

        // Create
        if (this.statusForm !== "editOrder") {
          this.$store
            .dispatch("order/saveOrder", {
              form_data: this.form,
              form_container: this.containers_form,
            })
            .then((res) => {
              this.isLoading = false;
              if (!res.data.success) {
                for (const data in res.data.data.error) {
                  this.$swal.fire({
                    icon: "error",
                    title: "Save Failed",
                    text: res.data.data.error[data][0],
                  });
                }
              } else {
                this.$swal
                  .fire({
                    icon: "success",
                    title: "Save Order successfully!",
                    showConfirmButton: false,
                    timer: 1500,
                  })
                  .then((result) => {
                    this.$store.commit("order/SET_MODE", "import");
                    this.$router.push("/order");
                  });
              }
            })
            .catch((error) => {
              this.isLoading = false;
              for (const data in error.response.data.data.error) {
                this.$swal.fire({
                  icon: "error",
                  title: "Save Failed",
                  text: error.response.data.data.error[data][0],
                });
              }
            });
        }
        // Update
        else {
          this.$store
            .dispatch("order/updateOrder", {
              id: this.order_id,
              form_data: this.form,
              form_container: this.containers_form,
            })
            .then((res) => {
              this.isLoading = false;
              if (!res.data.success) {
                for (const data in res.data.data.error) {
                  this.$swal.fire({
                    icon: "error",
                    title: "Save Failed",
                    text: res.data.data.error[data][0],
                  });
                }
              } else {
                this.$swal
                  .fire({
                    icon: "success",
                    title: "Update Order successfully!",
                    showConfirmButton: false,
                    timer: 1500,
                  })
                  .then((result) => {
                    this.$store.commit("order/SET_MODE", "import");
                    this.$router.push("/order");
                  });
              }
            })
            .catch((error) => {
              this.isLoading = false;
              for (const data in error.response.data.data.error) {
                this.$swal.fire({
                  icon: "error",
                  title: "Save Failed",
                  text: error.response.data.data.error[data][0],
                });
              }
            });
        }
      }
    },
    updateMode(val) {
      this.mode = val;
    },
    updateData(name, val) {
      this.form[name] = val;
    },
    updateSelect(name, keyId, keyName, val) {
      if (val) {
        this.form[name + "_id"] = val[keyId];
        this.form[name + "_name"] = val[keyName];
      } else {
        this.form[name + "_id"] = "";
        this.form[name + "_name"] = "";
      }
    },
    updateDataContainer(name, i, val) {
      this.containers_form[i][name] = val ? val : "";
    },
    updateDateContainer(name, i, val) {
      this.containers_form[i][name] = val ? val : "";
      this.date_con[i][name] = val ? this.customDate(val) : "";
    },
    async onFileChange(e, type) {
      if (type == "overtime_card") {
        this.overtime_card = e.target.files[0].name;
        this.form.overtime_card = await this.convertToBase64(e.target.files[0]);
      } else if (type == "return_card") {
        this.return_card = e.target.files[0].name;
        this.form.return_card = await this.convertToBase64(e.target.files[0]);
      }
    },
    convertToBase64(file) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
          resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    },

    updateDataContainerSelect(name, i, val) {
      if (name === "container_size") {
        this.containers_form[i][name + "_id"] = val
          ? val.container_size_id
          : "";
        this.containers_form[i][name + "_name"] = val ? val.size : "";
      } else if (name === "container_isotype") {
        this.containers_form[i][name + "_id"] = val
          ? val.container_isotype_id
          : "";
        this.containers_form[i][name + "_name"] = val ? val.isotype : "";
      } else if (name === "return_place") {
        this.containers_form[i][name + "_id"] = val ? val.cy_place_id : "";
        this.containers_form[i][name + "_name"] = val ? val.cy_place_name : "";
      } else {
        this.containers_form[i][name + "_id"] = val ? val.wh_factory_id : "";
        this.containers_form[i][name + "_name"] = val
          ? val.wh_factory_name
          : "";
      }
    },
    updateDate(name, val) {
      this.form[name] = val ? val : "";
      this.date[name] = val ? this.customDate(val) : "";
    },
    customDate(val) {
      if (val) {
        let sub_year = val.substring(2, 4);
        let sub_month = val.substring(5, 7);
        let sub_day = val.substring(8, 10);
        let changeDate = sub_day + "/" + sub_month + "/" + sub_year;
        return changeDate;
      }
    },
    addContainer() {
      this.containers_form.push({
        // tender: data.tender.length,
        order_detail_id: null,
        return_place_id: "",
        return_place_name: "",
        container_size_id: "",
        container_size_name: "",
        container_isotype_id: "",
        container_isotype_name: "",
        qty: "0",
        wh_factory_1_id: "",
        wh_factory_1_name: "",
        wh_factory_2_id: "",
        wh_factory_2_name: "",
        unload_load_date1: "",
        unload_load_time1: "",
        unload_load_date2: "",
        unload_load_time2: "",
      });

      this.date_con.push({
        unload_load_date1: "",
        unload_load_date2: "",
      });
    },
    removeContainer(i) {
      this.containers_form = this.containers_form.filter(
        (item, index) => index !== i
      );
    },

    onHandleAddress(val) {
      this.form.address_id = val ? val.address_id : "";

      if (this.form.receipt_type_id === 1) {
        this.customerList.find((data) => {
          if (data.customer_id == this.form.customer_id) {
            if (this.form.address_id == 1) {
              this.form.address_name =
                (data.master_ssuance_backup_receipts1 &&
                data.master_ssuance_backup_receipts1 !== "null"
                  ? data.master_ssuance_backup_receipts1
                  : "") +
                " " +
                (data.address_receipts1 && data.address_receipts1 !== "null"
                  ? data.address_receipts1
                  : "") +
                " " +
                (data.tax_no1 && data.tax_no1 !== "null" ? data.tax_no1 : "");
            } else {
              this.form.address_name =
                (data.master_ssuance_backup_receipts2 &&
                data.master_ssuance_backup_receipts2 !== "null"
                  ? data.master_ssuance_backup_receipts2
                  : "") +
                " " +
                (data.address_receipts2 && data.address_receipts2 !== "null"
                  ? data.address_receipts2
                  : "") +
                " " +
                (data.tax_no2 && data.tax_no2 !== "null" ? data.tax_no2 : "");
            }
          }
        });
      }

      if (this.form.receipt_type_id === 2) {
        this.importerList.find((data) => {
          if (data.customer_id == this.form.importer_id) {
            if (this.form.address_id == 1) {
              this.form.address_name =
                (data.master_ssuance_backup_receipts1 &&
                data.master_ssuance_backup_receipts1 !== "null"
                  ? data.master_ssuance_backup_receipts1
                  : "") +
                " " +
                (data.address_receipts1 && data.address_receipts1 !== "null"
                  ? data.address_receipts1
                  : "") +
                " " +
                (data.tax_no1 && data.tax_no1 !== "null" ? data.tax_no1 : "");
            } else {
              this.form.address_name =
                (data.master_ssuance_backup_receipts2 &&
                data.master_ssuance_backup_receipts2 !== "null"
                  ? data.master_ssuance_backup_receipts2
                  : "") +
                " " +
                (data.address_receipts2 && data.address_receipts2 !== "null"
                  ? data.address_receipts2
                  : "") +
                " " +
                (data.tax_no2 && data.tax_no2 !== "null" ? data.tax_no2 : "");
            }
          }
        });
      }
    },

    onClickCancel() {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "No, Stay this page",
          confirmButtonText: "Yes, Cancel!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$router.push("/order");
          }
        });
    },
  },
};
</script>

<style></style>
