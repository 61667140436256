import AuthService from "../AuthService";

const orderDetail = {
  getData(page) {
    return AuthService.get(`/api/order-detail?page=${page}`)
      .then((res) => {
        return res;
      })
      // .catch((err) => {
      //   AuthService.error(err);
      // });
  },
};

export default orderDetail;
