import Service from "../../services";

const state = {};

const getters = {};

const actions = {
  save({ commit }, data) {
    return Service.lan_pass_lifting_fee.save(data).then((res) => {
      return res;
    });
  },
  update({ commit }, data) {
    return Service.lan_pass_lifting_fee.update(data).then((res) => {
      return res;
    });
  },
  delete({ commit }, id) {
    return Service.lan_pass_lifting_fee.delete(id).then((res) => {
      return res;
    });
  },

  getData({ commit }, page) {
    return Service.lan_pass_lifting_fee.getData(page).then((res) => {
      return res;
    });
  },
  getDataById({ commit }, id) {
    return Service.lan_pass_lifting_fee.getDataById(id).then((res) => {
      return res;
    });
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
