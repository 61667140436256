import Service from "../../services";

const state = {};

const getters = {};

const actions = {
  save({ commit }, data) {
    return Service.accept_tender.save(data).then((res) => {
      return res;
    });
  },
  update({ commit }, data) {
    return Service.accept_tender.update(data).then((res) => {
      return res;
    });
  },
  delete({ commit }, id) {
    return Service.accept_tender.delete(id).then((res) => {
      return res;
    });
  },
  changeStatus({ commit }, data) {
    return Service.accept_tender.changeStatus(data).then((res) => {
      return res;
    });
  },
  print({ commit }, data) {
    return Service.accept_tender.print(data).then((res) => {
      return res;
    });
  },
  getData({ commit }, mode) {
    return Service.accept_tender.getData(mode).then((res) => {
      return res;
    });
  },
  getDataById({ commit }, id) {
    return Service.accept_tender.getDataById(id).then((res) => {
      return res;
    });
  },
  search({ commit }, data) {
    return Service.accept_tender.search(data).then((res) => {
      return res;
    });
  },
  changeStatusJobAcceptTender({ commit }, data) {
    return Service.accept_tender.changeStatusJobAcceptTender(data).then((res) => {
      return res;
    });
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
