<template>
  <div class="pa-6">
    <div class="font-18 font-w-600 mb-3">Shipment Control</div>

    <v-card
      class=" pa-6"
      flat
      style="border: 1px solid #C4C4C4; border-radius: 0px;"
    >
      <!-- <v-layout>
        <v-flex xs6 class="pr-6">
          <v-autocomplete
            v-model="filter.customer_id"
            :items="customers"
            item-text="customer_name"
            item-value="customer_id"
            placeholder="Customer"
            outlined
            hide-details
            clearable
          ></v-autocomplete>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex shrink class="d-flex align-center">
          <p class="mr-3 font-weight-bold">Mode:</p>
          <v-radio-group
            class="ma-0"
            v-model="filter.mode"
            row
            @change="filter.cy_date = ''"
          >
            <v-radio label="All" value="all"></v-radio>
            <v-radio label="Import" value="Import"></v-radio>
            <v-radio label="Export" value="Export"></v-radio>
            <v-radio label="Empty" value="Empty"></v-radio>
            <v-radio label="Laden" value="Laden"></v-radio>
            <v-radio label="Drop-Load" value="Drop-Load"></v-radio>
          </v-radio-group>
        </v-flex>
      </v-layout>

      <v-layout>
        <v-flex xs6 class="pr-6">
          <v-menu
            v-model="menu_unload_load_date"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="filter.unload_load_date1"
                placeholder="Load/Unload date"
                prepend-inner-icon="mdi-calendar"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                clearable
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="filter.unload_load_date1"
              @input="menu_unload_load_date = false"
            ></v-date-picker>
          </v-menu>
        </v-flex>
        <v-flex xs6 v-if="filter.mode === 'Empty'">
          <v-menu
            v-model="menu_cy_date"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="filter.cy_date"
                placeholder="Load/Unload date"
                prepend-inner-icon="mdi-calendar"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                clearable
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="filter.cy_date"
              @input="menu_cy_date = false"
            ></v-date-picker>
          </v-menu>
        </v-flex>
      </v-layout> -->

      <v-row>
        <!-- Customer -->
        <v-col cols="3">
          <v-layout class="mb-3">
            <v-flex class="xs3 text-end font-12 pr-2 pt-3 font-weight-bold">
              Customer :
            </v-flex>
            <v-flex class="xs9">
              <v-autocomplete
                min-height="37px"
                append-icon="mdi-chevron-down"
                menu-props="offsetY"
                color="#98CA54"
                outlined
                dense
                class="gray"
                :items="customers"
                v-model="filter.customer_id"
                hide-details
                placeholder="Customer"
                item-text="customer_code"
                item-value="customer_id"
                clearable
                multiple
              >
              </v-autocomplete>
            </v-flex>
          </v-layout>
        </v-col>

        <!--  CY Date : -->
        <v-col cols="3">
          <v-layout class="align-center mb-3">
            <v-flex class="xs3 text-end font-12 pr-3 font-weight-bold">
              CY Date/<br />Relase Date :
            </v-flex>
            <v-flex class="xs4 px-0">
              <DatePicker
                placeholder="DD/MM/YY"
                hideDetails
                hideDetail
                rulse="fieldRequire"
                :model="date.cy_date_start"
                :max="filter.cy_date_end"
                @update-data="updateDate('cy_date_start', $event)"
              ></DatePicker>
            </v-flex>
            <v-flex class="xs1 text-end font-12 pr-1 font-weight-bold">
              To :
            </v-flex>
            <v-flex class=" xs4">
              <DatePicker
                placeholder="DD/MM/YY"
                hideDetails
                hideDetail
                rulse="fieldRequire"
                :model="date.cy_date_end"
                :min="filter.cy_date_start"
                @update-data="updateDate('cy_date_end', $event)"
              ></DatePicker>
            </v-flex>
          </v-layout>
        </v-col>

        <!--Closing Date -->
        <v-col cols="3">
          <v-layout class="align-center mb-3">
            <v-flex class="xs3 text-end font-12 pr-3 font-weight-bold">
              Closing Date :
            </v-flex>
            <v-flex class="xs4 px-0">
              <DatePicker
                placeholder="DD/MM/YY"
                hideDetails
                hideDetail
                rulse="fieldRequire"
                :model="date.closing_date_start"
                :max="filter.closing_date_end"
                @update-data="updateDate('closing_date_start', $event)"
              ></DatePicker>
            </v-flex>
            <v-flex class="xs1 text-end font-12 pr-1 font-weight-bold">
              To :
            </v-flex>
            <v-flex class=" xs4">
              <DatePicker
                placeholder="DD/MM/YY"
                hideDetails
                hideDetail
                rulse="fieldRequire"
                :model="date.closing_date_end"
                :min="filter.closing_date_start"
                @update-data="updateDate('closing_date_end', $event)"
              ></DatePicker>
            </v-flex>
          </v-layout>
        </v-col>
        <v-col cols="3"></v-col>
      </v-row>

      <v-row>
        <!-- Importer -->
        <v-col cols="3">
          <v-layout class="align-center mb-3">
            <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
              Importer <br />
              /Exporter :
            </v-flex>
            <v-flex class="xs9">
              <v-autocomplete
                height="37px"
                append-icon="mdi-chevron-down"
                menu-props="offsetY"
                color="#98CA54"
                outlined
                dense
                class="gray"
                :items="importerList"
                v-model="filter.importer_exporter_id"
                hide-details
                placeholder=" Importer/Exporter"
                item-text="customer_code"
                item-value="customer_id"
                clearable
                multiple
              >
              </v-autocomplete>
            </v-flex>
          </v-layout>
        </v-col>

        <!-- Load  Unload Date -->
        <v-col cols="3">
          <v-layout class="align-center mb-3">
            <v-flex class="xs3 text-end font-12 pr-3 font-weight-bold">
              Load/ <br />
              Unload Date :
            </v-flex>
            <v-flex class="xs4 px-0">
              <DatePicker
                placeholder="DD/MM/YY"
                hideDetails
                hideDetail
                rulse="fieldRequire"
                :model="date.unload_date_start"
                :max="filter.unload_date_end"
                @update-data="updateDate('unload_date_start', $event)"
              ></DatePicker>
            </v-flex>
            <v-flex class="xs1 text-end font-12 pr-1 font-weight-bold">
              To :
            </v-flex>
            <v-flex class=" xs4">
              <DatePicker
                placeholder="DD/MM/YY"
                hideDetails
                hideDetail
                rulse="fieldRequire"
                :model="date.unload_date_end"
                :min="filter.unload_date_start"
                @update-data="updateDate('unload_date_end', $event)"
              ></DatePicker>
            </v-flex>
          </v-layout>
        </v-col>

        <!-- Vendor -->
        <v-col cols="3">
          <v-layout class="align-center mb-3">
            <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
              Vendor :
            </v-flex>
            <v-flex class="xs9">
              <v-autocomplete
                height="37px"
                append-icon="mdi-chevron-down"
                menu-props="offsetY"
                color="#98CA54"
                outlined
                dense
                class="gray"
                :items="carriers"
                v-model="filter.carrier_id"
                hide-details
                placeholder="Carrier"
                item-text="name"
                item-value="carrier_id"
                clearable
              >
              </v-autocomplete>
            </v-flex>
          </v-layout>
        </v-col>

        <!-- Driver : -->
        <v-col cols="3">
          <v-layout class="align-center mb-3">
            <v-flex class="xs3 text-end font-12 pr-2 font-weight-bold">
              Driver :
            </v-flex>
            <v-flex class="xs9">
              <v-autocomplete
                height="37px"
                append-icon="mdi-chevron-down"
                menu-props="offsetY"
                color="#98CA54"
                outlined
                dense
                class="gray"
                :items="drivers"
                v-model="filter.driver_id"
                hide-details
                placeholder="Driver"
                item-text="full_name"
                item-value="driver_id"
                clearable
              >
              </v-autocomplete>
            </v-flex>
          </v-layout>
        </v-col>
      </v-row>
    </v-card>

    <v-card
      width="100%"
      outlined
      class="mt-4"
      flat
      style="border: 1px solid #C4C4C4; border-radius: 0px;"
    >
      <v-layout justify-space-between>
        <v-flex shrink>
          <v-layout class="align-center text-end pl-6">
            <v-flex shrink class=" font-weight-bold">Mode : </v-flex>
            <v-flex shrink pl-6>
              <v-layout>
                <v-flex shrink mr-4>
                  <v-checkbox
                    color="#338A68"
                    v-model="is_import"
                    label="Import"
                  ></v-checkbox>
                </v-flex>
                <v-flex shrink mr-4>
                  <v-checkbox
                    color="#338A68"
                    v-model="is_export"
                    label="Export"
                  ></v-checkbox>
                </v-flex>
                <v-flex shrink mr-4>
                  <v-checkbox
                    color="#338A68"
                    v-model="is_empty"
                    label="Empty"
                  ></v-checkbox>
                </v-flex>
                <v-flex shrink mr-4>
                  <v-checkbox
                    color="#338A68"
                    v-model="is_laden"
                    label="Laden"
                  ></v-checkbox>
                </v-flex>
                <v-flex shrink mr-4>
                  <v-checkbox
                    color="#338A68"
                    v-model="is_drop_load"
                    label="Drop Load"
                  ></v-checkbox>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex shrink class="text-end pr-6">
          <v-btn
            depressed
            color="#F7D25D"
            class="my-4 font-weight-bold"
            @click="onSearch(1)"
          >
            <v-icon class="mr-3">mdi-magnify</v-icon>
            <span class="text-capitalize">Search</span>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card>

    <div>
      <v-btn
        outlined
        depressed
        color="#338A68"
        dark
        class="shrink my-4 font-weight-bold mr-3"
        @click="exportExcel()"
      >
        <img
          width="20"
          src="../../../assets/images/menu-icons/excel.png"
          alt=""
        />
        <span class="ml-2 shrink text-capitalize">Export Excel </span>
      </v-btn>
    </div>

    <v-layout
      justify-center
      wrap
      style="box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);"
    >
      <v-flex xs12 class="pa-6">
        <apexchart
          v-if="series"
          ref="chart"
          width="100%"
          height="500"
          type="bar"
          :options="options"
          :series="series"
        ></apexchart>
      </v-flex>
    </v-layout>

    <div class="mt-6">
      <v-layout justify-center class="mt-3">
        <v-flex xs12>
          <div style="width: 100%" class="table-order">
            <v-data-table :headers="headers" :items="desserts" :search="search">
              <template v-slot:item.closing_date="{ item }">
                <div>
                  {{ item.closing_date | moment("DD/MM/YYYY") }}
                </div>
              </template>
              <!-- <template v-slot:item.return_place_name="{ item }">
                <div v-if="item.mode == 'Import'">
                  {{ item.return_place_name }}
                </div>
                <div v-else>
                  {{ item.return_place_name_order }}
                </div>
              </template> -->
            </v-data-table>
          </div>
        </v-flex>
      </v-layout>
    </div>

    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="false"
    />
  </div>
</template>

<script>
import ControlTower from "../../../services/api/ControlTower.Service";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import DatePicker from "../../../components/picker/DatePicker.vue";
import XLSX from "xlsx"; // import xlsx;
import moment from "moment";

export default {
  components: {
    Loading,
    DatePicker,
  },
  data() {
    return {
      menu_unload_load_date: false,
      menu_cy_date: false,
      is_import: false,
      is_export: false,
      is_empty: false,
      is_laden: false,
      is_drop_load: false,

      isLoading: false,
      options: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          events: {
            dataPointSelection: function(event, chartContext, config) {
              this.showDetail(
                config.w.config.xaxis.categories[config.dataPointIndex]
              );
            }.bind(this),
          },
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "60%",
            dataLabels: {
              position: "top",
            },
          },
        },
        colors: [
          "#338A68",
          "#59CB33",
          "#55BAA7",
          "#62C4E3",
          "#6296E3",
          "#6C62E3",
          "#D8D8D8",
        ],
        legend: {
          show: true,
          position: "top",
        },
        title: {
          text: "Shipment Control",
          align: "center",
          style: {
            fontSize: "20px",
            fontWeight: "bold",
            fontFamily: undefined,
            color: "#828282",
          },
        },
        xaxis: {
          categories: [
            "Order",
            "Tender",
            "Assign Driver",
            "Receive Container",
            "Load/Unload Container",
            "Return Container",
          ],
        },
      },

      series: [],

      search: "",
      headers: [
        {
          text: "No.",
          align: "center",
          sortable: false,
          value: "no",
        },
        {
          text: "Job No.",
          align: "start",
          sortable: false,
          value: "order_id",
          width: "120px",
        },
        {
          text: "Customer",
          align: "start",
          sortable: false,
          value: "customer_name",
        },
        {
          text: "Import/Export",
          align: "start",
          sortable: false,
          value: "import_exporter_name",
          width: "200px",
        },
        {
          text: "inv. p/o",
          align: "start",
          sortable: false,
          value: "inv_po",
          width: "100px",
        },
        {
          text: "Booking No",
          align: "start",
          sortable: false,
          value: "booking_no",
        },

        {
          text: "Qty",
          align: "center",
          sortable: false,
          value: "qty",
        },
        {
          text: "Size",
          align: "center",
          sortable: false,
          value: "container_size_name",
        },
        {
          text: "Type",
          align: "center",
          sortable: false,
          value: "container_isotype_name",
          width: "200px",
        },
        {
          text: "Vendor",
          align: "start",
          sortable: false,
          value: "carrier_name",
          width: "200px",
        },
        {
          text: "Driver",
          align: "start",
          sortable: false,
          value: "driver_name",
          width: "150px",
        },
        {
          text: "ทะเบียนรถ",
          align: "center",
          sortable: false,
          value: "truck_license_id",
          width: "150px",
        },
        {
          text: "CY Place/Release Port",
          align: "start",
          sortable: false,
          value: "cy_place_name",
          width: "200px",
        },
        {
          text: "W/H Factory 1",
          align: "start",
          sortable: false,
          value: "wh_factory_name",
          width: "150px",
        },
        {
          text: "Return Place",
          align: "start",
          sortable: false,
          value: "return_place_name",
          width: "150px",
        },
        {
          text: "Closing Date",
          align: "center",
          sortable: false,
          value: "closing_date",
        },
        {
          text: "Closing Time",
          align: "center",
          sortable: false,
          value: "closing_time",
        },
      ],
      desserts: [],
      table_title: "",
      customers: [],
      importerList: [],
      carriers: [],
      drivers: [],
      filter: {
        mode: [],
        customer_id: [],
        cy_date_start: "",
        cy_date_end: "",
        closing_date_start: "",
        closing_date_end: "",
        importer_exporter_id: [],
        unload_date_start: "",
        unload_date_end: "",
        carrier_id: "",
        driver_id: "",
      },
      date: {
        unload_date_start: "",
        unload_date_end: "",
        cy_date_start: "",
        cy_date_end: "",
        closing_date_start: "",
        closing_date_end: "",
      },
      dataExcel: [],
    };
  },
  watch: {
    is_import: {
      handler: function(val) {
        if (val) {
          if (this.filter.mode.indexOf("Import") === -1) {
            this.filter.mode.push("Import");
          }
        } else {
          this.filter.mode.splice(this.filter.mode.indexOf("Import"), 1);
        }
      },
    },
    is_export: {
      handler: function(val) {
        if (val) {
          if (this.filter.mode.indexOf("Export") === -1) {
            this.filter.mode.push("Export");
          }
        } else {
          this.filter.mode.splice(this.filter.mode.indexOf("Export"), 1);
        }
      },
    },
    is_empty: {
      handler: function(val) {
        if (val) {
          if (this.filter.mode.indexOf("Empty") === -1) {
            this.filter.mode.push("Empty");
          }
        } else {
          this.filter.mode.splice(this.filter.mode.indexOf("Empty"), 1);
        }
      },
    },
    is_laden: {
      handler: function(val) {
        if (val) {
          if (this.filter.mode.indexOf("Laden") === -1) {
            this.filter.mode.push("Laden");
          }
        } else {
          this.filter.mode.splice(this.filter.mode.indexOf("Laden"), 1);
        }
      },
    },
    is_drop_load: {
      handler: function(val) {
        if (val) {
          if (this.filter.mode.indexOf("Drop Load") === -1) {
            this.filter.mode.push("Drop Load");
          }
        } else {
          this.filter.mode.splice(this.filter.mode.indexOf("Drop Load"), 1);
        }
      },
    },
  },
  created() {
    this.isLoading = true;
    Promise.all([
      this.getShipmentControlFilter(),
      this.getShipmentControl(),
      this.getMasterData(),
    ]).then((res) => {
      if (res.length > 0) {
        this.customers = res[0].customers;
        this.carriers = res[0].carriers;
        this.drivers = res[0].drivers;
        this.series = res[1].data.graph.datasets;
        this.$refs.chart.updateSeries(this.series);
      }
      this.isLoading = false;
    });
  },
  methods: {
    async getShipmentControlFilter() {
      const res = await ControlTower.getShipmentControlFilter();
      return res;
    },
    async getShipmentControl() {
      const res = await ControlTower.getShipmentControl(this.filter);
      return res;
    },
    async getMasterData() {
      const table = ["importer"];
      const res = await this.$store.dispatch(
        "master_data/getMasterData",
        table
      );
      this.importerList = res.data[0].importer;
      return res.data;
    },
    updateDate(name, val) {
      this.filter[name] = val ? val : "";
      this.date[name] = val ? this.customDate(val) : "";
    },
    customDate(val) {
      let sub_year = val.substring(0, 4);
      let sub_month = val.substring(5, 7);
      let sub_day = val.substring(8, 10);
      let changeDate = sub_day + "/" + sub_month + "/" + sub_year;
      return changeDate;
    },
    onSearch() {
      this.isLoading = true;
      this.dataExcel = [];
      this.desserts = [];
      this.getShipmentControl().then((res) => {
        this.series = res.data.graph.datasets;
        this.$refs.chart.updateSeries(this.series);
        this.isLoading = false;
      });
    },
    showDetail(seriesIndex) {
      this.desserts = [];
      this.dataExcel = [];
      this.table_title = seriesIndex;
      this.isLoading = true;
      this.filter["type"] = seriesIndex;

      ControlTower.getDataShipmentControl(this.filter).then((res) => {
        this.desserts = res.data;
        this.setDataExcel(res.data);

        this.desserts = this.desserts.map((item, i) => {
          return {
            no: i + 1,
            order_id: item.order_id ? item.order_id : "-",
            customer_name: item.customer_name ? item.customer_name : "-",
            import_exporter_name: item.importer_name
              ? item.importer_name
              : item.exporter_name,
            inv_po: item.inv_po ? item.inv_po : "-",
            booking_no: item.booking_no ? item.booking_no : "-",
            qty:
              seriesIndex !== "Order" && seriesIndex !== "Tender"
                ? 1
                : item.qty
                ? item.qty
                : "-",
            container_size_name: item.container_size_name
              ? item.container_size_name
              : "-",
            container_isotype_name: item.container_isotype_name
              ? item.container_isotype_name
              : "-",
            carrier_name: item.carrier_name ? item.carrier_name : "-",
            driver_name: item.driver_name ? item.driver_name : "-",
            truck_license_id: item.truck_license_id
              ? item.truck_license_id
              : "-",
            cy_place_name:
              item.mode == "Import"
                ? item.release_port_name
                  ? item.release_port_name
                  : "-"
                : item.cy_place_name
                ? item.cy_place_name
                : "-",
            wh_factory_name: item.wh_factory_1_name
              ? item.wh_factory_1_name
              : "-",
            return_place_name:
              item.mode == "Import"
                ? item.return_place_name
                  ? item.return_place_name
                  : "-"
                : item.return_place_name_order
                ? item.return_place_name_order
                : "-",
            closing_date: item.closing_date ? item.closing_date : "-",
            closing_time: item.closing_time ? item.closing_time : "-",
          };
        });
        this.isLoading = false;
      });
    },
    exportExcel() {
      if (this.dataExcel.length === 0) {
        this.$swal.fire({
          title: "Something went wrong!",
          text: "Please, Choose Data",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      } else {
        this.$swal
          .fire({
            title: "Are you sure?",
            text: `Do you want to export Order to Excel?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Export!",
          })
          .then((result) => {
            if (result.isConfirmed) {
              const dataWS = XLSX.utils.json_to_sheet(this.dataExcel);
              const wb = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(wb, dataWS);
              const fileName = `ShipmentControl_${moment(new Date()).format(
                "DD_MM_YYYY_h_mm_ss"
              )}.xlsx`;
              XLSX.writeFile(wb, fileName);
            }
          });
      }
    },
    setDataExcel(items) {
      items.forEach((data) => {
        this.dataExcel.push({
          "Job No.": data.order_id,
          Customer: data.customer_name,
          "Importer/Exporter": data.importer_name
            ? data.importer_name
            : data.exporter_name,
          "Inv./PO.": data.inv_po,
          "Booking No.": data.booking_no,
          Qty: data.qty,
          "Container Size": data.container_size_name,
          "Container Type": data.container_isotype_name,
          Vendor: data.carrier_name,
          Driver: data.driver_name,
          ทะเบียนรถ: data.truck_license_id,
          "CY Place/Release Port": data.cy_place_name,
          "W/H Factory 1": data.wh_factory_1_name,
          "Return Place": data.return_place_name,
          "Closing Date": data.closing_date,
          "Closing Time": data.closing_time,
        });
      });
    },
  },
};
</script>

<style>
.v-input__append-inner,
.v-input__prepend-inner {
  margin-top: 4px !important;
}
span.apexcharts-legend-marker {
  width: 35px !important;
  height: 12px !important;
}
.apexcharts-legend-series {
  margin: 2px 10px !important;
}
</style>
